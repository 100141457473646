import sort from '@/common/sort'

export default {
    //RETORNA OS MEMBROS DA FAMILIA
    familiesMembers: (state) => {
        const user = state.user || {}
        const family = state.families || {}

        const members = family.members ? family.members : user.family ? user.family.members : []

        return members
    },

    families(state) {
        return state.families
    },

    activeMembers: (state, getters) => {
        return getters.familiesMembers.filter((member) => member.status === 'active')
    },
}
