<template>
    <div class="settings view-container">
        <div class="view-content">
            <ActionBar title="Configurações" :isSettings="true" />

            <div class="inner-container flex column">
                <div class="flex flex-between tab">
                    <label
                        class="tab-label"
                        @click="changeTab('profile')"
                        :class="[{ active: isActive('perfil') }, { noevents: isExpired }]"
                        data-cy="change-tab-profile"
                        >Meu Perfil
                    </label>

                    <label
                        class="tab-label"
                        @click="changeTab('plano')"
                        :class="[{ active: isActive('plano') }]"
                        data-cy="change-tab-plano"
                        >Plano de assinatura
                    </label>

                    <label
                        class="tab-label"
                        @click="changeTab('category')"
                        :class="[{ active: isActive('categorias') }, { noevents: isExpired }]"
                        data-cy="change-tab-category"
                        >Categorias
                    </label>

                    <label
                        class="tab-label"
                        @click="changeTab('preferences')"
                        :class="[{ active: isActive('preferencias') }]"
                        data-cy="change-tab-preferences"
                        >Preferências
                    </label>

                    <label
                        class="tab-label"
                        @click="changeTab('family-account')"
                        :class="[{ active: isActive('family-account') }]"
                        data-cy="change-tab-family"
                        >Conta Família
                    </label>

                    <label
                        class="tab-label"
                        @click="changeTab('bankaccess')"
                        :class="[
                            { active: isActive('bankaccess') },
                            { noevents: isExpired },
                            { alert: getBelvoRelatedPluggyConnectors().length > 0 },
                        ]"
                        data-cy="change-tab-bankaccess"
                        >Contas Conectadas
                    </label>
                </div>

                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import ActionBar from '../components/ActionBar'
import ProfileConfig from './settings/ProfileConfig'
import CategoryConfig from './settings/CategoryConfig'
import PreferencesConfig from './settings/PreferencesConfig'
import PlanDetails from './settings/PlanDetails'
import BankAccessConfig from './settings/BankAccessConfig'
import router from '../router/router'
import { mapGetters } from 'vuex'

export default {
    name: 'Settings',
    components: {
        ActionBar,
        ProfileConfig,
        CategoryConfig,
        PreferencesConfig,
        BankAccessConfig,
        PlanDetails,
    },

    data() {
        return {
            tab: this.$route.name,
        }
    },

    computed: {
        ...mapGetters(['isExpired', 'getBelvoRelatedPluggyConnectors']),
    },

    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler(newRoute) {
                this.tab = newRoute.name
            },
        },
    },

    methods: {
        changeTab(tab) {
            switch (tab) {
                case 'profile':
                    router.push({ name: 'perfil' })
                    break
                case 'plano':
                    router.push({ name: 'plano' })
                    break
                case 'category':
                    router.push({ name: 'categorias' })
                    break
                case 'preferences':
                    router.push({ name: 'preferencias' })
                    break
                case 'bankaccess':
                    router.push({ name: 'bankaccess' })
                    break
                case 'family-account':
                    router.push({ name: 'family-account' })
                    break
                default:
                    router.push({ name: 'perfil' })
                    break
            }
        },

        isActive(tab) {
            return this.tab === tab
        },
    },
}
</script>

<style lang="scss" scoped>
.view-content {
    .inner-container {
        padding: 16px 0 30px 0;
    }
}

.tab {
    color: #ffffff;
    width: 780px;

    .tab-label {
        color: $grey;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 5px;
        cursor: pointer;

        &.active {
            border-bottom: 2px solid $btn-blue;
            color: $btn-blue;
            font-weight: 500;
            pointer-events: none;
        }

        &.noevents {
            pointer-events: none;
        }
    }
}

.flex {
    display: flex;
}

.flex-around {
    justify-content: space-around;
}

.flex-between {
    justify-content: space-between;
}

.column {
    flex-direction: column;
}

.grow-1 {
    flex-grow: 1;
}

.flex-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}
.settings .tab-label {
    position: relative;
}
.settings label:not(.active).tab-label.alert:after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    top: -5px;
    right: -6px;
    background-color: red;
    border-radius: 100%;
    z-index: 1000;
}
</style>
