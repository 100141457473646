<template>
    <div class="range-input">
        <div class="label">
            <h2>{{ label }}</h2>
            <span :style="color">{{ percent + '%' }}</span>
        </div>

        <div class="input-wrapper">
            <div>
                <input
                    type="range"
                    min="0"
                    step="1"
                    :style="color"
                    :max="max"
                    v-model="percentage"
                    @change="onChange"
                />

                <div class="empty-bar" :style="calcPercentage"></div>
            </div>

            <input
                class="spend-input"
                type="text"
                v-money="money"
                v-model.number="value"
                v-model.number.lazy="value"
                @blur="updatePercentage"
            />
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import money from 'v-money'
import util from '@/common/util'

Vue.use(money, { precision: 4 })
export default {
    name: 'RangeInput',
    props: {
        budgetID: String, // ID do budget da categoria
        maxValue: Number, // valor máximo do range input
        currentIncome: Number, // valor máximo reservado para categoria em relação ao valor máximo reservado para o grupo
        label: String,
        pValue: Number, // porcentagem
        hasError: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            value: 0,
            max: 0,
            percentage: 0,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: util.returnCurrencyPrefix().prefix + ' ',
                precision: 2,
                masked: false,
            },
        }
    },
    methods: {
        onChange(event) {
            let newCategoryBudget = Number(event.target.value)
            this.percentage = Number(event.target.value)

            this.changeEvent(newCategoryBudget)
        },

        updatePercentage(event) {
            let value = util.sanitizeMoney(event.target.value)

            this.percentage = Number((value / this.currentIncome) * 100)
            this.changeEvent(this.percentage)
        },

        changeEvent(value) {
            this.$emit('change', {
                _id: this.budgetID,
                budgetValue: value,
            })
        },
    },

    computed: {
        calcPercentage() {
            return {
                '--percentage': this.percentage >= this.max ? 0 + '%' : 100 - (this.percentage / this.max) * 100 + '%',
            }
        },

        color() {
            return {
                '--color': !this.hasError ? '#028FFF' : '#EF8A97',
            }
        },

        percent() {
            if (typeof this.percentage === 'number') return this.percentage.toFixed(0)
            return this.percentage
        },
    },

    watch: {
        maxValue() {
            this.max = this.maxValue
        },

        pValue() {
            this.percentage = this.pValue
        },

        percentage(p) {
            this.value = (this.currentIncome * p * 0.01).toFixed(2)
        },
    },

    mounted() {
        this.value = (this.currentIncome * this.pValue * 0.01).toFixed(2)
        this.max = this.maxValue
        this.percentage = this.pValue
        this.relPercentage = this.relativePValue
    },
}
</script>

<style lang="scss" scoped>
.range-input {
    width: 100%;
    height: 10px;
    margin-bottom: 20px;

    .input-wrapper {
        display: flex;
        justify-content: space-between;

        & > div {
            width: 100%;
            position: relative;
            margin-right: 40px;

            .empty-bar {
                overflow: hidden;
                height: 11px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                background-color: #d9d9d9;
                position: absolute;
                right: 0;
                bottom: 26px;
                width: var(--percentage);
            }
        }

        .spend-input {
            text-align: right;
            box-sizing: border-box;
            border: 1px solid #bebebe;
            border-radius: 4px;
            padding: 10px;
            width: 140px;
            color: #696969;
            font-size: 18px;
            position: relative;
            bottom: 25px;
        }
    }

    .label {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 185px);
        padding-bottom: 5px;

        h2 {
            font-size: 16px;
        }

        span {
            font-size: 14px;
            font-weight: 600;
            color: var(--color);
        }
    }
}

input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
    text-align: right;
    border: none;
}

input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    position: relative;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
}

input[type='range']:focus {
    outline: none;
}

input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type='range']::-webkit-slider-thumb {
    height: 17.37px;
    width: 17.35px;
    margin-top: -4px;
    border-radius: 9.99px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.17);
    cursor: pointer;
    background-color: var(--color);
    position: relative;
    z-index: 1;
}

input[type='range']::-moz-range-thumb {
    height: 17.37px;
    width: 17.35px;
    margin-top: -4px;
    border-radius: 9.99px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.17);
    cursor: pointer;
    background-color: var(--color);
}

input[type='range']::-ms-thumb {
    height: 17.37px;
    width: 17.35px;
    margin-top: -4px;
    border-radius: 9.99px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.17);
    cursor: pointer;
    background-color: var(--color);
}

input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--color);
}

input[type='range']::-moz-range-track {
    height: 10px;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--color);
}

input[type='range']::-ms-track {
    height: 10px;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--color);
}
</style>
