import { render, staticRenderFns } from "./CategoryBudgetList.vue?vue&type=template&id=561e0dd8&scoped=true&"
import script from "./CategoryBudgetList.vue?vue&type=script&lang=js&"
export * from "./CategoryBudgetList.vue?vue&type=script&lang=js&"
import style0 from "./CategoryBudgetList.vue?vue&type=style&index=0&id=561e0dd8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "561e0dd8",
  null
  
)

export default component.exports