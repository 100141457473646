<template>
    <form @submit.prevent="checkForm" class="form flex column">
        <div class="flex form-el">
            <stacked-input
                class="space"
                :font="14"
                :errorFontSize="10"
                :errorValidation="error[0]"
                :required="true"
                label="Nome do cartão"
                v-model="paymentCCard.name"
                name="name"
            />

            <stacked-select
                class="grow-1"
                label="Bandeira"
                :font="14"
                :errorValidation="error[1]"
                customPositioning="right: 15px; bottom: 40px;"
                :errorFontSize="10"
                :notTransaction="true"
                :required="true"
                :model="flag"
                :previousSelected="previousFlag"
                @change="updateFlag"
            />
        </div>

        <div class="flex space-between form-el">
            <stacked-select
                class="half-width space"
                label="Fatura fecha dia"
                :errorValidation="error[3]"
                :customPositioning="customCSS"
                :errorFontSize="10"
                :required="true"
                :notTransaction="true"
                :model="31"
                :previousSelected="previousInvoice"
                :disabled="editData.externalId !== undefined"
                @change="updateInvoice"
            />

            <stacked-select
                class="half-width"
                label="Pago dia"
                :errorValidation="error[4]"
                :customPositioning="customCSS"
                :errorFontSize="10"
                :required="true"
                :notTransaction="true"
                :model="31"
                :previousSelected="previousPaid"
                @change="updatePaid"
            />
        </div>

        <stacked-input
            label="Limite"
            :errorValidation="error[5]"
            errorMsg="VALOR INVÁLIDO"
            :errorFontSize="10"
            :required="true"
            :money="money"
            v-model="paymentCCard.limit"
        />

        <div>
            <basic-button
                v-if="!editData._id"
                label="Cadastrar Forma de pagamento"
                :loading="disabled"
                type="submit"
                :width="320"
                data-cy="submit-form-payment"
            />

            <basic-button
                v-if="editData._id"
                label="Salvar Alterações"
                :loading="disabled"
                type="submit"
                :width="320"
            />

            <basic-button
                v-if="editData._id && !editData.externalId"
                label="Excluir"
                :reverse="true"
                :width="320"
                @click.native.prevent="deletePayment"
            />
        </div>
    </form>
</template>

<script>
import StackedInput from '../../components/inputs/StackedInput'
import StackedSelect from '../../components/inputs/StackedSelect'
import util from '../../common/util'
import _request from '@/common/request'
import { mapState } from 'vuex'
import { mask } from 'vue-the-mask'
import BasicButton from '../buttons/BasicButton.vue'

export default {
    name: 'FormPaymentsCCard',
    components: {
        StackedInput,
        StackedSelect,
        BasicButton,
    },
    directives: {
        mask,
    },
    props: {
        editData: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            flag: ['Visa', 'Mastercard', 'Hipercard', 'Elo', 'American Express'],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: util.returnCurrencyPrefix().prefix + ' ',
                precision: 2,
                masked: false,
            },
            paymentCCard: { flag: null },
            error: [],
            previousInvoice: 0,
            previousPaid: 0,
            previousFlag: '',
            customCSS: 'right: 15px; bottom: 32px;',
        }
    },
    methods: {
        updateFlag(value) {
            this.paymentCCard.flag = value
        },

        updateInvoice(value) {
            this.paymentCCard.invoiceCloseOn = value
        },

        updatePaid(value) {
            this.paymentCCard.paid = value
        },

        createPayment() {
            this.paymentCCard.type = 'credit'
            let _data = Object.assign({}, this.paymentCCard)

            _data.limit = util.sanitizeMoney(_data.limit)
            this.$store.commit('DISABLED')

            _request
                .post({ collection: 'payments', data: _data })
                .then((resp) => {
                    _request.fetch([
                        { collection: 'invoices', queryParams: this._date_selected },
                        { collection: 'transactions', queryParams: this._date_selected },
                    ])
                    this.closeModal()
                })
                .finally(() => this.$store.commit('DISABLED'))
        },

        editPayment() {
            let _data = { ...this.paymentCCard }

            _data.limit = util.sanitizeMoney(_data.limit)
            this.$store.commit('DISABLED')

            _request
                .patch({ collection: 'payments', data: _data, _id: _data._id })
                .then((resp) => {
                    if (_data.paid || _data.invoiceCloseOn) {
                        _request.fetch([
                            { collection: 'invoices', queryParams: this._date_selected },
                            { collection: 'transactions', queryParams: this._date_selected },
                        ])
                    }

                    this.closeModal()
                })
                .finally(() => this.$store.commit('DISABLED'))
        },

        checkForm: function (e) {
            let error = Array(6).fill(false)
            let errorCount = 0

            if (!this.paymentCCard.name) {
                error[0] = true
                errorCount++
            }
            if (!this.paymentCCard.flag) {
                error[1] = true
                errorCount++
            }
            if (!this.paymentCCard.invoiceCloseOn) {
                error[2] = true
                errorCount++
            }
            if (!this.paymentCCard.paid) {
                error[3] = true
                errorCount++
            }
            if (
                this.paymentCCard.limit ===
                util.returnCurrencyPrefix().prefix + '0' + util.returnCurrencyPrefix().decimal + '00'
            ) {
                error[4] = true
                errorCount++
            }

            this.error = error

            if (!errorCount && !this.editData._id) this.createPayment()
            else if (!errorCount && this.editData._id) this.editPayment()
        },

        closeModal() {
            this.$emit('closePaymentModalEvent')
        },

        deletePayment(e) {
            e.preventDefault()

            let payload = {
                data: {
                    _id: this.editData._id,
                    collection: 'payments',
                },
                text: 'Tem certeza que deseja excluir este pagamento',
                strongText: ' (Irá perder todas as despesas e pagamentos deste cartão)',
            }

            this.$root.$emit('confirmationDelete', payload)
        },
    },

    computed: {
        ...mapState(['disabled', '_date_selected']),
    },

    mounted() {
        if (this.editData.type) {
            this.editData.limit = this.editData.limit.toFixed(2)
            this.paymentCCard = this.editData
            this.previousFlag = this.editData.flag
            this.previousInvoice = this.editData.invoiceCloseOn
            this.previousPaid = this.editData.paid
        }
    },
}
</script>

<style lang="scss" scoped>
.form {
    margin: 0 25px 25px 25px;
    position: relative;
    height: 100%;
    width: 320px;
}

.form-el {
    margin-bottom: 10px;
}

.basic-button {
    margin-top: 10px;
    width: 100%;

    &:first-child {
        margin-top: 20px;
    }
}

.space {
    margin-right: 10px;
}

.half-width {
    width: 50%;
}

.grow-1 {
    flex-grow: 1;
}
.grow-2 {
    flex-grow: 2;
}

.column {
    flex-direction: column;
}

.flex {
    display: flex;
}

.space-between {
    justify-content: space-between;
}
</style>
