import vuetify from '@/plugins/vuetify'
import moment from 'moment'
import 'moment/locale/pt-br'
import SimpleVueValidation from 'simple-vue-validator'
import 'sweetalert2/dist/sweetalert2.min.css'
import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import VueTheMask from 'vue-the-mask'
import App from './App.vue'
import router from './router/router'
import store from './store'
import authService from './services/auth.service'
import util from './common/util'
import variables from './variables/index'
import { initializeFirebaseApp } from './services/firebase.service'

const firebase = initializeFirebaseApp()

Vue.prototype.$variables = variables
Vue.prototype.$firebase = firebase

Vue.use(require('vue-moment'), {
    moment,
})

Vue.use(VueTheMask)

Vue.use(SimpleVueValidation)

Vue.config.productionTip = false

Vue.filter('currency', function (value) {
    let formatter = new Intl.NumberFormat(util.returnCurrencyPrefix().locale, {
        style: 'currency',
        currency: authService.getOnLocalStorage('currency'),
    })
    return formatter.format(value)
})

Vue.filter('formatnum', function (value) {
    let formatter = new Intl.NumberFormat(util.returnCurrencyPrefix().locale, {})
    return formatter.format(value)
})

Vue.filter('longdate', function (date) {
    let theDate = new Date(date)
    let weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
    let weekDay = theDate.getDay()
    return weekDays[weekDay] + ', dia ' + theDate.getDate()
})

Vue.directive('focus', {
    // Quando o elemento vinculado é inserido no DOM...
    inserted: function (el, binding) {
        if (binding.value) el.focus()
    },
})

Vue.use(VueSweetalert2)

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app')
