import service from '../services/api.service'
import auth from '../services/auth.service'
import helper from './helpers/actions.helper'

export default {
    FETCH_DATA({ commit }, { payload }) {
        return new Promise((resolve, reject) => {
            service
                .get(payload.url)
                .then((response) => {
                    commit('SET_DATA', {
                        data: response.data.items || response.data,
                        attr: payload.attr,
                    })

                    resolve(response.data)
                })
                .catch((err) => {
                    commit('ERRO')
                    reject(err)
                    setTimeout(() => commit('UNERR'), 5000)
                })
        })
    },

    GET_BY_ID({ commit }, { payload }) {
        return new Promise((resolve, reject) => {
            service
                .get(payload.url)
                .then((response) => {
                    if (payload.attr === 'users') {
                        commit('SET_DATA', {
                            data: response.data,
                            attr: 'user',
                        })
                    } else {
                        commit('ATT_SETED_DATA', {
                            data: response.data,
                            attr: payload.attr,
                        })
                    }

                    resolve(response.data)
                })
                .catch((err) => {
                    commit('ERRO')
                    reject(err)
                    setTimeout(() => commit('UNERR'), 5000)
                })
        })
    },

    POST_DATA({ commit }, { payload }) {
        return new Promise((resolve, reject) => {
            let data = payload.data
            if (!payload.noUserId) data.user = auth.getOnLocalStorage('id')
            const mutation = payload.mutation || 'PUSH_DATA'

            service
                .post(payload.url, data)
                .then((response) => {
                    commit(mutation, {
                        data: response.data,
                        attr: payload.attr,
                    })

                    resolve(response.data)
                })
                .catch((err) => {
                    commit('ERRO')
                    reject(err)
                    setTimeout(() => commit('UNERR'), 5000)
                })
        })
    },

    POST_DATA_ELEMENT({ commit }, { payload }) {
        return new Promise((resolve, reject) => {
            const { mutation, mutation_params } = payload

            service
                .post(payload.url)
                .then((response) => {
                    commit(mutation, {
                        params: mutation_params,
                        attr: payload.attr,
                    })

                    resolve(response.data)
                })
                .catch((err) => {
                    commit('ERRO')
                    reject(err)
                    setTimeout(() => commit('UNERR'), 5000)
                })
        })
    },

    PATCH_DATA({ commit }, { payload }) {
        return new Promise((resolve, reject) => {
            let dados = helper.clean(payload.data, { applyForAll: payload.applyForAll })
            const attr = payload.attr === 'users' ? 'user' : payload.attr

            service
                .patch(payload.url, dados)
                .then((response) => {
                    commit('CHANGE_DATA', {
                        data: response.data,
                        applyForAll: payload.applyForAll,
                        attr: attr,
                    })

                    resolve(response.data)
                })
                .catch((err) => {
                    commit('ERRO')
                    reject(err)
                    setTimeout(() => commit('UNERR'), 5000)
                })
        })
    },

    DELETE_DATA({ commit }, { payload }) {
        return new Promise((resolve, reject) => {
            const mutation_params = payload.mutation_params

            service
                .del(payload.url)
                .then((status) => {
                    commit('REMOVE_DATA', {
                        params: mutation_params,
                        applyForAll: payload.applyForAll,
                        attr: payload.attr,
                    })

                    resolve(status)
                })
                .catch((err) => {
                    commit('ERRO')
                    reject(err)
                    setTimeout(() => commit('UNERR'), 5000)
                })
        })
    },
}
