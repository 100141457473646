import avatar1 from '../assets/img/avatar/avatar1.svg'
import avatar2 from '../assets/img/avatar/avatar2.svg'
import avatar3 from '../assets/img/avatar/avatar3.svg'
import avatar4 from '../assets/img/avatar/avatar4.svg'
import avatar5 from '../assets/img/avatar/avatar5.svg'
import avatar6 from '../assets/img/avatar/avatar6.svg'
import avatar7 from '../assets/img/avatar/avatar7.svg'
import avatar8 from '../assets/img/avatar/avatar8.svg'
import avatar9 from '../assets/img/avatar/avatar9.svg'
import avatar10 from '../assets/img/avatar/avatar10.svg'

export const avatares = {
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    avatar7,
    avatar8,
    avatar9,
    avatar10,
}

export const avatarBgColor = {
    avatar1: '#6D7487',
    avatar2: '#ADA7CB',
    avatar3: '#F9E7B9',
    avatar4: '#F8C9DB',
    avatar5: '#A6E1EB',
    avatar6: '#F0B6B5',
    avatar7: '#F6CAB1',
    avatar8: '#EFB5C6',
    avatar9: '#ACB9F7',
    avatar10: '#DFA5B6',
}
