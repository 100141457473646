<template>
    <v-btn
        class="basic-button ma-2"
        :class="[size, { 'no-events': loading }, { reverse }]"
        elevation="0"
        :style="styles"
        :type="type"
        :loading="loading"
        :disabled="disabled"
        >{{ label }}
        <slot></slot>
    </v-btn>
</template>

<script>
export default {
    name: 'BasicButton',

    props: {
        label: String,
        type: {
            type: String,
            default: 'button',
        },
        loading: Boolean,
        disabled: Boolean,
        bgColor: {
            type: String,
            default: '#028fff',
        },
        labelColor: {
            type: String,
            default: 'white',
        },
        reverse: Boolean,
        size: {
            type: String,
            default: 'medium',
        },
        width: {
            type: [String, Number],
            default: '158px',
        },
    },

    computed: {
        styles() {
            return {
                '--bg-color': this.bgColor,
                '--txt-color': this.labelColor,
                '--width': typeof this.width === 'number' ? this.width + 'px' : this.width,
            }
        },
    },
}
</script>

<style lang="scss">
.basic-button {
    text-transform: uppercase !important;
    letter-spacing: unset !important;
    box-shadow: none;
    background-color: var(--bg-color) !important;
    font-weight: 500 !important;
    min-width: var(--width) !important;
    color: var(--txt-color) !important;
    height: 56px !important;

    .v-btn__content {
        width: calc(var(--width) - 40px);
        white-space: break-spaces;
    }

    &.reverse {
        height: 54px !important;
        background-color: transparent !important;
        border: 2px solid var(--bg-color) !important;
        color: var(--bg-color) !important;
    }

    &.small {
        font-size: 13px !important;
        padding: 10px 15px !important;
    }

    &.medium {
        font-size: 14px !important;
        padding: 14px 20px !important;
    }

    &.large {
        font-size: 18px !important;
        padding: 16px 30px;
    }

    &.no-events {
        pointer-events: none;
        opacity: 0.7;
    }

    &:disabled {
        opacity: 0.4;
    }

    a {
        color: var(--txt-color);
    }
}
</style>
