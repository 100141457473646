<template>
    <div class="edit-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="14.24" height="16" viewBox="0 0 14.24 16">
            <path
                class="a"
                d="M1352.09,1112.437l-1.262-1.28a.528.528,0,0,0-.376-.156h0a.527.527,0,0,0-.376.156l-7.362,7.474a.54.54,0,0,0-.114.176l-.819,2.1a.528.528,0,0,0,.49.719.542.542,0,0,0,.2-.037l2.075-.823a.55.55,0,0,0,.176-.12l7.369-7.465A.527.527,0,0,0,1352.09,1112.437Zm-8.042,7.385-.844.336.338-.865,6.91-7.016.527.528Zm5.38-.629v7.105a.73.73,0,0,1-.733.7H1338.7a.7.7,0,0,1-.7-.7V1112.9a.677.677,0,0,1,.7-.672h7.311a.7.7,0,1,1,0,1.406h-6.607v11.956h8.615v-6.4a.7.7,0,0,1,1.407,0Z"
                transform="translate(-1338 -1111)"
            />
        </svg>

        <small>Editar</small>
    </div>
</template>

<script>
export default {
    name: 'EditIcon',
}
</script>

<style lang="scss" scoped>
.edit-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
        background-color: #ecf0fd;
        border-radius: 50%;

        .a {
            fill: $darker-blue;
        }
    }

    .a {
        fill: #b7b8b9;
    }

    small {
        @include easy-tooltip();
        line-height: 40px;
        right: 2px;
    }
}
</style>
