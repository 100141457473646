import helpers from '../helpers/getters.helper'

export default {
    // encontra um elemento dentro de um estado da store dado o seu ID
    findById: (state) => (collection, ID) => {
        return state[collection].find((element) => element._id === ID)
    },

    /**
     * filtra uma collection dado um objeto com os parametros de filtragem
     * - Obs: e possível usar "nested" atributos como filtro. *```Ex: { 'foo.bar' = "foobar" }```*
     * - Também e possivel verificar se um valor existe. *```Ex: { foo: { $exists: ```true``` } }```*
     */
    filterBy:
        (state) =>
        (collection, filters = {}) => {
            const [_collection, subcollection] = collection.split('.')
            let list = state[_collection]

            if (subcollection) {
                let trueList = []

                list.forEach((doc) => trueList.push(...doc[subcollection]))
                list = trueList
            }

            return list.filter((doc) => {
                let valid = true

                const [compositeFilters, normalFilters] = helpers.filterGroups(filters)

                normalFilters.forEach((filter) => {
                    const [attr, nestedAttr] = filter[0].split('.')

                    if (nestedAttr) valid = valid && doc[attr][nestedAttr] === filter[1]
                    else valid = valid && doc[attr] === filter[1]
                })

                compositeFilters.forEach((filter) => {
                    const sufix = Object.keys(filter[1])[0].split('$')[1]

                    switch (sufix) {
                        case 'exists':
                            valid = valid && !!doc[filter[0]]
                            break
                        default:
                    }
                })

                return valid
            })
        },
}
