<template>
    <div class="step-one step">
        <p class="alert-message">Tem certeza que deseja cancelar esta assinatura?</p>

        <div class="info-box changeplan" v-if="Object.keys(actualPlan).length > 1">
            <h3>{{ actualPlan.name }}</h3>
            <p>
                <span class="blue-text">R$ {{ actualPlan.price }} {{ actualPlan.period }}</span
                >, todo dia <span class="blue-text">{{ actualDate(actualPlan.period) }}</span>
            </p>
        </div>

        <basic-button label="não, mudei de ideia" width="100%" @click.native="endProcess" />
        <basic-button label="cancelar assinatura" width="100%" :reverse="true" @click.native="$emit('next-step')" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { environment } from '../../../common/environment'
import util from '../../../common/util'
import BasicButton from '../../../components/buttons/BasicButton.vue'
import apiService from '../../../services/api.service'

export default {
    components: { BasicButton },

    name: 'StepOne',

    data() {
        return {
            plans: [],
        }
    },

    async mounted() {
        try {
            this.plans = (await apiService.get(`${environment.API_URL}plans`)).data
        } catch (e) {
            console.error(e)
            this.plans = []
        }
    },

    computed: {
        ...mapState(['subscriptions']),

        subscription() {
            return this.subscriptions[0] || {}
        },

        actualPlan() {
            const { plan } = this.subscription

            return this.plans.find((p) => p.plan_identifier === plan) || {}
        },
    },

    methods: {
        actualDate(period) {
            if (period === 'anualmente') {
                return this.$moment(this.subscription.expireAt).format('DD/MM')
            } else {
                return new Date().getDate()
            }
        },

        endProcess() {
            const device = util.identifyUserDevice()

            if (device === 'mobile') window.location.href = `${process.env.VUE_APP_HOST_URL}/assine?sucesso`
            else this.$router.push('/configuracoes/plano')
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../steps/styles.scss';

.step {
    margin: 0;
}
</style>
