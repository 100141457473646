import sort from '@/common/sort'
import util from '@/common/util'

export default {
    // FILTRAR CATEGORIAS POR GRUPO
    filteredCategories: (state) => (group, userId) => {
        const filter = (category) => {
            let valid = true

            if (userId) valid = category.user === userId

            return valid && category.group === group
        }

        return state.categories.filter((category) => filter(category)).sort(sort.byName)
    },

    // FILTRAR TODAS AS SUBCATEGORIAS
    subcategories: (state) => {
        const { user } = state

        return state.categories.filter((category) => category.category && category.user === user._id).sort(sort.byName)
    },

    // AGRUPAR AS CATEGORIAS POR GRUPO
    primaryCategories: (state, getters) => (group) => {
        const { user } = state
        let cats = [
            {
                name: 'Receitas',
                data: getters
                    .filteredCategories('revenues')
                    .filter((category) => !category.category && category.user === user._id),
            },
            {
                name: 'Despesas',
                data: getters
                    .filteredCategories('expenses')
                    .filter((category) => !category.category && category.user === user._id),
            },
        ]

        switch (group) {
            case 'revenues':
                return cats[0].data
            case 'expenses':
                return cats[1].data
            default:
                return cats
        }
    },

    // AGRUPAR CATEGORIAS POR TIPO
    groupedCategoriesByType: (state, getters) => (group) => {
        const { user } = state

        let groups = [
            {
                name: 'Essenciais',
                data: [],
            },
            {
                name: 'Dispensáveis',
                data: [],
            },
        ]

        getters.filteredCategories(group, user._id).map((category) => {
            if (category.category && category.essential) groups[0].data.push(category)
            else if (!category.category && category.type === 'essential') groups[0].data.push(category)
            else groups[1].data.push(category)
        })

        groups[0].data.sort(sort.byName)
        groups[1].data.sort(sort.byName)

        return groups
    },

    //ENCONTRAR A CATEGORIAS DEFAUT POR TIPO
    getDefautCategoryListByType(state) {
        const { user } = state

        return (type, group, permitedCategories = []) => {
            return state.categories.filter((category) => {
                let valid = true

                if (permitedCategories.length) valid = permitedCategories.includes(util.sanitize(category.name))

                return category.type === type && category.group === group && category.user === user._id && valid
            })
        }
    },

    // ENCONTRAR UMA CATEGORIA PADRÃO PELO ID
    getDefaultCategoryById(state, getters) {
        return (category) => {
            const id = category._id || category
            const _category = getters.findById('categories', id)
            const defaultCategory = _category.category ? _category.category._id : _category._id

            if (defaultCategory.name) return defaultCategory
            return state.categories.find((category) => category._id === defaultCategory)
        }
    },

    /**
     * ENCONTRAR A CATEGORIA PADRÃO PARA SALDO INICIAL DE CONTAS BANCÁRIAS
     **/
    findInitialBalanceCategory: (state, getters) => {
        return getters.filteredCategories('revenues').find((category) => category.name === 'Outras rendas')._id
    },

    /**
     * ENCONTRAR CATEGORIA DE ESTORNOS DO CARTÂO
     **/
    findReversalCategory: (state) => {
        return (
            state.categories.find(
                (category) => !category.category && category.group === 'invoice' && category.name === 'Estorno'
            )._id || '31f30f1c20eecddcfada1926'
        )
    },

    /** Encontra a categoria de faturas */
    findInvoiceCategory: (state) => {
        return state.categories.find((category) => category.group === 'invoice' && category.name === 'Fatura Cartão')
    },
}
