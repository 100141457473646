<template>
    <div class="calendar-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18.043" viewBox="0 0 20 18.043">
            <path
                id="Caminho_339"
                data-name="Caminho 339"
                d="M1558.782,2227.872v-2.784a1.087,1.087,0,1,1,2.174,0v2.784a1.087,1.087,0,0,1-2.174,0Zm9.348,1.087a1.087,1.087,0,0,0,1.087-1.087v-2.784a1.087,1.087,0,1,0-2.174,0v2.784A1.087,1.087,0,0,0,1568.13,2228.958Zm5.87-2.486v14.7a.869.869,0,0,1-.87.869h-18.261a.869.869,0,0,1-.87-.869v-14.7a.941.941,0,0,1,.87-.95h2.033a.87.87,0,0,1,0,1.74h-1.163v3.7h16.522v-3.7H1571.1a.87.87,0,0,1,0-1.74h2.033A.942.942,0,0,1,1574,2226.472Zm-1.739,13.832v-7.826h-16.522v7.826Zm-9.633-13.043h2.743a.87.87,0,1,0,0-1.74h-2.743a.87.87,0,0,0,0,1.74Z"
                transform="translate(-1554 -2224)"
                :fill="fill"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'CalendarIcon',

    props: {
        fill: {
            type: String,
            default: '#8c8c8d',
        },
    },
}
</script>
