<template>
    <div class="preferences-config">
        <div class="flex">
            <div v-if="waiting" class="flex preferences-config-box column">
                <div v-for="n in 3" :key="n">
                    <bank-access-card :bankAccess="{}" :syncing="disabled" />
                </div>
            </div>

            <div v-else class="flex preferences-config-box column">
                <div v-for="item in bankaccesses" :key="item._id">
                    <bank-access-card
                        :bankAccess="item"
                        :warningShow="hasWarning(item)"
                        :warningMsg="warningMsg(item)"
                        :disableSync="disableSync(item)"
                        @updatePluggyAccount="updateBelvoPluggyAccount"
                        @syncAccountEvent="syncPluggyAccount"
                        @deleteAccountEvent="removeItem"
                    />
                </div>
            </div>
        </div>

        <div v-show="showSuccessDialog" class="overlay">
            <modal-transition :duration="200">
                <confirmation-create-modal
                    v-bind="confirmation"
                    v-if="showSuccessDialog"
                    @confirmEvent="() => (showSuccessDialog = false)"
                >
                    <basic-button
                        :bgColor="confirmation.error ? '#ff113a' : '#028FFF'"
                        width="100%"
                        label="Fechar"
                        @click.native="() => (showSuccessDialog = false)"
                    />
                </confirmation-create-modal>
            </modal-transition>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { environment } from '../../common/environment'
import _date from '@/common/formatDate'
import request from '@/common/request'
import apiService from '../../services/api.service'
import BankAccessCard from '../../components/cards/BankAccessCard.vue'
import ConfirmationCreateModal from '../../components/modals/confirmation-modals/ConfirmationCreateModal.vue'
import BasicButton from '../../components/buttons/BasicButton.vue'
import ModalTransition from '../../components/transitions/ModalTransition.vue'

export default {
    name: 'BankAccessConfig',
    components: {
        BankAccessCard,
        ConfirmationCreateModal,
        BasicButton,
        ModalTransition,
    },
    data() {
        return {
            confirmation: {
                title: '',
                text: '',
                error: false,
            },
            showSuccessDialog: false,
        }
    },
    methods: {
        async getPluggyAccessToken(linkId) {
            const response = await apiService.post(environment.PLUGGY_ACCESS_TOKEN, { linkId })
            return response.data
        },

        async pluggyWidget(bankAccessId, itemId) {
            const { accessToken } = await this.getPluggyAccessToken(itemId)
            const pluggyTokenPayload = {
                connectToken: accessToken,
                updateItem: itemId,
                connectorIds: this.getBelvoRelatedPluggyConnectors(),
                onSuccess: itemId
                    ? (itemData) => this.synchronizeAccount(bankAccessId)
                    : (itemData) => this.pluggySuccessCallbackFunction(itemData, bankAccessId),
                onError: (error) => {
                    console.error('Whoops! Pluggy Connect error... ', error)
                },
            }
            const pluggyConnect = new PluggyConnect(pluggyTokenPayload)
            pluggyConnect.init()
        },

        async updateBelvoPluggyAccount(bankAccessId) {
            await this.pluggyWidget(bankAccessId)
        },

        async syncPluggyAccount(bankAccess) {
            const lastSynced = this.$moment(bankAccess.lastSynced)
            const createdAt = this.$moment(bankAccess.createdAt)
            const today = this.$moment()
            if ((!bankAccess.lastSynced && today.diff(createdAt, 'days') > 0) || today.diff(lastSynced, 'days') > 0) {
                //Se não sincronizou ou criou HOJE
                console.log('NÃO Sincronizou HOJE')
                await this.pluggyWidget(bankAccess._id, bankAccess.linkId)
            } else {
                // Se já sincronizou HOJE
                console.log('Já Sincronizou HOJE')
                this.synchronizeAccount(bankAccess._id)
            }
        },

        async pluggySuccessCallbackFunction(itemData, bankAccessId) {
            this.loading = true
            this.confirmation.title = 'Atualização'

            const { item } = itemData

            try {
                await apiService.post(`${environment.BANK_ACCESSS_URL}/${bankAccessId}/migrate`, {
                    itemId: item.id,
                    name: item.connector.name,
                    type: 'pluggy',
                    connector: item.connector.id,
                })

                await request.getById({ collection: 'bankaccesses', _id: bankAccessId })
                await request.fetch([{ collection: 'payments' }])
            } catch (error) {
                console.error(error)
                this.confirmation.text = `Não foi possível salvar seus dados no momento. Por favor entre em contato com nosso suporte técnico.`
                this.confirmation.error = true
                this.showSuccessDialog = true
            } finally {
                this.loading = false
            }
        },

        async synchronizeAccount(bankAccessId) {
            this.confirmation.title = 'Sincronização de Conta'
            this.loading = true
            this.$store.commit('DISABLED')

            try {
                const data = (await apiService.post(`${environment.BANK_ACCESSS_URL}/${bankAccessId}/sync`, {})).data

                await request.getById({ collection: 'bankaccesses', _id: bankAccessId })
                await request.fetch([
                    { collection: 'invoices', queryParams: this._date_selected },
                    { collection: 'transactions', queryParams: this._date_selected },
                    { collection: 'payments' },
                ])

                this.confirmation.text = data.message || `Sua conta foi sincronizada com sucesso`
                this.confirmation.error = false
            } catch (error) {
                this.confirmation.text = `Não foi possível sincronizar sua conta automática`
                this.confirmation.error = true
            } finally {
                this.loading = false
                this.showSuccessDialog = true
                this.$store.commit('DISABLED')
            }
        },

        removeItem(bankAccessId) {
            this.$parent.$emit('openConfirmationModalEvent', {
                data: {
                    _id: bankAccessId,
                    isBankAccess: true,
                    collection: 'bankaccesses',
                },
                text: `Caso continue será removido alem do acesso ao banco todo o histórico transacional relacionado ao acesso. 
                    \n Deseja mesmo assim fazer a remoção do acesso aos dados`,
            })
        },

        hasWarning(bankaccess) {
            return bankaccess.type !== 'pluggy' || this.statusPending(bankaccess)
        },

        statusPending(bankaccess) {
            return bankaccess.status === 'pending'
        },

        disableSync(bankaccess) {
            return this.statusPending(bankaccess)
        },

        warningMsg(bankaccess) {
            if (bankaccess.type !== 'pluggy') {
                return 'É necessário atualizar esta conta'
            } else {
                return 'Essa conta está em processo de atualização pela instituição'
            }
        },
    },

    computed: {
        ...mapState(['bankaccesses', '_date_selected', 'disabled', 'waiting']),
        ...mapGetters(['getBelvoRelatedPluggyConnectors']),
    },
}
</script>

<style lang="scss" scoped>
.preferences-config {
    padding-top: 41px;

    .preferences-config-box {
        width: 780px;

        .form-width {
            max-width: 190px;
        }

        .stacked-input {
            margin-top: 0px;
        }

        .contend-margin {
            margin-top: 32px;

            .info-btn {
                color: #848484;
                font-size: 16px;
                line-height: 22px;
                margin-top: 24px;
            }

            .text-box {
                width: 398.98px;
                color: #848484;
                font-size: 16px;
                margin: 16px 0 5px 0;

                .box-title {
                    width: 300px;
                    font-weight: 500;
                }
            }
        }

        h1 {
            @include section-title();
        }

        .toggle-switch-container {
            margin: 24px 0 31px 20px;

            .toggle-label {
                width: 298px;
                color: #646464;
                font-size: 16px;
                line-height: 19px;
            }

            .info-btn {
                line-height: 1.5;
            }
        }

        .btn {
            height: 56px;
            width: 269.84px;
            margin-top: 24px;
            color: #9fa5b1;
            font-size: 16px;
            font-weight: 400;
            border-radius: 4px;
            background-color: #ebebeb;
            cursor: not-allowed;
        }

        .talk-to-us-btn {
            color: white;
            font-weight: 400;
            background: linear-gradient(45deg, #5617bc 0%, #9b56e0 100%);
        }
    }
}

.flex {
    display: flex;
}

.flex-basis {
    flex-basis: 190px;
}

.space-around {
    justify-content: space-around;
}

.column {
    flex-direction: column;
}

.center {
    align-items: center;
}

.grow-1 {
    flex-grow: 1;
}

.flex-center {
    justify-content: center;
}

.justify-start {
    justify-content: flex-start;
}

.space-between {
    justify-content: space-between;
}
.bank-access-item {
    margin-bottom: 10px;
}
</style>
