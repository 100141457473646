import _date from '@/common/formatDate'
import moment from 'moment'

const nextMonth = (payload) => {
    return {
        inicio: moment(payload.inicio).add(1, 'M').format('YYYY-MM-DD'),
        fim: moment(payload.fim).add(1, 'M').format('YYYY-MM-DD'),
    }
}

const prepareData = (_data, collection, state) => {
    let dados

    switch (collection) {
        case 'balancesChart':
            dados = [
                { name: 'receitas', data: _data.revenues },
                { name: 'despesas', data: _data.expenses },
            ]
            break
        case 'budgets':
        case 'families':
            dados = { ..._data[0] }
            break
        default:
            dados = _data
            break
    }

    return dados
}

const isPushableData = (state, payload) => {
    switch (payload.attr) {
        case 'transactions':
            return isPushableTransactions(state, payload.data.date)
        case 'invoices':
            return false
        default:
            return true
    }
}

function isPushableTransactions(state, transactionDate) {
    let appDate = state._date_selected.inicio

    return _date.compareDates(appDate, transactionDate)
}

function isPushableInvoiceTransaction(state, transaction) {
    let appDate = state._date_selected.inicio
    const invoice = state.invoices.find((invoice) => invoice.creditCardId === transaction.payment._id)

    return _date.compareInvoiceDates(appDate, transaction.date, invoice.closeOnDay)
}

const patchHandle = (state, payload) => {
    switch (payload.attr) {
        case 'transactions':
            patchTransactions(state, payload.data, payload.applyForAll)
            return true
        case 'invoices':
            // patchInvoices(state, payload.data, payload.applyForAll)
            return true
        case 'payments':
            updateTransactionsReferences(state.transactions, payload.data)
            return false
        default:
            return false
    }
}

function updateTransactionsReferences(transactions, payment) {
    let { _id, name } = payment

    transactions.map((transaction) => {
        if (transaction.payment._id === _id) transaction.payment.name = name
    })
}

function patchTransactions(state, data, applyForAll) {
    let appDate = state._date_selected.inicio

    if (applyForAll) {
        state.transactions = patchRecurrentData(state.transactions, data)
    } else {
        let index = state.transactions.findIndex((transaction) => transaction._id === data._id)

        if (index > -1) {
            if (_date.compareDates(appDate, data.date)) state.transactions.splice(index, 1, data)
            else state.transactions.splice(index, 1)
        }
    }
}

function patchInvoices(state, data, applyForAll) {
    let invoice = state.invoices.find((invoice) => invoice.creditCardId === data.payment._id)

    if (applyForAll) {
        let update = patchRecurrentData(invoice.items, data, true)
        invoice.items = update.newArray

        updateTransactionInvoice(state, update.diff, invoice._id)
    } else {
        let index = invoice.items.findIndex((transaction) => transaction._id === data._id)

        if (index > -1) {
            let appDate = state._date_selected.inicio
            let closeOnInvoice = state.payments.find((payment) => payment._id === data.payment._id).invoiceCloseOn

            if (_date.compareInvoiceDates(appDate, data.date, closeOnInvoice)) {
                invoice.items.splice(index, 1, data)
            } else {
                invoice.items.splice(index, 1)
            }
        }
    }
}

function updateTransactionInvoice(state, diff, invoiceId) {
    let index = state.transactions.findIndex((t) => t.invoiceReferenceId === invoiceId)

    console.log(index)

    if (index > -1) state.transactions[index].value += diff
}

function patchRecurrentData(collection, data, invoice) {
    let recurrentIndex = data.recurrentIndex
    let diff = 0
    let newArray = []

    delete data['recurrentIndex']

    newArray = collection.map((transaction) => {
        if (
            transaction.recurrentTransactionId == data.recurrentTransactionId &&
            transaction.recurrentIndex >= recurrentIndex
        ) {
            if (invoice) diff += transaction.value - data.value
            return (transaction = Object.assign(transaction, data))
        }
        return transaction
    })

    if (invoice) return { diff, newArray }
    return newArray
}

const deleteHandle = (state, payload) => {
    switch (payload.attr) {
        case 'transactions':
            if (payload.applyForAll) state.transactions = updateRecurrencyList(state.transactions, payload.params)
            else if (payload.params._bankOpId) removeConnectedTransaction(state, payload.params._bankOpId)
            return
        case 'categories':
            updateCategoriesInTransactions(state, payload.params)
            return
        case 'payments':
            if (!payload.params.creditCardAlert) updatePayentsInTransactions(state, payload.params)
            else deleteInvoice(state, payload.params)
            return
        case 'invoices':
            // if (payload.applyForAll) updateitemsList(state, payload.params)
            return
        case 'budgets':
            state.budgets = {}
            return true
        case 'objectives':
            removeTransactionsReferences(state, payload.params._id)
            return
        case 'families':
            removeFamilyMember(state, payload.params._id)
            return true
        default:
            return
    }
}

function removeFamilyMember(state, memberId) {
    const index = state.families.members.findIndex((el) => el._id === memberId)
    state.families.members.splice(index, 1)
}

/**
 * Removendo a transação irmã de uma mov. bancaria
 * @param {*} state
 * @param {*} bankOpId
 */
function removeConnectedTransaction(state, bankOpId) {
    const index = state.transactions.findIndex((el) => el._id === bankOpId)
    state.transactions.splice(index, 1)
}

function updateRecurrencyList(transactions, params, invoice) {
    let newTransactionsList = []
    let diff = 0

    transactions.map((transaction) => {
        if (transaction.recurrentTransactionId === params._recId) {
            if (transaction.recurrentIndex <= params._recIndex) {
                let { recurrentTransactionId, recurrentType, recurrentDay, recurrentIndex, ...updateTransaction } =
                    transaction
                updateTransaction.recurrent = false

                newTransactionsList.push(updateTransaction)
            } else {
                if (invoice) diff += -transaction.value
            }
        } else {
            newTransactionsList.push(transaction)
        }
    })

    if (invoice) return { diff, newTransactionsList }
    return newTransactionsList
}

function updateCategoriesInTransactions(state, params) {
    let { _id, name, group } = state.categories.find((cat) => cat._id === params._categoryId)

    state.transactions.map((transaction) => {
        if (transaction.category._id === params._id) {
            transaction.category = { _id, name, group }
        }
    })
}

function updatePayentsInTransactions(state, params) {
    let _id, name
    let deletedPayment = state.payments.find((payment) => payment._id === params._id)

    state.payments.map((payment) => {
        if (payment.type === 'money') {
            _id = payment._id
            name = payment.name
            payment.value += deletedPayment.value
        }
    })

    state.transactions.forEach((transaction, index) => {
        if (transaction.payment._id === params._id) {
            if (transaction.bankingOperationReferenceId) {
                state.transactions.splice(index, 1)
            } else {
                transaction.payment = { _id, name }
            }
        } else if (transaction.bankingOperationPayment === params._id) {
            state.transactions.splice(index, 1)
        }
    })
}

function deleteInvoice(state, params) {
    const index = state.invoices.findIndex((invoice) => invoice.creditCardId === params._id)

    state.invoices.splice(index, 1)
}

function updateitemsList(state, params) {
    let invoice = state.invoices.find((invoice) => invoice._id === params._id)

    let update = updateRecurrencyList(invoice.items, params, true)
    invoice.items = update.newTransactionsList
}

function removeTransactionsReferences(state, id) {
    let bankTransitionCategory = state.categories.find(
        (cat) => cat.group === 'bankOperation' && cat.name === 'Movimentação Bancária'
    )

    state.transactions.map((t) => {
        if (t.objectiveReferenceId) {
            delete t['objectiveReferenceId']
            t.category = {
                name: bankTransitionCategory.name,
                _id: bankTransitionCategory._id,
                group: bankTransitionCategory.group,
            }
        }
    })
}

export default {
    prepareData,
    isPushableData,
    patchHandle,
    deleteHandle,
    nextMonth,
}
