<template>

  <div class="round-progress-bar">
    <h1>Limite disponível</h1>
    <div class="circle-wrap" :style="degrees">
      <div class="circle" :style="degrees">

        <div class="mask full" :style="degrees">
          <div class="fill" :style="degrees"></div>
        </div>

        <div class="mask half">
          <div class="fill" :style="degrees"></div>
        </div>

        <div class="inside-circle">
          {{ percentage.toFixed(0) + '%'}}
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'RoundProgressBar',
  props: {
    limit: Number,
    value: Number
  },
  computed: {
    percentage: function (){
      let porcetagem = (((this.limit + this.value) / this.limit) * 100)

      if(porcetagem < 0) {
        return 0
      } 
        
      return porcetagem
    },

    degrees: function (){
      return {
        '--degrees': (((this.percentage.toFixed(1)) / 100) * 180) + 'deg'
      }
    }
  },

  watch: {
    value: function(value) {
      this.value = value
    }
  }
}
</script>

<style lang="scss" scoped>
.round-progress-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
}

.circle-wrap {
  width: 40px;
  height: 40px;
  background: #e6e2e7;
  border-radius: 50%;
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 50%;
}

.circle-wrap .circle .mask {
  clip: rect(0px, 40px, 50px, 20px);
}

.circle-wrap .circle .mask .fill {
  clip: rect(0px, 20px, 50px, 0px);
  background-color: #FA6437;
}

.circle-wrap .circle .mask.full,
.circle-wrap .circle .fill {
  animation: fill ease-in-out 1s;
  transform: rotate(var(--degrees));
}

.circle-wrap .inside-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  line-height: 30px;
  text-align: center;
  margin-top: 5px;
  margin-left: 5px;
  position: absolute;
  z-index: 1;
  font-weight: 700;
}

@keyframes fill {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(var(--degrees));
  }
}

</style>
