<template>
    <modal-frame v-bind="options" @close-modal="$emit('close-modal')" :style="styles">
        <template #content>
            <form @submit.prevent="validateForm">
                <div v-if="mode === 'redeem'" class="redeem">
                    <div class="bubble">
                        <img :src="$variables.chatIcon" />

                        <v-scale-transition origin="top left">
                            <div v-if="showBubble" class="otto-talk">
                                O valor resgatado será apenas subtraído do valor já depositado, não sendo creditado em
                                nenhuma de suas contas
                            </div>
                        </v-scale-transition>
                    </div>

                    <h3>{{ name }}</h3>
                </div>

                <div>
                    <stacked-input
                        label="Valor"
                        :money="{
                            decimal: money.decimal,
                            thousands: money.thousands,
                            prefix: money.prefix + ' ',
                            precision: 2,
                            masked: false,
                        }"
                        textAlign="right"
                        :required="true"
                        v-model="value"
                        :errorValidation="invalid"
                        :errorMsg="message"
                    />
                    <stacked-input label="Data" type="date" :value="date" :readonly="true" />
                </div>

                <basic-button :label="options.btnLabel" type="submit" :loading="disabled" />
                <basic-button
                    v-if="mode === 'redeem'"
                    label="Cancelar"
                    :disabled="disabled"
                    :reverse="true"
                    style="margin-top: 12px"
                    @click.native="$emit('close-modal')"
                />
            </form>
        </template>
    </modal-frame>
</template>

<script>
import { mapState } from 'vuex'
import formatDate from '../../../common/formatDate'
import util from '../../../common/util'
import BasicButton from '../../buttons/BasicButton.vue'
import ModalFrame from '../../../containers/ModalFrame.vue'
import StackedInput from '../../inputs/StackedInput.vue'

export default {
    name: 'DepositObjective',

    props: {
        name: String,
        installment: {
            type: Number,
            default: 0,
        },
        investedValue: {
            type: Number,
            default: 0,
        },
        mode: {
            type: String,
            default: 'deposit',
        },
    },

    components: { ModalFrame, StackedInput, BasicButton },

    data() {
        return {
            date: formatDate.inputFormatDate(new Date()),
            value:
                this.mode === 'deposit'
                    ? util.formatNumber(this.installment)
                    : util.returnCurrencyPrefix().prefix + '0' + util.returnCurrencyPrefix().decimal + '00',

            invalid: false,
            message: undefined,

            showBubble: false,

            money: util.returnCurrencyPrefix(),
        }
    },

    mounted() {
        if (this.mode === 'redeem')
            setTimeout(() => {
                this.showBubble = true
            }, 300)
    },

    computed: {
        ...mapState(['disabled']),

        options() {
            const options = {
                title: 'Parcela de',
                headerColor: '#4A86FA',
                headerTextColor: 'white',
                btnLabel: 'Depositar',
                backgroundColor: undefined,
                width: 435,
                closeBtn: undefined,
            }

            if (this.mode === 'redeem') {
                ;(options.title = 'Resgatar parte do'), (options.headerColor = undefined)
                options.btnLabel = 'Resgatar valor'
                options.backgroundColor = '#ecf0fd'
                options.headerTextColor = undefined
                options.closeBtn = false
                options.width = 370
            }

            options.title = options.title.concat(' objetivo')

            return options
        },

        styles() {
            return {
                '--flex-direction': this.mode === 'deposit' ? 'row' : 'row-reverse',
                '--form-margin': this.mode === 'deposit' ? '16px 12px 0 0' : '16px 0 0 12px',
            }
        },
    },

    methods: {
        toNumber(string) {
            return Number(util.sanitizeMoney(string))
        },

        validateForm() {
            this.message = undefined
            this.invalid = false
            let value = this.toNumber(this.value)

            if (!value) {
                this.invalid = true
            } else if (value > this.investedValue && this.mode === 'redeem') {
                this.message = 'Valor maior que o investido!'
                this.invalid = true
            } else {
                let { date } = this

                if (this.mode === 'redeem') value = -value

                this.$emit('deposit', { value, date })
            }
        },
    },

    watch: {
        value: {
            immediate: true,
            handler(v) {
                this.invalid = false
            },
        },
    },
}
</script>

<style lang="scss" scoped>
@include otto-talk();

form {
    margin-top: -16px;

    .bubble {
        margin-top: -40px;
        display: flex;
        padding: 0 60px 0 0;
        height: 110px;

        img {
            width: 30px;
            height: 30px;
            position: relative;
        }

        .otto-talk {
            width: 100%;
        }
    }

    h3 {
        margin: 24px 0 12px;
        text-align: center;
        font-weight: bold;
        color: $dark-blue;
    }

    & > div:not(.redeem) {
        display: flex;
        flex-direction: var(--flex-direction);
        margin-bottom: 24px;

        & > .stacked-input {
            width: 43%;
        }

        & > .stacked-input:first-child {
            width: 100%;
            margin: var(--form-margin);
        }
    }

    .basic-button {
        width: 100%;
    }
}
</style>
