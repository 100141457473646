<template>
    <div class="payments-calendar view-container">
        <div class="view-content">
            <action-bar title="calendário mensal" />

            <div class="grid">
                <div style="width: 800px">
                    <header class="menu-bar">
                        <balance-value title="Saldo previsto" :value="balanceCalendar()" />

                        <month-selector :hideButtons="hideButtons" @change-month="changeMonth" />

                        <custom-select :options="options" @update-option="updateOption" />
                    </header>

                    <transactions-list
                        :transactions="getLinkedTransactions(filter)"
                        @pay-calendar-transaction="payCalendarTransaction"
                    />
                </div>

                <summary-model header="" :tableData="_summaryData" :extraMargin="true">
                    <template #progress-bar>
                        <general-balance :summaryData="_summaryData" />
                    </template>
                </summary-model>
            </div>
        </div>
    </div>
</template>

<script>
import ActionBar from '../components/ActionBar.vue'
import { mapGetters, mapState } from 'vuex'
import TransactionsList from '../components/lists/TransactionsList.vue'
import MonthSelector from '../components/headers/MonthSelector.vue'
import BalanceValue from '../components/headers/transactions-screen/BalanceValue.vue'
import CustomSelect from '../components/inputs/CustomSelect.vue'
import SummaryModel from '../components/cards/summary-model/SummaryModel.vue'
import { summaryCalendar } from '../common/summary_configs'
import GeneralBalance from '../components/transactions-screen/GeneralBalance.vue'
import request from '../common/request'

export default {
    components: {
        ActionBar,
        TransactionsList,
        MonthSelector,
        BalanceValue,
        CustomSelect,
        SummaryModel,
        GeneralBalance,
    },

    name: 'PaymentsCalendar',

    data() {
        return {
            hideButtons: [true, false],
            options: [
                { label: 'Todas', value: 'all' },
                { label: 'Receitas', value: 'revenues' },
                { label: 'Despesas', value: 'expenses' },
            ],
            filter: undefined,
            modalOpen: false,
        }
    },

    computed: {
        ...mapState(['_date_selected']),

        ...mapGetters(['getLinkedTransactions', 'balanceCalendar', 'findById', 'isOttoFamily']),

        _summaryData() {
            return summaryCalendar()
        },
    },

    methods: {
        changeMonth(add) {
            if (add === 1) this.hideButtons = [false, true]
            else this.hideButtons = [true, false]

            this.$store.commit('DISABLED')
            this.$store.commit('WAITING', true)

            request.changeMonth(this._date_selected, undefined, false, this.isOttoFamily).finally(() => {
                this.$store.commit('DISABLED')
                this.$store.commit('WAITING')
            })
        },

        payCalendarTransaction(_id) {
            const data = { ...this.findById('linkedtransactions', _id) }

            if (data.lastPaymentDate) data.lastPaymentDate = ''
            else data.lastPaymentDate = new Date().toISOString()

            this.$store.commit('DISABLED')

            request.patch({ collection: 'linkedtransactions', data, _id }).finally(() => this.$store.commit('DISABLED'))
        },

        updateOption(value) {
            if (value === 'all') this.filter = undefined
            else this.filter = { transactionType: value }
        },
    },
}
</script>

<style lang="scss" scoped>
.payments-calendar {
    .menu-bar {
        height: 55px;
        padding: 0px 20px 0px;
        margin-bottom: 20px;
        color: $text-black;
        border-top: solid 1px $title-grey;
        border-bottom: solid 1px $title-grey;
        border-radius: 2px;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .grid {
        display: flex;
    }
}
</style>
