import util from '../../common/util'
import helpers from '../helpers/getters.helper'

export default {
    // ENCONTRAR INVOICE POR ID DO CARTÃO DE CRÉDITO
    getInvoiceByPaymentId: (state) => (payment, nextMonth) => {
        const collection = nextMonth ? 'nextMonthInvoices' : 'invoices'
        return state[collection].find((invoice) => invoice.creditCardId === payment)
    },

    // ENCONTRAR CARTÃO DE CRÉDITO ATRÁVES DO INVOICE ID
    getCreditCardInvoiceByID: (state) => (id) => {
        let paymentId = state.invoices.find((invoice) => invoice._id === id).creditCardId
        return state.payments.find((payment) => payment._id === paymentId)
    },

    // PEGAR A LISTA DE ITEMS
    getInvoiceItemsByPaymentId(state, getters) {
        return (payment, nextMonth) => {
            let list = getters.getInvoiceByPaymentId(payment, nextMonth)
            console.log(list)
            return list ? list.items : []
        }
    },

    // FILTRAR E AGRUPAR OS ITENS DE CADA INVOICE POR CATEGORIA
    getItemsByCategory(state, getters) {
        return (category, isBudget, userId, paymentId, nextMonth) => {
            let filteredItems = []

            let invoices = nextMonth ? state.nextMonthInvoices : state.invoices

            invoices = invoices.filter((i) => {
                let valid = true

                if (userId) valid = i.user === userId

                if (paymentId) valid = i.creditCardId === paymentId

                return valid
            })

            invoices.forEach((invoice) => {
                filteredItems.push(
                    ...invoice.items.filter((item) => {
                        if (isBudget)
                            return helpers.matchCategoryPlanning(item.category, userId, state)._id === category

                        if (Array.isArray(category)) return category.includes(item.category._id)
                        return item.category._id === category
                    })
                )
            })

            return filteredItems
        }
    },

    /**
     * Balanço de todos os invoices
     */

    invoiceBalance: (state, getters) => {
        /** @param nextMonth - Pega os invoices do mês seguinte  */
        return (nextMonth) => {
            const field = nextMonth ? 'nextMonthInvoices' : 'invoices'

            return state[field].reduce((prevValue, invoice) => {
                return prevValue + getters.invoiceTransactionsSum(invoice)
            }, 0)
        }
    },

    /**
     *  PEGA O VALOR DO INVOICE DO MÊS (EVITAR PROBLEMAS CASO DE DATAS DE FECHAMENTO ESPECIAIS)
     * */
    invoiceTransactionsSum: (state) => (invoice) => {
        if (invoice) {
            return invoice.items.reduce(function (prevValue, transaction) {
                const externalTransactionRevenue = transaction.transactionType === 'revenues' && transaction.externalId

                if (!transaction.paymentInvoiceReferenceId && !externalTransactionRevenue) {
                    return prevValue + transaction.value
                }

                return prevValue
            }, 0)
        }
        return 0
    },

    getInvoiceValue: (state, getters) => {
        return (payment) => {
            const invoice = state.invoices.find((invoice) => invoice.creditCardId === payment)
            return getters.invoiceTransactionsSum(invoice) + getters.calculateResidualValue(invoice)
        }
    },

    /** Busca a transação da fatura de um cartão manual */
    getInvoiceTransaction: (state) => (invoiceId) => {
        return (
            state.transactions.find((transaction) => transaction.invoiceReferenceId === invoiceId) || {
                invoiceBalance: 0,
                value: 0,
            }
        )
    },

    /** Calcula o residual do(s) mês(es) anterior(es) */
    calculateResidualValue: (state, getters) => {
        return (invoice) => {
            const invoiceTransaction = getters.getInvoiceTransaction(invoice._id)

            if (!invoice) return 0

            if (invoice.externalId) return 0

            const [invoiceBalance, value, trueValue] = [
                invoiceTransaction.invoiceBalance,
                invoiceTransaction.value,
                getters.invoiceTransactionsSum(invoice),
            ]

            if (invoiceTransaction.paid) return invoiceBalance + value - trueValue
            return invoiceBalance - trueValue
        }
    },

    /** Cria uma transação falsa representando o valor excedente */
    createResidualTransaction: (state, getters) => {
        return (payment) => {
            const invoice = state.invoices.find((invoice) => invoice.creditCardId === payment)

            if (!invoice) return

            const residualValue = getters.calculateResidualValue(invoice)

            if (Math.abs(residualValue) <= 0.01 || invoice.externalId) return

            const transaction = {
                _id: `${residualValue > 0 ? 'adicional' : 'residual'}`,
                name: `${residualValue > 0 ? 'Crédito' : 'Residual'} da fatura anterior`,
                value: residualValue,
                transactionType: residualValue > 0 ? 'revenues' : 'expenses',
                category: getters.findInvoiceCategory,
                date: util.exactDate(state._date_selected.inicio).date(invoice.closeOnDay).toISOString(),
                user: state.transactions.find((transaction) => transaction.invoiceReferenceId === invoice._id).user,
                paid: true,
            }

            return transaction
        }
    },

    /** Calcula o total do(s) pagamento(s) da fatura */
    invoicePaidValue: (state) => (invoiceId) => {
        const invoice = state.invoices.find((invoice) => invoice._id === invoiceId)

        const paidValue = invoice.items.reduce((prevValue, transaction) => {
            if (transaction.paymentInvoiceReferenceId) return prevValue + transaction.value
            return prevValue
        }, 0)

        return paidValue
    },

    // CALCÚLAR O VALOR DE TODAS AS DESPESAS RECORRENTES INFINITAS DO MÊS
    getRecurrencyInvoiceSum(state, getters) {
        return (payment) => {
            let sum = 0,
                paymentValue = 0

            const invoice = getters.getInvoiceByPaymentId(payment)
            const invoiceTransaction = getters.getInvoiceTransaction()

            if (!invoice) return 0

            invoice.items.forEach(function (transaction) {
                if (transaction.recurrentTransactionId && transaction.recurrentType) {
                    sum += transaction.value

                    return
                }

                if (transaction.paymentInvoiceReferenceId) paymentValue += transaction.value
            })

            // Não descontar do limite quando a fatura ja estiver paga
            if (invoiceTransaction.paid && sum) {
                sum = Math.floor(paymentValue) >= Math.floor(Math.abs(sum)) ? 0 : (sum += paymentValue)
            }

            return sum
        }
    },

    /**  PEGA E AGRUPA TODAS AS DESPESAS DO CARTÃO POR TIPO DE CATEGORIA (ESSENCIAL/NÃO ESSENCIAL)*/
    getItemsByCategoryType(state, getters) {
        return (categoryType) => {
            let filteredItems = []

            state.invoices.forEach((invoice) => {
                filteredItems.push(
                    ...invoice.items.filter((item) => {
                        let defaultCategory = getters.getDefaultCategoryById(item.category._id)

                        return defaultCategory.type === categoryType
                    })
                )
            })

            return filteredItems
        }
    },

    /**Pega todas as invoices sem filtro por período do cartão e junta numa grande lista */
    getDaylyInvoicesList: (state) => {
        let items = []

        state.invoicesWithoutFiltering.forEach((invoice) => {
            let transactions = []

            invoice.items.forEach((item) => {
                if (!item.paymentInvoiceReferenceId) transactions.push(item)
            })

            items.push(...transactions)
        })

        return items
    },
}
