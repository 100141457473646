import util from '../../common/util'

export default {
    filteredExperts:
        (state) =>
        (filters = {}) => {
            return state.experts.filter((expert) => {
                let valid = true

                if (filters.expertise)
                    valid = !!expert.expertises.find((expertise) => expertise.name === filters.expertise)
                valid = valid && util.customFilter({ name: filters.name }, expert)

                return valid
            })
        },
}
