<template>
    <modal-frame
        :width="475"
        title="Extrato de objetivo"
        headerColor="#4A86FA"
        headerTextColor="white"
        @close-modal="$emit('close-modal')"
    >
        <template #content>
            <div class="wrapper">
                <table class="story-table">
                    <thead>
                        <th>data</th>
                        <th>operação</th>
                        <th>valor</th>
                    </thead>
                    <tbody>
                        <tr v-for="(entrada, index) in extrato" :key="index">
                            <td v-for="(field, index) in Object.keys(entrada)" :key="`sub-${index}`">
                                <span :style="{ color: field === 'value' ? getColor(entrada, field) : '#4e4e4e' }">
                                    {{ entrada[field] }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </modal-frame>
</template>

<script>
import util from '../../../common/util'
import ModalFrame from '../../../containers/ModalFrame.vue'

export default {
    name: 'ObjectiveExtract',

    components: { ModalFrame },

    props: {
        transactions: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        extrato() {
            let depositoCount, extrato

            extrato = this.transactions.map((transaction, index) => {
                const isDeposit = transaction.value >= 0

                if (!index) depositoCount = 1
                else if (isDeposit) depositoCount++

                const name = isDeposit ? `Depósito ${depositoCount}` : 'Resgate'
                const value = util
                    .formatNumber(Math.abs(transaction.value))
                    .replace(util.returnCurrencyPrefix().prefix, '')
                const date = this.$moment(transaction.date).utcOffset(0, false).format('DD/MM/YYYY')

                return {
                    date,
                    name,
                    value,
                }
            })

            return extrato
        },
    },

    methods: {
        getColor(entrada) {
            const name = entrada.name || ''

            if (name.includes('Resgate')) {
                return '#EF8A97'
            }

            return '#2691D1'
        },
    },
}
</script>

<style lang="scss" scoped>
@include custom-scroll();

.wrapper {
    height: 275px;
    overflow-y: scroll;
    padding: 18px 2px 0 0;
    margin: -20px -10px 0 0;
}

.story-table {
    width: 100%;
    margin-top: -16px;

    tr,
    th {
        color: $black;
        text-align: left;
    }

    tr:nth-child(even) {
        background-color: #f5f5f5;
    }

    th {
        height: 30px;
        font-weight: bold;
        text-transform: uppercase;
        padding-left: 10px;
        letter-spacing: 0.8px;

        &:last-child {
            text-align: center;
        }
    }

    td {
        height: 30px;
        vertical-align: middle;
        font-weight: 500;
        padding-left: 8px;

        &:last-child {
            text-align: right;
            padding-right: 8px;
            font-weight: 600;
        }
    }
}
</style>
