<template>
    <div class="general-balance">
        <v-skeleton-loader
            v-if="waiting"
            style="position: relative; top: -72px"
            :height="8"
            type="progress"
            :types="{ progress: 'text' }"
        />
        <div v-else class="progress-bar-border gray" style="position: relative; top: -82px">
            <div
                class="progress-bar green"
                :style="{ width: percentage(summaryData[1].value, summaryData[0].value) + '%' }"
            ></div>
        </div>

        <v-skeleton-loader
            v-if="waiting"
            style="position: relative; top: -5px"
            :height="8"
            type="progress"
            :types="{ progress: 'text' }"
        />
        <div v-else class="progress-bar-border gray" style="position: relative; top: -15px">
            <div
                class="progress-bar"
                :style="{ width: percentage(summaryData[3].value, summaryData[2].value) + '%' }"
            ></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import util from '../../common/util'

export default {
    name: 'GeneralBalance',

    props: {
        summaryData: {
            type: Array,
            required: true,
        },
    },

    computed: {
        ...mapState(['waiting']),
    },

    methods: {
        percentage(n, d) {
            return util.percentage(n, d)
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/summary-card.scss';

.general-balance {
    .progress-bar-border {
        background-color: #37c2ce !important;

        &.gray {
            background: #e6e6e6 !important;
        }
    }

    .progress-bar {
        background-color: #ef8a97 !important;

        &.green {
            background: #37c2ce !important;
        }
    }
}
</style>
