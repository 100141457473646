<template>
    <div class="transactions view-container">
        <div class="view-content">
            <ActionBar
                :title="paymentId ? titleView(paymentId) : 'Movimentações'"
                :badge="paymentId && getPayment.type !== 'money' && !getPayment.externalId"
                :isTransactions="true"
                :showSearchIcon="true"
                @searchItem="(value) => (aditionalFilters = value)"
                @filterByUser="(value) => (userId = value)"
            />

            <div class="flex">
                <div class="transaction-list grow">
                    <!-- MenuBar -->
                    <div class="chart-container">
                        <div class="menu-bar flex space-between box">
                            <balance-value :title="balanceTitle" :value="balanceActualMonth(paymentId)" />

                            <month-selector @change-month="changeMonth" />

                            <div style="width: 105px" v-if="getPayment.type === 'credit'"></div>

                            <custom-select v-else :options="options" @update-option="updateOption" />
                        </div>

                        <div style="width: 100%">
                            <wavy-chart
                                v-if="getPayment.type !== 'credit'"
                                :chartSeries="balançosGrafico"
                                :categories="wavyCategories"
                                :filter="group"
                            />
                        </div>
                    </div>

                    <transactions-list
                        v-if="getPayment.type === 'credit'"
                        styles="margin-bottom: 24px"
                        :filter_payment="paymentId"
                        :transactions="creditTransactionsList"
                        @attBalancos="updateOption(group)"
                    />
                </div>

                <!-- SUMMARY CARD-->
                <summary-model
                    :header="summaryTitle"
                    :tableData="_summaryData"
                    :chartTitle="chartSeries.length ? chartTitle : ''"
                    :extraMargin="group === 'all'"
                >
                    <template v-if="getPayment.type === 'credit'" v-slot:progress-bar>
                        <credit-card-summary :creditCard="getPayment" />
                    </template>

                    <template v-else-if="group === 'all'" v-slot:progress-bar>
                        <general-balance :summaryData="_summaryData" />
                    </template>

                    <template v-if="chartSeries.length > 0" v-slot:chart>
                        <CategoryChart
                            :_options="chartOptions"
                            :series="chartSeries"
                            :categoriesID="chartCategoriesID"
                            :modalEvent="true"
                            @openCategoryModalEvent="openCategoryModal"
                        />
                    </template>
                </summary-model>
            </div>

            <transactions-list
                v-if="getPayment.type !== 'credit'"
                styles="margin-bottom: 24px"
                :filter_payment="paymentId"
                :transactions="transactionsList"
                @attBalancos="updateOption(group)"
            />
        </div>
        <!-- /.content -->

        <div class="overlay" v-if="categoryOn">
            <modal-transition :transform="transform">
                <categories-details
                    v-if="categoryOn"
                    :category="category"
                    :type="group"
                    :chartCategories="wavyCategories"
                    :userId="user._id"
                    :paymentId="paymentId"
                    @closeCategoryModalEvent="closeCategoryModal"
                    @updateView="changeMonth"
                />
            </modal-transition>
        </div>

        <div v-if="show">
            <modal-transition :duration="200">
                <confirmation-modal
                    v-if="confirmation"
                    :data="payload"
                    :text="text"
                    :strongText="strongText"
                    :isEdit="edit"
                    :recurrent="isRecurrent"
                    @closeModalEvent="closeConfirmationModal"
                />
            </modal-transition>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import TransactionsList from '../components/lists/TransactionsList.vue'
import ActionBar from '../components/ActionBar.vue'
import WavyChart from '../components/charts/WavyChart'
import SummaryModel from '../components/cards/summary-model/SummaryModel'
import CategoryChart from '../components/charts/CategoryChart'
import CategoriesDetails from '../components/modals/CategoriesDetailsModal'
import ConfirmationModal from '../components/modals/confirmation-modals/ConfirmationModal'
import ModalTransition from '@/components/transitions/ModalTransition'
import MonthSelector from '../components/headers/MonthSelector.vue'
import BalanceValue from '../components/headers/transactions-screen/BalanceValue.vue'
import CustomSelect from '../components/inputs/CustomSelect.vue'
import CreditCardSummary from '../components/transactions-screen/CreditCardSummary.vue'
import GeneralBalance from '../components/transactions-screen/GeneralBalance.vue'

import request from '../common/request'
import _date from '../common/formatDate'
import util from '../common/util'
import { summaryTransactions } from '../common/summary_configs'

export default {
    name: 'Transactions',

    components: {
        TransactionsList,
        ActionBar,
        WavyChart,
        SummaryModel,
        CategoryChart,
        CategoriesDetails,
        ConfirmationModal,
        ModalTransition,
        MonthSelector,
        BalanceValue,
        CustomSelect,
        CreditCardSummary,
        GeneralBalance,
    },

    data() {
        return {
            paymentId: null,
            categoryOn: false,
            filterTransactions: false,
            confirmation: false,
            show: false,
            payload: {},
            text: '',
            strongText: '',
            balanceTitle: 'movimento do mês',
            isRecurrent: null,
            edit: null,
            category: '',
            // opções do filtro de transações
            options: [
                { label: 'Todas', value: 'all' },
                { label: 'Receitas', value: 'revenues' },
                { label: 'Despesas', value: 'expenses' },
            ],
            group: 'all', // filtro atual (Todas)
            chartSeries: [],
            chartOptions: {
                labels: [],
            },
            chartCategoriesID: [],
            wavyChartCategories: [],
            transform: {
                translateX: 0,
                translateY: 100,
            },

            aditionalFilters: {},
            userId: '',

            residualInvoiceTransaction: undefined,
        }
    },

    computed: {
        ...mapState(['_date', '_date_selected', 'balancesChart', 'transactions', 'waiting', 'user', 'invoices']),

        ...mapGetters([
            'findById',
            'getInvoiceItemsByPaymentId',
            'createResidualTransaction',
            'filteredTransactions',
            'filteredCategories',
            'balanceActualMonth',
            'categoriesChartOptions',
            'getRecurrencyInvoiceSum',
            'isOttoFamily',
        ]),

        getPayment() {
            return this.findById('payments', this.paymentId) || {}
        },

        // Quando for cartão de crédito
        expensesValue() {
            const value = this.getPayment.value + this.getRecurrencyInvoiceSum(this.paymentId)

            return value > 0 ? 0 : value
        },

        _summaryData() {
            if (this.paymentId && this.getPayment.type === 'credit') {
                return [
                    {
                        title: 'LIMITE TOTAL',
                        color: '#028FFF',
                        value: this.getPayment.limit,
                    },
                    {
                        title: 'Fatura fecha',
                        color: '#028FFF',
                        value: this.invoiceDeadline(),
                    },
                ]
            } else {
                return summaryTransactions({
                    type: this.group,
                    payment: this.paymentId,
                    user: this.user._id,
                })
            }
        },

        chartTitle() {
            if (this.group === 'revenues') return 'Receitas por categoria'
            return 'Despesas por categoria'
        },

        summaryTitle() {
            if (!this.paymentId) {
                switch (this.group) {
                    case 'all':
                        return 'Seu orçamento'
                    case 'revenues':
                        return 'Suas receitas'
                    case 'expenses':
                        return 'Suas despesas'
                    default:
                        return ''
                }
            }
            return ''
        },

        balançosGrafico() {
            // evitar alguns warnings do vuex a respeito de mutação de estado
            return [...this.balancesChart]
        },

        transactionsList: function () {
            if (this.paymentId) {
                if (this.getPayment.type === 'credit') {
                    return [...this.getInvoiceItemsByPaymentId(this.paymentId)]
                }
                return this.filteredTransactions(this.group, this.aditionalFilters, this.userId)
            }
            return this.filteredTransactions(this.group, this.aditionalFilters, this.userId)
        },

        creditTransactionsList() {
            if (this.residualInvoiceTransaction) return [...this.transactionsList, this.residualInvoiceTransaction]
            return this.transactionsList
        },

        wavyCategories() {
            return _date.getMonths(this._date_selected.inicio)
        },
    },

    methods: {
        openCategoryModal(config) {
            this.categoryOn = config.state
            this.category = config.category
        },

        closeCategoryModal() {
            this.categoryOn = false
        },

        percentage(n, d) {
            return util.percentage(n, d)
        },

        openTransactionsFilter() {
            this.filterTransactions = !this.filterTransactions
        },

        openConfirmationModal(modal) {
            this.payload = modal.payload
            this.text = modal.text
            this.strongText = modal.strongText
            this.isRecurrent = modal.isRecurrent
            this.edit = modal.edit
            this.confirmation = this.show = true
        },

        closeConfirmationModal() {
            this.confirmation = false
            setTimeout(() => (this.show = false), 200)
        },

        updateOption(type) {
            this.group = type
            this.updateChart(type, this.paymentId)
        },

        updateChart(group, invoice) {
            let options
            this.chartSeries = []
            this.chartOptions.labels = []

            if (group !== 'all') {
                options = this.categoriesChartOptions(group, invoice, this.user._id)

                this.chartSeries = options.chartSeries
                this.chartOptions = { ...options.chartOptions }
                this.chartCategoriesID = [...options.categories_id]
            }
        },

        changeMonth() {
            this.$store.commit('DISABLED')
            this.$store.commit('WAITING', true)

            const paymentId = this.getPayment.type !== 'credit' ? this.getPayment._id : undefined

            request
                .changeMonth(this._date_selected, paymentId, false, this.isOttoFamily)
                .then(() => {
                    this.updateChart(this.group, this.paymentId)
                    this.wavyChartCategories = _date.getMonths(this._date_selected.inicio)
                })
                .finally(() => {
                    this.$store.commit('DISABLED')
                    this.$store.commit('WAITING')
                })
        },

        addEventListener() {
            this.$root.$on('attBalancosMensais', () => {
                this.updateOption(this.group)
            })
        },

        titleView(id) {
            let title = 'MOVIMENTAÇÕES | '

            if (id) {
                const payment = this.findById('payments', id)

                if (payment) {
                    if (payment.type === 'debit') {
                        return title + payment.name
                    } else if (payment.type === 'credit') {
                        this.balanceTitle = 'Valor da fatura'
                        return (title = 'FATURA CARTÃO DE CRÉDITO ' + payment.name)
                    } else {
                        return payment.name
                    }
                }
            } else {
                return title
            }
        },

        invoiceDeadline() {
            const actualDate = this.$moment()
            let nextInvoice = actualDate.clone()

            if (actualDate.date() > this.getPayment.invoiceCloseOn)
                nextInvoice.add(1, 'M').date(this.getPayment.invoiceCloseOn)
            else nextInvoice.date(this.getPayment.invoiceCloseOn)

            const diff = nextInvoice.diff(actualDate, 'days')

            if (diff < 1) {
                const hoursDiff = nextInvoice.diff(actualDate, 'hours')

                if (!hoursDiff) return 'Hoje'

                return `${hoursDiff} ${hoursDiff === 1 ? 'hora' : 'horas'}`
            } else {
                return `${diff} ${diff === 1 ? 'dia' : 'dias'}`
            }
        },
    },

    watch: {
        transactions: {
            immediate: true,
            handler(p) {
                if (this.paymentId) this.residualInvoiceTransaction = this.createResidualTransaction(this.paymentId)

                if (p && this.getPayment) {
                    if (this.getPayment.type === 'credit') this.updateOption('expenses')
                }
            },
        },
    },

    beforeDestroy() {
        if (this.paymentId && this.getPayment.type !== 'credit') {
            request.changeMonth(this._date_selected, undefined, true)
        }

        this.$root.$off('attBalancosMensais')
        this.$root.$off('openConfirmationModal')
    },

    mounted() {
        this.addEventListener()
    },

    beforeRouteEnter(to, from, next) {
        if (to.name === 'payment_transactions') {
            next((vm) => {
                vm.paymentId = vm.$route.params.payment

                if (!vm.getPayment) return

                if (vm.getPayment.type === 'credit') vm.updateOption('expenses')
                else
                    request.fetch({
                        collection: 'balancesChart',
                        queryParams: { ...vm._date_selected, paymentId: vm.paymentId, family: vm.isOttoFamily },
                    })
            })
        } else
            next((vm) => {
                vm.balanceTitle = 'movimento do mês'
                vm.changeMonth()
            })
    },

    async beforeRouteLeave(to, from, next) {
        if (to.name === 'transactions') {
            this.updateOption('all')
            this.paymentId = null
        }

        next()
    },
}
</script>

<style lang="scss" scoped>
fieldset {
    margin: 0;
    padding: 0;
}

.chart-container {
    box-shadow: 0px 0px 6px #00000026;
}

.menu-bar {
    height: 55px;
    padding: 0px 20px 0px;
    color: $text-black;
    border-bottom: solid 2px #eef1f6;
    border-radius: 2px;
    text-align: center;
}

.flex {
    display: flex;
}

.grow {
    flex-grow: 1;
}

.center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.box {
    align-items: center;
    text-align: center;
    vertical-align: middle;
}

.transaction-list {
    min-width: 762px;
    margin-bottom: 16px;
    position: relative;
}
</style>
