<template>
    <div class="line-chart">
        <v-skeleton-loader
            v-if="loading"
            style="padding: 0 20px 20px"
            :height="160"
            type="legend, image"
            :types="{ legend: 'table-cell@2' }"
        />

        <apexcharts v-else width="100%" height="180" type="line" ref="line" :options="chartOptions" :series="series" />
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import util from '../../common/util'
import { mapState } from 'vuex'

export default {
    name: 'LineChart',

    components: {
        apexcharts: VueApexCharts,
    },

    props: {
        _options: Object,
        series: Array,
        _loading: Boolean,
    },

    data() {
        return {
            chartOptions: {
                colors: ['#37C2CE', '#EF8A97'],
                chart: {
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                    fontFamily: 'Metropolis',
                },
                dataLabels: {
                    enabled: false,
                },
                legend: {
                    show: true,
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    markers: {
                        radius: 0,
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            let valor = Number(Math.abs(value)).toFixed(2)
                            return value < 0 ? '-' + util.sanitizeChartLabels(valor) : util.sanitizeChartLabels(valor)
                        },
                    },
                },
                stroke: {
                    width: 2,
                    curve: 'straight',
                    /* colors: ['whitesmoke', 'whitesmoke'], */
                },
                grid: {
                    strokeDashArray: 3,
                },
                tooltip: {
                    y: {
                        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                            return util.formatNumber(value)
                        },
                    },
                },
            },
        }
    },

    methods: {
        updateChart(options) {
            if (this.$refs.line)
                this.$refs.line.updateOptions(
                    {
                        xaxis: {
                            categories: options.xaxis.categories,
                        },
                    },
                    true,
                    true
                )

            // this.$refs.wave.updateSeries(this.chartSeries, true)
        },
    },

    computed: {
        ...mapState(['waiting']),

        loading() {
            return this.waiting || this._loading
        },
    },

    updated() {
        this.updateChart(this._options)
    },

    mounted() {
        this.chartOptions = Object.assign({}, this.chartOptions, this._options)
    },
}
</script>
