<template>
    <div class="transactions-list">
        <h1>{{ cardTitle }}</h1>

        <ul class="t-list" v-if="waiting">
            <li v-for="(n, index) in 5" style="height: 60px" :key="index">
                <div class="list-item" style="padding: 0">
                    <div class="left-col">
                        <v-skeleton-loader type="head" style="margin: auto" :types="types" />

                        <v-skeleton-loader
                            type="arms"
                            style="max-width: 150px"
                            :width="randomWidth(100)"
                            :types="types"
                        />
                    </div>

                    <div class="value-payment">
                        <v-skeleton-loader
                            type="foot"
                            style="max-width: 120px"
                            :width="randomWidth(70)"
                            :types="types"
                        />
                    </div>
                </div>

                <div class="border"></div>
            </li>
        </ul>

        <transition-group name="list" tag="ul" v-else-if="sortedTransactions.length > 0 && !waiting" class="t-list">
            <li v-for="transaction in sortedTransactions" :key="transaction._id">
                <div class="list-item">
                    <div class="left-col">
                        <div class="item-icons">
                            <div
                                class="list-item_icon"
                                :class="[
                                    transaction.paid
                                        ? sanitize(getDefaultCategoryById(transaction.category).name)
                                        : 'unpaid',
                                    transaction.category.group,
                                ]"
                                :style="{
                                    backgroundImage:
                                        'url(' +
                                        require('@/assets/img/categories/' +
                                            sanitize(transaction.category.group) +
                                            '-' +
                                            sanitize(getDefaultCategoryById(transaction.category).name) +
                                            '.png') +
                                        ')',
                                }"
                            ></div>

                            <div v-if="isOttoFamily" class="family-user">
                                <avatar :avatar="transaction.user.image" :letter="transaction.user.name.charAt(0)" />
                            </div>
                        </div>

                        <div class="item-category">
                            <span>{{ transaction.name }}</span>
                            <h4>
                                {{ transaction.category.name }}
                            </h4>
                        </div>
                    </div>

                    <div class="value-payment">
                        <h3 class="expenses">{{ Math.abs(transaction.value) | currency }}</h3>
                        <h4>{{ transaction.payment.name }}</h4>
                    </div>
                </div>

                <div class="border"></div>
            </li>
        </transition-group>

        <ul v-else>
            <li class="t-list">
                <div class="border"></div>

                <div class="list-item">
                    <p class="no-data">Nenhum pagamento para amanhã</p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import sort from '@/common/sort'
import util from '@/common/util'
import Avatar from '../icons/Avatar.vue'

export default {
    components: { Avatar },
    name: 'NextTransactionsList',
    props: {
        cardTitle: {
            type: String,
            required: true,
        },
        transactionType: {
            type: String,
        },
        transactionsList: {
            type: Array,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            types: {
                head: 'avatar',
                arms: 'list-item-two-line',
                foot: 'list-item-two-line',
            },
        }
    },

    methods: {
        sanitize(string) {
            return util.sanitize(string)
        },

        randomWidth(base) {
            return util.randomWidth(base)
        },
    },

    computed: {
        ...mapState(['waiting']),

        ...mapGetters(['getDefaultCategoryById', 'isOttoFamily']),

        sortedTransactions() {
            return this.transactionsList.sort(sort.byDate).slice(0, 5)
        },
    },
}
</script>

<style lang="scss" scoped>
.transactions-list {
    @include rounded-box();
    @include box-shadow();
    overflow: hidden;

    h1 {
        padding: 22px 24px 16px;
        @include card-title();
        border-bottom: 2px solid #eef1f6;
    }

    .border {
        box-sizing: border-box;
        border-bottom: 1px solid #eef1f6;
    }

    .t-list {
        margin: 0 15px 0 15px;

        li:last-child {
            .border {
                display: none;
            }
        }

        h2 {
            padding: 6px;
            background-color: $lighter-grey;
            @include date-title();
            text-align: center;

            .capital-begin {
                text-transform: capitalize;
            }
        }

        .list-item {
            @include flex-between();
            padding: 12px 0;
            background-color: white;
            min-width: 290px;

            .no-data {
                font-size: 14px;
                color: $title-grey;
            }

            .left-col {
                @include flex-between();

                .item-category {
                    display: inline-block;
                    width: 140px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-left: 10px;
                }
            }

            .item-icons {
                margin-right: 8px;
                position: relative;

                .list-item_icon {
                    display: inline-block;
                    width: 28px;
                    height: 28px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    border-radius: 50%;
                }

                .family-user {
                    position: absolute;
                    left: 15px;
                    top: -1px;
                    display: inline-block;
                    background: transparent;
                    border-radius: 50%;
                    overflow: hidden;
                    z-index: 1;
                    transform: scale(0.8);
                }
            }

            .item-category h3 {
                margin-bottom: 4px;
                @include list-item();
                letter-spacing: initial;
            }

            .item-category h4,
            .value-payment h4 {
                margin-top: 4px;
                @include list-category();
                letter-spacing: initial;
            }

            .expenses {
                color: $expense-red;
            }

            .revenues {
                color: $revenue-green;
            }

            .investments {
                color: $light-blue;
            }

            .value-payment {
                text-align: right;

                h3 {
                    margin-bottom: 4px;
                    @include std-value();
                }
            }
        }
    }
}
</style>
