export const categoriesList = {
    faturacartao: '#9f1b0f',
    pets: '#b5b93c',
    compras: '#b14cff',
    cuidadospessoais: '#2dd0b0',
    transporte: '#6db0e5',
    telefonia: '#45b5d8',
    telefoneinternet: '#45b5d8',
    credito: '#2980b9',
    contasresidenciais: '#ff4c93',
    saude: '#eb4242',
    educacao: '#576da5',
    lazer: '#4ec4c4',
    servicos: '#e9ba00',
    alimentacao: '#e8b546',
    moradia: '#ff4cf3',
    investimento: '#00446c',
    outros: '#e873b0',
    diversos: '#e873b0',
    objetivo: '#00446c',
    rendafixa: '#2fbc9c',
    salario: '#2fbc9c',
    rendaextra: '#27a085',
    emprestimo: '#55be71',
    rendimento: '#86f0e7',
    beneficio: '#e67e22',
    resgate: '#9b59b6',
    cartao: 'none',
    contabancaria: '#9f261a',
    movimentacaobancaria: '#9f261a',
    objetivos: '#fa6437',
    outrasrendas: '#37cc71',
    mercado: '#dda018',
    assinaturas: '#4c63e0',
    doacoespresentes: '#cf6a87',
    impostos: '#696969',
    investimentos: '#9372ef',
    seguros: '#c651f0',
    servicos: '#9db2d9',
    taxasbancarias: '#c35471',
    trabalho: '#67ba6a',
    viagem: '#82ccdd',
    aluguel: '#e74c3b',
    bonus: '#f1c40d',
    comissao: '#3498db',
    heranca: '#34495e',
    mesada: '#8e44ad',
    presente: '#2dae60',
    juros: '#e6a8a8',
}

export const alphabet = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
]

export const expertisesDictonary = {
    financas: 'finanças',
    dividas: 'dívidas',
}
