<template>
    <div class="bank-transition-modal">
        <div class="container colunm flex modal">
            <header class="head" :class="[type]">
                <h1>MOVIMENTAÇÃO BANCÁRIA</h1>

                <button class="close" type="button" @click.stop="closeModal"><ion-icon name="close"></ion-icon></button>
            </header>

            <form @submit="checkForm" class="form-container">
                <div class="flex flex-around tab-menu" :class="[type]">
                    <label
                        class="tab-label"
                        @click="changeMovimentType('deposito', 'deposit')"
                        :class="{ active: isActive('deposito') }"
                    >
                        <div></div>
                        <div>Depósito</div>
                    </label>

                    <label
                        class="tab-label"
                        @click="changeMovimentType('saque', 'withdraw')"
                        :class="{ active: isActive('saque') }"
                    >
                        <div></div>
                        <div>Saque</div>
                    </label>

                    <label
                        class="tab-label"
                        @click="changeMovimentType('transferencia', 'transfer')"
                        :class="{ active: isActive('transferencia') }"
                    >
                        <div></div>
                        <div>Transferência</div>
                    </label>
                </div>

                <div class="container-inputs flex colunm">
                    <stacked-input
                        label="Valor"
                        ref="money"
                        :required="true"
                        :focus="true"
                        style="margin-right: 10px; width: 100%"
                        :money="money"
                        textAlign="right"
                        :font="24"
                        :errorValidation="error[0]"
                        :errorMsg="valueMsg"
                        v-model="bankTransition.value"
                    />

                    <stacked-input
                        label="data"
                        type="date"
                        :max="max"
                        style="width: 50%"
                        :required="true"
                        :errorValidation="error[1]"
                        v-model="bankTransition.date"
                    />

                    <stacked-select
                        v-if="type !== 'deposito'"
                        class="form-el"
                        :previousSelected="previous"
                        label="Conta de origem"
                        :required="true"
                        :errorValidation="error[2]"
                        :errorMsg="msg"
                        :model="filteredPayments('debit', true)"
                        @change="updateSourceAccount"
                    />

                    <stacked-select
                        v-if="type !== 'saque'"
                        class="form-el"
                        :previousSelected="previous"
                        label="Conta de destino"
                        :errorValidation="error[3]"
                        :required="true"
                        :errorMsg="msg"
                        :model="filteredPayments('debit', true)"
                        @change="updateDestinationAccount"
                    />

                    <div v-if="type === 'saque'" class="text-box">
                        Ao realizar um saque, uma despesa é cadastrada na conta de origem, enquanto que uma receita é
                        cadastrada em sua carteira Dinheiro
                    </div>
                </div>

                <basic-button
                    :label="`Nov${type === 'transferencia' ? 'a' : 'o'} ${formatedType}`"
                    type="submit"
                    :loading="disabled"
                    :bgColor="bgColor"
                    width="88%"
                    data-cy="submit-button-transition-modal"
                />
            </form>
        </div>
    </div>
</template>

<script>
import StackedInput from '../../components/inputs/StackedInput'
import StackedSelect from '../../components/inputs/StackedSelect'
import util from '../../common/util'
import date from '../../common/formatDate'
import request from '@/common/request'
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import money from 'v-money'
import BasicButton from '../buttons/BasicButton.vue'

Vue.use(money, { precision: 4 })

export default {
    name: 'BankTransitionModal',
    components: {
        StackedInput,
        StackedSelect,
        BasicButton,
    },
    props: {
        modalData: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            type: null,
            reset: false,
            previous: {},
            bankTransition: {
                bankingOperationType: 'deposit',
                paid: true,
            },
            max: null,
            error: [],
            valueMsg: undefined,
            msg: undefined,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: util.returnCurrencyPrefix().prefix + ' ',
                precision: 2,
                masked: false,
            },
        }
    },

    methods: {
        closeModal() {
            this.$emit('modalClosedEvent')
        },

        changeMovimentType(type, translate) {
            this.type = type
            this.updateAccounts()
            this.bankTransition.bankingOperationType = translate
            this.bankTransition.date = this.max
            this.error = []
            this.previous = {}
        },

        updateAccounts() {
            delete this.bankTransition['bankingOperationPayment']
            delete this.bankTransition['payment']
            delete this.bankTransition['value']

            switch (this.type) {
                case 'deposito':
                    this.bankTransition.bankingOperationPayment = this.findMoney
                    break
                case 'saque':
                    this.bankTransition.payment = this.findMoney
                    break
                default:
                    break
            }
        },

        newBankingOperation() {
            let _data = { ...this.bankTransition }

            _data.transactionType = 'revenues'
            _data.category = this.filteredCategories('bankOperation')[0]._id
            _data.value = util.sanitizeMoney(_data.value)

            this.$store.commit('DISABLED')

            request
                .post({ collection: 'transactions', data: _data })
                .then(async () => {
                    await request.fetch([
                        { collection: 'transactions', queryParams: this._date_selected },
                        { collection: 'payments' },
                    ])

                    this.closeModal()
                })
                .catch((err) => console.error(err))
                .finally(() => this.$store.commit('DISABLED'))
        },

        checkForm: function (e) {
            e.preventDefault()

            let error = Array(4).fill(false)
            let errorCount = 0

            const payment = this.findById('payments', this.bankTransition.bankingOperationPayment)

            if (payment) {
                const [roundPaymentValue, roundValue] = [
                    Number(payment.value.toFixed(2)),
                    Number(util.sanitizeMoney(this.bankTransition.value)),
                ]

                if (roundValue > roundPaymentValue) {
                    error[0] = true
                    this.valueMsg = 'SALDO INSUFICIENTE'
                    errorCount++
                }
            }

            if (!this.bankTransition.date) {
                error[1] = true
                errorCount++
            }

            if (!this.bankTransition.bankingOperationPayment) {
                error[2] = true
                errorCount++
            }

            if (!this.bankTransition.payment) {
                error[3] = true
                errorCount++
            }

            if (
                this.bankTransition.payment === this.bankTransition.bankingOperationPayment &&
                this.bankTransition.payment
            ) {
                error[2] = true
                error[3] = true
                this.msg = 'MESMA CONTA'
                errorCount += 2
            }

            this.error = error

            if (errorCount === 0) this.newBankingOperation()
        },

        resetForm() {
            this.reset = !this.reset
        },

        isActive(type) {
            return type === this.type
        },

        updateSourceAccount(value) {
            this.bankTransition.bankingOperationPayment = value
        },

        updateDestinationAccount(value) {
            this.bankTransition.payment = value
        },
    },

    watch: {
        findMoney: function (money) {
            this.bankTransition.bankingOperationPayment = money
        },
    },

    computed: {
        ...mapState(['_date_selected', 'disabled']),

        ...mapGetters(['filteredPayments', 'findMoney', 'findById', 'filteredCategories']),

        bgColor() {
            switch (this.type) {
                case 'transferencia':
                    return '#3f63ed'
                case 'saque':
                    return '#ef8a97'
                default:
                    return '#37c2ce'
            }
        },

        formatedType() {
            switch (this.type) {
                case 'transferencia':
                    return 'transferência'
                case 'deposito':
                    return 'depósito'
                default:
                    return this.type
            }
        },
    },

    mounted() {
        let today = date.inputFormatDate(new Date())

        this.type = this.modalData
        this.bankTransition.bankingOperationPayment = this.findMoney
        this.max = this.bankTransition.date = today
    },
}
</script>

<style lang="scss" scoped>
@include tab-menu();

.modal {
    height: 500px;
    width: 380px;
    border-radius: 8px;
    background-color: #ffffff;
    overflow: hidden;
}

.error-msg-container {
    position: absolute;
    height: 0;
    right: 15px;
    top: 25px;
    margin-top: 3px;
    font-size: 13px;
    color: #d0021b;
    text-align: right;
    padding-right: 2px;

    .fa-exclamation-circle {
        position: relative;
        z-index: 999;
        bottom: 8px;
        font-size: 18px;
    }
}

.container {
    margin: 0 auto;
}

.form-container {
    position: relative;
    height: 100%;
    width: 100%;
    text-align: center;

    .container-inputs {
        padding: 24px;
        padding-top: 4px;
    }
}

.form-el {
    width: 100%;
}

.head {
    position: relative;
    padding-top: 20px;
    width: 100%;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    font-weight: 500;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 18px 16px;
    font-size: 20px;
    color: white;
}

.icon {
    margin-right: 8px;
    font-size: 20px;
    vertical-align: middle;
    color: #ffffff;
}

.icon-color {
    color: #999999;
}

.thumb-active {
    color: #63b9f2;
}

.fa-thumbs-up {
    font-size: 16px;
    line-height: 19px;
    color: #999999;
    padding-top: 16px;
}

.input-value {
    position: relative;
    z-index: 999;
    background: none;
    border: none;
    height: 30px;
    font-size: 25px;
    font-weight: 600;
    text-align: right;
    color: #ffffff;
}

.btn-thumb {
    color: #999999;
    position: relative;
    top: 8px;
    margin-left: 35px;
}

.basic-button {
    position: absolute;
    left: 24px;
    bottom: 24px;
}

.text-box {
    margin-top: 16px;
    color: #848484;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
}

.content-head {
    color: white;
    padding: 24px 24px 24px 41px;
}

.label-title {
    font-size: 16px;
    font-weight: 500;
}

.deposito {
    background-color: #37c2ce !important;
}

.saque {
    background-color: #ef8a97 !important;
}

.transferencia {
    background-color: #3f63ed !important;
}

.flex {
    display: flex;
}

.colunm {
    flex-direction: column;
}

.flex-between {
    justify-content: space-between;
}

.flex-around {
    justify-content: space-around;
}

.center {
    align-items: center;
}

input::placeholder {
    color: white;
}

.tab-label {
    font-size: 14px !important;
}
</style>
