<template>
    <div class="update-subscription" :style="styles">
        <header>
            <router-link to="/painel">
                <h1>
                    <img :src="$variables.brandIcon" alt="brand" />
                </h1>
            </router-link>
        </header>

        <form>
            <p class="alert-message">O que gostaria de fazer?</p>

            <basic-button
                label="MUDAR FORMA DE PAGAMENTO"
                width="100%"
                @click.native="$router.push('/assinatura/mudar-pagamento')"
            />

            <basic-button
                label="MUDAR plano"
                width="100%"
                :reverse="true"
                @click.native="$router.push('/assinatura/mudar-plano')"
            />

            <div style="display: flex; justify-content: center">
                <a @click="endProcess">NADA, MUDEI DE IDEIA</a>
            </div>
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { checkSubscription } from '../../common/checkSubscription'
import util from '../../common/util'
import BasicButton from '../../components/buttons/BasicButton.vue'

export default {
    components: { BasicButton },

    name: 'UpdateSubscription',

    data() {
        return {
            step: 1,
        }
    },

    computed: {
        ...mapState(['subscriptions']),

        styles() {
            return {
                '--background': `url(${this.$variables.background})`,
            }
        },
    },

    watch: {
        subscriptions: {
            deep: true,
            immediate: true,
            async handler(subscriptions) {
                if (!subscriptions.length) {
                    await checkSubscription()
                }
            },
        },
    },

    methods: {
        endProcess() {
            const device = util.identifyUserDevice()

            if (device === 'mobile') window.location.href = `${process.env.VUE_APP_HOST_URL}/assine?sucesso`
            else this.$router.push('/configuracoes/plano')
        },
    },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';

header,
.alert-message {
    margin-bottom: 32px;
}

.alert-message {
    text-align: center;
    color: #1e3351;
}

.basic-button:nth-child(3) {
    margin-top: 10px;
}

form {
    a {
        margin-top: 32px;
    }
}
</style>
