import store from '../store'
import { environment } from './environment'
import _date from './formatDate'
import moment from 'moment'
import mutationHelper from '../store/helpers/mutation.helper'

const api_url = environment.API_URL

/**
 * Fetch uma coleção
 * @param {Object | String} params - paramêtros da requisição | coleção
 * @param {String} params.collection - nome da coleção
 * @param {String} params.attr - nome do atributo no storage. **Obs:** Obrigatório quando o atributo difere do nome da coleção
 */

const fetchCollection = async (params) => {
    const attr = params.attr || params.collection || params
    let collectionUrl = params.collection || params
    collectionUrl = collectionUrl.replace('Chart', '')

    return await store.dispatch({
        type: 'FETCH_DATA',
        payload: {
            url: api_url + collectionUrl + generateURLQuery(params),
            mutation: 'SET_DATA',
            attr,
        },
    })
}

/**
 * Recupera um documento através do seu _id
 * @param {Object} params - paramêtros da requisição
 * @param {String} params.collection - nome da coleção
 * @param {String} params._id - id do documento
 */

const getById = async (params) => {
    return await store.dispatch({
        type: 'GET_BY_ID',
        payload: {
            url: api_url + params.collection + '/' + params._id,
            attr: params.collection,
        },
    })
}
/**
 *
 * @param {Object} payload - Payload com os dados
 * @param {String} payload.collection - Coleção de dados da busca
 * @param {Object} payload.queryParams - parametros de busca enviadas na URL
 * @param {String} payload.queryParams.inicio - data de inicio `(AAAA-MM-DD)` da busca por data
 * @param {String} payload.queryParams.fim - data de término `(AAAA-MM-DD)` da busca por data
 * @param {String} payload.queryParams.paymentId - ID do pagamento da busca
 * @param {Boolean} payload.queryParams.family - Flag que indica se o conta assina um plano family
 * @param {Number} payload.queryParams.chartPeriod - Período que gráfico ira cubrir em meses `default: 6`
 * @param {Boolean} payload.queryParams.ignoreInvoicePeriod - Quando enviado ignora o período da fatura do cartão
 * @returns
 */
function generateURLQuery(payload) {
    const { collection } = payload

    if (payload.queryParams) {
        let { inicio, fim, paymentId, family, chartPeriod, chartType, ignoreInvoicePeriod } = payload.queryParams

        if (!chartPeriod) chartPeriod = 5

        switch (collection) {
            case 'transactions':
            case 'balances':
            case 'budgets':
                return (
                    `?startDate=${inicio}&endDate=${fim}` +
                    `${paymentId ? `&paymentId=${paymentId}` : ''}` +
                    `${family ? `&family=true` : ''}`
                )
            case 'balancesChart':
                const _inicio = moment(inicio).add(-chartPeriod, 'M').format('YYYY-MM-DD')

                return (
                    `?type=chart${chartType ? `&chartType=${chartType}` : ''}` +
                    `&startDate=${_inicio}&endDate=${fim}` +
                    `${paymentId ? `&paymentId=${paymentId}` : ''}` +
                    `${family ? `&family=true` : ''}`
                )
            case 'invoices':
                return (
                    '?month=' +
                    _date.getMonth(inicio, true) +
                    '&year=' +
                    _date.getYear(inicio, true) +
                    `${ignoreInvoicePeriod ? '&ignoreInvoicePeriod=true' : ''}`
                )
            default:
                return ''
        }
    } else {
        return ''
    }
}

/**
 * Atualiza determidas coleções pelo mês selecionado
 * @param {Object} date - objeto contendo a data do inicio e o fim do mês com o formato DD-MM-YYYY
 */

const changeMonth = async (date, paymentId, onlyBalances, family) => {
    if (!onlyBalances) await fetch({ collection: 'invoices', queryParams: { ...date } })

    const promises = [
        { collection: 'transactions', queryParams: { ...date } },
        { collection: 'budgets', queryParams: { ...date } },
        { collection: 'balances', queryParams: { ...date } },
        { collection: 'balancesChart', queryParams: { ...date, paymentId, family } },
    ]

    if (onlyBalances) {
        promises.splice(0, 2)
    }

    await fetch(promises)
}

/**
 * Realiza o fetch de uma ou várias coleções
 * @param {Array | Object} payloads - array de objetos contendo os parametros da requisição.
 * Pode-se também enviar somente o objeto caso seja só um payload
 */
const fetch = (payloads) => {
    let promises

    if (typeof payloads === 'object' && Array.isArray(payloads)) {
        promises = []

        payloads.forEach((payload) => {
            promises.push(!payload.byId ? fetchCollection(payload) : getById(payload))
        })

        return Promise.all(promises)
    } else {
        return fetchCollection(payloads)
    }
}

/**
 * Realiza uma requisição POST numa determinada coleção
 * @param {Object} params - paramêtros da requisição
 * @param {String} params.collection - nome da coleção
 * @param {Object} params.data - documento a ser salvo
 * @param {String} params.subroute - subrota de uma rota
 * @param {String} params.mutation - vuex mutation a ser usada
 * @param {Boolean} params.noUserId - quando `true` não envia o userId no body da requisição
 */

const post = async (params) => {
    let subroute = params.subroute || ''
    const { data, mutation, noUserId } = params

    return await store.dispatch({
        type: 'POST_DATA',
        payload: {
            url: api_url + params.collection + subroute,
            data,
            attr: params.collection,
            mutation,
            noUserId,
        },
    })
}

/**
 * Realiza uma requisição PATCH numa determinada coleção
 * @param {Object} params - paramêtros da requisição
 * @param {String} params.collection - nome da coleção
 * @param {Object} params.data - atualizações do documento
 * @param {String} params._id - id do documento a ser atualizado (No caso da coleção invoices esse id so é utilizado para localizar o subdocumento contido que será atualizado)
 * @param {String} params.sub_id - id do subdocumento a ser atualizado (Necessário somente pela coleção invoices onde este id representa o documento a ser atualizado)
 * @param {String} params.queryParams - paramêtros de busca da requisição
 */

const patch = async (params) => {
    let sub_id = params.sub_id ? '/' + params.sub_id : ''
    let queryParams = params.queryParams || ''

    return await store.dispatch({
        type: 'PATCH_DATA',
        payload: {
            url: api_url + params.collection + '/' + params._id + sub_id + queryParams,
            data: params.data,
            applyForAll: queryParams.includes('applyForAll=true') ? true : false,
            attr: params.collection,
        },
    })
}

/**
 * Realiza uma requisição DELETE numa determinada coleção
 * @param {Object} params - paramêtros da requisição
 * @param {String} params.collection - nome da coleção
 * @param {String} params._id - id do documento a ser deletado (No caso da coleção invoices esse id so é utilizado para localizar o subdocumento contido que será deletado)
 * @param {String} params.sub_id - id do subdocumento a ser deletado (Necessário somente pela coleção invoices onde este id representa o documento a ser deletado)
 * @param {String} params.queryParams - paramêtros de busca da requisição
 */

const remove = async (params) => {
    let sub_id = params.sub_id ? '/' + params.sub_id : ''
    let queryParams = params.queryParams || ''

    return await store.dispatch({
        type: 'DELETE_DATA',
        payload: {
            url: api_url + params.collection + '/' + params._id + sub_id + queryParams,
            mutation_params: params.data || {},
            applyForAll: queryParams ? true : false,
            attr: params.collection,
        },
    })
}

/**
 * Realiza uma requisição POST e deleta o elemento mutation_params._id da coleção
 * @param {Object} params - paramêtros da requisição
 * @param {String} params.collection - nome da coleção
 * @param {String} mutation_params._id - id do elemento a ser deletado
 */

const removeElement = async (params) => {
    let subroute = params.subroute || ''
    const { data, mutation, mutation_params } = params

    return await store.dispatch({
        type: 'POST_DATA_ELEMENT',
        payload: {
            url: api_url + params.collection + subroute,
            attr: params.collection,
            mutation_params: mutation_params || {},
            data: data,
            mutation,
        },
    })
}

export default {
    fetch,
    post,
    patch,
    remove,
    removeElement,
    changeMonth,
    getById,
}
