<template>
    <div class="paymentsCard flex column">
        <!-- TITULO -->
        <div class="title flex center space-between">
            <div>{{ title }}</div>
        </div>

        <div v-if="waiting" class="content">
            <div v-for="(n, index) in paymentType === 'Dinheiro' ? 1 : 2" :key="index" class="grid-template">
                <div class="flex payment center">
                    <div style="width: 30px">
                        <v-skeleton-loader type="list_avatar" :types="{ list_avatar: 'avatar' }" />
                    </div>

                    <div class="flex item center space-between">
                        <div style="width: 150px">
                            <v-skeleton-loader class="name sub-item" :width="randomWidth(50)" type="text" />
                        </div>

                        <div v-if="paymentType === 'Cartões de Crédito'" class="center space-between">
                            <span class="progress-title">Uso do limite</span>
                            <v-skeleton-loader :width="160" type="text" />
                        </div>

                        <div style="width: 150px; justify-content: flex-end; display: flex">
                            <v-skeleton-loader class="name sub-item" :width="randomWidth(50)" type="text" />
                        </div>
                    </div>

                    <div v-if="paymentType !== 'Dinheiro'" style="width: 60px"></div>
                </div>
            </div>
        </div>

        <!-- LISTA -->
        <transition-group name="list" tag="div" v-else class="content">
            <div data-cy="payments-card-list" v-for="payment in payments" :key="payment._id" class="grid-template">
                <!-- ITEM DA LISTA -->
                <div class="flex payment center space-between">
                    <!-- ICONE -->
                    <router-link :to="/movimentacoes/ + payment._id" class="flex center icon-content">
                        <i v-if="payment.type === 'credit'">
                            <img class="flag" :src="'img/payments/' + sanitize(payment.flag) + '.png'" />
                        </i>

                        <i v-if="payment.type === 'money'">
                            <img src="@/assets/img/payments/dinheiro.svg" />
                        </i>

                        <i v-if="payment.accountType === 'Conta corrente'">
                            <img src="@/assets/img/payments/contacorrente.svg" alt="" />
                        </i>

                        <i v-if="payment.accountType === 'Poupança'">
                            <img src="img/payments/poupanca.svg" alt="" />
                        </i>
                    </router-link>

                    <!-- INFO -->
                    <router-link :to="/movimentacoes/ + payment._id" class="flex item center space-between">
                        <div class="name sub-item">
                            {{ payment.name }}
                            <badge v-if="!payment.externalId && payment.type !== 'money'" label="Manual" />
                        </div>

                        <div v-if="paymentType === 'Cartões de Crédito'" class="center space-between">
                            <span class="progress-title">Uso do limite</span>

                            <div class="progress-bar-container">
                                <div class="progress-bar-border gray">
                                    <div
                                        class="progress-bar"
                                        style="background-color: #ef8a97"
                                        :style="{
                                            width: percentage(Math.abs(expensesValue(payment)), payment.limit) + '%',
                                        }"
                                    ></div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="valor"
                            :class="[
                                (payment.type === 'credit' && balanceActualMonth(payment._id) > 0) ||
                                (payment.type !== 'credit' && Math.trunc(payment.value) > 0)
                                    ? 'blue'
                                    : 'red',
                            ]"
                        >
                            {{
                                payment.type === 'credit'
                                    ? Math.abs(balanceActualMonth(payment._id))
                                    : Math.abs(payment.value) | currency
                            }}
                        </div>
                    </router-link>

                    <!-- BOTAO DE EDIÇAO -->
                    <div class="flex">
                        <a
                            class="icon"
                            v-if="payment.name != 'Dinheiro' && payment.user === user._id"
                            @click="openPaymentModal(paymentType, payment)"
                        >
                            <edit-icon />
                        </a>

                        <div v-else style="width: 44px"></div>
                    </div>
                </div>
            </div>

            <!-- SE VAZIO... -->
            <div v-if="payments == 0" class="no-data" key="no-data">
                {{ emptyText }}
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import RoundProgressBar from '../RoundProgressBar.vue'
import EditIcon from '../icons/EditIcon.vue'
import Badge from '../icons/Badge.vue'

import util from '@/common/util'

export default {
    name: 'Payments',

    components: {
        RoundProgressBar,
        EditIcon,
        Badge,
    },

    props: {
        paymentType: {
            type: String,
            required: true,
        },
        payments: {
            type: Array,
            required: true,
        },
        emptyText: {
            type: String,
        },
        title: {
            type: String,
            required: true,
        },
    },

    methods: {
        openPaymentModal(type, payment) {
            let _data = {}
            if (payment) _data = Object.assign({}, _data, payment)

            let payload = {
                data: _data,
                state: 'PaymentsModal',
                modalData: { modalData: type },
            }

            this.$parent.$emit('openModalEvent', payload)
        },

        sanitize(str) {
            return util.sanitize(str)
        },

        randomWidth(base) {
            return util.randomWidth(base)
        },

        percentage(n, d) {
            return util.percentage(n, d)
        },

        expensesValue(payment) {
            return payment.value + this.getRecurrencyInvoiceSum(payment._id)
        },
    },

    watch: {
        payments: function (payments) {
            this.payments = payments
        },
    },

    computed: {
        ...mapState(['waiting', 'user']),

        ...mapGetters(['getRecurrencyInvoiceSum', 'balanceActualMonth']),
    },
}
</script>

<style lang="scss" scoped>
@include easy-progress-bar();

.paymentsCard {
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.03);

    button {
        padding: 16px;
        font-size: 16px;
    }
}

.border {
    box-sizing: border-box;
    border: 1px solid #eef1f6;
}

.hide-border {
    display: none;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 3px;
    background-color: white;
}

.payment {
    padding: 16px 0;
    border-bottom: 1px solid #eef1f6;
    height: 56px;
}

.content {
    padding: 0 16px 0 16px;
    border-radius: 0 0 2px 2px;
    background-color: #ffffff;
    font-size: 16px;
    line-height: 19px;
    position: relative;
}

.title {
    padding: 16px 0 16px 16px;
    height: 40px;
    border-radius: 2px 2px 0 0;
    background-color: $list-blue;
    color: $darker-blue;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

.fa-money-bill-alt {
    transform: rotate(-46deg);
    color: #2dc785;
}

.fa-university {
    color: #066c5e;
}

.fa-piggy-bank {
    color: rgba(239, 172, 237, 0.97);
}

.fa-cc-visa {
    color: #00319e;
}

.icon-content {
    width: 30px;
    font-size: 15px;

    img {
        width: 24px;
    }

    img.flag {
        width: 30px;
        padding-top: 5px;
    }
}

.item {
    width: 100%;
    padding-left: 21px;
}

.sub-item {
    width: 50%;
}

.valor {
    width: 20%;
    text-align: right;
}

.name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $light-black;
}

.center {
    align-items: center;
}

.space-between {
    justify-content: space-between;
}

.row {
    flex-direction: row;
}

.flex {
    display: flex;
}

.column {
    flex-direction: column;
}

.blue {
    color: #63b9f2;
    font-weight: 600;
}

.red {
    color: #ef8a97;
    font-weight: 600;
}

.gray {
    color: #848484;
}

.icon {
    font-size: 25px;
    padding-left: 20px;
}

a,
.icon {
    color: #848484;
}

span {
    font-size: 13px;
    line-height: 14px;
}

.no-data {
    padding: 22px 0 23px;
    color: #5b6772;
    font-size: 14px;

    .lowercase {
        font-size: 14px;
        text-transform: lowercase;
    }
}

.progress-title {
    color: $blueish-gray;
}

.progress-bar-container {
    width: 160px;
}
</style>
