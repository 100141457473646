export const errorHandler = (error) => {
    if (error.response) {
        if (error.response.data) {
            if (error.response.data.message) {
                let message = error.response.data.message.toString()

                if (error.response.data.errors) {
                    error.response.data.errors.forEach((error) => {
                        message = message.concat(`\n${error.message}`)
                    })
                }

                return message
            }

            return false
        }

        return false
    }

    return false
}
