import sort from '@/common/sort'

export default {
    // FILTRO DE FORMAS PAGAMENTOS POR TIPO
    filteredPayments(state) {
        return (type, notExternal, userId) => {
            const payments = state.payments
                .filter((payment) => {
                    let valid = true

                    if (notExternal) valid = !payment.externalId
                    if (userId) valid = valid && payment.user === userId

                    return payment.type === type && valid
                })
                .sort(sort.byName)

            if (type === 'debit') payments.sort(sort.byPaymentType)

            return payments.sort(sort.byName)
        }
    },

    // FORMAS PAGAMENTOS QUE NÃO SÃO DO TIPO CRÉDITO
    notCredit: (state) => {
        return state.payments.filter((payment) => payment.type !== 'credit').sort(sort.byName)
    },

    // FORMAS PAGAMENTOS QUE NÃO SÃO DO TIPO DINHEIRO
    notMoney: (state) => {
        return state.payments.filter((payment) => payment.type !== 'money').sort(sort.byName)
    },

    // ENCONTRA O PAGAMENTO "DINHEIRO"
    findMoney: (state, getters) => {
        const { user } = state

        return state.payments.find((payment) => payment.type === 'money' && payment.user === user._id)._id
    },

    // AGRUPAR OS PAGAMENTOS POR TIPO ORDENANDO EM ORDEM ALFABÉTICA
    groupedPayments: (state, getters) => {
        return (group, notExternalPayments) => {
            const { user } = state
            let pays = [
                {
                    name: 'Dinheiro',
                    data: getters.filteredPayments('money', notExternalPayments, user._id),
                },
                {
                    name: 'Contas Bancárias',
                    data: getters.filteredPayments('debit', notExternalPayments, user._id),
                },
            ]

            if (['all', 'expenses'].includes(group))
                pays.push({
                    name: 'Cartões de crédito',
                    data: getters.filteredPayments('credit', notExternalPayments, user._id),
                })

            return pays
        }
    },

    findTransactionInvoice: (state) => (creditCardId) => {
        let invoice = state.invoices.find((invoice) => invoice.creditCardId === creditCardId)

        if (invoice) {
            return state.transactions.find((transaction) => transaction.invoiceReferenceId === invoice._id)
        }
        return null
    },
}
