<template>
    <div
        :id="id"
        class="credit-card-expire-date"
        @click="active"
        :class="[err ? 'error' : '', labelColor, { disabled }]"
    >
        <span :id="`${id}-label`" class="stacked-label" :class="isRequired && 'required'">Vencimento</span>

        <div style="display: flex">
            <select
                :id="id + '-first'"
                class="select"
                :style="{ fontSize: font + 'px' }"
                v-model="selectedOne"
                :disabled="disabled"
                @change="onChange"
                @blur="blurred"
                @click="active"
                name="select-one"
            >
                <option v-for="(element, index) in modelOne" :key="index" :value="element">
                    {{ element }}
                </option>
            </select>

            <div v-if="!disabled" style="left: 35%" class="arrow right"></div>

            <div class="bar">/</div>

            <select
                :id="id + '-second'"
                class="select"
                :style="{ fontSize: font + 'px' }"
                style="padding-left: 5px"
                v-model="selectedTwo"
                :disabled="disabled"
                @change="onChange"
                @blur="blurred"
                @click="active"
                name="select-two"
            >
                <option v-for="(element, index) in modelTwo" :key="index" :value="element">
                    {{ element }}
                </option>
            </select>

            <div v-if="!disabled" class="arrow right"></div>
        </div>

        <div v-if="err" class="error-msg-container">
            <span :style="{ fontSize: errorFontSize + 'px' }">{{ errorMsg }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CreditCardExpireDate',
    props: {
        labelColor: {
            type: String,
            default: 'default-blue',
        },
        modelOne: {
            type: Array,
            required: true,
        },
        modelTwo: {
            type: Array,
            required: true,
        },
        font: {
            type: Number,
            default: 16,
        },
        disabled: Boolean,
        errorValidation: Boolean,
        errorMsg: {
            type: String,
            default: 'data inválida',
        },
        errorFontSize: {
            type: [Number, String],
            default: 12,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            id: `select-${this._uid}`,
            selectedOne: '',
            selectedTwo: '',
            err: false,
        }
    },

    methods: {
        onChange() {
            let parentElClassList = document.getElementById(this.id).parentElement.classList
            let labelClassList = document.getElementById(this.id + '-label').classList

            if (!this.selectedOne || !this.selectedTwo) {
                this.err = true
            } else {
                this.err = false
            }

            labelClassList.remove(this.labelColor)
            parentElClassList.remove('active')

            this.$emit('change', { validMonth: this.selectedOne, validYear: this.selectedTwo })
        },

        blurred() {
            let parentElClassList = document.getElementById(this.id).parentElement.classList
            let labelClassList = document.getElementById(this.id + '-label').classList

            if (!this.selectedOne || !this.selectedTwo) {
                if (this.required) this.err = true
            } else {
                this.err = false
            }

            labelClassList.remove(this.labelColor)
            parentElClassList.remove('active')

            this.$emit('blur', { validMonth: this.selectedOne, validYear: this.selectedTwo })
        },

        active() {
            this.err = false
            document.getElementById(this.id).parentElement.classList.add('active')
            document.getElementById(this.id + '-label').classList.add(this.labelColor)
        },
    },

    watch: {
        errorValidation: {
            immediate: true,
            handler(err) {
                this.err = err
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.credit-card-expire-date {
    display: block;
    height: 56px;
    border: 1px solid #bebebe;
    border-radius: 4px;
    background-color: #ffffff;
    margin-top: 16px;
    position: relative;
    width: 100%;

    &.default-blue:hover,
    &:focus,
    &.active {
        border: 2px solid $btn-blue;
        background: white !important;
    }

    &.default-blue:hover > .stacked-label {
        color: $btn-blue !important;
    }

    &.disabled {
        border: 1px solid #b7b8b9;
        pointer-events: none;

        .stacked-label,
        input {
            color: #b7b8b9;
        }

        &:hover {
            border: 1px solid #b7b8b9;
        }
    }

    .stacked-label {
        position: absolute;
        top: 8px;
        left: 10px;
        background-color: transparent;
        font-size: 13px;
        width: max-content;
        font-weight: 500;
        z-index: 2;
        pointer-events: none;
        color: $soft-gray;

        &.default-blue {
            color: $btn-blue;
        }
    }

    .select {
        width: 50%;
        height: 100%;
        padding: 30px 10px 8px;
        background-color: transparent;
        border: 0;
        color: #646464;
    }

    select[disabled] {
        color: #9e9e9e !important;
        cursor: not-allowed;
    }

    .arrow.right {
        position: absolute;
        right: 20px;
        top: 60%;
        z-index: 2;
    }

    .bar {
        position: absolute;
        left: 45%;
        top: 50%;
        z-index: 2;
    }
}

.error {
    border: 2px solid $hint-red;
    background: $hint-bg-red;

    .stacked-label {
        color: $hint-red !important;
    }
}

.error-msg-container {
    margin-top: 6px;
    font-size: 13px;
    color: $hint-red;
    text-align: left;
    padding-left: 15px;
    text-transform: capitalize;
    z-index: 3;
}

.required:after {
    content:" *"; 
    color: red;
}
</style>
