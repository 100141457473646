<template>
    <div class="person-icon">
        <svg
            v-if="!userId"
            @mouseover="showTable = true"
            xmlns="http://www.w3.org/2000/svg"
            width="17.09"
            height="19"
            viewBox="0 0 17.09 19"
        >
            <g transform="translate(-5 -3.5)">
                <path
                    class="a"
                    d="M21.185,29.04a.9.9,0,0,1-.9-.9v-1.91a2.919,2.919,0,0,0-2.915-2.915H9.725A2.919,2.919,0,0,0,6.81,26.225v1.91a.9.9,0,0,1-1.81,0v-1.91A4.73,4.73,0,0,1,9.725,21.5h7.64a4.73,4.73,0,0,1,4.725,4.725v1.91A.9.9,0,0,1,21.185,29.04Z"
                    transform="translate(0 -6.54)"
                />
                <path
                    class="a"
                    d="M15.725,12.95A4.725,4.725,0,1,1,20.45,8.225,4.73,4.73,0,0,1,15.725,12.95Zm0-7.64A2.915,2.915,0,1,0,18.64,8.225,2.919,2.919,0,0,0,15.725,5.31Z"
                    transform="translate(-2.18 0)"
                />
            </g>
        </svg>

        <avatar @mouseover.native="showTable = true" v-else :avatar="avatar" :letter="letter" />

        <div v-show="showTable" class="triangle-wrapper">
            <div class="tr"></div>
        </div>

        <div
            v-show="showTable"
            v-click-outside="onClickOutside"
            @mouseover="showTable = true"
            @mouseleave="showTable = false"
        >
            <div class="transactions-filter">
                <div class="table">
                    <div class="row" v-for="person in members" :key="person._id">
                        <div class="element" @click="updateElement(person)">
                            {{ person.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Avatar from './Avatar.vue'

export default {
    components: { Avatar },
    name: 'PersonIcon',

    props: {
        members: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            showTable: false,
            avatar: null,
            userId: undefined,
            letter: '',
        }
    },

    methods: {
        updateElement(person) {
            this.userId = person._id
            this.avatar = person.image
            this.letter = person.name.charAt(0)

            this.$emit('updateElement', this.userId)
        },

        onClickOutside() {
            this.showTable = false
        },
    },
}
</script>

<style lang="scss" scoped>
@include easy-triangle();

.triangle-wrapper {
    position: absolute;
    z-index: 3;
    bottom: -10px;
    left: 9px;
    width: 40px;
}

.person-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
        background-color: #ecf0fd;
        border-radius: 50%;

        .a {
            fill: $darker-blue;
        }
    }

    .a {
        fill: #b7b8b9;
    }
}

.transactions-filter {
    position: absolute;
    top: 40px;
    right: -31px;
    min-width: 80px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    z-index: 2;
    padding: 16px 24px;

    div.table {
        color: #9fa5b1;
        text-align: left;
        width: max-content;

        div.row {
            border-bottom: 1px solid #ebebeb;

            &:last-child {
                border: none;
            }
        }

        div.element {
            color: #8d8d8d;
            font-size: 14px;
            line-height: 35px;
            width: 100%;
            cursor: pointer;

            &:hover {
                font-weight: 500;
            }
        }
    }
}
</style>
