<template>
    <div class="plan-details">
        <div class="row">
            <otto-premium-card @voucher-modal="modal = true" @update-subscription="confirmUpdate" />
        </div>

        <div class="row">
            <premium-bills />
        </div>

        <div v-show="confirmationModal" class="overlay">
            <confirmation-create-modal v-if="confirmationModal" v-bind="confirmation" @confirmEvent="confirm">
                <basic-button label="fechar" @click.native="confirm" />
            </confirmation-create-modal>
        </div>

        <div v-if="confirmationUpdateSub" class="overlay">
            <confirmation-modal
                text="Tem certeza que deseja reativar esta assinatura"
                strongText=" Sujeito a cobrança automática caso sua assinatura já estiver expirada"
                :isGeneric="true"
                @closeModalEvent="confirmationUpdateSub = false"
            >
                <template #generic-btn>
                    <basic-button
                        label="Ativar mesmo assim"
                        :loading="disabled"
                        width="100%"
                        @click.native="updateSubscription('active')"
                        data-cy="update-subscription-active"
                    />
                </template>
            </confirmation-modal>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { checkSubscription } from '@/common/checkSubscription'
import { environment } from '@/common/environment'
import { errorHandler } from '@/common/errorHandler'
import apiService from '../../services/api.service'
import BasicButton from '../../components/buttons/BasicButton.vue'
import OttoPremiumCard from '../../components/cards/otto-premium-card/OttoPremiumCard.vue'
import PremiumBills from '../../components/lists/PremiumBills.vue'
import ConfirmationCreateModal from '../../components/modals/confirmation-modals/ConfirmationCreateModal.vue'
import ConfirmationModal from '../../components/modals/confirmation-modals/ConfirmationModal.vue'

export default {
    name: 'PlanDetails',
    components: {
        ConfirmationModal,
        BasicButton,
        OttoPremiumCard,
        PremiumBills,
        ConfirmationCreateModal,
        ConfirmationModal,
    },

    data() {
        return {
            modal: false,
            confirmationModal: false,

            confirmation: {
                text: '',
                error: false,
                title: '',
            },

            confirmationUpdateSub: false,

            default: {
                confirmation: {
                    text: '',
                    error: false,
                    title: '',
                },
            },
        }
    },

    computed: {
        ...mapState(['disabled']),
    },

    methods: {
        async confirm() {
            this.confirmationModal = false
            this.confirmation = this.default.confirmation

            if (!this.confirmation.error) await checkSubscription()
        },

        confirmUpdate(status) {
            if (status === 'inactive') {
                this.updateSubscription(status)
            } else {
                this.confirmationUpdateSub = true
            }
        },

        updateSubscription(status) {
            this.$store.commit('DISABLED')

            apiService
                .post(`${environment.SUBSCRIPTION_URL}/update?status=${status}`)
                .then((response) =>
                    this.$store.commit('ATT_SETED_DATA', {
                        attr: 'subscriptions',
                        data: response.data,
                    })
                )
                .catch((error) => {
                    this.confirmation.text = errorHandler(error) || 'Não foi possível ativar sua assinatura'
                    this.confirmation.title = 'Ativação de assinatura'
                    this.confirmation.error = true

                    this.confirmationModal = true
                })
                .finally(() => {
                    this.$store.commit('DISABLED')
                    this.confirmationUpdateSub = false
                })
        },
    },

    async beforeRouteEnter(to, from, next) {
        if (!from.name) {
            await checkSubscription()
            next()
        }

        next()
    },
}
</script>

<style lang="scss" scoped>
.plan-details {
    width: 780px;

    .row {
        margin: 30px 0;

        &:last-child {
            margin-top: 60px;
        }
    }
}
</style>
