const transaction = (data) => {
    delete data.recurrent
    delete data.transactionType

    if (!!data.category) data.category = data.category._id || data.category

    return data
}

const transaction_recurrent = (data, applyForAll) => {
    delete data.recurrentIndex
    delete data.recurrentDay
    delete data.recurrentTransactionId
    delete data.transactionType

    if (applyForAll) {
        delete data.recurrent
        delete data.recurrentType
        delete data.date
    }

    if (!!data.category) data.category = data.category._id || data.category

    return data
}

const payment = (data) => {
    delete data.type

    return data
}

const category = (data) => {
    // ...

    return data
}

const user = (data) => {
    delete data.profiles
    delete data.email
    delete data.address._id

    return data
}

const clean = (data, options = {}) => {
    let dados = JSON.parse(JSON.stringify(data))
    delete dados._id
    delete dados.user
    delete dados._links
    delete dados.createdAt
    delete dados.updatedAt
    delete dados.__v

    if (dados.transactionType) {
        if (dados.recurrent) {
            dados = transaction_recurrent(dados, options.applyForAll)
        } else {
            dados = transaction(dados)
        }
    } else if (dados.group) {
        dados = category(dados)
    } else if (dados.type) {
        dados = payment(dados)
    } else if (dados.profiles) {
        dados = user(dados)
    }

    return dados
}

export default {
    clean,
}
