<template>
    <div class="column-chart">
        <v-skeleton-loader v-if="waiting" style="padding: 20px 0; margin-bottom: 24px" :height="200" type="image" />

        <apexcharts v-else width="300" type="bar" :height="180" ref="bars" :options="chartOptions" :series="series" />
    </div>
</template>

<script>
import apexcharts from 'vue-apexcharts'
import util from '@/common/util'
import { mapState } from 'vuex'

export default {
    name: 'ColumnChart',
    components: {
        apexcharts,
    },
    props: {
        series: Array,
        colors: {
            type: Function,
            default: () => '#BAC0CC',
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    type: 'bar',
                    fontFamily: 'Metropolis',
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '70%',
                        colors: {
                            ranges: [
                                {
                                    from: 0,
                                    to: 0,
                                    color: undefined,
                                },
                            ],
                            backgroundBarColors: [],
                            backgroundBarOpacity: 1,
                            backgroundBarRadius: 0,
                        },
                    },
                },
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent'],
                },
                xaxis: {
                    categories: ['Essenciais', 'Secundários', 'Investimentos'],
                    labels: {
                        style: {
                            fontSize: 10,
                        },
                        offsetY: -5,
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            let valor = Number(Math.abs(value)).toFixed(2)
                            return value < 0 ? '-' + util.sanitizeChartLabels(valor) : util.sanitizeChartLabels(valor)
                        },
                    },
                },
                fill: {
                    opacity: 1,
                },
                tooltip: {
                    y: {
                        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                            return util.formatNumber(value)
                        },
                    },
                },
                colors: [this.colors],
            },
        }
    },

    computed: {
        ...mapState(['waiting']),
    },
}
</script>
