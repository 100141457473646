var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal-frame',_vm._b({style:(_vm.styles),on:{"close-modal":function($event){return _vm.$emit('close-modal')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[(_vm.mode === 'redeem')?_c('div',{staticClass:"redeem"},[_c('div',{staticClass:"bubble"},[_c('img',{attrs:{"src":_vm.$variables.chatIcon}}),_c('v-scale-transition',{attrs:{"origin":"top left"}},[(_vm.showBubble)?_c('div',{staticClass:"otto-talk"},[_vm._v(" O valor resgatado será apenas subtraído do valor já depositado, não sendo creditado em nenhuma de suas contas ")]):_vm._e()])],1),_c('h3',[_vm._v(_vm._s(_vm.name))])]):_vm._e(),_c('div',[_c('stacked-input',{attrs:{"label":"Valor","money":{
                        decimal: _vm.money.decimal,
                        thousands: _vm.money.thousands,
                        prefix: _vm.money.prefix + ' ',
                        precision: 2,
                        masked: false,
                    },"textAlign":"right","required":true,"errorValidation":_vm.invalid,"errorMsg":_vm.message},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('stacked-input',{attrs:{"label":"Data","type":"date","value":_vm.date,"readonly":true}})],1),_c('basic-button',{attrs:{"label":_vm.options.btnLabel,"type":"submit","loading":_vm.disabled}}),(_vm.mode === 'redeem')?_c('basic-button',{staticStyle:{"margin-top":"12px"},attrs:{"label":"Cancelar","disabled":_vm.disabled,"reverse":true},nativeOn:{"click":function($event){return _vm.$emit('close-modal')}}}):_vm._e()],1)]},proxy:true}])},'modal-frame',_vm.options,false))
}
var staticRenderFns = []

export { render, staticRenderFns }