<template>
    <div class="summary-card flex column">
        <!-- TITULO DO SUMMARY CARD -->
        <header v-if="header">
            <h2 class="summary-title">{{ header }}</h2>
            <v-skeleton-loader
                style="margin-top: 8px"
                :width="100"
                :height="12"
                v-if="waiting && extraValue"
                type="text"
            />
            <span v-if="extraValue && !waiting" class="valor-box big" :class="[extraValue < 0 ? 'red' : 'blue']">{{
                extraValue | currency
            }}</span>
        </header>

        <!-- TABELA -->
        <div class="flex" style="flex-wrap: wrap" :class="{ 'less-margin': extraValue === undefined }">
            <div
                v-for="(element, index) in tableData"
                :key="index"
                class="text-group"
                :class="{
                    margin: extraMargin,
                    'full-width': tableData.length % 2 > 0 && index + 1 === tableData.length,
                }"
            >
                <h2 class="title-box">{{ element.title }}</h2>

                <v-skeleton-loader style="margin-top: 8px" :height="12" :width="100" v-if="waiting" type="text" />
                <span
                    v-else-if="typeof element.value === 'number'"
                    class="valor-box"
                    :style="{ color: element.color, fontSize: element.size + 'px' || 'inherit' }"
                    >{{ element.value | currency }}</span
                >
                <span v-else class="valor-box" :style="{ color: element.color }">{{ element.value }}</span>
            </div>
        </div>

        <!-- PROGRESS-BAR -->
        <div class="progress-bar-container">
            <slot name="progress-bar"></slot>
        </div>

        <div v-if="chartTitle" class="title-graph">{{ chartTitle }}</div>

        <!-- CHART -->
        <div class="chart-containeir">
            <v-expand-transition>
                <slot name="chart"></slot>
            </v-expand-transition>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'SummaryModel',
    props: {
        header: String,
        tableData: Array,
        chartTitle: String,
        extraMargin: Boolean,
        extraValue: Number,
    },

    computed: {
        ...mapState(['waiting']),
    },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/summary-card.scss';
</style>
