import moment from 'moment'
import util from '../../common/util'

export default {
    getLinkedTransactions: (state, getters) => {
        const user = state.user._id

        return (filters) => {
            let transactions = getters.filterBy('linkedtransactions').map((linkedtransaction) => {
                const { _id, value, lastPaymentDate, user, day } = linkedtransaction
                const { name, category, payment, transactionType } = linkedtransaction.transaction

                const isPaid = () => {
                    if (!lastPaymentDate) return false

                    return moment(lastPaymentDate).isSameOrAfter(moment(), 'M')
                }

                let date = moment().date(day).toISOString()

                return {
                    _id,
                    value,
                    paid: isPaid(),
                    date,
                    name,
                    category,
                    payment,
                    user,
                    transactionType,
                }
            })

            transactions.push(...getters.filterBy('transactions', { recurrent: true, 'user._id': user }))
            transactions.push(
                ...getters.filterBy('transactions', { invoiceReferenceId: { $exists: true }, 'user._id': user })
            )

            return transactions.filter((transaction) => util.customFilter(filters, transaction))
        }
    },
}
