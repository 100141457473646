import { render, staticRenderFns } from "./NextTransactionsList.vue?vue&type=template&id=551a4fcd&scoped=true&"
import script from "./NextTransactionsList.vue?vue&type=script&lang=js&"
export * from "./NextTransactionsList.vue?vue&type=script&lang=js&"
import style0 from "./NextTransactionsList.vue?vue&type=style&index=0&id=551a4fcd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "551a4fcd",
  null
  
)

export default component.exports