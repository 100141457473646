import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import AccountRecovery from '../views/AccountRecovery.vue'
import AccountActivation from '../views/AccountActivation.vue'
import Dashboard from '../views/Dashboard.vue'
import Transactions from '../views/Transactions'
import Wallet from '../views/Wallet'
import Budget from '../views/Budget'
import Settings from '../views/Settings'
import Objectives from '../views/Objectives'
import ProfileConfig from '../views/settings/ProfileConfig'
import CategoryConfig from '../views/settings/CategoryConfig'
import PlanDetails from '../views/settings/PlanDetails'
import PreferencesConfig from '../views/settings/PreferencesConfig'
import BankAccessConfig from '../views/settings/BankAccessConfig'
import FamilyAccount from '../views/settings/FamilyAccount'
import PageModel from '../views/PageModel'
import Subscription from '../views/subscription/Main'
import Empresas from '../views/Empresas.vue'
import ChangePlan from '../views/subscription/ChangePlan.vue'
import UpdateSubscription from '../views/subscription/UpdateSubscription.vue'
import CancelSubscription from '../views/subscription/CancelSubscription.vue'
import PaymentsCalendar from '../views/PaymentsCalendar.vue'
import Report from '../views/Report.vue'
import Advisors from '../views/Advisors.vue'

import auth from '../services/auth.service'
import store from '../store'
import request from '../common/request'
import _date from '../common/formatDate'
import { checkSubscription } from '../common/checkSubscription'
import authService from '../services/auth.service'

Vue.use(Router)

const beforeActiveRoutes = ['account-activation']

const ifNotAuthenticated = async (to, from, next) => {
    if (!auth.getOnLocalStorage('token')) {
        next()
        return
    } else {
        if (store.state.user.active) {
            if (await isExpired()) {
                redirectUnsubscribedUser(next)
                return
            }
            next('/painel')
            return
        }
        next('/account-activation')
    }
}

const ifAuthenticated = async (to, from, next) => {
    if (auth.getOnLocalStorage('token')) {
        const notActive = !store.state.user.active && !beforeActiveRoutes.includes(to.name)

        if (notActive) {
            next('/account-activation')
            return
        }
        if (to.name === 'plano') await checkSubscription()

        next()
        return
    }
    next('/login')
}

const ifAuthenticatedAndSubscribed = async (to, from, next) => {
    if (auth.getOnLocalStorage('token')) {
        if (store.state.user.active) {
            if (await isExpired()) {
                redirectUnsubscribedUser(next)
                return
            }
            next()
            return
        }
        next('/account-activation')
        return
    }
    next('/login')
}

const ifAuthenticatedOrNot = (to, from, next) => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const token = urlSearchParams.get('token')

    if (auth.getOnLocalStorage('token') && !token) {
        next()
        return
    } else if (token) {
        store.commit('LOGOUT')
        authService.clearLocalStorage()
        authService.saveOnLocalStorage('token', token)
        next()
        return
    }
    next('/login')
}

const redirectUnsubscribedUser = (next) => {
    const subscription = store.state.subscriptions[0]

    if (!subscription.subscriptionId) {
        next('/assine')
    } else {
        next('/configuracoes/plano')
    }
}

const isExpired = async () => {
    const subscription = store.state.subscriptions[0]

    if (subscription) return store.getters.isExpired
    return await checkSubscription()
}

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/cadastro',
            name: 'cadastro',
            component: SignUp,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/account-recovery',
            name: 'account-recovery-request',
            component: AccountRecovery,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/account-recovery/:token',
            name: 'account-recovery',
            component: AccountRecovery,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/account-activation',
            name: 'account-activation',
            component: AccountActivation,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/assine',
            name: 'subscribe',
            component: Subscription,
            beforeEnter: ifAuthenticatedOrNot,
        },
        {
            path: '/assinatura/update',
            name: 'updateSubscription',
            component: UpdateSubscription,
            beforeEnter: ifAuthenticatedOrNot,
        },
        {
            path: '/assinatura/mudar-pagamento',
            name: 'changeSubscriptionPayment',
            component: Subscription,
            beforeEnter: ifAuthenticatedOrNot,
        },
        {
            path: '/assinatura/mudar-plano',
            name: 'changeSubscriptionPlan',
            component: ChangePlan,
            beforeEnter: ifAuthenticatedOrNot,
        },
        {
            path: '/assinatura/cancelar',
            name: 'cancelSubscriptionPayment',
            component: CancelSubscription,
            beforeEnter: ifAuthenticatedOrNot,
        },
        {
            path: '/empresas/activation',
            name: 'activation-corporate-plan',
            component: Empresas,
        },
        {
            path: '',
            name: 'container',
            component: PageModel,
            redirect: '/',
            children: [
                {
                    path: '/',
                    name: 'home',
                    component: Dashboard,
                    beforeEnter: ifAuthenticatedAndSubscribed,
                },
                {
                    path: '/painel',
                    name: 'dashboard',
                    component: Dashboard,
                    beforeEnter: ifAuthenticatedAndSubscribed,
                },
                {
                    path: '/movimentacoes',
                    name: 'transactions',
                    component: Transactions,
                    beforeEnter: ifAuthenticatedAndSubscribed,
                },
                {
                    path: '/movimentacoes/:payment',
                    name: 'payment_transactions',
                    component: Transactions,
                    beforeEnter: ifAuthenticatedAndSubscribed,
                },
                {
                    path: '/planejamento',
                    name: 'budget',
                    component: Budget,
                    beforeEnter: ifAuthenticatedAndSubscribed,
                },
                {
                    path: '/carteiras',
                    name: 'wallet',
                    component: Wallet,
                    beforeEnter: ifAuthenticatedAndSubscribed,
                },
                {
                    path: '/calendario',
                    name: 'calendar',
                    component: PaymentsCalendar,
                    beforeEnter: ifAuthenticatedAndSubscribed,
                },
                {
                    path: '/configuracoes',
                    redirect: '/configuracoes/perfil',
                    name: 'settings',
                    component: Settings,
                    children: [
                        {
                            path: '/configuracoes/perfil',
                            name: 'perfil',
                            component: ProfileConfig,
                            beforeEnter: ifAuthenticatedAndSubscribed,
                        },
                        {
                            path: '/configuracoes/plano',
                            name: 'plano',
                            component: PlanDetails,
                            beforeEnter: ifAuthenticated,
                        },
                        {
                            path: '/configuracoes/categorias',
                            name: 'categorias',
                            component: CategoryConfig,
                            beforeEnter: ifAuthenticatedAndSubscribed,
                        },
                        {
                            path: '/configuracoes/preferencias',
                            name: 'preferencias',
                            component: PreferencesConfig,
                            beforeEnter: ifAuthenticated,
                        },
                        {
                            path: '/configuracoes/conta-familia',
                            name: 'family-account',
                            component: FamilyAccount,
                            beforeEnter: ifAuthenticated,
                        },
                        {
                            path: '/configuracoes/bankaccess',
                            name: 'bankaccess',
                            component: BankAccessConfig,
                            beforeEnter: ifAuthenticatedAndSubscribed,
                        },
                    ],
                },
                {
                    path: '/objetivos',
                    name: 'objectives',
                    component: Objectives,
                    beforeEnter: ifAuthenticatedAndSubscribed,
                },
                {
                    path: '/relatorio',
                    name: 'relatorio',
                    component: Report,
                    beforeEnter: ifAuthenticatedAndSubscribed,
                },
                // {
                //     path: '/especialistas',
                //     name: 'especialistas',
                //     component: Advisors,
                //     beforeEnter: ifAuthenticatedAndSubscribed,
                // },
            ],
        },
    ],
})

router.beforeEach(async (to, from, next) => {
    const user = store.state.user
    const id = localStorage.getItem('id')

    store.commit('toggleSidebar', false)

    if (!user._id && id) {
        await request
            .getById({
                collection: 'users',
                _id: id,
            })
            .then((user) => localStorage.setItem('currency', user.currency || 'BRL'))
            .catch(() => auth.logout())
    }

    next()
})

router.afterEach(async (to, from) => {
    const id = localStorage.getItem('id')
    const notTransactionPage = to.name !== 'transactions' && to.name !== 'payment_transactions'

    if (!store.state.fetched && id) {
        const user = store.state.user
        store.commit('WAITING', true)
        setDate()

        if (user._id) {
            try {
                const subscription = store.state.subscriptions[0]
                const isSubscribed = subscription ? subscription.status : false

                if (isSubscribed && !beforeActiveRoutes.includes(to.name)) {
                    const family = subscription.plan === ('otto_family_monthly' || 'otto-family-member')

                    await request.fetch(['categories', 'payments'])

                    if (notTransactionPage)
                        await request.fetch({
                            collection: 'invoices',
                            queryParams: store.state._date,
                        })

                    const paymentId = getPaymentId(to)

                    await request.fetch([
                        { collection: 'budgets', queryParams: store.state._date },
                        { collection: 'transactions', queryParams: store.state._date },
                        { collection: 'objectives' },
                        { collection: 'bankaccesses' },
                        { collection: 'families' },
                        { collection: 'linkedtransactions' },
                    ])

                    if (notTransactionPage) {
                        await request.fetch([
                            { collection: 'balancesChart', queryParams: { ...store.state._date, paymentId, family } },
                            { collection: 'balances', queryParams: { ...store.state._date, family } },
                        ])
                    }

                    store.commit('FETCHED')
                }
            } catch (e) {
                console.error(e)
            }
        } else {
            auth.logout()
        }
    } else if (to.name !== 'login') {
        await resetStore(to, from)
    }

    if (store.state.waitingTime) store.commit('WAITING')
})

async function resetStore(to, from) {
    const reset = ![
        'login',
        'account-recovery',
        'subscribe',
        'updateSubscription',
        'changeSubscriptionPlan',
        'changeSubscriptionPayment',
    ].includes(to.name)
    const notTransactionPage = to.name !== 'transactions' && to.name !== 'payment_transactions'
    const subscription = store.state.subscriptions[0] || {}
    const family = subscription.plan === ('otto_family_monthly' || 'otto-family-member')

    if (!_date.compareDates(store.state._date.inicio, store.state._date_selected.inicio)) {
        try {
            store.commit('DISABLED')
            store.commit('WAITING', true)

            if (to.name === 'transactions') {
                await request.fetch([
                    { collection: 'balances', queryParams: store.state._date_selected },
                    { collection: 'balancesChart', queryParams: store.state._date_selected, family },
                ])
            } else if (to.name === 'payment_transactions') {
                const paymentId = getPaymentId(to)

                await request.fetch([
                    { collection: 'balances', queryParams: { ...store.state._date_selected, paymentId } },
                    { collection: 'balancesChart', queryParams: { ...store.state._date_selected, paymentId } },
                ])
            } else if (reset) {
                await request.changeMonth(store.state._date)
                store.commit('setSelectedDate', store.state._date)
            }
        } catch (e) {
            console.error(e)
        }

        store.commit('DISABLED')
    } else {
        if (family && notTransactionPage && reset) {
            await request.fetch([
                { collection: 'balances', queryParams: { ...store.state._date_selected, family } },
                { collection: 'balancesChart', queryParams: { ...store.state._date_selected, family } },
            ])
        }
    }
}

function setDate() {
    let date = new Date()

    // salvando o mês atual
    let dataAtual = {
        inicio: _date.convertDate(date.getFullYear(), date.getMonth(), 1),
        fim: _date.convertDate(date.getFullYear(), date.getMonth() + 1, 0),
    }

    store.commit('setAtualDate', dataAtual)
}

function getPaymentId(to) {
    return to.name === 'payment_transactions' && store.getters.findById('payments', to.params.payment) !== 'credit'
        ? to.params.payment
        : undefined
}

export default router
