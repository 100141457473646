<template>
    <div class="advisor-card">
        <div class="content">
            <div class="img" :style="styles"></div>
            <p>{{ expert.name }}</p>
            <small>{{ expertises }}</small>

            <star-rating-vue :rating="expert.stars" />

            <p>
                <strong>{{ expert.sessionPrice | currency }}</strong
                >/sessão
            </p>

            <div class="like">
                <p>{{ likes }}</p>
                <a @click.prevent="likeExpert">
                    <ion-icon v-if="youLiked > -1" class="icon red" name="heart"></ion-icon>
                    <ion-icon v-else class="icon" name="heart-outline"></ion-icon>
                </a>
            </div>

            <basic-button-vue label="Conheça mais" :reverse="true" :width="280" @click.native="openModal = true" />
        </div>

        <expert-modal v-if="openModal" :expert="modifiedExpert" @close-modal="openModal = false" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { expertisesDictonary } from '../../common/lists'
import util from '../../common/util'
import BasicButtonVue from '../buttons/BasicButton.vue'
import StarRatingVue from '../icons/StarRating.vue'
import ExpertModal from '../modals/ExpertModal.vue'

export default {
    name: 'AdvisorCard',

    components: {
        BasicButtonVue,
        StarRatingVue,
        ExpertModal,
    },

    props: {
        expert: {
            required: true,
            default: () => {},
        },
    },

    data() {
        return {
            openModal: false,
        }
    },

    computed: {
        ...mapState(['user']),

        expertises() {
            return this.expert.expertises
                .map((expertise, index) => {
                    let translated = expertisesDictonary[expertise.name]
                        ? expertisesDictonary[expertise.name]
                        : expertise.name
                    return index > 0 ? ' ' + translated : translated
                })
                .toString()
        },

        modifiedExpert() {
            return {
                ...this.expert,
                expertises: this.expertises,
            }
        },

        likes() {
            const _likes = this.expert.liked.length
            return _likes > 999 ? util.sanitizeNumber(String(_likes)) : _likes
        },

        youLiked() {
            return this.expert.liked.findIndex((l) => l === this.user._id)
        },

        styles() {
            return {
                '--background': `url(${this.resolveImg()})`,
            }
        },
    },

    methods: {
        money() {
            util.formatNumber()
        },

        resolveImg() {
            return this.expert.image || this.$variables.placeholder
        },

        likeExpert() {
            let expert = Object.assign({}, this.expert)

            if (this.youLiked > -1) expert.liked.splice(this.youLiked, 1)
            else expert.liked.push(this.user._id)

            this.$store.commit('CHANGE_DATA', { attr: 'experts', data: expert })
        },
    },

    mounted() {
        this.resolveImg()
    },
}
</script>

<style lang="scss" scoped>
.advisor-card {
    width: 330px;
    height: 280px;
    margin: 0 24px 50px 0;
    border-radius: 4px;
    position: relative;
    box-shadow: 0px 0px 10px #0000002b;
    padding: 16px 0;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    }

    .img {
        height: 80px;
        width: 80px;
        border: 2px solid $btn-blue;
        background: white var(--background) no-repeat;
        border-radius: 50%;
        background-position-x: center;
        background-position-y: center;
        background-size: 100%;
    }

    small {
        text-transform: capitalize;
    }

    .like {
        position: absolute;
        top: 16px;
        right: 16px;
        display: flex;

        p {
            line-height: 20px;
        }

        .icon {
            visibility: inherit;
            transform: scale(1.5);
            margin-left: 6px;

            &.red {
                color: $hint-red;
            }
        }
    }
}
</style>
