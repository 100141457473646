let activeUrl = process.env.VUE_APP_API_URL || 'http://localhost:3000'
let activeAdminUrl = process.env.VUE_APP_ADMIN_API_URL || 'http://localhost:3002'

export const environment = {
    API_URL: `${activeUrl}/`,
    LOGIN_URL: `${activeUrl}/users/authenticate`,
    FORGOT_PASSWORD_URL: `${activeUrl}/users/account/recovery`,
    UPDATE_PASSWORD_URL: `${activeUrl}/users/password`,
    ACTIVATE_ACCOUNT_URL: `${activeUrl}/users/account/activation`,
    RESEND_EMAIL_ACTIVATE_ACCOUNT_URL: `${activeUrl}/users/account/activation/resend_email`,
    RESET_ACCCOUNT_URL: `${activeUrl}/users/account/reset`,
    REGISTER_URL: `${activeUrl}/users`,
    TRANSACTION_URL: `${activeUrl}/transactions`,
    CATEGORY_URL: `${activeUrl}/categories`,
    PAYMENTS_URL: `${activeUrl}/payments`,
    BALANCES_URL: `${activeUrl}/balances`,
    USERS_URL: `${activeUrl}/users`,
    FAMILIES_URL: `${activeUrl}/families`,
    INVOICE_URL: `${activeUrl}/invoices`,
    SUBSCRIPTION_URL: `${activeUrl}/subscriptions`,
    SUBSCRIPTION_GATEWAY_CLIENT_URL: `${activeUrl}/subscriptions/gateway/client`,
    SUBSCRIPTION_GATEWAY_PAYMENT_URL: `${activeUrl}/subscriptions/gateway/payment`,
    SUBSCRIPTION_GATEWAY_SUB_URL: `${activeUrl}/subscriptions/gateway/subscription`,
    BANK_ACCESSS_URL: `${activeUrl}/bankaccesses`,
    BELVO_ACCESS_TOKEN: `${activeUrl}/integrations/belvo/access-token`,
    PLUGGY_ACCESS_TOKEN: `${activeUrl}/integrations/pluggy/access-token`,
    ACTIVATE_CORPORTATE_PLAN: `${activeUrl}/b2b/companies/employee/activate`,
    PROMOTIONAL_CODE: `${activeAdminUrl}/promotionalcodes`,
}
