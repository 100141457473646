import util from './util'

const byDate = (a, b) => {
    return a.date > b.date ? 1 : a.date < b.date ? -1 : 0
}

const byName = (a, b) => {
    return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
}

const byPaymentType = (a, b) => {
    const values = { credit: 3, poupanca: 2, contacorrente: 1 }

    const typeA = util.sanitize(a.accountType ? a.accountType : a.type)
    const typeB = util.sanitize(b.accountType ? b.accountType : b.type)

    return values[typeA] > values[typeB] ? 1 : values[typeA] < values[typeB] ? -1 : 0
}

export default {
    byDate,
    byName,
    byPaymentType
}