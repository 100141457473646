<template>
    <div class="transaction-card" :class="[type]" :style="styles">
        <h1>{{ texts.bigTitle }}</h1>

        <v-skeleton-loader v-if="waiting" v-bind="attrs" style="margin-bottom: 10px" class="big-value" />
        <h2 v-else class="big-value">
            <span v-show="!hide">{{ verifyValue(totalValue) | currency }}</span>
            <div v-show="hide" :class="[type, { hide }, 'big-value']">
                {{ money.prefix }}
                <div></div>
                <img v-if="switchingEye" src="@/assets/img/hide-eye.png" alt="eye" @click="$emit('hide-info')" />
            </div>
            <img v-if="switchingEye && !hide" src="@/assets/img/hide-eye.png" alt="eye" @click="$emit('hide-info')" />
        </h2>

        <div class="flex-grid">
            <div class="even-col">
                <h3>{{ texts.smallTitle1 }}</h3>

                <v-skeleton-loader v-if="waiting" v-bind="attrs" class="sub-value" />
                <span v-show="!hide" v-else class="sub-value">{{ verifyValue(budgetValue) | currency }}</span>
                <div v-show="hide && !waiting" :class="[type, { hide }, 'sub-value']">
                    {{ money.prefix }}
                    <div></div>
                </div>
            </div>

            <div class="even-col">
                <h3>{{ texts.smallTitle2 }}</h3>

                <v-skeleton-loader v-if="waiting" v-bind="attrs" class="sub-value" />
                <span v-show="!hide" v-if="type !== 'investments' && !waiting" class="sub-value">{{
                    hide ? '' : verifyValue(remainValue) | currency
                }}</span>
                <div v-show="hide && !waiting && type !== 'investments'" :class="[type, { hide }, 'sub-value']">
                    {{ money.prefix }}
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import util from '../../common/util'


export default {
    name: 'TransactionCard',
    props: {
        type: {
            type: String,
            required: true,
        },
        totalValue: {
            type: Number,
            required: true,
            default: 0,
        },
        budgetValue: {
            type: Number,
            required: true,
            default: 0,
        },
        remainValue: {
            type: Number,
            required: true,
            default: 0,
        },
        switchingEye: Boolean,
        hide: Boolean
    },

    data() {
        return {
            attrs: {
                height: 24,
                width: '100%',
                loading: true,
                type: 'list-item',
            },
            money: {
                decimal: ',',
                thousands: '.',
                prefix: util.returnCurrencyPrefix().prefix + ' ',
                precision: 2,
                masked: false,
            },
        }
    },

    methods: {
        verifyValue(value) {
            return this.type === 'expenses' ? Math.abs(value) : value
        },
    },

    computed: {
        ...mapState(['waiting', 'user']),

        texts() {
            const texts = {
                bigTitle: '',
                smallTitle1: '',
                smallTitle2: '',
            }

            switch (this.type) {
                case 'balance':
                    texts.bigTitle = 'Saldo atual'
                    texts.smallTitle1 = 'Saldo prévio'
                    texts.smallTitle2 = 'Saldo do mês'
                    break
                case 'revenues':
                    texts.bigTitle = 'Receitas'
                    texts.smallTitle1 = 'Recebidas'
                    texts.smallTitle2 = 'a receber'
                    break
                case 'expenses':
                    texts.bigTitle = 'Despesas'
                    texts.smallTitle1 = 'Pagas'
                    texts.smallTitle2 = 'a pagar'
                    break
                case 'investments':
                    texts.bigTitle = 'Poupança'
                    texts.smallTitle1 = 'Programado'
                    break
            }

            return texts
        },

        styles() {
            return {
                '--eye-opacity': this.hide ? 1 : 0.5,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.transaction-card {
    width: 25%;
    min-width: 272px;
    margin-right: 16px;
    padding: 16px;
    color: white;
    @include rounded-box();

    &:last-child {
        margin: unset;
    }

    &.balance {
        background-color: #028fff;
    }

    &.revenues {
        background-color: $revenue-green;
    }

    &.expenses {
        background-color: $expense-red;
    }

    &.investments {
        background-color: $light-blue;
    }

    h1 {
        margin-bottom: 4px;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
    }

    h2 {
        margin-bottom: 20px;
        @include big-value();
        position: relative;

        img {
            width: 16px;
            cursor: pointer;
            opacity: var(--eye-opacity);
            margin-left: 8px;
            position: relative;
            bottom: 3px;
        }

        .hide {
            & > div {
                background: white;
                width: 120px;
                height: 22px;
                display: inline-block;
                opacity: 0.5;
                position: relative;
                bottom: -1px;
            }
        }
    }

    h3 {
        margin-bottom: 4px;
        font-size: 13px;
        height: 12px;
        font-weight: 500;
        text-transform: uppercase;
    }

    .sub-value {
        @include std-value();
    }

    .flex-grid {
        @include flex-between();

        .even-col {
            width: 49%;

            .hide {
                & > div {
                    background: white;
                    width: 100px;
                    height: 15px;
                    display: inline-block;
                    opacity: 0.5;
                    position: relative;
                    bottom: -1px;
                }
            }
        }
    }
}
</style>
