<template>
    <div class="login-container" :style="styles">
        <div class="content">
            <img class="logo" :src="$variables.brandIcon" alt="Otto" />

            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoginContainer',

    computed: {
        styles() {
            return {
                '--background': `url(${this.$variables.background})`,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.login-container {
    min-height: 100vh;
    padding: 40px 0;
    background: var(--background);
    background-color: #ecf0fd;
    background-blend-mode: multiply;
}

.logo {
    display: block;
    width: 200px;
    margin: 24px auto 2em;

    &.icon {
        width: 70px;
        margin-top: 0;
    }
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .content {
        padding: 0 3%;
    }

    .logo {
        margin: 30px auto 15px;
    }
}
</style>
