<template>
    <div class="month-selector" :style="{ '--color': color }">
        <a
            data-cy="change-month-back"
            :class="{ hide: hideButtons[0] }"
            class="icon ion-ios-arrow-back"
            @click="changeMonth(-1)"
            ><ion-icon name="chevron-back-outline"></ion-icon
        ></a>

        <span class="data-label">{{ period }}</span>

        <a
            data-cy="change-month-forward"
            :class="{ hide: hideButtons[1] }"
            class="icon ion-ios-arrow-forward"
            @click="changeMonth(1)"
            ><ion-icon name="chevron-forward-outline"></ion-icon
        ></a>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import formatDate from '../../common/formatDate'

export default {
    name: 'MonthSelector',

    props: {
        hideButtons: {
            type: Array,
            default: () => [false, false],
        },
        color: {
            type: String,
            default: '#9FA5B1',
        },
    },

    computed: {
        ...mapState(['_date_selected']),

        period() {
            return (
                formatDate.getMonthName(this._date_selected.inicio.slice(5, 7)) +
                ' ' +
                this._date_selected.inicio.slice(0, 4)
            )
        },
    },

    methods: {
        changeMonth(add) {
            this.$store.commit('setSelectedDate', formatDate.changeMonth(this._date_selected.inicio, add))

            this.$emit('change-month', add)
        },
    },
}
</script>

<style lang="scss" scoped>
.month-selector {
    display: flex;
    justify-content: space-between;
    width: 200px;

    .data-label {
        font-weight: 500;
    }

    .icon {
        height: 18.57px;
        width: 18.57px;
        color: var(--color);
        text-align: center;

        &.hide {
            opacity: 0;
            pointer-events: none;
        }
    }
}
</style>
