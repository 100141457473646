export default {
    budgetBalance: (state, getters) => type => {
        if (state.budgets._id) {
            switch (type) {
                case 'essential':
                    return state.budgets.essentialCategories.reduce((prevValue, bCategory) => {
                        return prevValue + getters.balancesPerCategory(bCategory.category._id)
                    }, 0)
                case 'noessential':
                    return state.budgets.noessentialCategories.reduce((prevValue, bCategory) => {
                        return prevValue + getters.balancesPerCategory(bCategory.category._id)
                    }, 0)
                default:
                    return getters.balancesPerCategory()
            }
        }

        return 0
    }
}