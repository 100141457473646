<template>
    <div class="step-four step">
        <h3 class="step-title">Resumo da Operação</h3>

        <div class="section">
            <h4>Plano</h4>

            <div class="info-box" v-if="Object.keys(plan).length > 1">
                <h3>{{ plan.name }}</h3>
                <p>
                    <span class="blue-text"
                        ><span :class="{ 'gray-value': insertPromoCode }">R$ {{ plan.price }}</span>
                        <span class="preposition">{{ insertPromoCode ? ' por ' : '' }}</span>
                        <span class="blue-text">{{ promoValue }}</span> {{ plan.period }}</span
                    >, todo dia <span class="blue-text">{{ actualDate(plan.period) }}</span>
                </p>
            </div>
        </div>

        <div class="section">
            <h4>Seus dados pessoais</h4>

            <table>
                <thead>
                    <th></th>
                    <th></th>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in getTable('client')" :key="`${index}-row`">
                        <td v-for="(column, index) in row" :key="`${index}-column`" :colspan="row.length > 1 ? 1 : 2">
                            <h5>{{ column.title }}</h5>
                            <p v-html="column.value"></p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="section">
            <h4>Informações de pagamento</h4>

            <table>
                <thead>
                    <th></th>
                    <th></th>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in getTable('payment')" :key="`${index}-row`">
                        <td v-for="(column, index) in row" :key="`${index}-column`" :colspan="row.length > 1 ? 1 : 2">
                            <h5>{{ column.title }}</h5>
                            <p v-html="column.value"></p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="checkbox-container">
            <input
                class="checkbox"
                type="checkbox"
                name="agreement"
                value="true"
                v-model="authorization"
                @change="$emit('authorize', authorization)"
                data-cy="checkbox-step-four"
            />
            <label>
                Concordo com os
                <a :href="$variables.usageTerms" target="_blank">Termos e Condições</a>
                e a
                <a :href="$variables.privatePolicy" target="_blank">Política de Privacidade</a>
            </label>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import util from '../../../common/util'
export default {
    name: 'StepFour',
    props: {
        plan: Object,
        payment: Object,
        client: Object,
        promotionalCode: Object,
    },
    data() {
        return {
            authorization: false,
        }
    },

    mounted() {
        this.$emit('authorize', false)
    },

    computed: {
        ...mapState(['user']),

        cardBrand() {
            return window.Iugu.utils.getBrandByCreditCardNumber(this.payment.creditcard.number)
        },

        promoValue() {
            if (!this.insertPromoCode) return ''

            const realValue = Number(this.plan.price.replace(',', '.') || '0')
            const discount = this.promotionalCode.discount || 0

            const value = realValue - discount

            return value ? util.formatNumber(realValue - discount) : ''
        },

        insertPromoCode() {
            return !!this.promotionalCode.discount
        },
    },

    methods: {
        actualDate(period) {
            if (period === 'anualmente') {
                return this.$moment().format('DD/MM')
            } else {
                return new Date().getDate()
            }
        },

        getTable(prop) {
            switch (prop) {
                case 'client':
                    return [
                        [{ title: 'Nome Contratante', value: this.user.name }],
                        [
                            { title: 'CPF', value: this[prop].cpf },
                            { title: 'Data Nascimento', value: this.$moment(this[prop].birthday).format('DD/MM/YYYY') },
                        ],
                        [{ title: 'Telefone', value: this[prop].phoneNumber }],
                        [{ title: 'Email', value: this[prop].email }],
                    ]
                case 'payment':
                    if (this.payment.method === 'credit_card') {
                        return [
                            [{ title: 'Forma de pagamento', value: this.resolvePaymentMethod(this[prop].method) }],
                            [
                                { title: 'Nº Cartão', value: this[prop].creditcard.number },
                                {
                                    title: 'Vencimento',
                                    value: `${this[prop].creditcard.validMonth}/${this[prop].creditcard.validYear}`,
                                },
                            ],
                            [{ title: 'Nome do Titular', value: this[prop].creditcard.owner }],
                        ]
                    }
                    return [[{ title: 'Forma de pagamento', value: this.resolvePaymentMethod(this[prop].method) }]]
                default:
                    return []
            }
        },

        resolvePaymentMethod(method) {
            const styles = 'font-size: 20px; margin: 0 0.5em 0 0; position: relative; top: 2px; color: #979797'

            switch (method) {
                case 'bank_slip':
                    return `<span><i class="fas fa-barcode" style="${styles}"></i> Boleto</span>`
                case 'pix':
                    return `<span>
                            <img src="${require('@/assets/img/payments/pix.svg')}" style="width:17px; height:17px; ${styles}" alt="" />
                            Pix</span>`
                case 'credit_card':
                    return `<span style="display: flex; align-items: center;">
                        <i class="fas fa-credit-card" style="${styles}"></i> Cartão de Crédito
                        <div class="cc_icon" style="background-image: url(img/payments/${this.parseCardBrand(
                            this.cardBrand
                        )}.png); position: static; margin-left: 0.5em; transform: scale(1.3)"></div></span>`
                default:
                    return ''
            }
        },

        parseCardBrand(brand) {
            if (brand === 'amex') return 'americanexpress'
            return brand
        },

        validate() {
            return this.$emit('validate', true)
        },
    },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';

.section {
    margin-bottom: 1em;

    h4 {
        @include section-title();
        font-size: 16px;
        padding: 0;
    }

    table {
        width: 100%;
        margin-top: 0.5em;

        tr {
            td {
                line-height: 19px;
                padding-bottom: 0.5em;
                position: relative;
                width: 50%;

                h5 {
                    font-weight: 600;
                    font-size: 13px;
                    color: $dark-grey;
                }

                p {
                    color: $darker-blue;
                }
            }
        }
    }
}
</style>
