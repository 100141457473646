<template>
    <div class="success-msg">
        <i :class="resolveIcon"></i>

        <div class="text" v-html="msg"></div>

        <div class="btn-container">
            <basic-button
                v-if="apiErr && !hasSubscriptions"
                label="Tentar novamente"
                width="100%"
                @click.native="$emit('restart')"
            />

            <basic-button
                v-else
                data-cy="back-to-otto"
                width="100%"
                label="Voltar para o Otto"
                @click.native="endProcess"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import util from '../../../common/util'
import BasicButton from '../../../components/buttons/BasicButton.vue'

export default {
    components: { BasicButton },
    name: 'SuccessMsg',
    props: {
        apiErr: Boolean,
        msg: String,
    },

    computed: {
        ...mapState(['subscriptions']),

        hasSubscriptions() {
            return this.subscriptions[0] ? this.subscriptions[0].subscriptionId !== undefined : false
        },

        resolveIcon() {
            return this.apiErr ? 'fas fa-exclamation-circle' : 'far fa-check-circle'
        },
    },

    methods: {
        endProcess() {
            const device = util.identifyUserDevice()

            if (device === 'mobile') window.location.href = `${process.env.VUE_APP_HOST_URL}/assine?sucesso`
            else this.$router.push('/configuracoes/plano')
        },
    },
}
</script>

<style lang="scss" scoped>
.success-msg {
    text-align: center;

    .fa-check-circle,
    .fa-exclamation-circle {
        font-size: 60px;
        color: #2dc785;
    }

    .fa-exclamation-circle {
        color: $hint-red;
    }

    .text {
        margin: 1.5em 0;
        color: $light-black;
        font-weight: 500;
        font-size: 18px;
    }

    a {
        color: white;
        font-weight: 500;
        line-height: 56px;
        width: 100%;
    }
}
</style>
