<template>
    <div class="step-two step">
        <div class="info-box changeplan" v-if="Object.keys(newPlan).length > 1">
            <h2>NOVO PLANO ESCOLHIDO</h2>
            <h3>{{ newPlan.name }}</h3>
            <p>
                <span class="blue-text">R$ {{ newPlan.price }} {{ newPlan.period }}</span
                >, todo dia <span class="blue-text">{{ actualDate(newPlan.period) }}</span>
            </p>
        </div>

        <div class="border"></div>

        <div class="simulation" v-for="(key, index) in Object.keys(parsedSimulation)" :key="index">
            <p>
                <strong>{{ key }}: </strong>
                <span>{{ parsedSimulation[key] }}</span>
            </p>
        </div>

        <div class="checkbox-container">
            <input
                class="checkbox"
                type="checkbox"
                name="agreement"
                value="true"
                v-model="authorization"
                @change="$emit('authorize', authorization)"
                data-cy="checkbox-step-four"
            />
            <label>
                Concordo com os
                <a :href="$variables.usageTerms" target="_blank">Termos e Condições</a>
                e a
                <a :href="$variables.privatePolicy" target="_blank">Política de Privacidade</a>
            </label>
        </div>
    </div>
</template>

<script>
import util from '../../../common/util'

export default {
    name: 'StepTwo',

    props: {
        newPlan: {
            type: Object,
            required: true,
        },
        simulation: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            authorization: false,
        }
    },

    computed: {
        parsedSimulation() {
            const { cost, discount, expires_at } = this.simulation
            let custo = (cost || 0) * 0.01
            let disconto = (discount || 0) * 0.01

            return {
                'Valor cobrado': util.formatNumber(custo),
                Desconto: util.formatNumber(disconto),
                'Próxima cobrança': expires_at ? this.$moment(expires_at).format('DD/MM/YYYY') : '--/--/----',
            }
        },
    },

    methods: {
        actualDate(period) {
            if (period === 'anualmente') {
                return this.$moment().format('DD/MM')
            } else {
                return new Date().getDate()
            }
        },

        validate() {
            this.$emit('validate', this.authorization)
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../steps/styles.scss';

.border {
    height: 2px;
    border-radius: 8px;
    background-color: $light-grey;
    margin-bottom: 16px;
}

.checkbox-container {
    margin-top: 2em;

    label {
        color: #979797;
    }
}

.simulation {
    p {
        line-height: 22px;
        vertical-align: middle;
        color: $blueish-gray;
    }

    strong {
        font-weight: 500 !important;
        color: $black;
    }
}
</style>
