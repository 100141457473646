<template>
    <div class="custom-select">
        <div class="filter-container" @click="openTransactionsFilter">
            <span :class="{ hide: filterIsOpen }">{{ label }}</span>

            <a class="icon ion-ios-arrow-down" :class="{ active: filterIsOpen }">
                <ion-icon name="chevron-down-outline"></ion-icon>
            </a>
        </div>

        <v-expand-transition>
            <div class="the-filter" v-show="filterIsOpen">
                <table>
                    <tr v-for="(option, index) in options" :key="index">
                        <td>
                            <button class="btn-filter" @click="onChange(index)"></button>
                            <a>{{ option.label }}</a>
                        </td>
                    </tr>
                </table>
            </div>
        </v-expand-transition>
    </div>
</template>

<script>
export default {
    name: 'CustomSelect',

    props: {
        options: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            filterIsOpen: false,
            index: 0,
        }
    },

    computed: {
        label() {
            return this.options[this.index].label
        },
    },

    methods: {
        openTransactionsFilter() {
            this.filterIsOpen = true
        },

        onChange(index) {
            this.index = index
            this.$emit('update-option', this.options[index].value)
            this.filterIsOpen = false
        },
    },
}
</script>

<style lang="scss" scoped>
.custom-select {
    .filter-container {
        width: 105px;
        position: relative;
        font-size: 14px;
        display: flex;
        cursor: pointer;
        justify-content: space-between;

        span {
            margin-left: 10px;
            font-weight: 500;

            &.hide {
                display: none;
            }
        }

        .icon {
            height: 18.57px;
            width: 18.57px;
            color: $title-grey;

            &.hide {
                opacity: 0;
                pointer-events: none;
            }

            &.active {
                color: #9fa5b1;
            }
        }
    }

    .the-filter {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 98px;
        width: 120px;
        border-radius: 2px;
        background-color: #ffffff;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
        z-index: 2;

        table {
            position: relative;
            width: 100%;
            color: #9fa5b1;
            font-size: 14px;
            text-align: left;
            margin: 11px 0px 13px 15px;

            .btn-filter {
                position: absolute;
                left: -10px;
                width: 105px;
                box-shadow: unset !important;
            }

            td {
                height: 30px;
            }

            td > a {
                color: #9fa5b1;
                font-weight: 500;
            }
        }
    }
}
</style>
