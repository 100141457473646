import util from './util'
import moment from 'moment'

const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
]

const getMonthName = (month) => {
    let mes = Number(month) - 1

    return meses[mes]
}

const inputFormatDate = (date) => {
    return date.toISOString().slice(0, 10)
}

const convertDate = (year, month, day) => {
    return inputFormatDate(new Date(year, month, day))
}

const getDay = (date) => {
    if (typeof date === 'string') return Number(date.slice(8, 10))
    return date
}

const getMonth = (date, invoice) => {
    let mes = Number(date.slice(5, 7))

    return !invoice ? mes : mes === 1 ? 12 : mes - 1
}

const getYear = (date, invoice) => {
    let mes = Number(date.slice(5, 7))
    let year = Number(date.slice(0, 4))

    return !invoice ? year : mes === 1 ? year - 1 : year
}

const getDayAndMonth = (date) => {
    let day = getDay(date)
    let month = getMonth(date)

    return (day < 10 ? '0' + day : day) + '/' + (month < 10 ? '0' + month : month)
}

// se mes e ano forem iguais
const compareDates = (date, other_date) => {
    if (!date || !other_date) return true

    let month = getMonth(date)
    let year = getYear(date)
    let other_month = getMonth(other_date)
    let other_year = getYear(other_date)

    return month === other_month && year === other_year
}

// comparar data do transação de condiz com mês do query do invoice
const compareInvoiceDates = (date, transactionDate, closeOnDay) => {
    const appInvoiceStartDate = util.exactDate(date).date(closeOnDay).add(-1, 'M')
    const appInvoiceEndDate = util.exactDate(date).date(closeOnDay)
    const theDate = util.exactDate(transactionDate)

    return theDate.isSameOrAfter(appInvoiceStartDate) && theDate.isBefore(appInvoiceEndDate)
}

const getDays = (date) => {
    let day = new Date(getYear(date), getMonth(date), 0).getDate()
    let days = []

    for (let i = 1; i <= day; i++) {
        days.push(i)
    }

    return days
}

const changeMonth = (date, value) => {
    let dateCopy = new Date(getYear(date), getMonth(date), 0)
    let data = {}

    data.inicio = convertDate(dateCopy.getFullYear(), dateCopy.getMonth() + value, 1)
    data.fim = convertDate(dateCopy.getFullYear(), dateCopy.getMonth() + ++value, 0)

    return data
}

const getMonths = (date) => {
    let dateCopy = new Date(getYear(date), getMonth(date), 0)
    let months = []

    for (let i = 0; i < 6; i++) {
        months.push(getMonthName(dateCopy.toISOString().slice(5, 7)).slice(0, 3).toUpperCase())
        dateCopy = new Date(dateCopy.getFullYear(), dateCopy.getMonth() - 1, 1)
    }

    return months.reverse()
}

export default {
    getMonthName,
    inputFormatDate,
    convertDate,
    getDay,
    getDays,
    getDayAndMonth,
    changeMonth,
    getMonth,
    getMonths,
    getYear,
    compareDates,
    compareInvoiceDates,
}
