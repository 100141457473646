<template>
    <div class="confirmation-modal flex column">
        <header>
            <div class="flex">
                <h1>{{ title }}</h1>
                <button data-cy="comfirm-close" class="close grow-1" type="button" @click.stop="confirm">
                    <i class="icon ion-md-close"><ion-icon name="close"></ion-icon></i>
                </button>
            </div>
        </header>

        <div class="flex container-button column center space-between">
            <div>
                <i v-if="error">
                    <img src="@/assets/img/payments/exclamation.svg" />
                </i>
                <i v-else class="far fa-check-circle"></i>
            </div>

            <div class="text" v-html="text"></div>

            <div class="btn-container">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import BasicButton from '../../buttons/BasicButton.vue'
export default {
    components: { BasicButton },

    name: 'ConfirmationCreateModal',

    props: {
        type: { type: String },
        title: String,
        text: String,
        error: Boolean,
    },

    methods: {
        confirm() {
            this.$emit('confirmEvent')
        },
    },
}
</script>

<style lang="scss" scoped>
.overlay {
    z-index: 1001;
}

.confirmation-modal {
    min-height: 280px;
    width: 400px;
    border-radius: 8px;
    background-color: white;
    overflow: hidden;
    padding: 0 23px;

    header {
        font-size: 18px;
        height: 56px;
        position: relative;
        margin-bottom: 40px;

        h1 {
            height: 56px;
            padding: 24px 0;
            margin: auto;
            line-height: 1.6;
            text-transform: uppercase;
            font-weight: 600;
            color: $darker-blue;
            font-size: 18px;
        }

        .close {
            position: absolute;
            top: 0;
            right: 0;
            padding: 24px 0 0 0;
            font-size: 24px;
        }

        .icon {
            height: 18.57px;
            max-width: 18.57px;
            color: #bbbcbf;
        }
    }

    .container-button {
        width: 100%;
        margin-bottom: 23px;
        position: relative;

        .btn-container {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            width: 100%;
            margin-top: 16px;

            & * {
                margin-bottom: 10px;
            }
        }

        .text {
            margin-top: 16px;
            margin-bottom: 25px;
            color: $light-black;
            text-align: center;
            line-height: 20px;
            font-size: 18px;
            font-weight: 500;
        }

        .text::first-letter {
            text-transform: uppercase;
        }

        .fa-check-circle,
        .fa-exclamation-circle {
            font-size: 69px;
            color: #2dc785;
        }

        .fa-exclamation-circle {
            color: $hint-red;
        }
    }
}

.flex {
    display: flex;
}

.center {
    align-items: center;
}

.space-between {
    justify-content: space-between;
}

.column {
    flex-direction: column;
}

.grow-1 {
    flex-grow: 1;
}
</style>
