<template>
    <div class="subscription" :style="styles">
        <header>
            <router-link to="/painel">
                <h1>
                    <img :src="$variables.brandIcon" alt="brand" />
                </h1>
            </router-link>

            <h2>assinatura {{ $variables.brand }} premium</h2>
        </header>

        <div v-if="!update" class="stepper">
            <svg width="310" height="35" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <title>Layer 1</title>
                    <rect fill="#B7B8B9" x="2.5" y="14.5" width="300" height="6" id="svg_3" />
                    <ellipse fill="#B7B8B9" cx="15" cy="18" id="svg_4" rx="15" ry="15" />
                    <ellipse fill="#B7B8B9" cx="292" cy="17" id="svg_5" rx="15" ry="15" />
                    <ellipse fill="#B7B8B9" cx="200.5" cy="17.5" id="svg_6" rx="10" ry="10" stroke="null" />
                    <ellipse fill="#B7B8B9" cx="109.5" cy="17.5" id="svg_1" rx="10" ry="10" stroke="null" />
                </g>
            </svg>

            <svg class="stepper-figure" :style="stepperWidth" height="35" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <title>Layer 1</title>
                    <rect fill="#028FFF" x="2.5" y="14.5" width="300" height="6" id="svg_3" />
                    <ellipse fill="#028FFF" cx="15" cy="18" id="svg_4" rx="15" ry="15" />
                    <ellipse fill="#028FFF" cx="292" cy="17" id="svg_5" rx="15" ry="15" />
                    <ellipse fill="#028FFF" cx="200.5" cy="17.5" id="svg_6" rx="10" ry="10" stroke="null" />
                    <ellipse fill="#028FFF" cx="109.5" cy="17.5" id="svg_1" rx="10" ry="10" stroke="null" />
                </g>
            </svg>
        </div>

        <form @submit.prevent="() => $refs.step.validate()">
            <v-expand-transition v-if="step < 5">
                <component
                    :is="component"
                    :period="getPeriod"
                    :insertPromoCode="insertPromoCode"
                    v-bind="summary"
                    ref="step"
                    @subscriptionData="updateSubscription"
                    @validate="validate"
                    @authorize="(authorization) => (authorize = authorization)"
                />
            </v-expand-transition>

            <v-expand-transition v-else>
                <success-msg v-bind="successData" @restart="restart" />
            </v-expand-transition>

            <basic-button
                v-if="step < 5 && !update"
                :loading="disabled"
                :disabled="!authorize"
                :label="btnLabel"
                type="submit"
                width="100%"
                bgColor="#FA7D20"
                data-cy="submit-next-step"
            />

            <basic-button
                v-else-if="step < 5"
                :loading="disabled"
                :disabled="!authorize"
                label="Atualizar"
                type="submit"
                width="100%"
                data-cy="submit-update"
            />

            <basic-button
                v-if="update"
                style="margin-top: 10px"
                :disabled="disabled"
                label="Mudei de ideia"
                :reverse="true"
                width="100%"
                @click.native="endProcess"
                data-cy="submit-update-cancel"
            />

            <basic-button
                v-if="step === 1 && !this.insertPromoCode"
                style="margin: 12px 0 24px"
                label="Desejo usar um cupom"
                type="button"
                :disabled="disabled"
                :reverse="true"
                width="100%"
                bgColor="#FA7D20"
                @click.native="
                    insertPromoCode = true
                    stepPromoCode++
                "
            />

            <basic-button
                v-if="this.insertPromoCode"
                style="margin: 12px 0 0px"
                label="Voltar"
                type="button"
                :reverse="true"
                width="100%"
                bgColor="#FA7D20"
                @click.native="
                    insertPromoCode = false
                    stepPromoCode = 0
                    promoCode = undefined
                "
            />

            <div style="text-align: center">
                <a v-if="step === 1 && !this.insertPromoCode" class="logout-btn" @click="logout"
                    >Utilizar outra conta</a
                >
            </div>
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import request from '../../common/request'
import BasicButton from '../../components/buttons/BasicButton.vue'
import StepOne from './steps/StepOne'
import StepTwo from './steps/StepTwo'
import StepThree from './steps/StepThree'
import StepFour from './steps/StepFour'
import SuccessMsg from './steps/SuccessMsg.vue'
import { checkSubscription } from '../../common/checkSubscription'
import apiService from '../../services/api.service'
import { environment } from '../../common/environment'
import authService from '../../services/auth.service'
import util from '../../common/util'

const initialState = {
    step: 1,
    stepPromoCode: 0,
    client: {},
    plan: {},
    payment: {},
    authorize: true,
    successData: {
        apiErr: false,
        msg: '',
    },
    done: false,
    update: false,
    subscription: {},
    insertPromoCode: false,
    promoCode: undefined,
    promotionalCode: {},
}

export default {
    components: { StepOne, StepTwo, StepThree, StepFour, SuccessMsg, BasicButton },

    name: 'Subscription',

    data() {
        return {
            ...initialState,
        }
    },

    mounted() {
        window.Iugu.setAccountID('64637D959B596A472DFB60D1D0B16B0F')
        window.Iugu.setTestMode(process.env.VUE_APP_IUGU_MODE === 'develop')
    },

    computed: {
        ...mapState(['user', 'disabled', 'subscriptions']),

        component() {
            switch (this.step) {
                case 1:
                    return 'step-one'
                case 2:
                    return 'step-two'
                case 3:
                    return 'step-three'
                case 4:
                    return 'step-four'
                default:
                    return null
            }
        },

        btnLabel() {
            return this.step === 4 ? 'Assinar' : this.stepPromoCode === 1 ? 'Aplicar' : 'próximo'
        },

        getPeriod() {
            return !this.plan.period ? '' : this.plan.period === 'mensalmente' ? 'mensal' : 'anual'
        },

        summary() {
            const { plan, client, payment, promotionalCode } = this

            return {
                plan,
                client,
                payment,
                promotionalCode,
            }
        },

        stepperWidth() {
            switch (this.step) {
                case 1:
                    return { width: '9.6%' }
                case 2:
                    return { width: '38.5%' }
                case 3:
                    return { width: '67.9%' }
                default:
                    return { width: '100%' }
            }
        },

        styles() {
            return {
                '--background': `url(${this.$variables.background})`,
            }
        },
    },

    watch: {
        subscriptions: {
            deep: true,
            immediate: true,
            async handler(subscriptions) {
                if (!subscriptions.length) {
                    await checkSubscription()
                }
                this.subscription = this.subscriptions[0]
            },
        },
    },

    methods: {
        logout() {
            authService.logout()
        },

        restart() {
            if (this.$route.name === 'updateSubscription') {
                this.step = 3
                return
            }
            this.step = 1
            this.stepPromoCode = 0
        },

        endProcess() {
            const device = util.identifyUserDevice()

            if (device === 'mobile') window.location.href = `${process.env.VUE_APP_HOST_URL}/assine?sucesso`
            else this.$router.push('/configuracoes/plano')
        },

        updateSubscription(data) {
            switch (this.step) {
                case 1:
                    this.stepOne(data)
                    break
                case 2:
                    this.client = { ...data }
                    break
                case 3:
                    this.payment = Object.assign(this.payment, data)
                default:
                    break
            }
        },

        async updateUserData() {
            let payload = {
                address: this.client.address,
                birthdate: new Date(this.client.birthday).toISOString(),
                phoneNumber: this.client.phoneNumber,
                cpf: this.client.cpf,
                gender: this.client.gender,
                socialEmail: this.user.socialLogin === 'facebook.com' ? this.client.email : undefined,
            }

            payload.address.neighborhood = payload.address.district
            delete payload.address['district']

            return request.patch({ collection: 'users', data: payload, _id: this.user._id })
        },

        stepOne(data) {
            if (data.promoCode) {
                this.promoCode = data.promoCode
                this.promotionalCode = data.promotionalCode

                return
            }

            this.plan = { ...data }
            this.payment.day = new Date().getDate()
        },

        setPromoCodeStep() {
            switch (this.stepPromoCode) {
                case 1:
                    this.$refs.step.setPromoPlan().then(() => this.stepPromoCode++)
                    break
                case 2:
                default:
                    this.insertPromoCode = false
                    this.stepPromoCode++
                    this.step++
            }
        },

        async validate(valid) {
            if (valid) {
                switch (this.step) {
                    case 1:
                    case 3:
                        if (this.update) await this.updatePaymentMethod()
                        else if (this.insertPromoCode) {
                            this.setPromoCodeStep()
                        } else this.step++

                        break
                    case 2:
                        this.$store.commit('DISABLED')

                        try {
                            await this.updateUserData()
                        } catch (e) {
                            console.error(e)
                        } finally {
                            this.$store.commit('DISABLED')
                            this.authorize = false
                        }
                        this.step++
                        break
                    case 4:
                        await this.subscribe()
                    default:
                        break
                }
            }
        },

        parseClient() {
            let [phonePrefix, phone] = this.client.phoneNumber.split(' ')
            let cpf = this.client.cpf.replace(/[^\d]+/g, '')
            let cep = this.client.address.cep.replace(/[^\d]+/g, '')
            let number = this.client.address.number
            phone = phone.replace(/[^\d]+/g, '')
            phonePrefix = phonePrefix.substring(1, 3)

            return {
                phonePrefix,
                phone,
                cpf,
                cep,
                number,
                street: this.client.address.street,
                district: this.client.address.district,
            }
        },

        splitOwnerName() {
            let firstName = ''
            let lastName = ''
            this.payment.creditcard.owner.split(' ').map((part, index) => {
                if (index === 0) {
                    firstName = firstName.concat(part)
                } else {
                    lastName = lastName.concat(part + ' ')
                }
            })

            return { firstName, lastName }
        },

        getUrl() {
            return `${environment.SUBSCRIPTION_URL}/checkout${this.promoCode ? `?promoCode=${this.promoCode}` : ''}`
        },

        async subscribe() {
            this.successData.apiErr = false
            this.$store.commit('DISABLED')

            const subscription = this.subscriptions[0]
            let client = this.parseClient()
            client.plan = this.plan.plan_identifier
            client.paymentMethod = this.payment.method

            if (subscription.subscriptionId) {
                if (subscription.status) {
                    this.successData.msg = 'Você já tem uma assinatura ativa!'
                } else {
                    this.successData.msg = 'Você já tem uma assinatura criada. Renove-a para ativar seu plano premium'
                }
                this.successData.apiErr = true
                this.step++
                this.$store.commit('DISABLED')

                return
            }

            if (client.paymentMethod === 'credit_card') {
                const splitedName = this.splitOwnerName()

                let cc = window.Iugu.CreditCard(
                    this.payment.creditcard.number,
                    this.payment.creditcard.validMonth,
                    this.payment.creditcard.validYear,
                    splitedName.firstName,
                    splitedName.lastName,
                    this.payment.creditcard.cvv
                )

                window.Iugu.createPaymentToken(cc, async (response) => {
                    if (response.errors) {
                        console.log(JSON.stringify(response.errors))
                        this.successData.msg = 'Houve um problema ao tentar subscrever com esse cartão'
                        this.successData.apiErr = true
                        this.$store.commit('DISABLED')
                        this.step++
                    } else {
                        try {
                            client.cardToken = response.id
                            const sub = (await apiService.post(this.getUrl(), client)).data
                            this.$store.commit('ATT_SETED_DATA', {
                                attr: 'subscriptions',
                                data: sub,
                            })

                            this.successData.msg = 'Sua assinatura foi realizada com sucesso!'
                        } catch (error) {
                            this.successData.msg = 'Houve um problema ao tentar subscrever a sua conta'
                            this.successData.apiErr = true
                            console.log(error)
                        } finally {
                            this.$store.commit('DISABLED')
                            this.step++
                        }
                    }
                })
            } else {
                try {
                    const sub = (await apiService.post(this.getUrl(), client)).data
                    this.$store.commit('ATT_SETED_DATA', {
                        attr: 'subscriptions',
                        data: sub,
                    })
                    this.successData.msg = 'Sua assinatura foi realizada com sucesso!'
                } catch (error) {
                    this.successData.msg = 'Houve um problema ao tentar subscrever a sua conta'
                    this.successData.apiErr = true
                    console.log(error)
                } finally {
                    this.$store.commit('DISABLED')
                    this.step++
                }
            }
        },

        async updatePaymentMethod() {
            this.successData.apiErr = false
            this.$store.commit('DISABLED')

            let paymentUpdate = {}
            paymentUpdate.paymentMethod = this.payment.method

            if (paymentUpdate.paymentMethod === 'credit_card') {
                const splitedName = this.splitOwnerName()

                let cc = window.Iugu.CreditCard(
                    this.payment.creditcard.number,
                    this.payment.creditcard.validMonth,
                    this.payment.creditcard.validYear,
                    splitedName.firstName,
                    splitedName.lastName,
                    this.payment.creditcard.cvv
                )

                window.Iugu.createPaymentToken(cc, async (response) => {
                    if (response.errors) {
                        this.successData.msg = 'Houve um problema ao tentar usar esse cartão'
                        this.successData.apiErr = true
                        this.$store.commit('DISABLED')
                        this.step++
                    } else {
                        try {
                            paymentUpdate.cardToken = response.id

                            const sub = (
                                await apiService.post(`${environment.SUBSCRIPTION_URL}/change_payment`, paymentUpdate)
                            ).data
                            this.$store.commit('ATT_SETED_DATA', {
                                attr: 'subscriptions',
                                data: sub,
                            })

                            this.successData.msg = 'Método de pagamento alterado com sucesso!'
                        } catch (error) {
                            this.successData.msg = 'Houve um problema ao tentar alterar sua forma de pagamento'
                            this.successData.apiErr = true
                            console.log(error)
                        } finally {
                            this.$store.commit('DISABLED')
                            this.step += 2
                        }
                    }
                })
            } else {
                try {
                    const sub = (await apiService.post(`${environment.SUBSCRIPTION_URL}/change_payment`, paymentUpdate))
                        .data
                    this.$store.commit('ATT_SETED_DATA', {
                        attr: 'subscriptions',
                        data: sub,
                    })

                    this.successData.msg = 'Método de pagamento alterado com sucesso!'
                } catch (error) {
                    this.successData.msg = 'Houve um problema ao tentar alterar sua forma de pagamento'
                    this.successData.apiErr = true
                    console.log(error)
                } finally {
                    this.$store.commit('DISABLED')
                    this.step += 2
                }
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        if (to.name === 'changeSubscriptionPayment') {
            next((vm) => {
                vm.step = 3
                vm.update = true
            })

            return
        }

        next()
    },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';

form {
    padding-bottom: 32px !important;

    .logout-btn {
        color: $otto-orange !important;
        text-transform: uppercase;
        font-size: 14px;
    }
}
</style>
