<template>
    <div class="otto-premium-card">
        <card-title :plan="subscription.plan" />

        <card-badge :subscription="subscription" />

        <card-body :subscription="subscription" />

        <span class="plan-cycle">{{ planCycle }}</span>

        <card-footer :subscription="subscription" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CardTitle from './components/CardTitle.vue'
import CardBadge from './components/CardBadge.vue'
import CardBody from './components/CardBody.vue'
import CardFooter from './components/CardFooter.vue'

export default {
    name: 'OttoPremiumCard',

    components: { CardTitle, CardBadge, CardBody, CardFooter },

    computed: {
        ...mapState(['subscriptions', 'disabled']),

        ...mapGetters(['isExpired']),

        subscription() {
            return this.subscriptions[0] ? this.subscriptions[0] : {}
        },

        planCycle() {
            const expireAt = this.subscription.expireAt
                ? this.$moment(this.subscription.expireAt.slice(0, 10)).format('DD/MM')
                : ''

            if (!this.isExpired && !this.subscription.suspended) {
                switch (this.subscription.plan) {
                    case 'otto_annual_new':
                    case 'otto_basic_annual':
                    case 'otto_annual':
                        return `Cobrado anualmente a partir de ${expireAt}`
                    case 'otto_monthly_new':
                    case 'otto_monthly':
                    case 'otto_basic_monthly':
                    case 'otto_family_monthly':
                        return `Cobrado mensalmente a partir de ${expireAt}`
                    default:
                        return
                }
            } else if (this.subscription.status && this.subscription.suspended) {
                return `Assinatura suspensa. Válida até ${expireAt}`
            } else {
                return
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.otto-premium-card {
    width: 340px;
    height: 240px;
    box-shadow: 0px 0px 7px #00000019;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 24px;

    * {
        margin-bottom: 6px;
    }

    .plan-cycle {
        font-size: 14px;
        font-weight: 500;
        position: absolute;
        bottom: 70px;
        color: $darker-blue;
    }
}
</style>
