<template>
    <div class="modal-frame">
        <div class="overlay">
            <v-slide-y-reverse-transition>
                <div v-if="modal" class="modal" :style="styles">
                    <header :style="{ backgroundColor: headerColor, marginBottom: noHeader ? '0' : '40px' }">
                        <h1>{{ title }}</h1>

                        <button v-if="closeBtn" class="close" type="button" @click.stop="$emit('close-modal')">
                            <ion-icon name="close"></ion-icon>
                        </button>
                    </header>

                    <div class="content">
                        <slot name="content"></slot>
                    </div>

                    <div class="btn-container">
                        <slot name="btn-container"></slot>
                    </div>
                </div>
            </v-slide-y-reverse-transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalFrame',

    props: {
        width: {
            type: [String, Number],
            default: '715px',
        },
        title: String,
        headerColor: {
            type: String,
            default: 'trasparent',
        },
        headerTextColor: {
            type: String,
            default: '#1e3351',
        },
        headerTextSize: {
            type: Number,
            default: 18,
        },
        noHeader: {
            type: Boolean,
            default: false,
        },
        backgroundColor: {
            type: String,
            default: 'white',
        },
        closeBtn: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            modal: false,
        }
    },

    mounted() {
        this.modal = true
    },

    computed: {
        styles() {
            return {
                '--modal-width': typeof this.width === 'number' ? `${this.width}px` : this.width,
                '--header-text': this.headerTextColor,
                '--close-btn': this.headerColor === 'trasparent' ? '#9fa5b1' : 'white',
                '--bg-color': this.backgroundColor,
                '--header-text-size': this.headerTextSize,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.modal {
    background: var(--bg-color);
    border-radius: 8px;
    width: var(--modal-width);

    header {
        @include page-title();
        text-align: center;
        position: relative;
        font-weight: 600;
        height: 70px;
        padding: 28px 24px;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        color: var(--header-text);
        font-size: var(--header-text-size);

        .close {
            position: absolute;
            padding: unset;
            top: 24px;
            right: 24px;
            font-size: 22px;
            color: var(--close-btn);
        }
    }

    .content {
        padding: 0 24px;

        .text {
            padding-top: 16px;
            margin-bottom: 32px;
            color: $darker-blue;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
        }
    }

    .btn-container {
        padding: 0 24px 24px;

        & > .basic-button {
            margin: 0 0 8px 0 !important;
        }
    }
}
</style>
