<template>
    <div class="step-two step">
        <h3 class="step-title">Suas informações pessoais</h3>

        <stacked-input
            type="email"
            label="Seu e-mail"
            v-model="client.email"
            :focus="!lockEmailInput"
            :readonly="lockEmailInput"
            :errorValidation="validation.hasError('client.email')"
            :errorMsg="validateField(validation.firstError('client.email'))"
            @blur="validateSingleField('client.email')"
            name="email"
            :isRequired="true"
        />

        <div class="form-row">
            <stacked-input
                label="Seu CPF"
                v-model="client.cpf"
                v-mask="'###.###.###-##'"
                :focus="lockEmailInput"
                :errorValidation="validation.hasError('client.cpf')"
                :errorMsg="validateField(validation.firstError('client.cpf'))"
                @blur="validateSingleField('client.cpf')"
                name="cpf"
                inputmode="numeric"
                pattern="[0-9, ., -]*"
                :isRequired="true"
            />

            <stacked-input
                label="Nascimento"
                type="date"
                :max="$moment().format('YYYY-MM-DD')"
                v-model="client.birthday"
                :errorValidation="validation.hasError('client.birthday')"
                @blur="validateSingleField('client.birthday')"
                name="birthday"
                :isRequired="true"
            />
        </div>

        <div class="radio-title">Como se identifica</div>

        <div class="radio-group">
            <div>
                <input type="radio" id="male" value="Male" v-model="client.gender" />
                <label class="radio-label" for="male">Masculino</label>
            </div>

            <div>
                <input type="radio" id="female" value="Female" v-model="client.gender" />
                <label class="radio-label" for="female">Feminino</label>
            </div>
        </div>

        <div class="form-row">
            <stacked-input
                label="CEP"
                v-mask="'#####-###'"
                v-model="client.address.cep"
                :errorValidation="validation.hasError('client.address.cep')"
                :errorMsg="validateField(validation.firstError('client.address.cep'))"
                @blur="validateSingleField('client.address.cep')"
                name="cep"
                inputmode="numeric"
                pattern="[0-9, -]*"
                :isRequired="true"
            />

            <stacked-input
                label="Nº da Casa"
                v-model="client.address.number"
                :errorValidation="validation.hasError('client.address.number')"
                @blur="validateSingleField('client.address.number')"
                name="number"
                :isRequired="true"
            />
        </div>

        <stacked-input
            label="Logradouro"
            v-model="client.address.street"
            :errorValidation="validation.hasError('client.address.street')"
            @blur="validateSingleField('client.address.street')"
            name="street"
            :isRequired="true"
        />

        <stacked-input
            label="Bairro"
            v-model="client.address.district"
            :errorValidation="validation.hasError('client.address.district')"
            @blur="validateSingleField('client.address.district')"
            name="district"
            :isRequired="true"
        />

        <div class="form-row">
            <stacked-input
                label="Seu telefone"
                v-model="client.phoneNumber"
                v-mask="['(##) ####-####', '(##) #####-####']"
                :blur="false"
                :errorValidation="validation.hasError('client.phoneNumber')"
                :errorMsg="validateField(validation.firstError('client.phoneNumber'))"
                @blur="validateSingleField('client.address.phoneNumber')"
                name="phoneNumber"
                type="tel"
                pattern="[0-9, (, ), -]*"
                :isRequired="true"
            />

            <div class="stacked-input"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import StackedInput from '../../../components/inputs/StackedInput.vue'
import { mask } from 'vue-the-mask'
import SimpleVueValidation from 'simple-vue-validator'
import validation from '@/common/validation'
import apiService from '../../../services/api.service'
import { environment } from '../../../common/environment'
import authService from '../../../services/auth.service'

const Validator = SimpleVueValidation.Validator

export default {
    components: { StackedInput },
    name: 'StepTwo',
    directives: {
        mask,
    },

    data() {
        return {
            client: {
                email: '',
                cpf: '',
                birthday: '',
                phoneNumber: '',
                gender: '',
                address: {
                    cep: '',
                    street: '',
                    number: '',
                    district: '',
                    city: '',
                    state: '',
                },
            },

            lastCep: '',
        }
    },

    computed: {
        ...mapState(['user']),

        lockEmailInput() {
            return this.user.socialLogin !== 'facebook.com'
        },
    },

    methods: {
        validateField(errorMsg) {
            return validation.svvDictionaryMessageValidation(errorMsg)
        },

        async validateSingleField(field) {
            await this.$validate(field)
        },

        async validate() {
            await this.validation.reset()

            this.$emit('validate', await this.$validate())
        },
    },

    watch: {
        user: {
            deep: true,
            immediate: true,
            async handler(user, oldUser) {
                if (!Object.keys(user).length) {
                    try {
                        this.$store.commit('DISABLED')
                        const data = (await apiService.get(environment.USERS_URL)).data
                        this.$store.commit('SET_DATA', { data, attr: 'user' })

                        authService.saveOnLocalStorage('id', data._id)
                    } catch (err) {
                        console.log(err)
                    } finally {
                        this.$store.commit('DISABLED')
                    }
                }

                if (oldUser !== user) {
                    const { socialEmail, socialLogin, email, cpf, birthdate, phoneNumber, gender } = user

                    if (socialLogin === 'facebook.com') this.client.email = socialEmail || ''
                    else this.client.email = socialEmail || email

                    this.client.cpf = cpf || ''
                    this.client.birthday = birthdate ? birthdate.slice(0, 10) : ''
                    this.client.phoneNumber = phoneNumber || ''
                    this.client.gender = gender

                    if (this.user.address) {
                        this.client.address.cep = this.user.address.cep || ''
                        this.client.address.number = this.user.address.number || ''
                        this.client.address.street = this.user.address.street || ''
                        this.client.address.district = this.user.address.neighborhood || ''
                    }

                    this.lastCep = this.client.address.cep
                }
            },
        },

        client: {
            deep: true,
            immediate: true,
            handler(client) {
                this.$emit('subscriptionData', client)
            },
        },
    },

    validators: {
        'client.email': function (value) {
            return Validator.value(value)
                .required()
                .custom(() => {
                    const valid = validation.validateEmail(value)
                    return !valid ? 'Email inválido' : ''
                })
        },
        'client.cpf': function (value) {
            return Validator.value(value)
                .required()
                .custom(() => {
                    return validation.validateCPF(value)
                })
        },
        'client.birthday': function (value) {
            return Validator.value(value).required()
        },
        'client.phoneNumber': function (value) {
            return Validator.value(value)
                .required()
                .custom(() => {
                    return validation.validatePhone(value)
                })
        },
        'client.address.cep': function (value) {
            return Validator.value(value)
                .required()
                .custom(async () => {
                    const validator = await validation.validateCEP(value)

                    if (typeof validator === 'object') {
                        if (this.lastCep !== this.client.address.cep) {
                            this.client.address.street = validator.logradouro
                            this.client.address.district = validator.bairro
                        }

                        this.lastCep = this.client.address.cep

                        return ''
                    }
                    return validator
                })
        },
        'client.address.number': function (value) {
            return Validator.value(value).required()
        },
        'client.address.street': function (value) {
            return Validator.value(value).required()
        },
        'client.address.district': function (value) {
            return Validator.value(value).required()
        },
    },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';

input[type='tel'] {
    -webkit-text-security: disc;
}
</style>
