<template>
    <transition name="fade">
        <div
            v-show="show"
            class="element"
            :style="{backgroundColor: backgroundColor, color: textColor}"
        >
            <span>{{message}}</span>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'AlertHeader',
    props: {
        message: {
            type: String,
            required: true
        },
        timer: {
            type: Number,
            default: 0
        },
        show: {
            type: Boolean,
            default: false
        },
        backgroundColor: {
            type: String,
            required: false,
            default: "green"
        },
        textColor: {
            type: String,
            required: false,
            default: "black"
        }
    },
    data() {
        return {
            showAlert: false
        };
    },
};
</script>

<style scoped>
.element {
    position: absolute;
    z-index: 1001;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 8px 20px;
    /* filter: opacity(80%); */
    font-weight: bold;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>