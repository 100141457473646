<template>
    <div class="flex column">
        <div class="flex-bubble row">
            <img :src="$variables.chatIcon" class="chat-otto-img" />

            <svg width="15px" height="10px" class="svg-path-otto">
                <polygon points="0,0 15,0 15,10" fill="white" class="otto-path" />
            </svg>

            <p class="otto-talk">
                Pronto! Quando esta categoria estiver próxima de atingir o limite estabelecido, você será avisado. Novas
                categorias podem ser adicionadas usando o botão ao lado.
            </p>
        </div>

        <div v-if="budget.essentialCategories.length" style="margin-bottom: 16px">
            <spending-card
                :categoriesList="budget.essentialCategories"
                :percentage="totalPercentage"
                :revenues="balance"
                title="Gastos Essenciais"
                @toggleEdition="editBudget"
                :creation="true"
            />
        </div>

        <div v-if="budget.noessentialCategories.length" style="margin-bottom: 16px">
            <spending-card
                :categoriesList="budget.noessentialCategories"
                :percentage="totalPercentage"
                :revenues="balance"
                essential="no"
                title="Gastos Secundários"
                @toggleEdition="editBudget"
                :creation="true"
            />
        </div>

        <div v-if="budget.investiments.length">
            <spending-card
                :categoriesList="budget.investiments"
                :percentage="totalPercentage"
                :revenues="balance"
                :edit="isEdit"
                essential="inv"
                :investiments="true"
                title="Investimentos"
                @toggleEdition="editBudget"
                :creation="true"
            />
        </div>

        <category-budget-edition-modal
            v-if="modalVisible"
            @closeModalEvent="closeModal"
            :data="onEditCategory"
            :addedCategories="budget"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import SpendingCard from '../cards/SpendingCard.vue'
import CategoryBudgetEditionModal from '../modals/CategoryBudgetEditionModal.vue'

export default {
    name: 'CategoryBudgetList',

    components: {
        SpendingCard,
        CategoryBudgetEditionModal,
    },

    props: {
        budget: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            totalPercentage: 0,
            budgets: {},
            modalVisible: false,
            onEditCategory: {},
        }
    },

    methods: {
        editBudget(payload) {
            this.onEditCategory = payload
            this.modalVisible = true
        },

        closeModal(payload) {
            this.onEditCategory = payload
            this.$emit('editCategoryEvent', this.onEditCategory)
            this.totalPercentage = this.totalSpending
            this.modalVisible = false
        },
    },

    computed: {
        ...mapGetters(['balanceMonthExpectedPerType']),

        balance() {
            return this.balanceMonthExpectedPerType('revenues', null)
        },

        totalSpending() {
            return this.budget.essentialSpending + this.budget.noessentialSpending + this.budget.investimentSpending
        },
    },
}
</script>

<style lang="scss" scoped>
.column {
    flex-direction: column;
}

.flex {
    display: flex;
}

.flex-bubble {
    display: flex;
    margin-bottom: 8px;
    &.column {
        flex-direction: column;
    }

    &.row {
        flex-direction: row;
        margin: 0;
    }
}
.otto-talk {
    left: -3px;
    background-color: #ffffff;
    text-align: left;
    margin-bottom: 8px;
    color: #5b6772;
}

.user-talk,
.otto-talk {
    position: relative;
    max-width: 529px;
    line-height: 18px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 30px;
}
.chat-otto-img,
.chat-user-img {
    position: relative;
    top: 200;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50%;
    padding: 4px;
    box-shadow: 1px 1px 2px #00000030;
    border: 1px solid $btn-blue;
}
</style>
