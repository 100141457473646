<template>
    <div class="chart-box" :class="width">
        <h1>{{ boxTitle }}</h1>

        <div class="content">
            <component
                :is="chart"
                :dataset="data"
                :series="chartSeries"
                :_options="chartOptions"
                v-bind="chartProps"
                :_loading="loading"
                @openCategoryModalEvent="openCategoriesModal"
            />

            <div class="overlay" v-if="category">
                <modal-transition :transform="transform">
                    <categories-details
                        v-if="category"
                        :category="category.category"
                        type="expenses"
                        @closeCategoryModalEvent="closeCategoryModal"
                    />
                </modal-transition>
            </div>
        </div>
    </div>
</template>

<script>
import LineChart from '../charts/LineChart.vue'
import CategoryChart from '../charts/CategoryChart'
import ObjectivesChart from '../charts/ObjectivesChart'
import CategoriesDetails from '../modals/CategoriesDetailsModal.vue'
import ModalTransition from '../transitions/ModalTransition.vue'

export default {
    name: 'ChartBox',

    components: {
        LineChart,
        CategoryChart,
        ObjectivesChart,
        CategoriesDetails,
        ModalTransition,
    },

    props: {
        width: {
            type: String,
            default: 'full',
        },
        boxTitle: {
            type: String,
            required: true,
        },
        chart: String,
        objectiveName: String,
        objAchieved: Number,
        objValue: Number,
        data: [Object, Array],
        chartSeries: Array,
        chartOptions: Object,
        chartProps: {
            type: Object,
            default: () => {},
        },
        loading: Boolean,
    },

    data() {
        return {
            category: '',
            transform: {
                translateX: 0,
                translateY: 100,
            },
        }
    },

    methods: {
        openCategoriesModal(params) {
            this.category = params
        },

        closeCategoryModal() {
            this.category = null
        },
    },
}
</script>

<style lang="scss" scoped>
.full {
    width: auto;
}
.half {
    width: calc(50% - 12px);
}
.float-l {
    float: left;
}
.margin-left {
    margin-left: 16px;
}

.chart-box {
    position: relative;
    min-width: 244px;
    @include rounded-box();
    @include box-shadow();

    h1 {
        padding: 22px 24px 16px;
        @include card-title();
    }

    .more {
        position: absolute;
        top: 18px;
        right: 24px;
        color: #3f63ed;
        font-size: 10px;
        text-transform: uppercase;
        text-decoration: none;
    }

    .content {
        .obj-text {
            margin-bottom: 16px;
            font-size: 14px;
            color: $medium-grey;
            font-weight: 300;
        }

        .obj-info {
            width: 50%;
            margin-left: 50%;
            color: $dark-blue;
            font-size: 14px;
            line-height: 1.4;
            text-align: right;

            p {
                font-weight: 300;
            }

            .actual-value {
                color: $green;
                font-size: 18px;
            }

            .total-value {
                color: $green;
                font-size: 16px;
            }
        }
    }
}
</style>
