<template>
    <div class="view-container">
        <div class="view-content">
            <ActionBar title="Planejamento" />

            <div class="flex" style="margin-bottom: 24px">
                <div class="flex grow-1 column">
                    <div class="menu-bar flex flex-center box">
                        <month-selector @change-month="changeMonth" :hideButtons="[false, false]" />
                    </div>

                    <div v-if="waiting">
                        <spending-card :creation="false" :waiting="{ is: true, size: 6 }" />
                    </div>

                    <div v-else-if="customBudgets.total && !budgets._id && !waiting">
                        <category-budget-list :budget="customBudgets" @editCategoryEvent="editCustomCategory" />
                    </div>

                    <div class="no-budget-wrapper" v-else-if="!budgets._id && !waiting">
                        <div>
                            Mantenha o orçamento sob controle determinando limites para categorias de gastos, ou, se
                            preferir, o {{ $variables.brand }} cria um planejamento automático pra você.
                        </div>

                        <div class="orcamento-bg"></div>

                        <div class="button-wrapper">
                            <basic-button
                                label="GERAR PLANEJAMENTO AUTOMÁTICO"
                                style="margin-bottom: 8px"
                                @click.native="createBudget"
                                :width="315"
                            />
                            <basic-button
                                label="CRIAR MEU PRÓPRIO PLANEJAMENTO"
                                :reverse="true"
                                @click.native="() => (modalVisible = true)"
                                :width="315"
                            />
                            <small v-if="err">Você precisa cadastrar pelo menos uma receita pra este mês!</small>
                        </div>
                    </div>

                    <div class="flex column" v-else>
                        <spending-card
                            :categoriesList="budgets.essentialCategories"
                            v-if="budgets.essentialCategories.length > 0"
                            :percentage="totalPercentage"
                            :revenues="balance"
                            :edit="isEdit"
                            title="Gastos Essenciais"
                            @toggleEdition="editBudget"
                            @cancelEdition="closeEdition"
                            @updateBudgetEvent="updateBudgetPayload"
                        />

                        <spending-card
                            :categoriesList="budgets.noessentialCategories"
                            v-if="budgets.noessentialCategories.length > 0"
                            :percentage="totalPercentage"
                            :revenues="balance"
                            :edit="isEdit"
                            essential="no"
                            title="Gastos Secundários"
                            @toggleEdition="editBudget"
                            @cancelEdition="closeEdition"
                            @updateBudgetEvent="updateBudgetPayload"
                        />

                        <spending-card
                            :categoriesList="investiments"
                            v-if="budgets.investimentSpending > 0"
                            :percentage="totalPercentage"
                            :revenues="balance"
                            :edit="isEdit"
                            essential="inv"
                            :investiments="true"
                            title="Investimentos"
                            @toggleEdition="editBudget"
                            @cancelEdition="closeEdition"
                            @updateBudgetEvent="updateBudgetPayload"
                        />
                    </div>
                </div>

                <div class="flex column" style="align-items: center; padding-left: 24px">
                    <summary-model
                        :tableData="summaryData"
                        :chartTitle="budgets._id ? 'Equilíbrio do orçamento' : ''"
                        style="margin: 0px; padding-right: 0px"
                    >
                        <template v-slot:progress-bar>
                            <div class="flex space-between" style="margin: 20px 0 5px" v-if="!waiting && budgets._id">
                                <div class="summary">Orçamento planejado de {{ month }}</div>
                                <v-skeleton-loader :width="30" v-if="waiting" type="text" />
                            </div>

                            <v-skeleton-loader
                                v-if="waiting"
                                :height="8"
                                type="progress"
                                :types="{ progress: 'text' }"
                            />

                            <div v-if="budgets._id" style="margin: 3px 0; justify-content: flex-end; width: 100%">
                                <v-skeleton-loader v-if="waiting" :width="120" style="margin-top: 3px" type="text" />
                                <div v-else class="row" style="margin: 0; justify-content: flex-end">
                                    <div class="chart-text expenses-color">
                                        {{ totalIncome | currency }}
                                    </div>
                                    <div class="chart-text" style="color: #bcbcbc">usado de</div>
                                    <div class="chart-text revenues-color">
                                        {{ summaryData[0].value | currency }}
                                    </div>
                                </div>
                            </div>

                            <div v-if="!waiting && budgets._id" class="progress-bar-border green-background">
                                <div
                                    class="progress-bar"
                                    style="background-color: #ef8a97"
                                    :style="{ width: percentage(totalIncome, summaryData[0].value) + '%' }"
                                ></div>
                            </div>

                            <div v-if="budgets._id" class="flex" style="margin: 4px 0; justify-content: flex-end">
                                <v-skeleton-loader v-if="waiting" :width="120" style="margin-top: 3px" type="text" />
                                <div class="chart-text percentage" v-else style="color: #bcbcbc">
                                    {{ percentage(totalIncome, summaryData[0].value).toFixed(0) }}%
                                </div>
                            </div>
                        </template>

                        <template v-if="budgets._id" v-slot:chart>
                            <column-chart :series="seriesChart" :colors="chartColors" />
                        </template>
                    </summary-model>

                    <div class="button-container">
                        <basic-button
                            v-if="customBudgets.total > 0 && !budgets._id"
                            label="ADICIONAR CATEGORIA"
                            :loading="disabled"
                            type="submit"
                            @click.native.prevent="() => (modalVisible = true)"
                            :width="344"
                        />

                        <basic-button
                            v-if="customBudgets.total > 0 && !budgets._id"
                            label="SALVAR PLANEJAMENTO"
                            :loading="disabled"
                            type="submit"
                            @click.native.prevent="saveBudget"
                            :width="344"
                        />

                        <basic-button
                            v-if="isEdit"
                            label="Salvar novo planejamento"
                            :loading="disabled"
                            type="submit"
                            @click.native.prevent="validatePayload"
                            :width="270"
                        />

                        <basic-button
                            v-else-if="!customBudgets.total && budgets._id"
                            label="Redefinir planejamento"
                            :loading="disabled"
                            type="submit"
                            @click.native.prevent="deleteBudget"
                            :width="270"
                        />
                    </div>
                </div>
            </div>
        </div>

        <category-budget-modal v-if="modalVisible" @closeModalEvent="closeModal" :addedCategories="customBudgets" />
    </div>
</template>

<script>
import ActionBar from '../components/ActionBar'
import SummaryModel from '../components/cards/summary-model/SummaryModel'
import CategoryChart from '../components/charts/CategoryChart'
import SpendingCard from '../components/cards/SpendingCard.vue'
import _date from '../common/formatDate'
import util from '../common/util'
import request from '../common/request'
import { summaryBudget } from '../common/summary_configs'
import { mapState, mapGetters } from 'vuex'
import BasicButton from '../components/buttons/BasicButton.vue'
import ColumnChart from '../components/charts/ColumnChart.vue'
import MonthSelector from '../components/headers/MonthSelector.vue'
import CategoryBudgetList from '../components/lists/CategoryBudgetList.vue'
import CategoryBudgetModal from '../components/modals/CategoryBudgetModal.vue'

export default {
    name: 'Budget',

    components: {
        ActionBar,
        SummaryModel,
        SpendingCard,
        CategoryChart,
        BasicButton,
        ColumnChart,
        MonthSelector,
        CategoryBudgetModal,
        CategoryBudgetList,
    },

    data() {
        return {
            isEdit: false,
            budget: {},
            isNotActualBudget: false,
            totalPercentage: 0,
            err: false,

            chartColors: function ({ value, seriesIndex, w }) {
                const add = !seriesIndex ? 0 : 3
                const index = w.config.series[seriesIndex].data.findIndex((s) => s === value)

                const count = index + add

                switch (count) {
                    case 0:
                    case 1:
                    case 2:
                        return '#BAC0CC'
                    case 4:
                        return '#F5A623'
                    case 3:
                        return '#D0021B'
                    case 5:
                        return '#63B9F2'
                    default:
                        return '#BAC0CC'
                }
            },
            modalVisible: false,
            customBudgets: {
                essentialCategories: [],
                noessentialCategories: [],
                investiments: [],
                allIds: [],
                total: 0,
                budgetTotalValue: 0,
                essentialSpending: 0,
                noessentialSpending: 0,
            },
        }
    },

    computed: {
        ...mapState(['_date', '_date_selected', 'budgets', 'disabled', 'waiting']),

        ...mapGetters([
            'balanceMonthExpectedPerType',
            'getDefautCategoryListByType',
            'budgetBalance',
            'balancesPerCategory',
        ]),

        balance() {
            return this.balanceMonthExpectedPerType('revenues', null)
        },

        seriesChart() {
            return [
                {
                    name: 'Orçamento',
                    data: [this.essentialIncome, this.noessentialIncome, this.investimentIncome],
                },
                {
                    name: 'Utilizado/Aplicado',
                    data: [
                        Math.abs(this.budgetBalance('essential')),
                        Math.abs(this.budgetBalance('noessential')),
                        this.investimentIncome ? this.budgetBalance('investiments') : 0,
                    ],
                },
            ]
        },

        chartOptions() {
            return {
                labels: ['Essenciais', 'Dispensáveis', 'Investimentos', 'Não-reservado'],
                colors: ['#37C2CE', '#EF8A97', '#63B9F2', '#9FB6CD'],
            }
        },

        essentialIncome() {
            return this.balance * (this.budgets.essentialSpending / 100 || 0)
        },

        noessentialIncome() {
            return this.balance * (this.budgets.noessentialSpending / 100 || 0)
        },

        investimentIncome() {
            return this.balance * (this.budgets.investimentSpending / 100 || 0)
        },

        // total utilizado
        spended() {
            let totalSpended = 0
            if (this.budgets.essentialSpending > 0) {
                this.budgets.essentialCategories.forEach((cat) => {
                    totalSpended += Math.abs(this.balancesPerCategory(cat.category._id))
                })
            }
            if (this.budgets.noessentialSpending > 0) {
                this.budgets.noessentialCategories.forEach((cat) => {
                    totalSpended += Math.abs(this.balancesPerCategory(cat.category._id))
                })
            }
            if (this.budgets.investimentSpending > 0) {
                totalSpended += this.balancesPerCategory()
            }
            return totalSpended
        },

        // Total reservado
        totalIncome() {
            return this.essentialIncome + this.noessentialIncome + this.investimentIncome
        },

        remainIncome() {
            return this.balance - this.essentialIncome - this.noessentialIncome - this.investimentIncome
        },

        // temporario ja que não há investimentos de fato
        investiments() {
            return [
                {
                    category: {
                        name: 'Poupanças',
                        group: 'investiments',
                    },
                    budgetValue: this.budgets.investimentSpending,
                },
            ]
        },

        totalSpending() {
            return this.budgets.essentialSpending + this.budgets.noessentialSpending + this.budgets.investimentSpending
        },

        date: function () {
            return (
                _date.getMonthName(this._date_selected.inicio.slice(5, 7)) +
                ' ' +
                _date.getYear(this._date_selected.inicio)
            )
        },

        month: function () {
            return _date.getMonthName(this._date_selected.inicio.slice(5, 7))
        },

        summaryData: {
            get() {
                return summaryBudget()
            },
            set(summaryBudget) {
                return summaryBudget
            },
        },
    },

    mounted() {
        this.$store.commit('setSelectedDate', this._date)
        this.changeMonth()
    },

    methods: {
        async changeMonth() {
            this.err = false
            this.$store.commit('DISABLED')
            this.$store.commit('WAITING')

            try {
                await request.fetch([{ collection: 'invoices', queryParams: this._date_selected }])
                await request.fetch([
                    { collection: 'budgets', queryParams: this._date_selected },
                    { collection: 'transactions', queryParams: this._date_selected },
                ])

                await this.setBudget()

                this.summaryData = summaryBudget()
            } catch (e) {
                console.error(e)
            } finally {
                this.isEdit = false
                this.$store.commit('DISABLED')
                this.$store.commit('WAITING')
            }
        },

        async setBudget() {
            const actualDate = this.$moment(this._date.inicio)
            const selectedDate = this.$moment(this._date_selected.inicio)
            const diff = selectedDate.diff(actualDate, 'M')

            if (!this.budgets._id && !diff) {
                await request.post({ collection: 'budgets', noUserId: true })
            }

            this.isNotActualBudget = false
        },

        async createBudget() {
            if (this.balance > 0) {
                const permitedCategories = [
                    'compras',
                    'cuidadospessoais',
                    'lazer',
                    'diversos',
                    'pets',
                    'telefoneinternet',
                ]

                let essentialCategories = this.getDefautCategoryListByType('essential', 'expenses')
                let noessentialCategories = this.getDefautCategoryListByType(
                    'noessential',
                    'expenses',
                    permitedCategories
                )

                this.err = false
                this.budget.essentialCategories = this.prepareData(essentialCategories)
                this.budget.noessentialCategories = this.prepareData(noessentialCategories)
                this.budget.date = new Date(this._date_selected.inicio)
                this.$store.commit('WAITING', true)
                await request.post({ collection: 'budgets', data: this.budget })
                this.$store.commit('WAITING')
            } else {
                this.err = true
            }
        },

        async deleteBudget() {
            this.$store.commit('DISABLED')

            request
                .remove({ collection: 'budgets', _id: this.budgets._id })
                .catch(console.error)
                .finally(() => this.$store.commit('DISABLED'))
        },

        percentage(n, d) {
            return util.percentage(n, d)
        },

        prepareData(categories) {
            return categories.map((category) => {
                return (category = { category: category._id, budgetValue: category.defaultBudgetValue })
            })
        },

        validatePayload() {
            if (this.totalPercentage <= 100) {
                this.$store.commit('DISABLED')

                request
                    .patch({ collection: 'budgets', data: this.budget, _id: this.budgets._id })
                    .then(() => this.closeEdition())
                    .finally(() => this.$store.commit('DISABLED'))
            }
        },

        editBudget() {
            this.isEdit = true
            this.totalPercentage = this.totalSpending
            this.budget = {}

            this.$emit('updateChat', { editBudget: true })
        },

        updateBudgetPayload(payload) {
            if (payload.isEssential) {
                this.updateCategoriesArray('essentialCategories', payload.category)
                this.budget.essentialSpending = payload.essentialSpending
            } else if (payload.isEssential === false) {
                this.updateCategoriesArray('noessentialCategories', payload.category)
                this.budget.noessentialSpending = payload.noessentialSpending
            } else {
                let diff =
                    payload.investimentSpending - (this.budget.investimentSpending || this.budgets.investimentSpending)

                this.totalPercentage += diff
                this.budget.investimentSpending = payload.investimentSpending
            }
        },

        updateCategoriesArray(field, category) {
            let index, diff, _category

            if (this.budget[field]) {
                index = this.budget[field].findIndex((c) => c._id === category._id)

                if (index > -1) {
                    diff = category.budgetValue - this.budget[field][index].budgetValue

                    this.totalPercentage += diff
                    this.budget[field].splice(index, 1, category)
                } else {
                    _category = this.budgets[field].find((c) => c._id === category._id)
                    diff = category.budgetValue - _category.budgetValue

                    this.totalPercentage += diff
                    this.budget[field].push(category)
                }
            } else {
                _category = this.budgets[field].find((c) => c._id === category._id)
                diff = category.budgetValue - _category.budgetValue

                this.totalPercentage += diff
                this.budget[field] = []
                this.budget[field].push(category)
            }
        },

        closeEdition() {
            this.isEdit = false
            this.totalPercentage = this.totalSpending
            this.budget = {}

            this.$emit('updateChat', { editBudget: false })
        },

        closeModal(payload) {
            if (payload) {
                let budgetValue = (payload.budgetValue / 100) * this.balance
                const { type, essential } = payload.category

                const isEssential = type ? type === 'essential' : essential
                const noessential = type ? type === 'noessential' : !essential

                if (budgetValue + this.customBudgets.budgetTotalValue <= this.balance) {
                    if (isEssential) {
                        this.customBudgets.essentialCategories.push(payload)
                        this.customBudgets.essentialSpending += payload.budgetValue
                    } else if (noessential) {
                        if (payload.category.name === 'Investimentos') {
                            this.customBudgets.investiments.push(payload)
                            this.customBudgets.investimentSpending = payload.budgetValue
                        } else {
                            this.customBudgets.noessentialCategories.push(payload)
                            this.customBudgets.noessentialSpending += payload.budgetValue
                        }
                    }

                    this.customBudgets.allIds.push(payload.category._id)
                    this.customBudgets.total += 1
                    this.customBudgets.budgetTotalValue += budgetValue
                    this.modalVisible = false
                }
            } else {
                this.modalVisible = false
            }
        },

        async saveBudget() {
            let object = {
                essentialCategories: this.customBudgets.essentialCategories,
                noessentialCategories: this.customBudgets.noessentialCategories,
                investimentSpending:
                    this.customBudgets.investiments.length === 0 ? 0 : this.customBudgets.investimentSpending,
                essentialSpending: this.customBudgets.essentialSpending,
                noessentialSpending: this.customBudgets.noessentialSpending,
                date: new Date(this._date_selected.inicio),
                isCustom: true,
            }
            this.$store.commit('WAITING', true)
            await request.post({ collection: 'budgets', data: object })
            this.$store.commit('WAITING')
        },

        editCustomCategory(payload) {
            let diffValue = 0,
                diff = 0

            if (!payload) return

            if (payload.category.type === 'essential') {
                let index = this.customBudgets.essentialCategories.findIndex(
                    (ca) => ca.category._id === payload.category._id
                )
                diff = payload.budgetValue - this.customBudgets.essentialCategories[index].budgetValue

                this.customBudgets.essentialCategories.splice(index, 1, payload)
                this.customBudgets.essentialSpending += diff
            } else {
                if (payload.category.name === 'Investimentos') {
                    diff = payload.budgetValue - this.customBudgets.investiments[0].budgetValue

                    this.customBudgets.investiments.splice(0, 1, payload)
                    this.customBudgets.investimentSpending = payload.budgetValue
                } else {
                    let index = this.customBudgets.noessentialCategories.findIndex(
                        (ca) => ca.category._id === payload.category._id
                    )
                    diff = payload.budgetValue - this.customBudgets.noessentialCategories[index].budgetValue

                    this.customBudgets.noessentialCategories.splice(index, 1, payload)
                    this.customBudgets.noessentialSpending += diff
                }
            }

            diffValue = (diff / 100) * this.balance
            this.customBudgets.budgetTotalValue = this.customBudgets.budgetTotalValue + diffValue
        },
    },

    watch: {
        disabled: {
            immediate: true,
            handler(newVal, oldVal) {
                if (oldVal) this.summaryData = summaryBudget()
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.grow {
    flex-grow: 1;
}

.column {
    flex-direction: column;
}

.view-content {
    .menu-bar {
        height: 55px;
        padding: 0px 20px 0px;
        margin-bottom: 20px;
        color: $text-black;
        border-top: solid 1px $title-grey;
        border-bottom: solid 1px $title-grey;
        border-radius: 2px;
        text-align: center;
    }

    .box {
        align-items: center;
        text-align: center;
        vertical-align: middle;
    }

    .label-header {
        width: 170px;
        font-weight: 500;
    }

    .icon {
        height: 18.57px;
        max-width: 18.57px;
        color: $title-grey;
    }
}

.no-budget-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;

    & > div {
        width: 529px;
        color: #737373;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;
    }

    .orcamento-bg {
        height: 260px;
        margin: 60px 0 60px;
        background-image: url('../assets/img/planejamento.svg');
        background-position: center;
        background-size: contain;
    }

    .button-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        color: #d0021b;
    }
}

.submit-btn {
    height: 56px !important;
    width: 270px;
    color: white !important;
    font-size: 16px !important;
    font-weight: 500;
    border-radius: 4px;
    background: $brand-blue !important;
    position: relative;
}

.chart {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.container {
    .column-chart {
        margin: 16px 0 -16px 0;
    }

    .spendingCard {
        margin-bottom: 18px;
    }
}

.flex {
    display: flex;
}

.grow-1 {
    flex-grow: 1 !important;
}

.flex-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}
.green-background {
    background-color: #37c2ce !important;
}
.chart-text {
    font-weight: 600;
    font-size: 10px;
    margin-top: auto;
    margin-bottom: auto;
    &.expenses-color {
        color: #ef8a97;
        font-size: 13px !important;
        margin-right: 3px;
    }
    &.revenues-color {
        color: #37c2ce;
        font-size: 13px !important;
        margin-left: 3px;
    }
    &.percentage {
        font-size: 13px !important;
    }
}
.summary {
    font-weight: 500;
    font-size: 16px;
    color: #1e3351;
    margin-bottom: 16px;
}
.button-container {
    margin-top: 20px;
}
</style>
