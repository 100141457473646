export default {
    // collections
    user: {},
    payments: [],
    transactions: [],
    invoices: [],
    nextMonthInvoices: [],
    invoicesWithoutFiltering: [], // listagem de invoice sem levar em consideração o período do cartão
    categories: [],
    budgets: {},
    balances: {
        currentBalance: 0,
    },
    balancesChart: [],
    objectives: [],
    subscriptions: [],
    bankaccesses: [],
    families: {},
    linkedtransactions: [],

    // config
    waiting: false,
    waitingTime: 1000,
    error: false,
    fetched: false,
    disabled: false,
    action: false, // action bar
    chat: false,
    _date: {}, // data atual
    _date_selected: {}, // data da ultima query
    _next_month_date: {}, // date do mês seguinte a última query

    // misc
    hasPendingInvoices: undefined,
}
