<template>
    <div class="search-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="19.176" height="19.529" viewBox="0 0 19.176 19.529">
            <path
                class="a"
                d="M459.05,890.25a6.976,6.976,0,0,0-5.42,11.248l-6.534,6.616a1,1,0,0,0,1.416,1.4l6.536-6.63a6.786,6.786,0,0,0,4,1.3,6.965,6.965,0,0,0,0-13.93Zm0,12.163a5.2,5.2,0,1,1,5.112-5.2,5.2,5.2,0,0,1-5.112,5.2ZM462.5,897.3a.776.776,0,0,1-.765.667.828.828,0,0,1-.1,0,.776.776,0,0,1-.667-.869c.156-1.158-1.136-1.762-1.191-1.778a.773.773,0,0,1,.631-1.411,3.4,3.4,0,0,1,2.1,3.4Zm-1.625,1.691a.876.876,0,1,1-.625-.262A.889.889,0,0,1,460.874,898.993Z"
                transform="translate(-446.837 -890.25)"
            />
        </svg>

        <small>Procurar</small>
    </div>
</template>

<script>
export default {
    name: 'SearchIcon',
}
</script>

<style lang="scss" scoped>
.search-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
        background-color: #ecf0fd;
        border-radius: 50%;

        .a {
            fill: $darker-blue;
        }
    }

    .a {
        fill: #b7b8b9;
    }

    small {
        @include easy-tooltip();
        line-height: 40px;
        right: -5px;
        width: max-content;
    }
}
</style>
