<template>
    <modal-frame noHeader :closeBtn="false" :width="400">
        <template #content>
            <div class="category-budget-modal not-default-fieldset">
                <div class="flex-bubble row">
                    <img :src="$variables.chatIcon" class="chat-otto-img" />

                    <svg width="15px" height="10px" class="svg-path-otto">
                        <polygon points="0,0 15,0 15,10" fill="white" class="otto-path" />
                    </svg>

                    <p class="otto-talk" v-html="speeches[stage]"></p>
                </div>

                <stacked-select
                    class="form-el"
                    label="Categoria"
                    :required="true"
                    v-if="stage === 0"
                    :errorValidation="error[1]"
                    :notTransaction="true"
                    :model="categories"
                    @change="updateCategory"
                    style="margin-bottom: 16px"
                />

                <div v-if="stage === 1">
                    <div
                        class="row center"
                        style="margin: 0; margin-bottom: 8px; align-items: center; margin-top: 22px"
                    >
                        <div class="icon-contend">
                            <img
                                :src="
                                    require('@/assets/img/categories/' +
                                        'expenses' +
                                        '-' +
                                        categoryDefaultName(category) +
                                        '.png')
                                "
                                class="icon"
                                :class="[categoryDefaultName(category), 'expenses']"
                            />
                        </div>

                        <h1 class="center category-title limited">{{ category.name }}</h1>
                    </div>

                    <div class="row" style="margin-bottom: 18px; margin-top: 12px">
                        <unity-radio :type="type" @selectUnity="selectType" />

                        <stacked-input
                            label="Limite"
                            style="max-width: 204px; margin-top: 0px"
                            :errorValidation="error[5] || error[6]"
                            :errorMsg="error[5] ? 'VALOR INVÁLIDO' : 'VALOR ULTRAPASSA AS RECEITAS'"
                            :errorFontSize="10"
                            :required="true"
                            :money="type === 'currency' ? money : percentage"
                            v-model="value"
                        />
                    </div>
                </div>
            </div>
        </template>

        <template #btn-container>
            <div class="button-wrapper">
                <basic-button
                    :label="`${stage === 0 ? 'PRÓXIMO' : 'DEFINIR LIMITE'}`"
                    style="margin-bottom: 8px"
                    @click.native="selectCategory"
                    width="100%"
                />
                <basic-button
                    label="CANCELAR"
                    :reverse="true"
                    @click.native="() => $emit('closeModalEvent')"
                    width="100%"
                />
            </div>
        </template>
    </modal-frame>
</template>

<script>
import { mapGetters } from 'vuex'

import BasicButton from '../buttons/BasicButton.vue'
import StackedSelect from '../inputs/StackedSelect.vue'
import ModalTransition from '../transitions/ModalTransition.vue'
import ConfirmationCreateModal from './confirmation-modals/ConfirmationCreateModal.vue'
import UnityRadio from '../inputs/UnityRadio.vue'
import StackedInput from '../inputs/StackedInput'
import ModalFrame from '../../containers/ModalFrame.vue'

import util from '../../common/util'

export default {
    components: {
        ModalTransition,
        ConfirmationCreateModal,
        BasicButton,
        StackedSelect,
        UnityRadio,
        StackedInput,
        ModalFrame,
    },

    name: 'CategoryBudgetModal',

    props: {
        addedCategories: Object,
    },

    data() {
        return {
            category: {},
            error: [],
            categories: [],
            speeches: [
                'Para começar, selecione uma categoria de despesas na lista abaixo.',
                'Agora, defina um limite de gastos para ela. Pode ser um valor fixo ou uma porcentagem do orçamento.',
            ],
            stage: 0,
            value: 0,
            type: 'currency',
            money: {
                decimal: ',',
                thousands: '.',
                prefix: util.returnCurrencyPrefix().prefix + ' ',
                precision: 2,
                masked: false,
            },
            percentage: {
                decimal: ',',
                thousands: '.',
                suffix: '%',
                precision: 0,
                masked: false,
            },
        }
    },
    methods: {
        updateCategory(value) {
            this.category = value
        },

        filterCategories() {
            this.categories = this.filteredCategories('expenses')
            if (this.addedCategories.total > 0) {
                let addedCategoriesIds = this.addedCategories.allIds
                let filteredByIds = this.categories.filter(function (item) {
                    return addedCategoriesIds.indexOf(item._id) === -1
                })
                this.categories = filteredByIds
            }
        },

        selectCategory() {
            let error = Array(7).fill(false)

            if (this.stage === 0) {
                if (this.category._id) {
                    this.stage = 1
                } else {
                    error[1] = true
                    this.error = error
                }
            } else {
                let budgetValue = 0
                let budgetValueInCurrency = 0

                if (this.type === 'currency') {
                    budgetValueInCurrency = Number(util.sanitizeMoney(this.value))
                    budgetValue = (Number(util.sanitizeMoney(this.value)) / this.balance) * 100
                } else {
                    const percentage = util.sanitizePercentage(this.value)

                    budgetValueInCurrency = (percentage * this.balance) / 100
                    budgetValue = percentage
                }

                if (
                    this.value ===
                        util.returnCurrencyPrefix().prefix + '0' + util.returnCurrencyPrefix().decimal + '00' ||
                    this.value === '0%'
                ) {
                    error[5] = true
                    this.error = error
                } else if (budgetValueInCurrency + this.addedCategories.budgetTotalValue <= this.balance) {
                    let categoryToAdd = {}
                    categoryToAdd.category = this.category
                    categoryToAdd.budgetValue = budgetValue
                    this.categories = this.categories.filter((ca) => ca._id !== this.category._id)
                    this.$emit('closeModalEvent', categoryToAdd)
                } else {
                    error[6] = true
                    this.error = error
                }
            }
        },

        sanitize(string) {
            return util.sanitize(string)
        },

        parsePercentage: (percentage) => {
            return Number(percentage.replace('%', '')) / 100
        },

        selectType(payload) {
            if (payload === this.type) return

            this.type = payload

            switch (payload) {
                case 'currency':
                    this.value = util.formatNumber(this.balance * this.parsePercentage(this.value))
                    break
                default:
                    this.value = Math.round((Number(util.sanitizeMoney(this.value)) / this.balance) * 100)
            }
        },

        categoryDefaultName(category) {
            return this.sanitize(this.getDefaultCategoryById(category).name)
        },
    },

    computed: {
        ...mapGetters(['filteredCategories', 'balanceMonthExpectedPerType', 'getDefaultCategoryById']),

        balance() {
            return this.balanceMonthExpectedPerType('revenues', null)
        },
    },

    mounted() {
        this.filterCategories()
    },
}
</script>

<style lang="scss" scoped>
.category-budget-modal {
    margin-top: -42px;
    border-radius: 8px;
    background: white;

    &:disabled {
        cursor: wait;
    }
}

.loader {
    font-size: 14px;
    margin: 0;
}

.chat-otto-img,
.chat-user-img {
    position: relative;
    top: 200;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50%;
    padding: 4px;
    box-shadow: 1px 1px 2px #00000030;
    border: 1px solid $btn-blue;
}

.otto-talk {
    left: -3px;
    background-color: #ffffff;
    text-align: left;
    margin-bottom: 8px;
    color: #5b6772;
}

.user-talk,
.otto-talk {
    position: relative;
    max-width: 287px;
    line-height: 18px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}
.flex-bubble {
    display: flex;
    margin-bottom: 8px;
    &.column {
        flex-direction: column;
    }
}

.row {
    flex-direction: row;
    margin: 0;
}

.button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: #d0021b;
}

.icon-contend {
    height: 24px !important;
    .icon {
        height: 24px;
        width: 24px;
        border-radius: 50%;
    }
}

.category-title {
    font-weight: 500;
    font-size: 16px;
    color: #1e3351;
    margin-left: 8px;
}
</style>
