<template v-slot:progress-bar>
    <div class="chart-box" :style="style">
        <div class="row" style="margin: 0; justify-content: space-between; height: 14px">
            <h1>{{ boxTitle }}</h1>
            <div class="flex" style="margin: 0; justify-content: flex-end">
                <div v-if="waiting" style="margin: 3px 0; justify-content: flex-end; width: 100%">
                    <v-skeleton-loader v-if="waiting" :width="10" style="margin-left: 10px" type="text" />
                </div>
                <div v-else class="chart-text expenses-color">
                    {{ percentage(expenses, revenues) + '%' }}
                </div>
            </div>
        </div>
        <template>
            <div class="bar-chart">
                <div class="progress-bar-container" style="padding: 0">
                    <div v-if="waiting" style="margin: 3px 0; justify-content: flex-end; width: 100%">
                        <v-skeleton-loader v-if="waiting" :width="120" style="margin-top: 3px" type="text" />
                    </div>

                    <div v-else class="progress-bar-border green-background">
                        <div
                            class="progress-bar"
                            style="background-color: #ef8a97"
                            :style="{ width: percentage(expenses, revenues) + '%' }"
                        ></div>
                    </div>
                </div>
                <h1 style="margin-top: 14px; margin-bottom: 8px">MAIORES GASTOS DO MÊS</h1>
                <div v-if="waiting" style="margin: 3px 0; justify-content: flex-end; width: 100%">
                    <v-skeleton-loader v-if="waiting" :width="120" style="margin-top: 3px" type="text" />
                </div>
                <category-waste-list v-else />
                <h1 style="margin-top: 14px; margin-bottom: 10px">CATEGORIA MAIS USADA</h1>
                <div v-if="waiting" style="margin: 3px 0; justify-content: flex-end; width: 100%">
                    <v-skeleton-loader v-if="waiting" :width="120" style="margin-top: 3px" type="text" />
                </div>
                <div v-else class="description row space-between" style="margin: 0">
                    <div class="row" style="vertical-align: middle; margin: 0">
                        <img
                            :src="
                                require('@/assets/img/categories/' +
                                    sanitize('expenses') +
                                    '-' +
                                    sanitize(usedTransaction[0].name) +
                                    '.png')
                            "
                            class="avatar item"
                            :class="[sanitize(usedTransaction[0].name), 'expenses']"
                        />

                        <div>
                            <span class="decription font-regular">{{ usedTransaction[0].name }}</span>
                        </div>
                    </div>

                    <div class="chart-text font-semibd">
                        {{ percentage(usedTransaction[0].count, usedTransaction[1]) + '%' }}
                    </div>
                </div>
                <div class="progress-bar-container" style="padding: 0">
                    <div v-if="waiting" style="margin: 3px 0; justify-content: flex-end; width: 100%">
                        <v-skeleton-loader :width="120" style="margin-top: 3px" type="text" />
                    </div>

                    <div v-else class="progress-bar-border gray-background">
                        <div
                            class="progress-bar"
                            style="background-color: #ef8a97"
                            :style="{ width: percentage(usedTransaction[0].count, usedTransaction[1]) + '%' }"
                        ></div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import util from '../../common/util'
import CategoryWasteList from '../lists/CategoryWasteList'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'CategorySummaryCard',
    components: {
        CategoryWasteList,
    },
    props: {
        width: {
            type: Number,
        },
        height: {
            type: Number,
        },
        boxTitle: {
            type: String,
            required: true,
        },
        chart: String,
        objectiveName: String,
        objAchieved: Number,
        objValue: Number,
        data: [Object, Array],
        chartSeries: Array,
        chartOptions: Object,
        chartProps: {
            type: Object,
            default: () => {},
        },
        type: String,
        marginBottom: Boolean,
        marginRight: Boolean,
    },

    data() {
        return {
            transform: {
                translateX: 0,
                translateY: 100,
            },
            loading: true,
        }
    },
    computed: {
        ...mapState(['waiting', 'balances', 'transactions']),

        ...mapGetters(['balanceMonthExpectedPerType', 'balanceMonthPerType', 'filteredTransactions']),

        style() {
            let style = ''
            if (this.marginBottom) style += 'margin-bottom: 24px;'
            style += 'min-width: ' + this.width + 'px;'
            style += 'height: ' + this.height + 'px;'
            style += 'padding: 16px;'
            return style
        },
        revenues() {
            return this.balanceMonthPerType('revenues', true)
        },
        expenses() {
            return this.balanceMonthPerType('expenses', true) * -1
        },
        usedTransaction() {
            let ft = this.filteredTransactions('expenses').filter((transaction) => {
                return transaction.category.name !== 'Fatura Cartão' && transaction.paid
            })
            let countCategories = []
            let total = 0
            ft.forEach((el) => {
                total += 1
                let index = 0
                index = countCategories.findIndex((cat) => {
                    return cat.name === el.category.name
                })
                if (index !== -1) {
                    countCategories[index].count += 1
                } else {
                    countCategories.push({ name: el.category.name, count: 1 })
                }
            })
            if (countCategories.length > 0)
                countCategories = countCategories.reduce((max, cat) => (max.count > cat.count ? max : cat))
            return [countCategories, total]
        },
    },
    methods: {
        percentage(n, d) {
            return util.percentage(n, d).toFixed(0)
        },
        sanitize(string) {
            return util.sanitize(string)
        },
    },
}
</script>

<style lang="scss" scoped>
.full {
    width: auto;
}
.half {
    width: calc(50% - 12px);
}
.float-l {
    float: left;
}
.margin-left {
    margin-left: 16px;
}

.chart-box {
    position: relative;
    @include rounded-box();
    @include box-shadow();
}
.green-background {
    background-color: #37c2ce !important;
}

.gray-background {
    background-color: #efefef !important;
}

.flex {
    display: flex;
}
.space-between {
    justify-content: space-between;
}
.summary {
    font-weight: 500;
    font-size: 16px;
    color: #1e3351;
    margin-bottom: 16px;
}
.chart-text {
    font-weight: 600;
    font-size: 10px;
    &.expenses-color {
        color: #ef8a97;
        font-size: 14px !important;
        margin-right: 3px;
    }
    &.revenues-color {
        color: #37c2ce;
        font-size: 12px !important;
        margin-left: 3px;
    }
    &.percentage {
        font-size: 12px !important;
    }
}
.bar-chart {
    position: relative;
    align-self: start;
    height: auto;
    width: calc(100%);
    @include easy-progress-bar();
}
h1 {
    padding: 0;
    margin: 0;
    font-size: 10px;
    color: #1e3351;
    font-family: 'Metropolis' !important;
    font-weight: 500;
}

.space-between {
    justify-content: space-between;
    vertical-align: middle;
}

.avatar {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 8px;
}

img:first-child {
    margin-bottom: 4px;
}

.font-semibd {
    color: #1e3351;
    font-size: 12px;
}

.font-regular {
    font-weight: 400;
}

.description {
    font-size: 12px;
    color: #1e3351;
}
</style>
