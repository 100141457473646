<template>
    <div class="flex bars-chart" style="height: 100%">
        <v-skeleton-loader
            v-if="waiting"
            style="padding: 20px 0; margin-bottom: 24px"
            :width="550"
            :height="150"
            type="image"
        />

        <v-chart ref="barCharts" :option="option" v-else autoresize />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { UniversalTransition } from 'echarts/features'
import { BarChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent } from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'

use([CanvasRenderer, BarChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent, UniversalTransition])

export default {
    name: 'SimpleBarChart',
    components: {
        VChart,
    },
    provide: {
        [THEME_KEY]: 'light',
    },
    props: {
        series: Array,
    },
    data() {
        return {
            loading: true,
        }
    },

    computed: {
        ...mapState(['waiting']),
        option() {
            if (!this.waiting) {
                let sd = []
                let yd = []
                this.series.forEach((se) => {
                    sd.push(((se.initialValue + se.paidValue) / se.totalValue).toFixed(2) * 100)
                    yd.push(se.name.replace(' ', '\n'))
                })

                return {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                        },
                        axisLabel: {
                            show: true,
                            formatter: function (value) {
                                return value + '%%\n'
                            },
                        },
                        valueFormatter: function (value) {
                            return value + '%\n'
                        },
                    },
                    grid: {
                        top: '0%',
                        left: '2%',
                        right: '6%',
                        bottom: '0%',
                        containLabel: true,
                        show: false,
                    },
                    xAxis: {
                        type: 'value',
                        boundaryGap: [0, 0.01],
                        axisLabel: {
                            show: true,
                            formatter: function (value) {
                                return value + '%\n'
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#000000',
                            },
                        },
                    },
                    yAxis: {
                        type: 'category',
                        data: yd,
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#ffffff',
                            },
                        },
                    },
                    textStyle: {
                        fontFamily: 'Metropolis',
                        color: '#848484',
                        fontSize: 10,
                    },
                    series: [
                        {
                            type: 'bar',
                            data: sd,
                            barWidth: '30px',
                        },
                    ],
                    responsive: true,
                    maintainAspectRatio: false,
                    color: '#0EE227',
                }
            }
        },
    },
}
</script>

<style scoped>
.chart {
    height: 150px;
    width: '100%';
}
</style>
