import Vue from 'vue'
import Vuex from 'vuex'
import state from '@/store/states/index'
import mutations from '@/store/mutations'
import actions from '@/store/actions'
import getters from '@/store/getters/index'

Vue.use(Vuex)

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    state,
    mutations,
    actions,
    getters,
})
