<template>
    <div class="delete-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="15.121" height="16" viewBox="0 0 15.121 16">
            <path
                class="a"
                d="M1126.257,1338.167l-.536,10.166a.7.7,0,0,1-.7.667H1116.1a.7.7,0,0,1-.7-.667l-.538-10.166a.7.7,0,0,1,1.407-.074l.5,9.495h7.58l.5-9.495a.7.7,0,0,1,1.407.074Zm1.864-2.354a.7.7,0,0,1-.7.7H1113.7a.7.7,0,0,1,0-1.406h3.868v-1.583a.506.506,0,0,1,.566-.527h4.846a.506.506,0,0,1,.566.527v1.583h3.868A.7.7,0,0,1,1128.121,1335.813Zm-9.319-.7h3.516v-.879H1118.8Zm.3,11.428h.014a.643.643,0,0,0,.6-.666l-.176-7.6a.621.621,0,0,0-.629-.61.614.614,0,0,0-.6.626l.176,7.618A.647.647,0,0,0,1119.1,1346.538Zm2.9,0a.631.631,0,0,0,.615-.617l.176-7.592a.619.619,0,0,0-.6-.633.612.612,0,0,0-.629.6l-.176,7.6a.624.624,0,0,0,.6.645Z"
                transform="translate(-1113 -1333)"
            />
        </svg>

        <small>Excluir</small>
    </div>
</template>

<script>
export default {
    name: 'DeleteIcon',
}
</script>

<style lang="scss" scoped>
.delete-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
        background-color: #ecf0fd;
        border-radius: 50%;

        .a {
            fill: $hint-red;
        }
    }

    .a {
        fill: #b7b8b9;
    }

    small {
        @include easy-tooltip();
        line-height: 40px;
        color: $hint-red;
        right: 3px;
    }
}
</style>
