<template>
    <transition name="bounce">
        <div v-if="show" class="popupmsg" :style="positionStyling">
            <i class="fas fa-exclamation-circle"></i>

            <span>{{ msg }}</span>

            <div id="triangle"></div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'PopUpMsg',
    props: {
        msg: {
            type: String,
            default: 'mensagem',
        },
        position: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            show: false,
        }
    },

    mounted() {
        this.show = true

        setTimeout(() => {
            this.show = false
            this.$emit('onClose')
        }, 3000)
    },

    computed: {
        positionStyling() {
            const splitedPosition = this.position.split(' ')

            return {
                right: splitedPosition[0] || 'unset',
                top: splitedPosition[1] || '-50px',
                left: splitedPosition[2] || 'unset',
                bottom: splitedPosition[3] || 'unset',
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.popupmsg {
    padding: 5px 2px 5px 2px;
    width: 200px;
    position: absolute;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    color: white;
    background: rgba(184, 13, 13, 0.8);
    border-radius: 5px;
    box-shadow: 0px 0 4px 0 rgba(0, 0, 0, 0.2);
    z-index: 999;
    text-align: center;
}

.bounce-enter-active {
    animation: bounce-in 0.3s;
}
.bounce-leave-active {
    animation: bounce-in 0.3s reverse;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

#triangle {
    width: 0;
    height: 0;
    position: absolute;
    bottom: -10px;
    right: 50%;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgb(184, 13, 13);
}

i {
    font-size: 20px;
    padding: 0 5px 5px 0;
}
</style>
