import moment from 'moment'
import util from '../../common/util'

export default {
    /** Retorna true se assinatura estiver expirada ou inválida */
    isExpired: (state, getters) => {
        const { activeTest, active } = getters

        if (activeTest) return false
        return !active
    },

    active: (state, getters) => {
        const { status } = state.subscriptions[0] || {}
        return status && getters.notExpired
    },

    activeTest: (state, getters) => {
        const { plan } = state.subscriptions[0] || {}
        return plan === 'no_plan' && getters.notExpired
    },

    notExpired: (state) => {
        const { expireAt } = state.subscriptions[0] || {}
        return expireAt ? util.exactDate(expireAt).isSameOrAfter(util.exactDate(), 'days') : false
    },

    /** informa a quantidade de dias restantes da assinatura */
    daysLeft: (state) => {
        let subscription = state.subscriptions[0]
        let diff = moment(subscription.expireAt || new Date()).diff(new Date(), 'days')

        return diff
    },

    isOttoFamily: (state) => {
        const subscription = state.subscriptions[0]

        return subscription
            ? (subscription.status && subscription.plan === 'otto_family_monthly') ||
                  (subscription.status && subscription.plan === 'otto-family-member')
            : false
    },
}
