<template>
    <div id="app">
        <router-view />
        <div id="alert-box"></div>
    </div>
</template>

<script>
export default {
    name: 'App',
}
</script>

<style lang="scss">
body {
    margin: 0;
}

#app {
    color: rgb(109, 109, 109);
    font-size: 1rem;
}

p {
    font-weight: normal;
}

button {
    border-radius: 5px;
    padding: 12px;
    font-family: inherit;
    font-size: inherit;
    font-weight: bold;
    border: none;
}

a {
    font-weight: bolder;
    font-style: inherit;
    font-size: inherit;
}

a:hover {
    cursor: pointer;
}

@media (max-width: 1365px) {}
</style>
