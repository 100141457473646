<template>
    <div class="categories flex colunm modal">
        <header class="flex colunm default">
            <div class="flex grow-1 center title-container" :class="[categoryStyle, { morePadd: !showNav }]">
                <h1 class="grow-1">
                    {{ type === 'revenues' ? 'Receitas com' : 'Despesas com' }}
                    {{ category.name }}
                </h1>

                <button class="close grow-1" type="button" :class="{ morePadd: !showNav }" @click.stop="closeModal">
                    <ion-icon name="close"></ion-icon>
                </button>
            </div>

            <div v-if="showNav" class="flex grow-1 center flex-center label-container" :class="categoryStyle">
                <month-selector @change-month="changeMonth" color="white" />
            </div>
        </header>

        <div class="content flex colunm">
            <bars-chart :categories="chartCategories" :series="chartSeries" :loading="loadingChart" />

            <transactions-list
                class="categoryList"
                :transactions="filterTransactionsPerCategory(type, category.id, false, userId, paymentId, nextMonth)"
                :isTransactions="false"
            />
        </div>
    </div>
</template>

<script>
import TransactionsList from '../lists/TransactionsList.vue'
import formatDate from '../../common/formatDate'
import util from '../../common/util'
import { mapState, mapGetters } from 'vuex'
import BarsChart from '../charts/BarsChart.vue'
import apiService from '../../services/api.service'
import { environment } from '../../common/environment'
import dateFunctions from '@/common/formatDate'
import MonthSelector from '../headers/MonthSelector.vue'

export default {
    name: 'CategoriesDetails',
    components: {
        TransactionsList,
        BarsChart,
        MonthSelector,
    },
    props: {
        category: {
            type: Object,
            required: true,
        },
        type: String,
        userId: String,
        paymentId: String,
        nextMonth: Boolean, // Pegar as transações do cartao do prox. mês
    },
    data() {
        return {
            categoryStyle: null,
            showNav: true,
            datePayload: {
                start: '',
                end: '',
            },
            chartSeries: [
                {
                    name: 'Total',
                    data: [0, 0, 0, 0, 0, 0],
                },
            ],

            loadingChart: false,
        }
    },

    methods: {
        closeModal() {
            this.$emit('closeCategoryModalEvent')
        },

        changeMonth(value) {
            this.$emit('updateView', value)
            this.getCategoryBalances()
        },

        getCategoryBalances() {
            this.loadingChart = true
            const startDate = this.$moment(this._date_selected.inicio).add(-5, 'M').format('YYYY-MM-DD')

            apiService
                .get(
                    `${environment.BALANCES_URL}?type=chart&startDate=${startDate}&endDate=${this._date_selected.fim}&categoryId=${this.category.id}${this.balanceQuery}`
                )
                .then((response) => {
                    this.chartSeries[0].data = response.data.map((d) => Math.abs(d))
                })
                .catch((e) => console.error(e))
                .finally(() => {
                    this.loadingChart = false
                })
        },
    },

    computed: {
        ...mapState(['_date_selected']),

        ...mapGetters(['filterTransactionsPerCategory', 'getDefaultCategoryById', 'isOttoFamily']),

        date() {
            return (
                formatDate.getMonthName(this._date_selected.inicio.slice(5, 7)) +
                ' ' +
                this._date_selected.inicio.slice(0, 4)
            )
        },

        chartCategories() {
            return dateFunctions.getMonths(this._date_selected.inicio)
        },

        balanceQuery() {
            let query = ''

            if (this.isOttoFamily) {
                query = query.concat('&family=true')

                if (this.userId) {
                    query = query.concat(`&userId=${this.userId}`)
                }
            }

            if (this.paymentId) query = query.concat(`&paymentId=${this.paymentId}`)

            return query
        },
    },

    mounted() {
        this.categoryStyle = util.sanitize(this.getDefaultCategoryById(this.category.id[0]).name)

        if (this.$route.name === 'dashboard') this.showNav = false

        this.getCategoryBalances()
    },
}
</script>

<style lang="scss" scoped>
@include custom-scroll();

.modal {
    height: 90vh;
    width: 70vw;
    border-radius: 8px;
    background-color: #ffffff;
    font-weight: 500;
}

.default {
    background-color: $brand-blue;
}

header {
    color: #ffffff;
    font-size: 18px;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    h1 {
        text-transform: uppercase;
        text-align: center;
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 14px 16px;
        font-size: 24px;
        color: white;

        &.morePadd {
            top: 5px;
        }
    }

    .title-container {
        padding: 24px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        &.morePadd {
            padding: 20px;
        }
    }

    .icon {
        height: 18.57px;
        max-width: 18.57px;
        color: white;
    }

    .label-container {
        padding: 12px 0 24px;
    }

    .label-header {
        width: 170px;
        text-align: center;
        padding: 0 20px 0 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
    }
}

.content {
    padding-bottom: 32px;
    overflow-y: auto;
    overflow-x: hidden;

    .bars-chart {
        margin: auto;
    }

    .categoryList {
        margin: 24px;
        max-width: 100%;
    }
}

.flex {
    display: flex;
}

.grow-1 {
    flex-grow: 1;
}

.flex-end {
    justify-content: flex-end;
}

.flex-center {
    justify-content: center;
}

.center {
    align-content: center;
}

.colunm {
    flex-direction: column;
}
</style>
