import moment from 'moment'

export default {
    /** BALANÇO ESPERADO DO MÊS SELECIONADO POR GRUPO */
    balanceMonthExpectedPerType(state, getters) {
        /**
         * @param nextMonth - Pega a fatura do próximo mês
         */
        return (group, payment, userId, nextMonth) => {
            const getter = payment ? 'filteredTransactions' : 'transactionsWithoutMovBanc'
            let invoiceSum = 0

            if (nextMonth && group === 'expenses') {
                invoiceSum = getters.invoiceBalance(true)
            }

            return (
                invoiceSum +
                getters[getter](group).reduce((prevValue, transaction) => {
                    let valid = true

                    if (payment) valid = transaction.payment._id === payment
                    if (userId) valid = valid && transaction.user._id === userId
                    if (nextMonth) valid = valid && !transaction.invoiceReferenceId

                    return valid ? prevValue + transaction.value : prevValue
                }, 0)
            )
        }
    },

    // PREVISÃO DO SALDO DO MÊS ATUAL
    balanceTotalMonth(state, getters) {
        return (userId) => {
            return (
                getters.balanceMonthExpectedPerType('revenues', null, userId) +
                getters.balanceMonthExpectedPerType('expenses', null, userId)
            )
        }
    },

    // BALANÇO TOTAL DO MÊS PARA TRANSAÇÕES CONSOLIDADAS OU NÃO CONSOLIDADAS
    balanceMonthPerType(state, getters) {
        return (group, paid, payment, user) => {
            const getter = payment ? 'filteredTransactions' : 'transactionsWithoutMovBanc'

            let transactions = getters[getter](group).filter((transaction) => {
                let valid = true

                if (payment) valid = transaction.payment._id === payment
                if (user) valid = valid && transaction.user._id === user

                return valid && transaction.paid === paid
            })

            return transactions.reduce(function (prevValue, transaction) {
                return prevValue + transaction.value
            }, 0)
        }
    },

    // PREVISÃO DO SALDO DO MÊS ANTERIOR
    balancePreviousMonths: (state, getters) => {
        return state.balances.currentBalance - getters.balanceActualMonth(null)
    },

    // SALDO ATUALIZADO DO MÊS ATUAL
    balanceActualMonth(state, getters) {
        return (payment) => {
            if (payment) {
                let paymentId = payment._id || payment
                const creditCard = (getters.findById('payments', paymentId) || {}).type === 'credit'

                if (creditCard) {
                    return getters.getInvoiceValue(payment)
                }

                return (
                    getters.balanceMonthPerType('revenues', true, payment) +
                    getters.balanceMonthPerType('expenses', true, payment)
                )
            }
            return getters.balanceMonthPerType('revenues', true) + getters.balanceMonthPerType('expenses', true)
        }
    },

    // SOMA DE TODAS AS DESPESAS DE DETERMINADA CATEGORIA PELO SEU TIPO
    balancesPerCategoryByType(state, getters) {
        return (categoryType) => {
            let transactionsList = getters.filteredTransactions('expenses').filter((transaction) => {
                let defaultCategory = getters.getDefaultCategoryById(transaction.category._id)
                return defaultCategory.type === categoryType
            })

            transactionsList.push(...getters.getItemsByCategoryType(categoryType))

            return transactionsList.reduce((prevValue, transaction) => {
                return prevValue + transaction.value
            }, 0)
        }
    },

    // SOMA DE TODAS AS TRANSAÇÕES COM DETERMINADA CATEGORIA
    balancesPerCategory: (state, getters) => {
        return (category, group = 'expenses', paid) => {
            // remover quando investimentos estiver funcionando

            if (category) {
                let transactionsList = getters.filterTransactionsPerCategory(group, category, true)

                return transactionsList.reduce((prevValue, transaction) => {
                    return prevValue + transaction.value
                }, 0)
            } else {
                // significar que são o investimentos em poupança

                let investments = 0
                state.transactions.map((receita) => {
                    if (getters.findById('payments', receita.payment._id).accountType === 'Poupança')
                        investments += receita.value
                })

                return investments
            }
        }
    },

    // SALDO DE FORMAS DE PAGAMENTO POR TIPO
    balancePaymentsPerType(state, getters) {
        return (type, subType) => {
            return getters.filteredPayments(type).reduce(function (prevValue, payment) {
                if (subType) {
                    return payment.accountType === subType ? prevValue + payment.value : prevValue
                } else if (type === 'credit') {
                    if (!payment.externalId) {
                        const invoice = getters.findTransactionInvoice(payment._id)

                        return prevValue + (invoice ? invoice.value : 0)
                    }

                    return prevValue + getters.getInvoiceValue(payment._id)
                } else {
                    return prevValue + payment.value
                }
            }, 0)
        }
    },

    // BALANÇO DE TODAS AS FORMAS DE PAGAMENTO
    balancePayments: (state) => {
        const notCredit = state.payments.reduce(function (prevValue, payment) {
            if (payment.type === 'credit') {
                return prevValue
            }
            return prevValue + payment.value
        }, 0)

        const credit = state.transactions
            .filter((t) => t.invoiceReferenceId !== undefined && !t.externalId)
            .reduce((prevValue, transaction) => {
                return prevValue + transaction.value
            }, 0)

        return notCredit
    },

    /* BALANÇOS DOS INVESTIMENTOS (SÓ POUPANÇA AINDA) */
    balanceInvestments: (state, getters) => {
        const savingIds = state.payments.map((payment) => {
            if (payment.accountType === 'Poupança') return payment._id
        })

        const balanceSavings = state.transactions
            .filter((transaction) => savingIds.includes(transaction.payment._id) && !transaction.paid)
            .reduce((prevValue, transaction) => {
                return prevValue + transaction.value
            }, 0)

        return balanceSavings + getters.balancePaymentsPerType('debit', 'Poupança')
    },

    /* BALANÇO DO CALENDÁRIO DE PAGAMENTOS */
    balanceCalendar: (state, getters) => {
        return (filter = {}, paid) => {
            let linkedtransactionBalance = getters
                .filterBy('linkedtransactions', filter)
                .reduce((prevValue, transaction) => {
                    const isPaid = () => {
                        if (!transaction.lastPaymentDate) return false
                        return moment(transaction.lastPaymentDate).isSameOrAfter(moment(), 'M')
                    }

                    if (paid !== undefined) {
                        if ((paid && isPaid()) || (!paid && !isPaid())) return prevValue + transaction.value
                        return prevValue
                    }

                    return prevValue + transaction.value
                }, 0)

            let transactions = []
            const _filter = {}

            Object.entries(filter).forEach((entry) => {
                const [attr, nestedAttr] = entry[0].split('.')

                if (nestedAttr) _filter[nestedAttr] = entry[1]
                else _filter[attr] = entry[1]
            })

            if (paid !== undefined) _filter.paid = paid

            transactions.push(...getters.filterBy('transactions', { recurrent: true, ..._filter }))
            transactions.push(
                ...getters.filterBy('transactions', { invoiceReferenceId: { $exists: true }, ..._filter })
            )

            return transactions.reduce(
                (prevValue, transaction) => prevValue + transaction.value,
                linkedtransactionBalance
            )
        }
    },
}
