<template>
    <div class="balance-value">
        <p>{{ title }}</p>

        <v-skeleton-loader v-if="waiting" type="text" />

        <span v-else :style="{ color: [value < 0 ? '#EF8A97' : '#63B9F2'] }">
            {{ value | currency }}
        </span>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'BalanceValue',

    props: {
        title: {
            type: String,
            required: true,
        },
        value: {
            type: Number,
            required: true,
        },
    },

    computed: {
        ...mapState(['waiting']),
    },
}
</script>

<style lang="scss" scoped>
.balance-value {
    min-width: 100px;
    font-weight: 600;
    text-align: left;

    p {
        margin-bottom: 4px;
        color: $title-grey;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
    }
}
</style>
