<template>
    <div class="profile-config">
        <div class="flex">
            <form @submit.prevent.self="checkForm" class="flex forms-box column">
                <div id="acesso">
                    <h1>Seus dados de acesso</h1>

                    <div class="contend-form-box form-margin-bottom flex space-between">
                        <div class="img-container">
                            <img class="img" src="@/assets/img/user-placeholder.svg" />
                        </div>

                        <form style="flex: 1" autocomplete="off">
                            <div class="flex space-between">
                                <stacked-input class="grow-1" label="Seu nome" v-model="usuario.name" />

                                <stacked-input
                                    class="grow-1 space"
                                    label="Seu e-mail"
                                    v-model="email"
                                    :readonly="true"
                                />
                            </div>

                            <div class="flex form-margin-top space-between">
                                <stacked-input
                                    class="grow-1"
                                    label="Nova senha"
                                    type="password"
                                    ph="* * * * * * * * * *"
                                    :showEyeButton="!!usuario.password"
                                    :errorValidation="error[0]"
                                    :errorMsg="msg[0]"
                                    v-model="usuario.password"
                                />

                                <div class="space" style="width: 320px"></div>
                            </div>
                        </form>
                    </div>
                </div>

                <div id="pessoal">
                    <h1>Suas informações pessoais</h1>

                    <div class="contend-form-box">
                        <form class="flex">
                            <div class="form-width flex">
                                <stacked-input
                                    class="form-width"
                                    label="Seu CPF"
                                    v-mask="'###.###.###-##'"
                                    :errorValidation="error[1]"
                                    :errorMsg="msg[1]"
                                    v-model="usuario.cpf"
                                    name="cpf"
                                />
                                <stacked-input
                                    class="form-width space"
                                    label="Data de nascimento"
                                    type="date"
                                    :max="max"
                                    v-model="usuario.birthdate"
                                    name="birthdate"
                                />
                            </div>

                            <div class="flex column space">
                                <div class="radio-title">Seu gênero</div>
                                <div>
                                    <input type="radio" id="male" value="Male" v-model="usuario.gender" name="gender" />
                                    <label class="radio-label" for="male">Masculino</label>

                                    <input type="radio" id="female" value="Female" v-model="usuario.gender" />
                                    <label class="radio-label" for="female">Feminino</label>
                                </div>
                            </div>
                        </form>

                        <div class="flex form-margin-top" style="width: 70%">
                            <stacked-input
                                style="width: 172px"
                                label="Seu nº de telefone"
                                v-mask="['(##) ####-####', '(##) #####-####']"
                                :errorValidation="error[2]"
                                :errorMsg="msg[2]"
                                v-model="usuario.phoneNumber"
                                name="phoneNumber"
                            />
                            <stacked-input
                                class="space"
                                style="width: 55%"
                                label="Cidade onde reside"
                                v-model="usuario.address.city"
                                name="city"
                            />
                        </div>
                    </div>
                </div>

                <basic-button data-cy="submit-profile" label="Salvar Alterações" :loading="disabled" type="submit" />
            </form>
        </div>
    </div>
</template>

<script>
import date from '@/common/formatDate'
import request from '@/common/request'
import { mapState } from 'vuex'
import { mask } from 'vue-the-mask'
import BasicButton from '../../components/buttons/BasicButton.vue'
import StackedInput from '../../components/inputs/StackedInput.vue'
import validation from '../../common/validation'

export default {
    name: 'ProfileConfig',

    components: { StackedInput, BasicButton },

    directives: {
        mask,
    },

    data() {
        return {
            usuario: {
                password: '',
                cpf: '',
                phoneNumber: '',
                address: {
                    cep: ' ',
                    state: ' ',
                    neighborhood: ' ',
                    city: ' ',
                    street: ' ',
                },
            },
            error: [],
            msg: [],
        }
    },

    methods: {
        setUserData() {
            const { name, email, socialEmail, cpf, birthdate, gender, phoneNumber, address, _id } = this.user

            this.usuario = { name, cpf, email, socialEmail, birthdate, gender, phoneNumber, _id, password: '' }
            if (address) this.usuario.address = { ...address }
            this.usuario.birthdate ? (this.usuario.birthdate = this.usuario.birthdate.slice(0, 10)) : ''
        },

        patchUser() {
            if (!this.usuario.password) delete this.usuario['password']
            this.$store.commit('DISABLED')

            request
                .patch({ collection: 'users', data: this.usuario, _id: this.usuario._id })
                .then(() => (this.usuario.password = ''))
                .finally(() => this.$store.commit('DISABLED'))
        },

        checkForm: function (e) {
            e.preventDefault()

            const { name, cpf, phoneNumber, password } = this.usuario

            let error = [false, false, false]
            let errorCount = 0

            if (!name.length) {
                this.usuario.name = this.user.name
            }
            if (password.length > 0 && password.length < 8) {
                error[0] = true
                this.msg[0] = 'SENHA MUITO PEQUENA'
                errorCount++
            }
            if (validation.validateCPF(cpf)) {
                error[1] = true
                this.msg[1] = 'CPF INVÁLIDO'
                errorCount++
            }
            if (validation.validatePhone(phoneNumber)) {
                error[2] = true
                this.msg[2] = 'TELEFONE INVÁLIDO'
                errorCount++
            }

            this.error = error

            if (!errorCount) {
                this.patchUser()
            }
        },
    },

    watch: {
        user: {
            immediate: true,
            deep: true,
            handler() {
                this.setUserData()
            },
        },
    },

    computed: {
        ...mapState(['user', 'disabled']),

        max: function () {
            return date.inputFormatDate(new Date())
        },

        email() {
            return this.usuario.socialEmail || this.usuario.email
        },
    },

    mounted() {
        this.setUserData()
    },
}
</script>

<style lang="scss" scoped>
.profile-config {
    padding-top: 42px;

    .img-container {
        box-sizing: border-box;
        margin-right: 24px;
        height: 100px;
        width: 100px;
        border: 4px solid $btn-blue;
        border-radius: 50%;
    }

    .forms-box {
        width: 780px;

        h1 {
            @include section-title();
        }

        h3 {
            color: #9fa5b1;
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            padding-bottom: 8px;
            border-bottom: 1px solid #9fa5b1;
        }

        .contend-form-box {
            flex: 1;
            padding: 24px 0 27px 0;
            font-size: 14px;
            color: #999999;

            strong {
                font-size: 18px;
                color: black;
            }

            span {
                color: black;
                font-size: 16px;
                font-weight: 500;
                margin-right: 25px;
            }

            a {
                color: $link-blue;
            }

            .form-margin-top {
                margin-top: 21px;
            }

            .form-width {
                width: 47%;
            }

            .stacked-input {
                margin-top: 0px;
            }

            .radio-title {
                position: relative;
                top: 2px;
                left: -10px;
                padding: 0 4px 10px;
                background-color: transparent;
                color: $dark-grey;
                font-size: 13px;
                width: max-content;
                font-weight: 500;
            }

            .radio-label {
                margin: 0 20px 0 8px;
            }
        }

        .form-margin-bottom {
            margin-bottom: 15px;
        }

        .line-height {
            line-height: 30px;
        }

        .space {
            margin-left: 15px;
        }

        .basic-button {
            width: 240px !important;
            margin-top: 10px;
        }
    }
}

.flex {
    display: flex;
}

.flex-around {
    justify-content: space-around;
}

.column {
    flex-direction: column;
}

.grow-1 {
    flex-grow: 1;
}

.flex-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}
</style>
