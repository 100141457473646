<template>
    <div class="dropdown">
        <div class="overlay"></div>
        <div class="container">
            <ul>
                <li @click="$emit('select', item.value || item)" v-for="(item, index) in model" :key="index">
                    {{ `${item.title || item}${sufix}` }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Dropdown',
    props: {
        model: {
            type: [Array, Number],
            default: () => [],
        },
        sufix: {
            type: String,
            default: '',
        },
    },
}
</script>

<style lang="scss" scoped>
@include custom-scroll();

.dropdown {
    position: relative;

    .overlay {
        top: 6%;
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        z-index: 10;
    }

    .container {
        width: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-shadow: 0px -3px 6px #00000029;
        position: fixed;
        background: white;
        z-index: 11;
        left: 0;
        bottom: 0;
        animation: dropdown-animation 0.7s ease-in-out;
        overflow-y: scroll;
        max-height: 70%;

        ul {
            padding: 0 24px;

            li {
                border-bottom: 2px solid #dddddd;
                padding: 20px 0;
                color: $light-black;
                cursor: pointer;

                &:last-child {
                    border: none;
                }
            }
        }
    }
}

@keyframes dropdown-animation {
    0% {
        max-height: 0;
    }
    100% {
        max-height: 70%;
    }
}
</style>
