import store from '@/store'

const hasBankAccessToUpdate = () => {
    return store.getters.getBelvoRelatedPluggyConnectors().length > 0
}

const dashboard = () => {
    const dashboardSpeech = [
        {
            text: `Olá, ${store.state.user.name}!`
        },
    ]

    if(hasBankAccessToUpdate()) {
        dashboardSpeech.push({ text: 'Você precisa reconectar algumas de suas contas.' })
        dashboardSpeech.push({ text: 'Acesse as configurações para atualizá-las' })
    } else {
        dashboardSpeech.push({ text: 'Nesta tela você tem algumas informações sobre suas finanças neste mês.' })
        dashboardSpeech.push({ text: 'Observe que os cards lá no topo te mostram tanto a situação total do seu orçamento, quanto a de cada tipo de movimentação (receitas, despesas e também o que você tem como poupança)' })
    } 
    return dashboardSpeech   
}

const transactions = () => {
    if (store.state.transactions.length) {
        return [
            {
                text: 'Se desejar filtrar os lançamentos por receitas ou despesas, use a seleção ao lado do indicador do mês, ali em cima.'
            },
            {
                text: 'Você também pode ver todos os lançamentos de uma categoria clicando sobre a área correspondente a ela no gráfico de categorias.'
            }
        ]
    }

    return []
}

const payment_transactions = () => [
    {
        text: 'Observe que lançamentos de contas automáticas não podem ser excluídos, ok?'
    }
]

const payment_transactions_credit = () => [
    {
        text: 'A fatura do seu cartão é formada pelos gastos feitos entre o dia de fechamento do mês anterior e o do mês atual. Portanto, fique atento a esse detalhe.'
    }
]

const wallet = () => {
    if (store.state.payments.length) {
        return [
            {
                text: 'O saldo total das suas carteiras é a soma dos saldos de todas as suas contas, <strong>menos</strong> o(s) valore(s) das faturas dos cartões de crédito.'
            }
        ]
    }

    return []
}

const budget = (options = {}) => {
    if (Object.keys(store.state.budgets).length) {
        if (options.editBudget) {
            return [
                {
                    text: 'Deslize os controles para indicar a porcentagem, ou digite um valor no campo.'
                },
                {
                    text: 'Observe que a soma total de cada limite não pode exceder o valor total do seu orçamento atual.'
                }
            ]
        }

        return [
            {
                text: 'Caso precise alterar algum limite, basta utilizar o botão de edição na barra da categoria desejada.'
            }
        ]
    }

    return [
        {
            text: 'Em um planejamento automático, são sugeridos limites ideais. Porém, você pode alterá-los conforme sua realidade.'
        },
        {
            text: 'A ideia é limitar os gastos e fazer sobrar uma grana. 😉'
        }
    ]
}

const objectives = () => [
    {
        text: 'Um orçamento baseado em metas financeiras tende a ser mais eficaz e prazeroso.'
    },
    {
        text: 'No dia do vencimento do seu objetivo, vou te lembrar a guardar o valor da parcela.'
    }
]

export default {
    dashboard,
    transactions,
    payment_transactions,
    payment_transactions_credit,
    wallet,
    budget,
    objectives
}