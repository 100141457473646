import moment from 'moment'
import authService from '../services/auth.service'
import { alphabet } from './lists'

/* FORMATS */
const sanitize = (string) => {
    return string
        .toLowerCase()
        .normalize('NFD')
        .replace(/[^a-z]/g, '')
}

/**
 *
 * @param {string} string
 * @returns {string}
 */
const sanitizeMoney = (string) => {
    return string.replace(returnCurrencyPrefix().prefix, '').replace(/\./g, '').replace(',', '.').trim()
}

export const formatNumber = (number) => {
    let locale = {
        BRL: {
            locale: 'pt-BR',
        },
        USD: {
            locale: 'en-US',
        },
        EUR: {
            locale: 'de-DE',
        },
        GBP: {
            locale: 'en-GB',
        },
        JPY: {
            locale: 'ja-JP',
        },
    }

    const currency = authService.getOnLocalStorage('currency') || 'BRL'

    return Intl.NumberFormat(locale[currency].locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: currency === 'JPY' ? 0 : 2,
    }).format(number)
}

const sanitizeChartLabels = (string) => {
    let m

    if (string >= 1000000) {
        string = string.slice(0, -7)
        m = Math.floor(string.length / 2)
        return string.substr(0, m).concat(',').concat(string.substr(m)).concat('M')
    }
    if (string >= 10000) {
        return string.slice(0, -6).padEnd(string.length - 5, 'K')
    }
    if (string >= 1000) {
        string = string.slice(0, -5)
        m = Math.floor(string.length / 2)
        return string.substr(0, m).concat(',').concat(string.substr(m)).concat('K')
    }

    return string.slice(0, -3)
}

const sanitizeNumber = (number) => {
    if (number >= 1000000000) return number / 1000000000 + 'B'
    if (number >= 1000000) return number / 1000000 + 'M'
    if (number >= 1000) return number / 1000 + 'K'
    return number
}

const sanitizePercentage = (string) => {
    return Number(string.replace('%', ''))
}

/* OUTROS */
const translateTransaction = (translate) => {
    let type

    switch (translate) {
        case 'revenues':
            type = 'receita'
            break
        case 'expenses':
            type = 'despesa'
            break
        case 'investiments':
            type = 'investimento'
            break
    }

    return type
}

const percentage = (n, d) => {
    let number = (n / d) * 100
    return Number.isNaN(number) || number === Infinity ? 0 : number
}

const randomWidth = (base) => {
    return Math.random() * 100 + base
}

const customFilter = (filters = {}, object = {}, userId = '') => {
    let pass = true

    for (const key in filters) {
        if (filters[key]) {
            let regex = new RegExp(`^${filters[key]}`, 'i')
            if (pass) pass = regex.test(object[key])
        }
    }

    if (userId) {
        const user = object.user._id || object.user

        if (pass) pass = user === userId
    }

    return pass
}

const identifyUserDevice = () => {
    const userAgent = window.navigator.userAgent
    const mobileRegex = /android|iphone|kindle|ipad/i

    const isMobile = mobileRegex.test(userAgent)

    if (isMobile) return 'mobile'
    return 'desktop'
}

const letterColor = (letter = '') => {
    let pos = alphabet.findIndex((l) => l === letter.toLowerCase()) + 1

    if (!(pos % 2)) pos = 26 - pos

    return rainbowStop(pos / alphabet.length)
}

// Gerar cores aleátorias vibrantes
const rainbowStop = (h) => {
    let f = (n, k = (n + h * 12) % 12) => 0.5 - 0.5 * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    let rgb2hex = (r, g, b) =>
        '#' +
        [r, g, b]
            .map((x) =>
                Math.round(x * 255)
                    .toString(16)
                    .padStart(2, 0)
            )
            .join('')
    return rgb2hex(f(0), f(8), f(4))
}

const returnCurrencyPrefix = () => {
    let object
    switch (authService.getOnLocalStorage('currency')) {
        case 'USD':
            object = {
                prefix: 'US$',
                locale: 'en-US',
                decimal: '.',
                thousands: ',',
            }
            break
        case 'EUR':
            object = {
                prefix: '€',
                locale: 'de-DE',
                decimal: ',',
                thousands: '.',
            }
            break
        case 'GBP':
            object = {
                prefix: '£',
                locale: 'en-GB',
                decimal: '.',
                thousands: ',',
            }
            break
        case 'JPY':
            object = {
                prefix: 'JP¥',
                locale: 'ja-JP',
                decimal: ',',
                thousands: '.',
            }
            break
        default:
            object = {
                prefix: 'R$',
                locale: 'pt-BR',
                decimal: ',',
                thousands: '.',
            }
    }
    return object
}

const exactDate = (date = undefined) => {
    return moment(date ? date : new Date())
        .utc()
        .startOf('day')
}

export default {
    sanitize,
    sanitizeMoney,
    sanitizeChartLabels,
    sanitizePercentage,
    sanitizeNumber,
    translateTransaction,
    percentage,
    randomWidth,
    formatNumber,
    customFilter,
    identifyUserDevice,
    letterColor,
    returnCurrencyPrefix,
    exactDate,
}
