import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth, GoogleAuthProvider, OAuthProvider, FacebookAuthProvider } from 'firebase/auth'

const firebaseConfig = {
    apiKey: 'AIzaSyATrX1PV4NOawYo06vK6Rcpo2qMvxpQQOA',
    authDomain: 'otto-financas.firebaseapp.com',
    projectId: 'otto-financas',
    storageBucket: 'otto-financas.appspot.com',
    messagingSenderId: '1018329530894',
    appId: '1:1018329530894:web:4139b6e180c806ad19325f',
    measurementId: 'G-CCLN6PWVZL',
}

export const initializeFirebaseApp = () => {
    const app = initializeApp(firebaseConfig)
    const analytics = getAnalytics(app)

    const auth = getAuth()
    auth.languageCode = 'it'

    const googleProvider = new GoogleAuthProvider()
    const appleProvider = new OAuthProvider('apple.com')
    const facebookProvider = new FacebookAuthProvider()

    appleProvider.addScope('email')
    appleProvider.addScope('name')

    appleProvider.setCustomParameters({
        locale: 'pt-br',
        clientId: 'com.ottoassistente',

        // Return URL added to your Apple dev console. We intercept this redirect, but it must still match
        // the URL you provided to Apple. It can be an empty route on your backend as it's never called.
        redirectUri: 'https://otto-financas.firebaseapp.com/__/auth/handler',
    })

    return {
        app,
        analytics,
        auth,
        googleProvider,
        appleProvider,
        facebookProvider,
    }
}
