<template>
    <modal-frame noHeader title="EDITAR PLANEJAMENTO DE CATEGORIA" :closeBtn="false" :width="400">
        <template #content>
            <div class="category-budget-modal not-default-fieldset">
                <stacked-select
                    class="form-el"
                    label="Categoria"
                    :required="true"
                    :errorValidation="error[1]"
                    :notTransaction="true"
                    :model="categories"
                    :disabled="true"
                    style="margin-bottom: 8px"
                    v-show="category"
                    :previousSelected="previousCategory"
                    data-cy="select-category"
                />

                <div>
                    <div class="row center" style="margin-bottom: 8px; align-items: center; flex-wrap: nowrap">
                        <unity-radio :type="type" @selectUnity="selectType" style="padding-top: 16px" />

                        <stacked-input
                            label="Limite"
                            style="width: 57.8%"
                            :errorValidation="error[5] || error[6]"
                            :errorMsg="error[5] ? 'VALOR INVÁLIDO' : 'VALOR ULTRAPASSA AS RECEITAS'"
                            :errorFontSize="10"
                            :required="true"
                            :money="type === 'currency' ? money : percentage"
                            v-model="value"
                        />
                    </div>
                </div>
            </div>
        </template>

        <template #btn-container>
            <div class="button-wrapper">
                <basic-button label="EDITAR" style="margin-bottom: 8px" @click.native="editCategory" width="100%" />

                <basic-button
                    label="CANCELAR"
                    :reverse="true"
                    @click.native="() => $emit('closeModalEvent')"
                    width="100%"
                />
            </div>
        </template>
    </modal-frame>
</template>

<script>
import { mapGetters } from 'vuex'

import BasicButton from '../buttons/BasicButton.vue'
import StackedSelect from '../inputs/StackedSelect.vue'
import ModalTransition from '../transitions/ModalTransition.vue'
import ConfirmationCreateModal from './confirmation-modals/ConfirmationCreateModal.vue'
import UnityRadio from '../inputs/UnityRadio.vue'
import StackedInput from '../inputs/StackedInput.vue'
import ModalFrame from '../../containers/ModalFrame.vue'

import util from '../../common/util'

export default {
    components: {
        ModalTransition,
        ConfirmationCreateModal,
        BasicButton,
        StackedSelect,
        UnityRadio,
        StackedInput,
        ModalFrame,
    },

    name: 'CategoryBudgetEditionModal',

    props: {
        data: Object,
        addedCategories: Object,
    },

    data() {
        return {
            category: {},
            error: [],
            categories: [],
            previousCategory: {},
            speeches: [
                'Para começar, selecione uma categoria de despesas na lista abaixo.',
                'Agora, defina um limite de gastos para ela. Pode ser um valor fixo ou uma porcentagem do orçamento.',
            ],
            stage: 0,
            value: 0,
            type: 'percentage',
            money: {
                decimal: ',',
                thousands: '.',
                prefix: util.returnCurrencyPrefix().prefix + ' ',
                precision: 2,
                masked: false,
            },
            percentage: {
                decimal: ',',
                thousands: '.',
                suffix: '%',
                precision: 0,
                masked: false,
            },
        }
    },

    methods: {
        updateCategory(value) {
            this.category = value
        },

        sanitize(string) {
            return util.sanitize(string)
        },

        parsePercentage: (percentage) => {
            return Number(percentage.replace('%', '')) / 100
        },

        selectType(payload) {
            if (payload === this.type) return

            this.type = payload

            switch (payload) {
                case 'currency':
                    this.value = util.formatNumber(this.balance * this.parsePercentage(this.value))
                    break
                default:
                    this.value = Math.round((Number(util.sanitizeMoney(this.value)) / this.balance) * 100)
            }
        },

        setForm() {
            this.previousCategory = this.data.category
            this.category = this.data.category
            this.categories.push(this.data.category)
            this.value = Math.round(this.data.budgetValue)
        },

        editCategory() {
            let error = Array(7).fill(false)
            let categoryToEdit = {}
            let budgetValue = 0
            let budgetValueInCurrency = 0
            let previusCategoryValue = (this.data.budgetValue / 100) * this.balance

            const value =
                this.type === 'currency' ? Number(util.sanitizeMoney(this.value)) : util.sanitizePercentage(this.value)

            if (this.type === 'currency') {
                budgetValueInCurrency = value
                budgetValue = (value / this.balance) * 100
            } else {
                budgetValueInCurrency = (value * this.balance) / 100
                budgetValue = value
            }

            if (!value) {
                error[5] = true
                this.error = error
            } else if (
                budgetValueInCurrency + this.addedCategories.budgetTotalValue - previusCategoryValue <=
                this.balance
            ) {
                categoryToEdit = {
                    category: this.data.category,
                    budgetValue: budgetValue,
                }
                this.$emit('closeModalEvent', categoryToEdit)
            } else {
                error[6] = true
                this.error = error
            }
        },
    },

    computed: {
        ...mapGetters(['filteredCategories', 'balanceMonthExpectedPerType']),

        balance() {
            return this.balanceMonthExpectedPerType('revenues', null)
        },
    },

    mounted() {
        this.setForm()
    },
}
</script>

<style lang="scss" scoped>
.category-budget-modal {
    margin: -12px 0 24px;
    border-radius: 8px;
    background: white;

    &:disabled {
        cursor: wait;
    }
}

.loader {
    font-size: 14px;
    margin: 0;
}

.chat-otto-img,
.chat-user-img {
    position: relative;
    top: 200;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50%;
    padding: 4px;
    box-shadow: 1px 1px 2px #00000030;
    border: 1px solid $btn-blue;
}

.otto-talk {
    left: -3px;
    background-color: #ffffff;
    text-align: left;
    margin-bottom: 8px;
    color: #5b6772;
}

.user-talk,
.otto-talk {
    position: relative;
    max-width: 260px;
    line-height: 18px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}
.flex-bubble {
    display: flex;
    margin-bottom: 8px;
    &.column {
        flex-direction: column;
    }

    &.row {
        flex-direction: row;
        margin: 0;
    }
}

.button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: #d0021b;
}

.icon-contend {
    .icon {
        height: 33px;
        width: 33px;
        border-radius: 50%;
    }
}
.head h1 {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
}
</style>
