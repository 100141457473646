<template>
    <div class="step-three step">
        <h3 class="step-title">Suas informações de pagamento</h3>

        <div class="radio-title">Forma de pagamento</div>

        <div class="radio-group">
            <div>
                <input type="radio" id="boleto" value="bank_slip" v-model="payment.method" />
                <label class="" for="boleto">
                    <div class="icon-container"><i class="fas fa-barcode"></i></div>
                    Boleto
                </label>
            </div>

            <div>
                <input type="radio" id="cartao" value="credit_card" v-model="payment.method" />
                <label class="" for="cartao">
                    <!-- <div class="icon-container"></div> -->
                    <div class="icon-container"><i class="far fa-credit-card"></i></div>
                    Cartão de crédito
                </label>
            </div>

            <div>
                <input type="radio" id="pix" value="pix" v-model="payment.method" />
                <label class="" for="pix">
                    <div class="icon-container"><img src="@/assets/img/payments/pix.svg" alt="" /></div>
                    Pix
                </label>
            </div>
        </div>

        <v-expand-transition>
            <div v-if="payment.method === 'credit_card'">
                <div style="position: relative">
                    <stacked-input
                        label="Número do cartão"
                        v-model="payment.creditcard.number"
                        v-mask="numberMask"
                        :blur="false"
                        :required="true"
                        :errorValidation="validation.hasError('payment.creditcard.number')"
                        :errorMsg="validateField(validation.firstError('payment.creditcard.number'))"
                        name="number"
                        :isRequired="true"
                    />
                    <div
                        class="cc_icon"
                        :style="'background-image: url(img/payments/' + parseCardBrand(cardBrand) + '.png)'"
                    ></div>
                </div>

                <div class="form-row">
                    <credit-card-expire-date
                        v-bind="expirationDates"
                        @change="updatePaymentData"
                        @blur="updatePaymentData"
                        :errorValidation="
                            validation.hasError('payment.creditcard.validYear') ||
                            validation.hasError('payment.creditcard.validMonth')
                        "
                        :isRequired="true"
                    />

                    <stacked-input
                        label="Cód. Verificação"
                        v-model="payment.creditcard.cvv"
                        v-mask="cvvMask"
                        :blur="false"
                        :required="true"
                        :errorValidation="validation.hasError('payment.creditcard.cvv')"
                        :errorMsg="validateField(validation.firstError('payment.creditcard.cvv'))"
                        name="cvv"
                        :isRequired="true"
                    />
                </div>

                <stacked-input
                    label="Titular do cartão"
                    v-model="payment.creditcard.owner"
                    :blur="false"
                    :required="true"
                    :errorValidation="validation.hasError('payment.creditcard.owner')"
                    :errorMsg="validateField(validation.firstError('payment.creditcard.owner'))"
                    name="owner"
                    :isRequired="true"
                />

                <div class="checkbox-container">
                    <input
                        class="checkbox"
                        type="checkbox"
                        name="authorization"
                        value="true"
                        v-model="authorization"
                        @change="$emit('authorize', authorization)"
                    />
                    <p>Autorizo a cobrança {{ period }} no meu cartão de crédito.</p>
                </div>
            </div>
        </v-expand-transition>
    </div>
</template>

<script>
import StackedInput from '../../../components/inputs/StackedInput.vue'
import { mask } from 'vue-the-mask'
import SimpleVueValidation from 'simple-vue-validator'
import validation from '@/common/validation'
import CreditCardExpireDate from '../../../components/inputs/CreditCardExpireDate.vue'

const Validator = SimpleVueValidation.Validator

export default {
    components: { StackedInput, CreditCardExpireDate },
    name: 'StepThree',
    directives: {
        mask,
    },
    props: {
        period: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            payment: {
                method: 'bank_slip',
                creditcard: {
                    number: '',
                    owner: '',
                    validMonth: '',
                    validYear: '',
                    cvv: '',
                },
            },

            authorization: false,
        }
    },

    computed: {
        numberMask() {
            if (this.cardBrand === 'diners') {
                return '#### #### #### ##'
            } else if (this.cardBrand === 'amex') {
                return '#### #### #### ###'
            } else {
                return '#### #### #### ####'
            }
        },

        cardBrand() {
            return window.Iugu.utils.getBrandByCreditCardNumber(this.payment.creditcard.number)
        },

        cvvMask() {
            return this.cardBrand === 'amex' ? '####' : '###'
        },

        expirationDates() {
            const actualDate = this.$moment()
            let thisYear = actualDate.year()
            let years = []
            for (var i = 0; i < 10; i++) {
                years.push(thisYear + i)
            }

            return {
                modelOne: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                modelTwo: years,
            }
        },
    },

    watch: {
        payment: {
            deep: true,
            immediate: true,
            handler(payment) {
                this.$emit('subscriptionData', payment)
                if (payment.method !== 'credit_card') {
                    this.$emit('authorize', true)
                } else {
                    this.$emit('authorize', this.authorization)
                }
            },
        },
    },

    methods: {
        validateField(errorMsg) {
            return validation.svvDictionaryMessageValidation(errorMsg)
        },

        updatePaymentData(payload) {
            this.payment.creditcard = Object.assign(this.payment.creditcard, payload)
        },

        parseCardBrand(brand) {
            if (brand === 'amex') return 'americanexpress'
            return brand
        },

        async validate() {
            await this.validation.reset()

            this.$emit('validate', await this.$validate())
        },
    },

    validators: {
        'payment.creditcard.number': function (value) {
            if (this.payment.method === 'credit_card') {
                return Validator.value(value)
                    .required()
                    .custom(() => {
                        return validation.validateCreditcardNumber(value)
                    })
            }
        },
        'payment.creditcard.validMonth': function (value) {
            if (this.payment.method === 'credit_card') {
                return Validator.value(value).required()
            }
        },
        'payment.creditcard.validYear, payment.creditcard.validMonth': function (validYear, validMonth) {
            if (this.payment.method === 'credit_card') {
                return Validator.value(validYear)
                    .required()
                    .custom(() => {
                        return validation.validateExpirationValidDate(validMonth, validYear)
                    })
            }
        },
        'payment.creditcard.cvv, payment.creditcard.number': function (cvv, cardNumber) {
            if (this.payment.method === 'credit_card') {
                return Validator.value(cvv)
                    .required()
                    .custom(() => {
                        return validation.validateCVV(cvv, cardNumber)
                    })
            }
        },
        'payment.creditcard.owner': function (value) {
            if (this.payment.method === 'credit_card') {
                return Validator.value(value)
                    .required()
                    .custom(() => {
                        return validation.validateCardOwner(value)
                    })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';

.radio-title {
    margin: 1.5em 0 0.5em;
    padding: 0;
}

.radio-group {
    flex-direction: column;
    margin: 0;

    & > div {
        padding: 0.3em 0;

        .icon-container {
            margin: 0 4px 0 10px;
            width: 30px;
            display: inline-block;
            text-align: center;

            img {
                width: 17px;
                height: 17px;
            }

            i {
                font-size: 20px;
                margin: 0 4px 0 8px;

                &.fa-credit-card {
                    margin-left: 7px;
                }
            }
        }
    }
}
</style>
