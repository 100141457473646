<template>
    <div class="report view-container">
        <div class="view-content content">
            <ActionBar title="RELATÓRIO DE EVOLUÇÃO MENSAL" />

            <div class="flex column">
                <div class="row" style="margin: 0 !important">
                    <report-chart-box
                        type="simpleColumn"
                        boxTitle="RESULTADOS MÊS A MÊS"
                        boxSubTitle="Quanto sobrou em cada mês"
                        :marginBottom="true"
                        :marginRight="true"
                        :width="858"
                        :height="188"
                        :tooltipText="'Quanto sobrou das suas receitas depois que todas as despesas foram pagas'"
                    >
                        <simple-column-chart
                            :width="858"
                            columnColor="#028FFF"
                            secondColor="#ef8a97"
                            :series="yBalance"
                        />
                    </report-chart-box>
                    <category-summary-card boxTitle="UTILIZAÇÃO DO ORÇAMENTO" :width="270" :height="188" />
                </div>
                <div class="row" style="margin: 0 !important">
                    <report-chart-box
                        type="columnLine"
                        boxTitle="RECEITAS x DESPESAS MÊS A MÊS"
                        :marginBottom="true"
                        :marginRight="true"
                        :width="564"
                        :height="240"
                    >
                        <column-line-chart :series="_revenuesExpensesBalance" :line="budgetsBalance" />
                    </report-chart-box>
                    <report-chart-box
                        type="dualColumn"
                        boxTitle="COMPRAS PARCELADAS NO CARTÃO X ORÇAMENTO"
                        :marginBottom="true"
                        :width="564"
                        :height="240"
                        :tooltipText="'Quanto do seu orçamento está comprometido com despesas parceladas no cartão de crédito.'"
                    >
                        <dual-column-chart :series="creditInstallmentss" :labels="chartLabels" />
                    </report-chart-box>
                </div>
                <div class="row" style="margin: 0 !important">
                    <report-chart-box
                        type="simpleColumn"
                        boxTitle="ACÚMULO DE RESERVA (POUPANÇA)"
                        :marginRight="true"
                        :width="564"
                        :height="240"
                        :tooltipText="'Quanto você acumulou em economias (poupança ou investimentos) até o mês atual.'"
                    >
                        <simple-column-chart
                            :width="564"
                            :columnColor="'#51A7DA'"
                            :series="savings"
                            :labels="chartLabels"
                        />
                    </report-chart-box>

                    <report-chart-box type="simpleBar" boxTitle="PROGRESSO DOS OBJETIVOS" :width="564" :height="240">
                        <simple-bar-chart :series="objectives" />
                    </report-chart-box>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ActionBar from '../components/ActionBar'
import ReportChartBox from '../components/cards/ReportChartBox.vue'
import CategorySummaryCard from '../components/cards/CategorySummaryCard.vue'
import ColumnLineChart from '../components/charts/ColumnLineChart.vue'
import DualColumnChart from '../components/charts/DualColumnChart.vue'
import SimpleColumnChart from '../components/charts/SimpleColumnChart.vue'
import SimpleBarChart from '../components/charts/SimpleBarChart.vue'
import { mapGetters, mapState } from 'vuex'
import request from '../common/request'
import apiService from '../services/api.service'
import { environment } from '../common/environment'

export default {
    name: 'EvolutionReport',
    components: {
        ActionBar,
        ReportChartBox,
        CategorySummaryCard,
        ColumnLineChart,
        DualColumnChart,
        SimpleColumnChart,
        SimpleBarChart,
    },

    data() {
        return {
            chartSeries: [],
            chartOptions: {},
            chartLabels: [],
            yBalance: [],
            revenuesExpensesBalance: [],
            budgetsBalance: [],
            creditInstallments: [],
            savings: [],
        }
    },

    computed: {
        ...mapState(['waiting', 'objectives', '_date', 'balancesChart', 'payments', 'transactions']),

        ...mapGetters(['filteredPayments', 'balancePayments', 'expensesPaymentsChart']),

        fullYearQuery() {
            return `&startDate=${this.$moment(this._date.inicio)
                .startOf('Y')
                .format('YYYY-MM-DD')}&endDate=${this.$moment(this._date.inicio).endOf('Y').format('YYYY-MM-DD')}`
        },

        halfYearQuery() {
            let month = this.$moment(this._date.inicio).month()
            if (month < 5) month = 5

            return `&startDate=${this.$moment(this._date.inicio)
                .month(month - 5)
                .startOf('M')
                .format('YYYY-MM-DD')}&endDate=${this.$moment(this._date.inicio)
                .month(month)
                .endOf('M')
                .format('YYYY-MM-DD')}`
        },

        money() {
            return this.filteredPayments('money')
        },

        debit() {
            return this.filteredPayments('debit')
        },

        credit() {
            return this.filteredPayments('credit')
        },

        noData() {
            return !this.credit.length && !this.debit.length
        },

        balançosGrafico() {
            // evitar alguns warnings do vuex a respeito de mutação de estado
            return [...this.balancesChart]
        },

        _revenuesExpensesBalance() {
            return [
                {
                    data: this.revenuesExpensesBalance.revenues,
                    name: 'receitas',
                },
                {
                    data: this.revenuesExpensesBalance.expenses,
                    name: 'despesas',
                },
            ]
        },

        creditInstallmentss() {
            let revenues = Array(6).fill(0)
            if (this.balançosGrafico.length) {
                revenues = this.balançosGrafico[0].data
            }
            if (this.creditInstallments.length < 6) this.creditInstallments = this.creditInstallments.push(0)
            return [revenues, this.creditInstallments]
        },
    },

    methods: {
        async loadCharts() {
            let response = await apiService.get(`${environment.BALANCES_URL}?type=chart${this.fullYearQuery}`)

            let yearBalance = []
            this.revenuesExpensesBalance = response.data
            response.data.revenues.forEach((el, i) => {
                yearBalance.push(el - response.data.expenses[i])
            })
            this.yBalance = yearBalance

            let chartType = 'budgets'
            response = await apiService.get(
                `${environment.BALANCES_URL}?type=chart&chartType=${chartType}${this.fullYearQuery}`
            )
            this.budgetsBalance = response.data

            chartType = 'creditInstallments'
            response = await apiService.get(
                `${environment.BALANCES_URL}?type=chart&chartType=${chartType}${this.halfYearQuery}`
            )
            this.creditInstallments = response.data.map(function (x) {
                return Math.abs(x)
            })
            chartType = 'savings'
            response = await apiService.get(
                `${environment.BALANCES_URL}?type=chart&chartType=${chartType}${this.halfYearQuery}`
            )
            this.savings = response.data
        },

        setHalfYearChatLabels() {
            let currentMonth = new Date().getMonth()
            let qtd = 5
            let months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

            while (this.chartLabels.length < 6) {
                let index = currentMonth - qtd
                if (index >= 0) {
                    this.chartLabels.push(months[index])
                } else {
                    this.chartLabels.push(months[months.length + index])
                }
                qtd -= 1
            }
        },
    },

    mounted() {
        this.setHalfYearChatLabels()

        this.$store.commit('DISABLED')
        request
            .fetch(['payments'])
            .then(() => this.loadCharts())
            .finally(() => this.$store.commit('DISABLED'))
    },
}
</script>

<style lang="scss" scoped>
@include empty-list();

.content {
    width: calc(100%);
}

.chart {
    color: #9fa5b1;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 33px;

    .chart-title {
        margin-left: 24px;
    }
}

.card {
    margin-bottom: 32px;
}

.margin-right {
    margin-right: 20px;
}

.item {
    min-width: 75%;
}

.grow {
    flex-grow: 1;
}

.column {
    flex-direction: column;
}

.row {
    flex-direction: row;
}

.flex {
    display: flex;
}

.half-col {
    width: 50%;
}
</style>
