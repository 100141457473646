import router from '../router/router'
import axios from 'axios'
import auth from '../services/auth.service'

const configHeader = (type) => {
    axios.defaults.headers.common['Content-Type'] = type || 'application/json'
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth.getOnLocalStorage('token')
}

const get = (url) => {
    configHeader()
    return axios.get(url).catch((error) => {
        if (error.response.data.message == 'Permission denied!') {
            router.push({
                name: 'login',
            })
            auth.logout()
        }
    })
}

const fetch = (url) => {
    configHeader()
    return axios.get(url)
}

const post = (url, data) => {
    configHeader()
    return axios.post(url, data)
}

const del = (url) => {
    configHeader()
    return axios.delete(url)
}

const patch = (url, data) => {
    configHeader()
    return axios.patch(url, data)
}

const put = (url, data) => {
    configHeader()
    return axios.put(url, data)
}

export default {
    get,
    fetch,
    post,
    del,
    patch,
    put,
    configHeader,
}
