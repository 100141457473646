<template>
    <div class="objectives view-container">
        <div class="view-content">
            <ActionBar title="Objetivos financeiros" />

            <div class="flex">
                <div v-if="waiting" class="objectives-list flex">
                    <v-skeleton-loader
                        v-for="n in 6"
                        :key="n"
                        class="skeleton"
                        height="330"
                        width="280"
                        type="empty_card"
                        :types="{ empty_card: 'image' }"
                    />
                </div>

                <div v-else class="objectives-list flex">
                    <div class="objective-wrapper" key="start">
                        <div class="add-label">Adicionar novo objetivo</div>
                        <v-btn
                            data-cy="open-modal-objective"
                            width="40"
                            height="40"
                            elevation
                            icon
                            @click.native="openModal()"
                        >
                            <v-icon size="16" dark> fas fa-plus </v-icon>
                        </v-btn>
                    </div>

                    <objectives-card
                        v-for="o in objectives"
                        :objective="o"
                        :key="o._id"
                        @open-modal="(payload) => viewObjective(o, payload)"
                        @delete="deleteObjective(o._id)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ActionBar from '../components/ActionBar'
import SummaryModel from '../components/cards/summary-model/SummaryModel'
import ObjectivesCard from '../components/cards/ObjectivesCard.vue'
import ObjectivesModal from '../components/modals/objectives/ObjectivesModal.vue'
import ModalTransition from '../components/transitions/ModalTransition.vue'
import { mapState } from 'vuex'

export default {
    name: 'Objectives',
    components: {
        ActionBar,
        SummaryModel,
        ObjectivesCard,
        ObjectivesModal,
        ModalTransition,
    },
    data() {
        return {
            summaryData: [],
            chartSeries: [],
            modal: true,
        }
    },
    methods: {
        openModal() {
            this.$emit('openModalEvent', {
                modalData: { title: 'Novo Objetivo' },
                state: 'ObjectivesModal',
            })
        },

        viewObjective(objective, payload) {
            this.$emit('openModalEvent', {
                modalData: {
                    mode: payload[0],
                    title: payload[1],
                    data: objective,
                },
                state: 'ObjectivesModal',
            })
        },

        deleteObjective(id) {
            this.$emit('openConfirmationModalEvent', {
                data: {
                    _id: id,
                    collection: 'objectives',
                },
                text: 'Tem certeza que deseja excluir esse objetivo',
            })
        },
    },
    computed: {
        ...mapState(['waiting', 'objectives']),
    },
}
</script>

<style lang="scss" scoped>
.view-container {
    .view-content {
        .objectives-list {
            flex-wrap: wrap;
            width: 100%;

            .objective-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 280px;
                height: 330px;
                background: white;
                margin: 0 24px 50px 0;
                border-radius: 4px;
                position: relative;
                border: 1px solid #dbdbdb;

                .add-label {
                  margin-bottom: 16px;
                }

                button {
                    background: $objective-green;
                    width: 40px;
                    height: 40px;

                    .fa-plus {
                        color: white;
                        font-size: 16px;
                    }
                }
            }

            .skeleton {
                margin: 0 24px 50px 0;

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }
    }
}

.flex {
    display: flex;
}
</style>
