<template>
    <div class="snack-bar" :class="[bgColor, state]">
        <span>{{ message }}</span>
    </div>
</template>

<script>
export default {
    name: 'SnackBar',

    props: {
        bgColor: {
            type: String,
            default: 'success',
        },
        message: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            state: '',
        }
    },

    watch: {
        message: {
            handler() {
                if (this.message) {
                    setTimeout(() => {
                        this.state = 'is-open'
                    }, 300)

                    setTimeout(() => {
                        this.state = 'is-closed'
                        this.$emit('closed')
                    }, 2000)
                }
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.snack-bar {
    height: 48px;
    width: 450px;
    color: white;
    font-weight: 500;
    text-align: center;
    line-height: 48px;
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -100px;
    border-radius: 4px;
    @include defTransition();

    &.success {
        background-color: $success-green;
    }
    &.fail {
        background-color: $hint-red;
    }

    &.is-closed {
        bottom: -100px;
        animation: openUp ease-in-out 0.5s reverse;
    }

    &.is-open {
        bottom: 150px;
        animation: openUp ease-in-out 0.5s;
    }
}

@keyframes openUp {
    0% {
        bottom: -100px;
    }
    100% {
        bottom: 150px;
    }
}
</style>
