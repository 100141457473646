<template>
    <div class="step-one step">
        <h3 class="step-title">{{ title }}</h3>

        <stacked-select
            v-if="!insertPromoCode"
            label="Plano"
            :notTransaction="true"
            :model="plans"
            ref="plan"
            @change="setPlan"
            :errorValidation="validation.hasError('plan.name')"
            dataCy="select-plan2"
            :isRequired="true"
        />

        <stacked-input
            v-else
            label="Código de desconto"
            @input="setPromoCode"
            :uppercase="true"
            :maxlength="20"
            :required="true"
            :value="promoCode"
            :errorValidation="validation.hasError('promoCode')"
        />

        <div class="info-box" v-if="plan.name">
            <h3>{{ plan.name }}</h3>
            <p>
                <span class="blue-text"
                    ><span :class="{ 'gray-value': _insertPromoCode }">R$ {{ plan.price }}</span>
                    <span class="preposition">{{ _insertPromoCode ? ' por ' : '' }}</span>
                    <span class="blue-text">{{ promoValue }}</span> {{ plan.period }}</span
                >, todo dia <span class="blue-text">{{ actualDate(plan.period) }}</span>
            </p>
        </div>
    </div>
</template>

<script>
import { environment } from '../../../common/environment'
import StackedSelect from '../../../components/inputs/StackedSelect.vue'
import apiService from '../../../services/api.service'
import SimpleVueValidation from 'simple-vue-validator'
import StackedInput from '../../../components/inputs/StackedInput.vue'
import util from '../../../common/util'

const Validator = SimpleVueValidation.Validator

export default {
    components: { StackedSelect, StackedInput },

    name: 'StepOne',

    props: {
        insertPromoCode: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            plans: [],
            plan: {
                name: '',
                price: '',
            },
            promoCode: '',
            promotionalCode: {},

            specialPromoCode: {}, // Código utilizado em promoções específicas do Otto
        }
    },

    computed: {
        title() {
            return this.insertPromoCode ? 'Informe o código de desconto' : 'Escolha seu plano de assinatura'
        },

        _insertPromoCode() {
            return this.insertPromoCode || this.specialPromoCode.plan === this.plan.plan_identifier
        },

        promoValue() {
            if (!this._insertPromoCode) return ''

            const realValue = Number(this.plan.price.replace(',', '.') || '0')
            const discount = this.promotionalCode.discount || this.specialPromoCode.discount || 0

            const value = realValue - discount

            return value ? util.formatNumber(realValue - discount) : ''
        },
    },

    async mounted() {
        this.$store.commit('DISABLED')

        try {
            const response = (await apiService.get(`${environment.API_URL}plans`)).data

            await this.setHiddenPromoCode()

            this.plans = response.filter((p) => !p.inactive)

            this.setQueryPlan()
        } catch (e) {
            console.error(e)
            this.plans = []
        } finally {
            this.$store.commit('DISABLED')
        }
    },

    watch: {
        insertPromoCode: {
            immediate: true,
            handler() {
                this.plan = { name: '' }
            },
        },
    },

    methods: {
        setQueryPlan() {
            const urlSearchParams = new URLSearchParams(window.location.search)
            const plan = this.plans.find((_plan) => _plan.plan_identifier === urlSearchParams.get('plan'))

            if (plan) {
                this.$refs.plan.selected = plan
                this.setPlan(plan)
            }
        },

        setPlan(plan) {
            if (plan.plan_identifier === this.specialPromoCode.plan) this.setPromoCode(this.specialPromoCode.code)

            this.plan = { ...plan }
            this.$emit('subscriptionData', plan)
        },

        async setHiddenPromoCode() {
            const { VUE_APP_PROMO_CODE, VUE_APP_PROMO_CODE_EXPIREAT } = process.env

            if (!VUE_APP_PROMO_CODE_EXPIREAT) return

            if (this.$moment().isAfter(VUE_APP_PROMO_CODE_EXPIREAT)) return

            this.specialPromoCode = (await this.getPromoCode(VUE_APP_PROMO_CODE)).data
        },

        setPromoCode(promoCode) {
            this.promoCode = promoCode
            this.$emit('subscriptionData', {
                promoCode,
                promotionalCode: Object.keys(this.promotionalCode).length
                    ? this.promotionalCode
                    : this.specialPromoCode,
            })
        },

        getPromoCode(code) {
            return apiService.get(`${environment.PROMOTIONAL_CODE}/search?code=${code}`)
        },

        async setPromoPlan() {
            try {
                this.$store.commit('DISABLED')

                this.promotionalCode = (await this.getPromoCode(this.promoCode)).data

                this.setPlan(this.plans.find((plan) => plan.plan_identifier === this.promotionalCode.plan))
                this.setPromoCode(this.promoCode)
            } catch (e) {
                console.error(e)
                this.promoCode = ''
                this.validate()

                throw new Error(e)
            } finally {
                this.$store.commit('DISABLED')
            }
        },

        actualDate(period) {
            if (period === 'anualmente') {
                return this.$moment().format('DD/MM')
            } else {
                return new Date().getDate()
            }
        },

        async validate() {
            await this.validation.reset()

            this.$emit('validate', await this.$validate())
        },
    },

    validators: {
        'plan.name': function (value) {
            if (this.insertPromoCode) return false

            return Validator.value(value).required()
        },
        promoCode: function (value) {
            if (!this.insertPromoCode) return false

            return Validator.value(value).required()
        },
    },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';

.gray-value {
    color: $medium-grey;
    text-decoration: line-through;
}

.preposition {
    color: $medium-grey;
    font-weight: 400;
}
</style>
