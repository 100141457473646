<template>
    <div class="step-two step">
        <p class="alert-message">
            Ao invés de cancelar, gostaria de conhecer outros planos ou alterar sua forma de pagamento?
        </p>

        <basic-button
            label="mudar forma de pagamento"
            width="100%"
            @click.native="$router.push('/assinatura/mudar-pagamento')"
        />

        <basic-button
            label="conhecer outros planos"
            width="100%"
            :reverse="true"
            @click.native="$router.push('/assinatura/mudar-plano')"
        />

        <div style="display: flex; justify-content: center">
            <a v-if="!disabled" @click="$emit('cancel-subscription')">CANCELAR ASSINATURA</a>
            <div v-else class="loader"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import BasicButton from '../../../components/buttons/BasicButton.vue'

export default {
    components: { BasicButton },

    name: 'StepTwo',

    computed: {
        ...mapState(['disabled']),
    },
}
</script>

<style lang="scss" scoped>
@import '../steps/styles.scss';

.alert-message {
    margin-bottom: 32px;
}

.basic-button:nth-child(3) {
    margin-top: 10px;
}

a {
    color: $btn-blue;
    font-weight: 500;
    margin-top: 32px;
}

.loader {
    font-size: 20px;
    color: $btn-blue;
    margin: 32px auto 0;
}
</style>
