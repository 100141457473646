<template>
    <div class="payments-modal">
        <div class="container flex modal">
            <div class="container-left column center flex">
                <header class="title-box">{{ title }}</header>

                <div class="flex column center">
                    <v-btn
                        v-if="editData.type === 'credit' || !editData.type"
                        elevation="0"
                        @click="changePaymentType('car')"
                        class="btn flex center"
                        :class="paymentType == 'car' ? 'car' : ''"
                    >
                        <div class="btn-label-container">
                            <div class="btn-label-icon flex center flex-around">
                                <i class="fas fa-credit-card"></i>
                            </div>
                            <span>Cartão de Crédito</span>
                        </div>
                    </v-btn>

                    <v-btn
                        v-if="editData.type === 'debit' || !editData.type"
                        elevation="0"
                        class="btn purple flex center"
                        @click="changePaymentType('con')"
                        :class="[paymentType == 'con' ? 'con' : '']"
                        data-cy="btn-change-payment-con"
                    >
                        <div class="btn-label-container">
                            <div class="btn-label-icon flex center flex-around">
                                <i class="fas fa-university"></i>
                            </div>

                            <span>Conta bancária</span>
                        </div>
                    </v-btn>
                </div>
            </div>

            <div class="flex container-right column">
                <button
                    data-cy="close-payments-modal"
                    class="close flex flex-end"
                    type="button"
                    @click.stop="closeModal"
                >
                    <ion-icon name="close"></ion-icon>
                </button>

                <FormPaymentsBank
                    v-if="paymentType === 'con'"
                    :editData="payment"
                    @closePaymentModalEvent="closeModal"
                />

                <FormPaymentsCCard
                    v-if="paymentType === 'car'"
                    :editData="payment"
                    @closePaymentModalEvent="closeModal"
                />
            </div>
        </div>
    </div>
</template>

<script>
import FormPaymentsCCard from '../../components/forms/FormPaymentsCCard.vue'
import FormPaymentsBank from '../../components/forms/FormPaymentsBank.vue'
import Confirmation from '../../components/modals/confirmation-modals/ConfirmationModal.vue'
import StackedInput from '../../components/inputs/StackedInput.vue'
import StackedSelect from '../../components/inputs/StackedSelect.vue'

export default {
    name: 'PaymentsModal',
    components: {
        FormPaymentsBank,
        FormPaymentsCCard,
        Confirmation,
        StackedInput,
        StackedSelect,
    },
    props: {
        modalData: String,
        editData: Object,
    },
    data() {
        return {
            paymentType: '',
            payment: {},
            showSuccessDialog: false,
        }
    },

    computed: {
        title() {
            return `${this.editData._id ? 'Editar' : `Nov${this.paymentType === 'con' ? 'a' : 'o'}`} ${
                this.paymentType === 'con' ? 'conta' : 'cartão'
            } manual`
        },
    },

    methods: {
        closeModal() {
            this.$emit('modalClosedEvent')
        },

        changePaymentType(paymentType) {
            this.paymentType = paymentType
        },
    },

    mounted() {
        this.paymentType = this.modalData.toLowerCase().slice(0, 3)
        this.payment = this.editData
    },
}
</script>

<style lang="scss" scoped>
.modal {
    min-height: 480px;
    width: 655px;
    border-radius: 8px;
    background-color: #ffffff;
    overflow: hidden;
}

.container {
    margin: 0 auto;
}

.container-left {
    background-color: #688bf2;
    padding: 24px 32px;
    width: 45%;
}

.container-right {
    width: 100%;
}

.title-box {
    margin-bottom: 36px;
    @include modal-title();
    color: white;
    text-align: center;
    font-size: 18px;
    width: 100%;
}

.btn {
    height: 56px !important;
    width: 225px !important;
    border-radius: 4px;
    background-color: #8b5ff9 !important;
    margin-bottom: 16px;
    color: #ffffff !important;
    font-size: 13px !important;
    box-shadow: unset !important;

    .btn-label-container {
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
    }
}

.btn-label-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background-color: #ffffff;
    border-radius: 50%;
    font-size: 15px;
}

.confirmation-modal {
    width: 400px;
    transform: translateY(0px) translateX(-50%);
}

.grow-1 {
    flex-grow: 1;
}

.grow-2 {
    flex-grow: 2;
}

.fa-credit-card {
    color: #8b5ff9 !important;
}

.fa-university {
    color: #5553db !important;
}

.fa-envelope {
    color: #5fd7f9 !important;
}

.purple {
    background-color: #5553db !important;
}

.light-blue {
    background-color: rgba(95, 215, 249, 0.5) !important;
}

.white {
    background-color: $lighter-grey !important;
    color: $dark-blue !important;
}

.car {
    background-color: rgba(139, 95, 249, 1) !important;
}

.con {
    background-color: rgba(85, 83, 219, 1) !important;
}

.flex-around {
    justify-content: space-around;
}

.flex-between {
    justify-content: space-between;
}

.flex-end {
    justify-content: flex-end;
}

.flex {
    display: flex;
}

.column {
    flex-direction: column;
}

.center {
    align-items: center;
}

.close {
    padding: 24px 32px 0;
    font-size: 22px;
    color: #9fa5b1;
    width: 100%;
}

.container-modal {
    position: relative;
}
.success-modal {
    position: absolute;
    width: 300px;
    max-width: 300px;
    top: calc(50% - 100px);
    left: calc(50% - 150px);
    z-index: 1500;
}
</style>
