<template>
    <modal-frame-vue noHeader title="PERFIL PROFISSIONAL" @close-modal="$emit('close-modal')">
        <template #content>
            <div class="header">
                <div class="img" :style="styles"></div>
                <div class="header-content">
                    <strong>{{ expert.name }}</strong>
                    <small>{{ expert.expertises }}</small>
                    <star-rating :rating="expert.stars" />
                    <p>
                        <strong>{{ expert.sessionPrice | currency }}</strong
                        >/sessão
                    </p>
                </div>
            </div>

            <div class="about-message">
                {{ expert.aboutMessage }}
            </div>

            <div class="perfil">
                <h1>Perfil</h1>
                <table class="section">
                    <tbody>
                        <td class="title">Descrição Pessoal</td>
                        <td>{{ expert.profileMessage }}</td>
                    </tbody>
                </table>
            </div>
        </template>
    </modal-frame-vue>
</template>

<script>
import ModalFrameVue from '../../containers/ModalFrame.vue'
import StarRating from '../icons/StarRating.vue'

export default {
    name: 'ExpertModal',

    components: {
        ModalFrameVue,
        StarRating,
    },

    props: {
        expert: {
            required: true,
        },
    },

    computed: {
        styles() {
            return {
                '--background': `url(${this.resolveImg()})`,
            }
        },
    },

    methods: {
        resolveImg() {
            return this.expert.image || this.$variables.placeholder
        },
    },
}
</script>

<style lang="scss" scoped>
.header {
    display: flex;

    .img {
        @include default-icon();
        height: 120px;
        width: 120px;
        border: 2px solid $btn-blue;
        background: white var(--background) no-repeat;
        background-size: 120px;
        margin-right: 16px;
    }

    .header-content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        small {
            margin-left: 2px;
            text-transform: capitalize;
        }
    }
}

.about-message {
    margin: 12px 0;
    line-height: 20px;
}

.perfil {
    h1 {
        @include section-title();
        border: none;
        margin: 16px 0;
    }

    .section {
        border-top: 1px solid $light-grey;
        border-bottom: 1px solid $light-grey;
        padding: 20px 0;
        display: flex;
        justify-content: space-between;

        .title {
            white-space: nowrap;
            width: 30%;
            color: $btn-blue;
        }
    }
}
</style>
