import moment from 'moment'

export default class IuguInvoice {
    constructor(invoice) {
        ;(this.date = moment(invoice.due_date).toDate()),
            (this.value = invoice.total),
            (this.status = invoice.status),
            (this.invoiceLink = invoice.secure_url)
    }

    parseStatus() {
        const waitingStatuses = [
            'pending',
            'draft',
            'partially_paid',
            'in_protest',
            'refunded',
            'chargeback',
            'in_analysis',
        ]
        const finishedStatuses = ['canceled', 'expired']

        if (waitingStatuses.includes(this.status)) return 'pending'
        else if (finishedStatuses.includes(this.status)) return 'error'
        else return 'paid'
    }

    getMessage() {
        switch (this.parseStatus()) {
            case 'pending':
                return 'Aguardando pagamento'
            case 'error':
                return 'Fatura cancelada ou expirada'
            case 'paid':
                return 'Pago'
            default:
                return
        }
    }
}
