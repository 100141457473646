<template>
    <div class="accounts-box">
        <h1>{{ boxTitle }}</h1>

        <div class="row">
            <div v-if="waiting" class="accounts-list">
                <ul>
                    <li v-for="(n, index) in 5" :key="index" class="list-item" style="padding: 0">
                        <div class="row">
                            <div class="left-col">
                                <v-skeleton-loader type="head" style="margin: auto" :types="types" />

                                <v-skeleton-loader
                                    type="arms"
                                    style="max-width: 150px"
                                    :width="randomWidth(100)"
                                    :types="types"
                                />
                            </div>

                            <div class="right-col" style="margin-top: 14px">
                                <v-skeleton-loader
                                    type="foot"
                                    style="max-width: 150px"
                                    :width="randomWidth(100)"
                                    :types="types"
                                />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div v-else class="accounts-list">
                <transition-group name="list" tag="ul">
                    <li v-for="payment in sortedPayments()" :key="payment._id" class="list-item">
                        <router-link :to="/movimentacoes/ + payment._id" class="list-btn">
                            <div class="row">
                                <div class="left-col">
                                    <div class="item-icon">
                                        <div
                                            v-if="payment.type == 'money'"
                                            class="list-item_icon"
                                            style="background-image: url(img/payments/dinheiro.svg)"
                                        ></div>

                                        <img
                                            v-if="payment.type == 'debit'"
                                            class="list-item_icon"
                                            :src="
                                                payment.accountType === 'Conta corrente'
                                                    ? 'img/payments/banco.png'
                                                    : 'img/payments/poupanca.svg'
                                            "
                                        />

                                        <div
                                            v-if="payment.type == 'credit'"
                                            class="list-item_icon"
                                            :style="
                                                'background-image:url(img/payments/' + sanitize(payment.flag) + '.png)'
                                            "
                                        ></div>
                                    </div>

                                    <div class="item-category">
                                        <h3>{{ payment.name }}</h3>
                                        <h4 v-if="payment.type === 'money'">Dinheiro</h4>
                                        <h4 v-if="payment.type === 'debit'">Conta bancária</h4>
                                        <h4 v-if="payment.type === 'credit'">Cartão de crédito</h4>
                                    </div>
                                </div>

                                <div class="right-col">
                                    <h3 v-if="payment.type === 'credit'" class="expenses">
                                        {{ balanceActualMonth(payment._id) | currency }}
                                    </h3>

                                    <h3 v-else :class="[payment.value >= 0 ? 'investments' : 'expenses']">
                                        {{ (payment.value + getRecurrencyInvoiceSum(payment._id)) | currency }}
                                    </h3>
                                </div>
                            </div>
                        </router-link>
                    </li>
                </transition-group>
            </div>

            <div class="balances">
                <h2>Saldo contas correntes</h2>
                <v-skeleton-loader
                    v-if="waiting"
                    :height="30"
                    width="150"
                    type="legend_payments"
                    :types="{ legend_payments: 'list-item' }"
                />
                <h3
                    v-else
                    class="medium-value"
                    :class="[
                        balancePaymentsPerType('debit', 'Conta corrente') < 0
                            ? 'red'
                            : balancePaymentsPerType('debit', 'Conta corrente') === 0
                            ? 'gray'
                            : 'blue',
                    ]"
                >
                    {{ balancePaymentsPerType('debit', 'Conta corrente') | currency }}
                </h3>

                <h2>Saldo contas poupanças</h2>
                <v-skeleton-loader
                    v-if="waiting"
                    :height="30"
                    width="150"
                    type="legend_payments"
                    :types="{ legend_payments: 'list-item' }"
                />
                <h3
                    v-else
                    class="medium-value"
                    :class="[
                        balancePaymentsPerType('debit', 'Poupança') < 0
                            ? 'red'
                            : balancePaymentsPerType('debit', 'Poupança') === 0
                            ? 'gray'
                            : 'blue',
                    ]"
                >
                    {{ balancePaymentsPerType('debit', 'Poupança') | currency }}
                </h3>

                <h2>Saldo cartões de crédito</h2>
                <v-skeleton-loader
                    v-if="waiting"
                    :height="30"
                    width="150"
                    type="legend_payments"
                    :types="{ legend_payments: 'list-item' }"
                />
                <h3 v-else class="medium-value last" :class="[balancePaymentsPerType('credit') === 0 ? 'gray' : 'red']">
                    {{ balancePaymentsPerType('credit') | currency }}
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import util from '../../common/util'

export default {
    name: 'AccountsBox',
    props: {
        boxTitle: {
            type: String,
            default: 'Carteiras',
        },
    },
    data() {
        return {
            types: {
                head: 'avatar',
                arms: 'list-item-two-line',
                foot: 'list-item',

                body: 'head, arms, foot',
                body_divider: 'body, divider',
            },
        }
    },

    methods: {
        sanitize(str) {
            return util.sanitize(str)
        },

        randomWidth(base) {
            return util.randomWidth(base)
        },

        sortedPayments() {
            let payments = []

            payments.push.apply(payments, this.filteredPayments('money'))
            payments.push.apply(payments, this.filteredPayments('debit'))
            payments.push.apply(payments, this.filteredPayments('credit'))

            return payments
        },
    },

    computed: {
        ...mapState(['waiting']),

        ...mapGetters([
            'filteredPayments',
            'balancePaymentsPerType',
            'getRecurrencyInvoiceSum',
            'findTransactionInvoice',
            'balanceActualMonth',
        ]),
    },
}
</script>

<style lang="scss" scoped>
.accounts-box {
    position: relative;
    min-width: 600px;
    @include rounded-box();
    @include box-shadow();
    overflow: hidden;
    height: max-content;

    h1 {
        padding: 22px 24px 16px;
        @include card-title();
        border-bottom: 2px solid #eef1f6;
    }

    .row {
        @include flex-between();
        flex-wrap: nowrap;
        margin: 0;
    }

    .accounts-list {
        flex: 1;
        margin-bottom: 12px;
        padding-left: 24px;
        padding-right: 24px;

        .list-item {
            padding: 1em 0;
            background-color: white;

            &:not(:last-child) {
                border-bottom: solid 1px $lighter-grey;
            }

            .left-col {
                @include flex-between();
            }

            .item-icon {
                margin-right: 8px;

                .list-item_icon {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-right: 0.5em;
                }
            }

            .item-category h3 {
                margin-bottom: 4px;
                @include list-item();
                max-width: 150px;
                letter-spacing: initial;
                color: $light-black;
                font-weight: normal;
            }

            .item-category h4 {
                @include list-category();
                letter-spacing: initial;
            }

            .right-col {
                text-align: right;

                h3 {
                    margin-bottom: 4px;
                    @include std-value();
                }
            }
        }
    }

    .balances {
        width: 250px;
        padding: 1em 0 1em 24px;
        border-left: solid 2px $lighter-grey;
        text-transform: uppercase;

        h2 {
            margin-bottom: 6px;
            font-size: 13px;
            font-weight: 500;
            color: $light-grey;
        }

        h3 {
            margin-bottom: 27px;
            @include medium-value();
            color: $title-grey;

            &.last {
                margin-bottom: 0;
            }
        }

        .blue {
            color: $light-blue;
        }

        .red {
            color: $expense-red;
        }
    }

    .expenses {
        color: $expense-red;
    }

    .revenues {
        color: $revenue-green;
    }

    .investments {
        color: $light-blue;
    }

    .grey {
        color: $medium-grey;
    }
}
</style>
