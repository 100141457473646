<template>
    <div class="change-plan" :style="styles">
        <header>
            <router-link to="/painel">
                <h1>
                    <img :src="$variables.brandIcon" alt="brand" />
                </h1>
            </router-link>

            <h2>ALTERAÇÃO DE PLANO DE ASSINATURA</h2>
        </header>

        <div class="stepper">
            <svg width="180" height="35" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <title>Layer 1</title>
                    <rect fill="#B7B8B9" x="2.5" y="14.5" width="170" height="6" id="svg_3" />
                    <ellipse fill="#B7B8B9" cx="15" cy="18" id="svg_4" rx="15" ry="15" />
                    <ellipse fill="#B7B8B9" cx="165" cy="17" id="svg_5" rx="15" ry="15" />
                    <ellipse fill="#B7B8B9" cx="91" cy="17.5" id="svg_1" rx="10" ry="10" stroke="null" />
                </g>
            </svg>

            <svg class="stepper-figure" :style="stepperWidth" height="35" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <title>Layer 1</title>
                    <rect fill="#028FFF" x="2.5" y="14.5" width="170" height="6" id="svg_3" />
                    <ellipse fill="#028FFF" cx="15" cy="18" id="svg_4" rx="15" ry="15" />
                    <ellipse fill="#028FFF" cx="165" cy="17" id="svg_5" rx="15" ry="15" />
                    <ellipse fill="#028FFF" cx="91" cy="17.5" id="svg_1" rx="10" ry="10" stroke="null" />
                </g>
            </svg>
        </div>

        <form @submit.prevent="() => $refs.step.validate()">
            <v-expand-transition v-if="step < 3">
                <component
                    v-if="step < 3"
                    :is="component"
                    ref="step"
                    :newPlan="newPlan"
                    :simulation="simulation"
                    @choose-plan="(value) => (newPlan = value)"
                    @validate="validate"
                    @authorize="(authorization) => (authorize = authorization)"
                />
            </v-expand-transition>

            <v-expand-transition v-else>
                <success-msg v-bind="successData" @restart="restart" />
            </v-expand-transition>

            <basic-button
                v-if="step < 3"
                :loading="disabled"
                :disabled="!authorize"
                :label="btnLabel"
                type="submit"
                width="100%"
                bgColor="#FA7D20"
                data-cy="submit-next-step-update"
            />
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import BasicButton from '../../components/buttons/BasicButton.vue'
import SuccessMsg from './steps/SuccessMsg.vue'
import StepOne from './steps-change-plan/StepOne.vue'
import StepTwo from './steps-change-plan/StepTwo.vue'
import apiService from '../../services/api.service'
import { environment } from '../../common/environment'
import { errorHandler } from '../../common/errorHandler'
import { checkSubscription } from '../../common/checkSubscription'

export default {
    components: { SuccessMsg, BasicButton, StepOne, StepTwo },
    name: 'ChangePlan',

    data() {
        return {
            step: 1,
            authorize: true,
            newPlan: {},
            simulation: {},

            successData: {
                apiErr: false,
                msg: '',
            },

            defaultState: {
                step: 1,
                authorize: true,
                newPlan: {},
                simulation: {},

                successData: {
                    apiErr: false,
                    msg: '',
                },
            },
        }
    },

    computed: {
        ...mapState(['disabled', 'subscriptions']),

        component() {
            switch (this.step) {
                case 1:
                    return 'step-one'
                case 2:
                    return 'step-two'
                default:
                    return null
            }
        },

        btnLabel() {
            return this.step === 2 ? 'Alterar plano' : 'próximo'
        },

        stepperWidth() {
            switch (this.step) {
                case 1:
                    return { width: '16.8%' }
                case 2:
                    return { width: '56.2%' }
                default:
                    return { width: '100%' }
            }
        },

        styles() {
            return {
                '--background': `url(${this.$variables.background})`,
            }
        },
    },

    watch: {
        subscriptions: {
            deep: true,
            immediate: true,
            async handler(subscriptions) {
                if (!subscriptions.length) {
                    await checkSubscription()
                }
            },
        },
    },

    methods: {
        restart() {
            Object.assign(this, this.defaultState)
        },

        async validate(valid) {
            if (valid) {
                switch (this.step) {
                    case 1:
                        try {
                            await this.loadSimulation()
                            this.step++
                            this.authorize = false
                        } catch (e) {
                            this.successData.apiErr = true
                            this.successData.msg = e || 'Não foi possível carregar a simulação'
                            this.step = 3
                        }

                        break
                    case 2:
                        try {
                            await this.changePlan()
                            this.successData.msg = 'Sua assinatura foi alterada com sucesso!'
                            this.step++
                        } catch (e) {
                            this.successData.apiErr = true
                            this.successData.msg = e || 'Não foi possível atualizar seu plano'
                            this.step = 3
                        }
                    default:
                }
            }
        },

        async loadSimulation() {
            this.$store.commit('DISABLED')

            try {
                this.simulation = (
                    await apiService.post(`${environment.SUBSCRIPTION_URL}/change_plan`, {
                        plan: this.newPlan.plan_identifier,
                    })
                ).data
            } catch (e) {
                return Promise.reject(errorHandler(e))
            } finally {
                this.$store.commit('DISABLED')
            }
        },

        async changePlan() {
            this.$store.commit('DISABLED')

            try {
                const updateSub = (
                    await apiService.post(`${environment.SUBSCRIPTION_URL}/change_plan?mode=change`, {
                        plan: this.newPlan.plan_identifier,
                    })
                ).data

                this.$store.commit('ATT_SETED_DATA', {
                    attr: 'subscriptions',
                    data: updateSub,
                })
            } catch (e) {
                return Promise.reject(errorHandler(e))
            } finally {
                this.$store.commit('DISABLED')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
