<template>
    <div class="cancel-subscription" :style="styles">
        <header>
            <router-link to="/painel">
                <h1>
                    <img :src="$variables.brandIcon" alt="brand" />
                </h1>
            </router-link>
        </header>

        <form>
            <v-expand-transition v-if="step < 3">
                <component :is="component" ref="step" @next-step="step++" @cancel-subscription="cancelSubscription" />
            </v-expand-transition>

            <v-expand-transition v-else>
                <success-msg v-bind="successData" @restart="restart" />
            </v-expand-transition>
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { checkSubscription } from '../../common/checkSubscription'
import SuccessMsg from './steps/SuccessMsg.vue'
import StepOne from './steps-cancel/StepOne.vue'
import StepTwo from './steps-cancel/StepTwo.vue'
import apiService from '../../services/api.service'
import { environment } from '../../common/environment'
import { errorHandler } from '../../common/errorHandler'

export default {
    components: { SuccessMsg, StepOne, StepTwo },

    name: 'CancelSubscription',

    data() {
        return {
            step: 1,
            successData: {
                apiErr: false,
                msg: '',
            },

            defaultState: {
                step: 1,
                successData: {
                    apiErr: false,
                    msg: '',
                },
            },
        }
    },

    computed: {
        ...mapState(['disabled', 'subscriptions']),

        component() {
            switch (this.step) {
                case 1:
                    return 'step-one'
                case 2:
                    return 'step-two'
                default:
                    return null
            }
        },

        styles() {
            return {
                '--background': `url(${this.$variables.background})`,
            }
        },
    },

    watch: {
        subscriptions: {
            deep: true,
            immediate: true,
            async handler(subscriptions) {
                if (!subscriptions.length) {
                    await checkSubscription()
                }
            },
        },
    },

    methods: {
        restart() {
            Object.assign(this, this.defaultState)
        },

        async cancelSubscription() {
            this.$store.commit('DISABLED')

            apiService
                .post(`${environment.SUBSCRIPTION_URL}/update?status=inactive`)
                .then((response) => {
                    this.$store.commit('ATT_SETED_DATA', {
                        attr: 'subscriptions',
                        data: response.data,
                    })

                    this.successData.msg = 'Sua assinatura foi cancelada'
                })
                .catch((error) => {
                    this.successData.msg = errorHandler(error) || 'Não foi possível cancelar sua assinatura'
                    this.successData.apiErr = true
                })
                .finally(() => {
                    this.$store.commit('DISABLED')
                    this.step++
                })
        },
    },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';

header {
    margin-bottom: 32px;
}
</style>
