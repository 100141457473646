<template>
    <div class="start-rating" :style="styles"></div>
</template>

<script>
export default {
    name: 'StarRating',

    props: {
        rating: {
            type: Number,
            default: 0,
        },
        size: {
            type: Number,
            default: 30,
        },
        color: {
            type: String,
            default: '#eef1f6',
        },
        background: {
            type: String,
            default: '#fc0',
        },
    },

    computed: {
        styles() {
            return {
                '--rating': this.rating,
                '--star-size': `${this.size}px`,
                '--star-color': this.color,
                '--star-background': this.background,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.start-rating {
    --percent: calc(var(--rating) / 5 * 100%);

    display: inline-block;
    font-size: var(--star-size);
    font-family: Times; // make sure ★ appears correctly
    line-height: 1;
    margin-top: -5px;

    &::before {
        content: '★★★★★';
        letter-spacing: -3px;
        background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
</style>
