import sort from "../../common/sort"

const pluggyBelvoDictionary = {
    'itau_br_retail': 201,
    'bradesco_br_retail': 203,
    'santander_br_retail': 208,
    'bancodobrasil_br_retail': 211,
    'nubank_br_retail': 212,
    'bancointer_br_retail': 215,
    'caixa_br_retail': 219,
}

export default {
    findPayments: (state) => (bankaccessId) => {
        return state.payments.filter(payment => payment.bankAccess === bankaccessId).sort(sort.byPaymentType)
    },
    getBelvoRelatedPluggyConnectors: (state) => () => {
        return state.bankaccesses.filter(bankAccess => bankAccess.type !== 'pluggy').map((bankAccess)=>{
            return pluggyBelvoDictionary[bankAccess.institution]
        })
    },
    getAvailablePluggyConnectors: (state, getters) => () => {
        const pluggyConnectors = Object.values(pluggyBelvoDictionary)
        const belvoRelatedPluggyConnectors = getters.getBelvoRelatedPluggyConnectors()

        return pluggyConnectors.filter((connector)=>{
            if(belvoRelatedPluggyConnectors.indexOf(connector) === -1) return connector
        })
    }
}