import util from '../../common/util'
import helpers from '../helpers/getters.helper'

export default {
    // PEGA DA TRANSAÇÃO DE UMA FATURA
    getInvoiceTransaction: (state) => (invoiceId) => {
        return state.transactions.find((transaction) => transaction.invoiceReferenceId === invoiceId)
    },

    // FILTRAR TRANSAÇÕES POR GRUPO E OUTROS CAMPOS
    filteredTransactions(state) {
        return (transactionType, aditionalFilters, userId) => {
            if (transactionType === 'all') {
                return state.transactions.filter((transaction) =>
                    util.customFilter(aditionalFilters, transaction, userId)
                )
            }

            return state.transactions.filter((transaction) =>
                util.customFilter({ ...aditionalFilters, transactionType }, transaction, userId)
            )
        }
    },

    // FILTRAR TRANSAÇÕES SEM MOVIMENTAÇÕES BANCÁRIAS
    transactionsWithoutMovBanc(state, getters) {
        return (group) => {
            return getters.filteredTransactions(group).filter((transaction) => !transaction.bankingOperationReferenceId)
        }
    },

    // FILTRAR TRANSAÇÕES POR CATEGORIA E GRUPO
    filterTransactionsPerCategory(state, getters) {
        return (group, category, isBudget, userId, paymentId, nextMonthInvoices) => {
            let transactionList = getters
                .filteredTransactions(group, isBudget ? { paid: true } : undefined, userId)
                .filter((transaction) => {
                    let valid = true

                    if (isBudget)
                        return helpers.matchCategoryPlanning(transaction.category, userId, state)._id === category

                    if (paymentId) valid = transaction.payment._id === paymentId

                    if (Array.isArray(category)) return valid && category.includes(transaction.category._id)
                    return valid && transaction.category._id === category
                })

            if (group === 'expenses')
                transactionList.push(
                    ...getters.getItemsByCategory(category, isBudget, undefined, paymentId, nextMonthInvoices)
                )

            return transactionList
        }
    },

    // FILTRAR DESPESAS POR PAGAMENTO
    filterExpensesTransactionsPerPaymentId(state, getters) {
        return (payment_id) => {
            return getters.transactionsWithoutMovBanc('expenses').filter((transaction) => {
                return transaction.payment._id
                    ? transaction.payment._id === payment_id
                    : transaction.payment === payment_id
            })
        }
    },

    // ENCONTRAR A 5 DESPESAS MAIS PRÓXIMAS DO DIA ATUAL
    nextExpenses: (state, getters) => {
        let date = new Date()
        let nextDespesas = getters
            .filteredTransactions('expenses')
            .filter(
                (elem) =>
                    Number(elem.date.slice(8, 10)) >= date.getDate() && elem.bankingOperationReferenceId === undefined
            )

        return nextDespesas
    },
}
