<template>
    <div class="bank-access-card">
        <div v-if="waiting">
            <header>
                <v-skeleton-loader :width="120" type="text" />

                <div class="action-box">
                    <v-skeleton-loader :width="180" type="text" />

                    <div>
                        <v-skeleton-loader :width="80" type="text" style="margin: 0 10px 0 20px" />
                    </div>
                </div>
            </header>

            <ul>
                <li v-for="n in 3" :key="n">
                    <div>
                        <v-skeleton-loader
                            type="list_avatar"
                            :width="30"
                            :types="{ list_avatar: 'avatar' }"
                            style="margin-right: 20px"
                        />

                        <v-skeleton-loader :width="180" type="text" />
                    </div>

                    <v-skeleton-loader :width="180" type="text" />

                    <div></div>

                    <div></div>
                </li>
            </ul>
        </div>

        <div v-else>
            <header>
                <h3>{{ institution.name }}</h3>

                <div class="action-box" :style="{ '--bg-color': institution.bgColor }">
                    <small class="alert" v-if="warningShow"><img src="@/assets/img/ionic-ios-alert.svg" alt="" />{{ warningMsg }}</small>
                    <small v-else>{{ updatedAt }}</small>

                    <div>                        
                        <button v-show="!disableSync" @click="warningShow ? $emit('updatePluggyAccount', bankAccess._id) : $emit('syncAccountEvent', bankAccess)">
                            <img src="../../assets/img/bank-logos/sync.svg" alt="" />
                        </button>
                        <button @click="$emit('deleteAccountEvent', bankAccess._id)">
                            <img src="../../assets/img/bank-logos/remove.svg" alt="" />
                        </button>
                    </div>
                </div>
            </header>

            <ul>
                <li v-for="payment in findPayments(bankAccess._id)" :key="payment._id">
                    <div>
                        <i v-if="payment.type === 'credit'">
                            <img class="flag" :src="'/img/payments/' + sanitize(payment.flag) + '.png'" />
                        </i>

                        <i v-if="payment.accountType === 'Conta corrente'">
                            <img src="@/assets/img/payments/contacorrente.svg" alt="" />
                        </i>

                        <i v-if="payment.accountType === 'Poupança'">
                            <img src="/img/payments/poupanca.svg" alt="" />
                        </i>

                        <div class="bank-access-text">{{ payment.name }}</div>
                    </div>

                    <div class="bank-access-text">
                        {{ formatAccountNumber(payment.accountNumber || payment.cardNumber) }}
                    </div>

                    <div></div>

                    <div></div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import util from '../../common/util'
import ToggleSwitch from '../inputs/ToggleSwitch.vue'

export default {
    name: 'BankAccessCard',
    components: {
        ToggleSwitch,
    },
    props: {
        bankAccess: {
            type: Object,
            required: true,
        },
        disableSync: Boolean,
        warningShow: Boolean,
        warningMsg: String
    },

    computed: {
        ...mapState(['waiting']),
        ...mapGetters(['findPayments']),

        institution() {
            switch (this.bankAccess.institution) {
                case 'caixa_br_retail':
                case 'Caixa Economica Federal ':
                case 'Caixa':
                    return { name: 'Caixa Econômica', bgColor: '#1c60ab' }
                case 'nubank_br_retail':
                case 'Nubank':
                    return { name: 'Nubank', bgColor: '#612F74' }
                case 'bancodobrasil_br_retail':
                case 'Banco do Brasil':
                    return { name: 'Banco do Brasil', bgColor: '#F9DD16' }
                case 'itau_br_retail':
                case 'Itaú':
                    return { name: 'Itaú', bgColor: '#1c60ab' }
                case 'santander_br_retail':
                case 'Santander':
                    return { name: 'Santander', bgColor: '#EC0000' }
                case 'bradesco_br_retail':
                case 'Bradesco':
                    return { name: 'Bradesco', bgColor: '#EC0000' }
                case 'bancointer_br_retail':
                case 'Banco Inter':
                    return { name: 'Banco Inter', bgColor: '#EC0000' }
                default:
                    return { name: 'Conta automática', bgColor: '#028fff' }
            }
        },

        updatedAt() {
            if (this.bankAccess.lastUpdate || this.bankAccess.lastSynced) {
                let date = this.$moment(this.bankAccess.lastUpdate || this.bankAccess.lastSynced)
                let today = this.$moment()
                if (today.diff(date, 'days') < 1) {
                    const day = date.day() !== today.day() ? 'ontem' : 'hoje'

                    return `Última atualização ${day} às ${date.format('HH:mm')}`
                } else {
                    return `Última atualização há ${date.fromNow('d')}`
                }
            }

            return 'Nunca foi atualizada'
        },
    },

    methods: {
        sanitize(str) {
            return util.sanitize(str)
        },

        formatAccountNumber(str) {
            return str.replace(/[X]/g, '*')
        },
    },
}
</script>

<style lang="scss" scoped>
.bank-access-card {
    width: 100%;
    box-shadow: 0px 0px 6px #00000027;
    border-radius: 0px 0px 2px 2px;
    margin-bottom: 16px;

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 4px 0 16px;
        background: $list-blue;
        height: 40px;

        h3 {
            font-weight: 500;
            color: $darker-blue;
        }

        .action-box {
            position: relative;
            margin: unset;
            padding: unset;
            display: flex;
            align-items: center;
            min-width: 200px;
            justify-content: space-around;
            .alert {
                color: red;
            }
            .alert img {
                width: 15px;
                margin-right: 5px;
            }

            small {
                font-size: 13px;
                color: $blueish-gray;
                position: relative;
                bottom: 2px;
                margin-right: 5px;
            }
            .loader {
                font-size: 14px;
                margin: 0 30px;
                color: var(--bg-color);
            }
        }

        button {
            background: transparent;
            box-shadow: none;
            color: #5b6772;
            opacity: 0.5;
            transition: all 0.3s ease-in-out;

            &:hover {
                opacity: 1;
            }
        }
    }

    ul {
        li {
            display: grid;
            grid-template-columns: 4fr 3fr 1fr;
            grid-template-rows: 1fr;
            gap: 0 16px;
            height: 56px;
            align-items: center;
            padding: 0 24px;
            position: relative;

            & > div {
                &:first-child {
                    display: flex;
                    align-items: center;
                }

                &:nth-child(3) {
                    justify-self: flex-end;
                }

                &:last-child {
                    box-sizing: border-box;
                    border: 1px solid #eef1f6;
                    position: absolute;
                    width: 730px;
                    bottom: 0;
                    left: 20px;
                }

                img {
                    width: 24px;
                    margin-right: 18px;
                }
            }

            &:last-child {
                & > div {
                    &:last-child {
                        border: none;
                    }
                }
            }

            .bank-access-text {
                color: $light-black;
            }
        }
    }
}
</style>
