<template>
    <div class="dashboard view-container">
        <div class="view-content">
            <div class="top-row">
                <!-- CONSIDERA TODOS OS USUÁRIOS QUANOD FAMILY -->
                <transaction-card
                    type="balance"
                    :totalValue="balances.currentBalance"
                    :budgetValue="balancePreviousMonths"
                    :remainValue="balanceTotalMonth(undefined)"
                    :switchingEye="true"
                    :hide="hideValues"
                    @hide-info="hideCardValues"
                />

                <transaction-card
                    type="revenues"
                    :totalValue="balanceMonthExpectedPerType('revenues')"
                    :budgetValue="balanceMonthPerType('revenues', true)"
                    :remainValue="balanceMonthPerType('revenues', false)"
                    :hide="hideValues"
                />

                <transaction-card
                    type="expenses"
                    :totalValue="balanceMonthExpectedPerType('expenses')"
                    :budgetValue="balanceMonthPerType('expenses', true)"
                    :remainValue="balanceMonthPerType('expenses', false)"
                    :hide="hideValues"
                />

                <transaction-card
                    type="investments"
                    :totalValue="balancePaymentsPerType('debit', 'Poupança')"
                    :budgetValue="balanceInvestments"
                    :remainValue="0"
                    :hide="hideValues"
                />
            </div>
            <!-- /.top-row -->

            <div class="main-content-row">
                <div class="left-col flex">
                    <chart-box
                        style="min-width: 600px"
                        class="card-mt"
                        boxTitle="Receitas x Despesas (Diário)"
                        chart="LineChart"
                        :chartSeries="daylyLineChart"
                        :chartOptions="getDays()"
                        :loading="loadings.daylyChart"
                    />

                    <chart-box
                        style="height: 250px"
                        class="card-mt"
                        :width="'240'"
                        boxTitle="Gastos por categoria"
                        chart="CategoryChart"
                        :chartSeries="categories.chartSeries"
                        :chartOptions="categories.chartOptions"
                        :chartProps="{
                            ...this.donutDimensions,
                            categoriesID: categories.chartCategoriesID,
                            modalEvent: true,
                        }"
                        :modalEvent="modalEvent"
                    />

                    <chart-box
                        style="height: 250px"
                        boxTitle="Uso do orçamento"
                        chart="CategoryChart"
                        :chartSeries="budget.chartSeries"
                        :chartOptions="budget.chartOptions"
                        :chartProps="{ ...this.donutDimensions }"
                        :modalEvent="false"
                    />
                </div>

                <div class="center-col flex">
                    <next-transactions-list
                        class="card-mt next-transactions"
                        cardTitle="próximos pagamentos"
                        transactionType="expenses"
                        :transactionsList="nextExpenses"
                        :user="user"
                    />

                    <accounts-box style="flex: 1" :userPayments="payments" />
                </div>
            </div>
            <!-- /.main-content-row -->
        </div>
        <!-- /.content -->

        <Onboarding v-if="!user.skipTutorial && !waiting" />
    </div>
    <!-- /.container -->
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import formatDate from '../common/formatDate'
import Onboarding from '../components/Onboarding'
import TransactionCard from '../components/cards/TransactionCard.vue'
import NextTransactionsList from '../components/lists/NextTransactionsList'
import ChartBox from '../components/cards/ChartBox.vue'
import AccountsBox from '../components/cards/AccountsBox'
import ChatWindow from '../components/ChatWindow'
import ActionMenu from '../components/ActionMenu'
import authService from '../services/auth.service'
import request from '../common/request'

export default {
    name: 'Dashboard',
    components: {
        Onboarding,
        TransactionCard,
        NextTransactionsList,
        ChartBox,
        AccountsBox,
        ChatWindow,
        ActionMenu,
    },

    data() {
        return {
            objectives: {},
            categories: {
                chartSeries: [],
                chartOptions: {
                    plotOptions: {
                        pie: {
                            offsetY: 5,
                            offsetX: 0,
                        },
                    },
                },
                chartCategoriesID: [],
            },
            budget: {
                chartOptions: {},
                chartSeries: [],
            },
            donutDimensions: { width: '300', height: 180 },

            modalEvent: true,

            hideValues: !!authService.getOnLocalStorage('hideDashboardCardValues'),

            loadings: {
                daylyChart: true,
            },
        }
    },

    methods: {
        hideCardValues() {
            if (!this.hideValues) {
                authService.saveOnLocalStorage('hideDashboardCardValues', 'hide')
                this.hideValues = true
                return
            }

            authService.removeFromLocalStorage('hideDashboardCardValues')
            this.hideValues = false
            return
        },

        getDays() {
            let chartOptions = {
                xaxis: {
                    categories: formatDate.getDays(this._date.inicio),
                },
            }
            return chartOptions
        },

        attExpensesChart() {
            let options = this.categoriesChartOptions('expenses')

            if (options.chartSeries.length < 1) {
                this.categories.chartSeries = [1]
                this.categories.chartOptions = {
                    labels: ['Sem gastos'],
                    colors: ['#DCDCDC'],
                    states: {
                        hover: {
                            filter: {
                                type: 'none',
                            },
                        },
                    },
                    plotOptions: {
                        pie: {
                            expandOnClick: false,
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                }
            } else {
                this.categories.chartSeries = options.chartSeries
                this.categories.chartOptions = Object.assign(this.categories.chartOptions, options.chartOptions)
                this.categories.chartCategoriesID = [...options.categories_id]
                this.modalEvent = true
            }
        },

        attBudgetChart() {
            if (!this.budgets._id) {
                ;(this.budget.chartSeries = [1]),
                    (this.budget.chartOptions = {
                        labels: ['Sem orçamento'],
                        colors: ['#DCDCDC'],
                        states: {
                            hover: {
                                filter: {
                                    type: 'none',
                                },
                            },
                        },
                        plotOptions: {
                            pie: {
                                offsetY: 5,
                                offsetX: 0,
                                expandOnClick: false,
                            },
                        },
                        tooltip: {
                            enabled: false,
                        },
                    })
            } else {
                ;(this.budget.chartOptions = {
                    labels: ['Essenciais', 'Dispensáveis', 'Investimentos', 'Não-reservado'],
                    colors: ['#37C2CE', '#EF8A97', '#63B9F2', '#9FB6CD'],
                    plotOptions: {
                        pie: {
                            offsetY: 5,
                            offsetX: 0,
                        },
                    },
                }),
                    (this.budget.chartSeries = [
                        this.essentialIncome,
                        this.noessentialIncome,
                        this.investimentIncome,
                        this.remainIncome,
                    ])
            }
        },

        loadDaylyInvoiceData() {
            return request.fetch({
                collection: 'invoices',
                attr: 'invoicesWithoutFiltering',
                queryParams: { ignoreInvoicePeriod: true, inicio: this._date.inicio },
            })
        },
    },

    watch: {
        transactions() {
            this.attExpensesChart()
        },

        essentialIncome() {
            this.attBudgetChart()
        },
    },

    computed: {
        ...mapState(['balances', 'balancesChart', '_date', 'payments', 'transactions', 'user', 'waiting', 'budgets']),

        ...mapGetters([
            'balancePreviousMonths',
            'balanceMonthExpectedPerType',
            'balancePaymentsPerType',
            'balanceMonthPerType',
            'balanceInvestments',
            'balanceTotalMonth',
            'nextExpenses',
            'categoriesChartOptions',
            'daylyLineChart',
        ]),

        balance() {
            return this.balanceMonthExpectedPerType('revenues', null)
        },

        essentialIncome() {
            return this.balance * (this.budgets.essentialSpending / 100 || 0)
        },

        noessentialIncome() {
            return this.balance * (this.budgets.noessentialSpending / 100 || 0)
        },

        investimentIncome() {
            return this.balance * (this.budgets.investimentSpending / 100 || 0)
        },

        remainIncome() {
            return this.balance - this.essentialIncome - this.noessentialIncome - this.investimentIncome
        },
    },

    async mounted() {
        this.attExpensesChart()
        this.attBudgetChart()

        try {
            await this.loadDaylyInvoiceData()
            this.loadings.daylyChart = false
        } catch (error) {
            console.error(error)
        }
    },
}
</script>

<style lang="scss" scoped>
.flex-between {
    @include flex-between();
}

.card-mt {
    margin-right: 24px;
}

.next-transactions {
    width: 320px;
    max-height: 350px;
}

.top-row {
    padding-bottom: 24px;
    display: flex;
    border-bottom: solid 1px $lighter-grey;
    width: max-content;
}

.main-content-row {
    display: flex;
    flex-direction: column;
    padding: 24px 0;

    .left-col {
        margin-bottom: 24px;
    }

    .right-col {
        width: 340px;
        position: relative;
    }
}

.flex {
    display: flex;
}

.grow-1 {
    flex-grow: 1;
}

.grow-2 {
    flex: 1;
}

.banner {
    width: 354px;
    padding: 20px;
    background: $deep-blue;
    text-align: center;

    h3 {
        margin-bottom: 15px;
        font-weight: bold;
        font-size: 20px;
        color: #ffffff;
    }

    p {
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 1.2;
        color: #ffffff;
    }

    .cta-btn {
        padding: 10px 20px;
        background: #37c2ce;
        border-radius: 67px;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
    }
}

.flex-expand {
    flex: 1;
}
</style>
