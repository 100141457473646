<template>
    <div class="step-one step">
        <div class="info-box changeplan" v-if="Object.keys(actualPlan).length > 1">
            <h2>SEU PLANO ATUAL</h2>
            <h3>{{ actualPlan.name }}</h3>
            <p>
                <span class="blue-text">R$ {{ actualPlan.price }} {{ actualPlan.period }}</span
                >, todo dia <span class="blue-text">{{ actualDate(actualPlan.period) }}</span>
            </p>
        </div>

        <stacked-select
            label="Novo Plano"
            :notTransaction="true"
            :model="activePlans"
            @change="setPlan"
            :errorValidation="validation.hasError('plan.name')"
            dataCy="update-select-plan2"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { environment } from '../../../common/environment'
import StackedSelect from '../../../components/inputs/StackedSelect.vue'
import apiService from '../../../services/api.service'
import SimpleVueValidation from 'simple-vue-validator'

const Validator = SimpleVueValidation.Validator

export default {
    components: { StackedSelect },
    name: 'StepOne',

    data() {
        return {
            plans: [],
            plan: {
                name: '',
            },
        }
    },

    async mounted() {
        try {
            this.plans = (await apiService.get(`${environment.API_URL}plans`)).data
        } catch (e) {
            console.error(e)
            this.plans = []
        }
    },

    computed: {
        ...mapState(['subscriptions']),

        subscription() {
            return this.subscriptions[0] || {}
        },

        actualPlan() {
            const { plan } = this.subscription

            return this.plans.find((p) => p.plan_identifier === plan) || {}
        },

        activePlans() {
            return this.plans.filter((p) => !p.inactive && p.plan_identifier !== this.actualPlan.plan_identifier)
        },
    },

    methods: {
        setPlan(plan) {
            this.plan = { ...plan }
            this.$emit('choose-plan', plan)
        },

        async validate() {
            await this.validation.reset()

            this.$emit('validate', await this.$validate())
        },

        actualDate(period) {
            if (period === 'anualmente') {
                return this.$moment(this.subscription.expireAt).format('DD/MM')
            } else {
                return new Date().getDate()
            }
        },
    },

    validators: {
        'plan.name': function (value) {
            return Validator.value(value).required()
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../steps/styles.scss';
</style>
