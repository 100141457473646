export default {
    // texto
    brand: process.env.VUE_APP_BRAND_NAME,

    // imagens
    logo: require(process.env.VUE_APP_LOGO),
    brandIcon: require(process.env.VUE_APP_BRAND),
    background: require(process.env.VUE_APP_BACKGROUND_BG),
    chatIcon: require(process.env.VUE_APP_CHAT_ICON),
    listIcon: require(process.env.VUE_APP_LIST_ICON),
    placeholder: require(process.env.VUE_APP_PLACEHOLDER_IMG),

    //links
    usageTerms: process.env.VUE_APP_USAGE_TERMS,
    privatePolicy: process.env.VUE_APP_PRIVATE_POLICY,
}
