<template>
    <div class="edit-linked-transactions transactions-modal">
        <form @submit.prevent.self="checkForm" class="container modal">
            <header class="item head" :class="[type]">
                <h1>{{ 'Editar ' + this.type }}</h1>

                <button
                    data-cy="close-transactions-modal"
                    class="close"
                    type="button"
                    @click.stop="$emit('modalClosedEvent')"
                >
                    <ion-icon name="close"></ion-icon>
                </button>
            </header>

            <div>
                <div class="modal-body">
                    <!-- VALOR -->
                    <div class="container">
                        <stacked-input
                            label="Valor"
                            ref="value"
                            focus
                            textAlign="right"
                            :required="true"
                            :money="money"
                            :font="24"
                            v-model="update.value"
                        />
                    </div>

                    <div class="container">
                        <!-- NOME -->
                        <stacked-input
                            :label="`Descrição ${type !== 'investimento' ? `da ${type}` : `do ${type}`}`"
                            ph="Insira aqui seu texto"
                            :required="true"
                            v-model="update.transaction.name"
                            ref="name"
                        />

                        <!-- CATEGORIA -->
                        <div class="modal-row">
                            <stacked-select
                                label="Categoria"
                                ref="categories"
                                :model="categories"
                                :isGroupSelect="type === 'despesa'"
                                :required="true"
                                :previousSelected="previousCategory"
                                @change="(value) => (update.transaction.category = value)"
                                data-cy="select-category"
                            />

                            <stacked-select
                                label="Dia"
                                ref="day"
                                :model="getDays"
                                :required="true"
                                :previousSelected="previousDay"
                                :notTransaction="true"
                                @change="(value) => (update.day = value)"
                                data-cy="select-dia"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <footer class="modal-footer">
                <basic-button
                    data-cy="submit-modal"
                    :label="`Editar ${this.type}`"
                    type="submit"
                    :loading="disabled"
                    :bgColor="btnBgColor"
                    :width="300"
                />
            </footer>
        </form>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { errorHandler } from '../../common/errorHandler'
import request from '../../common/request'
import util from '../../common/util'
import BasicButton from '../buttons/BasicButton.vue'
import ModalFrame from '../../containers/ModalFrame.vue'
import StackedInput from '../inputs/StackedInput.vue'
import StackedSelect from '../inputs/StackedSelect.vue'

export default {
    components: { StackedInput, StackedSelect, ModalFrame, BasicButton },

    name: 'EditLinkedTransaction',

    props: {
        editData: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            update: {
                transaction: {},
            },
            type: '',
            previousDay: undefined,
            previousCategory: undefined,

            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: false,
            },
        }
    },

    computed: {
        ...mapState(['_date_selected', 'disabled', 'user']),

        ...mapGetters(['groupedCategoriesByType', 'filteredCategories']),

        getDays() {
            return this.$moment(this._date_selected.inicio).daysInMonth()
        },

        btnBgColor() {
            switch (this.type) {
                case 'investimento':
                    return '#63b9f2'
                case 'despesa':
                    return '#ef8a97'
                default:
                    return '#37C2CE'
            }
        },

        categories() {
            switch (this.type) {
                case 'despesa':
                    return this.groupedCategoriesByType('expenses')
                case 'receita':
                    return this.filteredCategories('revenues', this.user._id)
                case 'investimento':
                    return this.filteredCategories('investiments', this.user._id)
                default:
                    return []
            }
        },
    },

    mounted() {
        const { _links, __v, _id, createdAt, updatedAt, transaction, value, day, ...data } = this.editData

        this.update = { ...data }
        this.update.transaction = { ...transaction }
        this.previousCategory = transaction.category
        this.update.day = day > this.getDays ? this.getDays : day
        this.previousDay = this.update.day
        this.update.value = Math.abs(value).toFixed(2)

        this.type = util.translateTransaction(transaction.transactionType)
    },

    methods: {
        checkValue() {
            let value = Number(util.sanitizeMoney(this.update.value))

            if (this.editData.transaction.transactionType === 'expenses') value *= -1

            return value
        },

        editLinkedTransaction() {
            this.$store.commit('DISABLED')

            this.update.value = this.checkValue()

            request
                .patch({ collection: 'linkedtransactions', data: this.update, _id: this.editData._id })
                .then(() => {
                    this.$emit('show-snack-bar', {
                        message: 'Item alterado com sucesso',
                    })

                    this.$emit('modalClosedEvent')
                })
                .catch((error) => {
                    this.$emit('show-snack-bar', {
                        message: errorHandler(error) || 'Não foi possível editar este item',
                        status: 'fail',
                    })

                    this.$emit('modalClosedEvent')
                })
                .finally(() => this.$store.commit('DISABLED'))
        },

        checkForm() {
            let valid = true

            const value = Number(util.sanitizeMoney(this.update.value))
            const { name } = this.update.transaction

            if (!value) {
                this.$refs.value.err = true
                valid = false
            }

            if (!name) {
                this.$refs.name.err = true
                valid = false
            }

            if (valid) this.editLinkedTransaction()
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/transactions-modal.scss';

.edit-linked-transactions {
    .modal-row {
        display: flex;
        justify-content: space-between;

        & > .stacked-select {
            width: 48%;
        }
    }
}
</style>
