var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex column"},_vm._l((_vm.topTransactions),function(tr){return _c('tr',{key:tr._id,attrs:{"data-cy":"category-list"}},[_c('td',{staticClass:"description row space-between"},[_c('div',{staticClass:"row",staticStyle:{"vertical-align":"middle"}},[_c('img',{staticClass:"avatar item",class:[
                        _vm.sanitize(tr.category.name),
                        tr.category.group,
                    ],attrs:{"src":require('@/assets/img/categories/' +
                            _vm.sanitize(tr.category.group) +
                            '-' +
                            _vm.sanitize(tr.category.name) +
                            '.png')}}),_c('div',[_c('span',{staticClass:"decription font-regular"},[_vm._v(_vm._s(tr.category.name))])])]),_c('span',{staticClass:"font-semibd"},[_vm._v(" "+_vm._s(_vm._f("currency")(Math.abs(tr.value)))+" ")])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }