<template>
    <div class="card-body" :class="{ 'auto-margin': giftedPremium }">
        <div v-if="!giftedPremium" class="actual-payment" :class="{ 'no-width': !getPayment.icon }">
            <h3>Pago com {{ getPayment.type }}</h3>

            <!-- Recuperar essas informações da API do Iugu -->
            <div :style="getPayment.styles">
                <ion-icon v-if="getPayment.icon" name="alert-circle"></ion-icon>

                <span>{{ getPayment.text }}</span>

                <img
                    v-if="getPayment.type === 'cartão de crédito'"
                    :src="`/img/payments/${getPayment.brand}.png`"
                    :alt="getPayment.brand"
                />
            </div>
        </div>

        <div v-else class="gifted-premium-message" v-html="planMessage"></div>
    </div>
</template>

<script>
export default {
    name: 'CardBody',

    props: {
        subscription: {
            type: Object,
            required: true,
        },
    },

    computed: {
        giftedPremium() {
            return this.subscription.plan === 'otto-company' || this.subscription.plan === 'otto-family-member'
        },

        getPayment() {
            let payload = {
                icon: true,
                text: 'Nenhuma forma de pagamento vinculada',
                styles: {
                    '--payment-text-color': '#bac0cc',
                    '--payment-font-size': '14px',
                },
            }

            /* Tratar aqui o texto de apresentação do dados do pagamento */

            switch (this.subscription.paymentType) {
                case 'credit_card':
                    const paymentMethod = this.subscription.paymentMethod.find((pm) => pm.active === true)

                    payload.icon = false
                    payload.type = 'cartão de crédito'
                    payload.text = this.formatCardNumber(paymentMethod.maskedNumber)
                    payload.brand = this.parseCardBrand(paymentMethod.brand)
                    payload.styles['--payment-text-color'] = 'black'
                    payload.styles['--payment-font-size'] = '24px'

                    break
                case 'pix':
                case 'bank_slip':
                    payload.text = this.subscription.paymentType === 'bank_slip' ? 'Boleto bancário' : 'Pix'
                    payload.styles['--payment-text-color'] = 'black'
                    payload.styles['--payment-font-size'] = '24px'
                    payload.icon = false
                    delete payload['type']
            }

            return payload
        },

        planMessage() {
            if (this.subscription.plan === 'otto-company') {
                return `Seu acesso Premium é um benefício oferecido pela <strong>${this.subscription.company.name}</strong> aos seus
            colaboradores. Aproveite!`
            }

            return `Seu acesso Premium é um benefício adquirido por ser um membro da família <strong>${this.subscription.family.name}</strong>!`
        },
    },

    methods: {
        parseCardBrand(brand) {
            const parsedBrand = brand.toLowerCase()

            if (parsedBrand === 'amex') return 'americanexpress'
            else if (parsedBrand === 'master') return 'mastercard'
            return parsedBrand
        },

        formatCardNumber(cardNumber) {
            return cardNumber.replace(/X/g, '*').replace(/-+/g, ' ')
        },
    },
}
</script>

<style lang="scss" scoped>
.card-body {
    &.auto-margin {
        margin: auto;
    }

    .actual-payment {
        width: 100%;
        height: 65px;
        margin-top: 10px;

        &.no-width {
            width: unset;
        }

        h3 {
            font-size: 14px;
            color: $darker-blue;
            font-weight: 600;
            margin-bottom: 5px;
        }

        h4 {
            margin: 5px 0 0;
            font-size: 14px;
        }

        div {
            display: flex;
            align-items: center;
            color: var(--payment-text-color);
            margin-top: 0;
            padding-left: 20px;
            width: 100%;

            ion-icon {
                font-size: 25px;
                margin-right: 5px;
            }

            img {
                width: 40px;
                height: 40px;
                object-fit: contain;
                margin-left: 10px;
            }

            span {
                font-size: var(--payment-font-size);
                font-weight: 500;
            }
        }
    }

    .gifted-premium-message {
        text-align: center;
        margin: auto;
        font-size: 14px;
        line-height: 19px;
        color: $darker-blue;
    }
}
</style>
