var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"transactions-list"},[_c('h1',[_vm._v(_vm._s(_vm.cardTitle))]),(_vm.waiting)?_c('ul',{staticClass:"t-list"},_vm._l((5),function(n,index){return _c('li',{key:index,staticStyle:{"height":"60px"}},[_c('div',{staticClass:"list-item",staticStyle:{"padding":"0"}},[_c('div',{staticClass:"left-col"},[_c('v-skeleton-loader',{staticStyle:{"margin":"auto"},attrs:{"type":"head","types":_vm.types}}),_c('v-skeleton-loader',{staticStyle:{"max-width":"150px"},attrs:{"type":"arms","width":_vm.randomWidth(100),"types":_vm.types}})],1),_c('div',{staticClass:"value-payment"},[_c('v-skeleton-loader',{staticStyle:{"max-width":"120px"},attrs:{"type":"foot","width":_vm.randomWidth(70),"types":_vm.types}})],1)]),_c('div',{staticClass:"border"})])}),0):(_vm.sortedTransactions.length > 0 && !_vm.waiting)?_c('transition-group',{staticClass:"t-list",attrs:{"name":"list","tag":"ul"}},_vm._l((_vm.sortedTransactions),function(transaction){return _c('li',{key:transaction._id},[_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"left-col"},[_c('div',{staticClass:"item-icons"},[_c('div',{staticClass:"list-item_icon",class:[
                                transaction.paid
                                    ? _vm.sanitize(_vm.getDefaultCategoryById(transaction.category).name)
                                    : 'unpaid',
                                transaction.category.group,
                            ],style:({
                                backgroundImage:
                                    'url(' +
                                    require('@/assets/img/categories/' +
                                        _vm.sanitize(transaction.category.group) +
                                        '-' +
                                        _vm.sanitize(_vm.getDefaultCategoryById(transaction.category).name) +
                                        '.png') +
                                    ')',
                            })}),(_vm.isOttoFamily)?_c('div',{staticClass:"family-user"},[_c('avatar',{attrs:{"avatar":transaction.user.image,"letter":transaction.user.name.charAt(0)}})],1):_vm._e()]),_c('div',{staticClass:"item-category"},[_c('span',[_vm._v(_vm._s(transaction.name))]),_c('h4',[_vm._v(" "+_vm._s(transaction.category.name)+" ")])])]),_c('div',{staticClass:"value-payment"},[_c('h3',{staticClass:"expenses"},[_vm._v(_vm._s(_vm._f("currency")(Math.abs(transaction.value))))]),_c('h4',[_vm._v(_vm._s(transaction.payment.name))])])]),_c('div',{staticClass:"border"})])}),0):_c('ul',[_vm._m(0)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"t-list"},[_c('div',{staticClass:"border"}),_c('div',{staticClass:"list-item"},[_c('p',{staticClass:"no-data"},[_vm._v("Nenhum pagamento para amanhã")])])])
}]

export { render, staticRenderFns }