<template>
    <login-container>
        <transition name="fade">
            <form id="register" class="screen-container" v-show="showRegister">
                <div v-if="disabled" class="loader"></div>

                <span v-else-if="!errorMessage" class="guide-message">
                    {{ ottoMsg }}
                </span>

                <span v-else class="guide-message error-message">
                    {{ errorMessage }}
                </span>

                <stacked-input
                    label="Seu primeiro nome"
                    v-model="name"
                    :required="true"
                    :errorValidation="errors.name.error"
                    :errorMsg="errors.name.message"
                    name="name-register"
                />

                <stacked-input
                    label="Seu e-mail"
                    type="email"
                    v-model="email"
                    :required="true"
                    :errorValidation="errors.email.error"
                    :errorMsg="errors.email.message"
                    name="email-register"
                />

                <stacked-input
                    label="Sua senha"
                    type="password"
                    ref="password"
                    :showEyeButton="true"
                    v-model="password"
                    :required="true"
                    :errorValidation="errors.password.error"
                    :errorMsg="errors.password.message"
                    name="password-register"
                />

                <div class="terms">
                    <input data-cy="checkbox-register" type="checkbox" name="terms" v-model="terms" />
                    <label for="terms"
                        >Li e concordo com os
                        <a :href="$variables.usageTerms" target="_blank"> Termos de uso</a>
                    </label>
                </div>

                <basic-button data-cy="submit-register" @click.native="validate" label="Cadastrar conta" />

                <div class="social-login-title">
                    <hr />
                    Ou cadastre-se com redes sociais
                    <hr />
                </div>

                <div class="btn-group">
                    <button class="external-button facebook-button" @click.prevent="socialRegister('facebook')">
                        <ion-icon name="logo-facebook"></ion-icon>
                    </button>
                    <button class="external-button google-button" @click.prevent="socialRegister('google')">
                        <ion-icon name="logo-google"></ion-icon>
                    </button>
                    <button class="external-button apple-button" @click.prevent="socialRegister('apple')">
                        <ion-icon name="logo-apple"></ion-icon>
                    </button>
                </div>

                <basic-button
                    data-cy="toggle-login"
                    :reverse="true"
                    type="button"
                    @click.native="$router.push('/login')"
                    >Voltar ao login</basic-button
                >
            </form>
        </transition>

        <transition name="fade">
            <form id="register-social" class="screen-container" v-show="showExtraRegister">
                <div v-if="disabled" class="loader"></div>

                <span v-else-if="!errorMessage" class="guide-message">
                    {{ ottoMsg }}
                </span>

                <span v-else class="guide-message error-message">
                    {{ errorMessage }}
                </span>

                <stacked-input
                    label="Seu nome"
                    v-model="name"
                    :required="true"
                    :errorValidation="errors.name.error"
                    :errorMsg="errors.name.message"
                    name="name-register"
                />

                <stacked-input v-if="showSecondEmailInput" label="Email" v-model="userExtraData.socialEmail" />

                <stacked-input label="Seu CPF" v-mask="'###.###.###-##'" v-model="userExtraData.cpf" />

                <stacked-input
                    label="Data de nascimento"
                    type="date"
                    style="width: 50%; justify-self: flex-start"
                    v-model="userExtraData.birthdate"
                />

                <div class="flex column space">
                    <div class="radio-title">Seu gênero</div>
                    <div>
                        <input type="radio" id="male" value="Male" v-model="userExtraData.gender" name="gender" />
                        <label class="radio-label" for="male">Masculino</label>

                        <input type="radio" id="female" value="Female" v-model="userExtraData.gender" />
                        <label class="radio-label" for="female">Feminino</label>
                    </div>
                </div>

                <stacked-input
                    label="Número de telefone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    v-model="userExtraData.phoneNumber"
                />

                <stacked-input label="Cidade onde reside" v-model="userExtraData.address.city" />

                <div class="terms">
                    <input data-cy="checkbox-register" type="checkbox" name="terms" v-model="terms" />
                    <label for="terms"
                        >Li e concordo com os
                        <a :href="$variables.usageTerms" target="_blank"> Termos de uso</a>
                    </label>
                </div>

                <basic-button data-cy="submit-register" @click.native="validate" label="Salvar" />

                <basic-button
                    data-cy="toggle-login"
                    :reverse="true"
                    type="button"
                    @click.native="$router.push('/login')"
                    >Voltar ao login</basic-button
                >
            </form>
        </transition>
    </login-container>
</template>

<script>
import { mapState } from 'vuex'
import BasicButton from '../components/buttons/BasicButton.vue'
import StackedInput from '../components/inputs/StackedInput.vue'
import LoginContainer from '../containers/LoginContainer.vue'
import validation from '../common/validation'
import authService from '../services/auth.service'
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, signInWithPopup, signOut } from '@firebase/auth'

const errors = {
    email: {
        error: false,
        message: undefined,
    },
    password: {
        error: false,
        message: undefined,
    },
    name: {
        error: false,
        message: undefined,
    },
}

export default {
    components: { StackedInput, LoginContainer, BasicButton },
    name: 'SignUp',

    data() {
        return {
            ottoMsg: `Informe seus dados para cadastrar uma nova conta com o ${this.$variables.brand}`,
            showRegister: false,
            showExtraRegister: false,
            isSocialRegister: false,

            email: '',
            password: undefined,
            name: '',
            terms: false,
            socialUid: undefined,
            socialLogin: undefined,
            facebookUserId: undefined,

            userExtraData: {
                address: {
                    city: undefined,
                },
            },

            errorMessage: '',
            errors: {
                ...errors,
            },
        }
    },

    computed: {
        ...mapState(['disabled']),

        showSecondEmailInput() {
            return !['google.com', 'apple.com'].includes(this.socialLogin)
        },
    },

    mounted() {
        setTimeout(() => {
            this.showRegister = true
        }, 300)
    },

    methods: {
        validate() {
            let validEmail = validation.validateEmail(this.email)

            // Limpando o efeito dos inputs
            this.errors = { ...errors }

            // Validações de erro
            if (!this.name) {
                this.errorMessage = 'Por favor insira seu nome!'
                this.errors.name.error = true
                this.errors.name.message = ''
                return
            }

            if (validEmail === false) {
                this.errorMessage = 'Por favor insira um email válido!'
                this.errors.email.error = true
                this.errors.email.message = ''
                return
            }

            this.email = this.email.trim()

            if (!this.socialUid) {
                if (!this.password) {
                    this.errorMessage = 'Por favor insira uma senha!'
                    this.errors.password.error = true
                    this.errors.password.message = ''
                    return
                }

                if (this.password.length < 8) {
                    this.errorMessage = 'Senha precisa no min. 8 caractéres'
                    this.$refs.password.err = true
                    this.errors.password.message = ''
                    return
                }
            }

            if (!this.terms) {
                this.errorMessage = 'Verifique nossos termos de uso'
                return
            }

            this.register()
        },

        register() {
            // Successo
            const { email, password, name, socialLogin, socialUid, facebookUserId, userExtraData } = this

            let userInformation = {
                email: email.toLowerCase(),
                password,
                name,
                socialLogin,
                socialUid,
                facebookUserId,
                ...userExtraData,
            }

            if (userInformation['birthdate'])
                userInformation['birthdate'] = new Date(userInformation['birthdate']).toISOString()

            authService.postUser(userInformation).then((result) => {
                const { status, message, code } = result
                this.errorMessage = ''
                this.terms = false

                switch (status) {
                    case 200:
                        this.autoLogin()

                        break
                    case 400:
                        if (code == 11000) {
                            this.errorMessage = 'Já existe uma conta com este email!'
                            this.email = this.password = ''
                        } else {
                            this.errorMessage = 'Erro ao efetuar o cadastro. Por favor tente novamente mais tarde.'
                            this.email = this.password = ''
                        }

                        if (this.isSocialRegister) this.logoutOnFirebase()

                        break
                    default:
                        this.errorMessage = 'Erro ao efetuar o cadastro. Por favor tente novamente mais tarde.'
                        this.email = this.password = ''
                }
            })
        },

        getProviderUid(providerData, providerId) {
            return providerData.find((data) => data.providerId === providerId).uid
        },

        setUserRegister(firebaseUser, providerId) {
            const { displayName, uid, email, providerData } = firebaseUser

            this.name = displayName || 'Anônimo'
            this.email = email
            this.socialUid = uid
            this.socialLogin = providerId

            console.log(firebaseUser)

            if (providerId === 'facebook.com') {
                if (email) this.userExtraData.socialEmail = email
                this.facebookUserId = this.getProviderUid(providerData, providerId)
            }

            this.nextStep()
        },

        autoLogin() {
            let { email, password } = this

            if (this.isSocialRegister) {
                password = this.socialUid
                if (this.socialLogin === 'facebook.com') email = `${this.facebookUserId}@ottoSocial.com`
            }

            authService.authenticate({ email, password }).catch((e) => console.error(e))
        },

        logoutOnFirebase() {
            signOut(this.$firebase.auth).catch((error) => console.error(error))
        },

        nextStep() {
            this.ottoMsg = 'Complete suas informações para termos uma experiência mais personalizada'

            this.errors = { ...errors }
            this.errorMessage = ''
            this.showRegister = false
            this.showExtraRegister = true
        },

        socialRegister(provider) {
            this[`${provider}Register`]()
        },

        googleRegister() {
            signInWithPopup(this.$firebase.auth, this.$firebase.googleProvider)
                .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result)
                    const token = credential.accessToken
                    // The signed-in user info.
                    const user = result.user

                    this.isSocialRegister = true

                    this.setUserRegister(user, result.providerId)
                })
                .catch((error) => {
                    const errorMessage = error.message
                    this.errorMessage = errorMessage
                })
        },

        appleRegister() {
            signInWithPopup(this.$firebase.auth, this.$firebase.appleProvider)
                .then((result) => {
                    // The signed-in user info.
                    const { user } = result

                    // Apple credential
                    const credential = OAuthProvider.credentialFromResult(result)
                    const accessToken = credential.accessToken
                    const idToken = credential.idToken

                    this.isSocialRegister = true

                    this.setUserRegister(user, result.providerId)
                })
                .catch((error) => {
                    const errorMessage = error.message
                    this.errorMessage = errorMessage

                    console.error(error)
                })
        },

        facebookRegister() {
            signInWithPopup(this.$firebase.auth, this.$firebase.facebookProvider)
                .then((result) => {
                    // The signed-in user info.
                    const { user } = result

                    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                    const credential = FacebookAuthProvider.credentialFromResult(result)
                    const accessToken = credential.accessToken

                    this.isSocialRegister = true

                    this.setUserRegister(user, result.providerId)
                })
                .catch((error) => {
                    const errorMessage = error.message
                    this.errorMessage = errorMessage
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.loader {
    font-size: 20px;
    margin: 20px 0;
    align-self: center;
}

.screen-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    width: 400px;
    position: relative;
    padding: 24px 40px 10px;
    box-shadow: 0px 3px 6px #00000029;
}

.guide-message {
    font-weight: 400;
    margin-bottom: 15px;
    color: #34495e;
    font-weight: 500;
    text-align: center;
    padding: 0 3px;
    line-height: 1.35rem;
}

.error-message {
    color: firebrick;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
    padding: 0 10px;
}

p {
    font-weight: 500;
}

.top-divider {
    padding-top: 18px;
    border-top: 1px solid #aaa;
}

.facebook-button {
    background-color: $facebook-blue;
}

.google-button {
    background-color: $google-red;
}

.apple-button {
    background-color: #000;
    color: #fff;
}

button {
    background-color: #3498db;
    color: white;
    border-radius: 4px;
    padding: 10px 8px 8px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bolder;
    border: none;
    margin: 0 12px;
}

button:hover {
    cursor: pointer;
}

.social-login-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $light-grey;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    margin: 24px 0 24px;
    letter-spacing: 0.7px;

    hr {
        width: 25px;
        margin: 0 12px 0 0;
        border-color: $lighter-grey;

        &:last-child {
            margin: 0 0 0 12px;
        }
    }
}

.radio-title {
    position: relative;
    top: 2px;
    left: -4px;
    padding: 12px 4px 10px;
    background-color: transparent;
    color: $dark-grey;
    font-size: 13px;
    width: max-content;
    font-weight: 500;
}

.radio-label {
    margin: 0 20px 0 8px;
}

.btn-group {
    @include flex-between-center();
    align-self: center;
    margin-bottom: 1em;

    .external-button {
        width: 50px;
        height: 50px;
        font-size: 1.8rem;
        text-align: center;
    }
}

.terms {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5em;

    & input[type='checkbox'] {
        width: 20px !important;
        margin-right: 10px;
        position: relative;
        top: 2px;
    }

    & label {
        width: 100%;
        font-weight: normal;
        font-size: 14px;
        margin-top: 5px;
    }
}

.stacked-input {
    width: 100%;
}

.basic-button {
    margin: 1.5em 0 0;
    width: 100%;

    &:last-child {
        margin: 8px 0 1.5em 0;
    }
}

a {
    font-style: inherit;
    font-size: inherit;
    margin-bottom: 30px;
    cursor: pointer;
    color: $btn-blue !important;
    font-weight: 500;
    align-self: center;
}

@media screen and (max-width: 480px) {
    .screen-container {
        width: 100%;
    }

    .content {
        padding: 0 3%;
    }

    .logo {
        margin: 30px auto 15px;
    }
}
</style>
