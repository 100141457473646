<template>
    <transition
        appear
        @before-enter="beforeEnter"
        @enter="enter"
        @leave="leave"
        :css="false">
        <slot></slot>
    </transition>
</template>

<script>
export default {
    name: 'ModalTransition',
    props: {
        transform: {
            type: Object,
            default: function (){
                return {
                    translateX: 0, 
                    translateY: 0
                }
            }
        },
        duration: {
            type: Number,
            default: 300
        }
    },
    methods: {
        beforeEnter(el) {
            el.style.opacity = 0
            el.style.position = 'fixed'
        },

        enter(el, done) {
            Velocity(el, 
                { opacity: 1 , translateY: [0, this.transform.translateY], translateX: [0 ,this.transform.translateX] },
                { duration: this.duration },
                { complete: done })
        },

        leave(el, done) {
            Velocity(el, 
                { opacity: 0, translateY: [this.transform.translateY, 0], translateX: [this.transform.translateX, 0]},
                { duration: this.duration },
                { complete: done })
        }
    },
}
</script>