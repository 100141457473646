<template>
    <div class="badge" :style="{ backgroundColor: color }">
        <span>{{ label }}</span>
    </div>
</template>

<script>
export default {
    name: 'Badge',
    props: {
        color: {
            type: String,
            default: '#028FFF',
        },
        label: String,
    },
}
</script>

<style lang="scss" scoped>
.badge {
    display: inline-block;
    margin-left: 10px;
    height: 14px;
    line-height: 14px;
    padding: 1px 8px;
    border-radius: 7px;
    text-transform: uppercase;
    font-size: 8px;
    font-weight: 500;
    width: max-content;
    color: white;
}
</style>
