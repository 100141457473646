<template>
    <v-slide-y-reverse-transition>
        <div v-if="open" class="flex center confirmation-modal" :style="{ width: modal.error ? '570px' : 'unset' }">
            <div v-show="!modal.error" class="delete-modal flex column">
                <header>
                    <button class="close" type="button" @click.stop="closeModal('cancel')">
                        <ion-icon name="close"></ion-icon>
                    </button>
                </header>

                <div class="flex center column">
                    <div class="text">
                        {{ text }}?<strong>{{ strongText }}</strong>
                    </div>

                    <!-- OPÇÕES DE EXCLUSÃO DE DOCUMENTOS-->
                    <div v-if="!isEdit && !isGeneric" class="btn-container flex column center">
                        <basic-button
                            :label="recurrent ? 'Excluir apenas esta' : 'Excluir'"
                            v-if="!data._installment"
                            class="gutter"
                            width="100%"
                            :loading="disabled"
                            data-cy="modal-confirm-delete"
                            @click.native="_delete(false)"
                        />

                        <basic-button
                            :label="data._installment ? 'excluir parcelas' : 'esta e as próximas'"
                            :class="{ gutter: data._installment }"
                            v-if="recurrent"
                            :reverse="!data._installment"
                            width="100%"
                            :loading="disabled"
                            @click.native="_delete(true)"
                            data-cy="modal-confirm-delete"
                        />

                        <basic-button
                            label="Cancelar"
                            v-if="data._installment || !recurrent"
                            :reverse="true"
                            width="100%"
                            :disabled="disabled"
                            @click.native="closeModal('cancel')"
                            data-cy="modal-confirm-cancel"
                        />
                    </div>

                    <!-- OPÇÕES DE EDIÇÃO DE DOCUMENTOS-->
                    <div v-else-if="!isGeneric" class="btn-container flex column center">
                        <basic-button
                            :label="recurrent ? 'Editar apenas esta' : 'Editar'"
                            class="gutter"
                            width="100%"
                            :loading="disabled"
                            @click.native="editTransaction(false)"
                        />

                        <basic-button
                            label="Cancelar"
                            v-if="!recurrent"
                            :reverse="true"
                            width="100%"
                            :disabled="disabled"
                            @click.native="closeModal('cancel')"
                        />

                        <basic-button
                            label="Editar esta e as próximas"
                            v-else
                            :reverse="true"
                            width="100%"
                            :loading="disabled"
                            @click.native="editTransaction(true)"
                        />
                    </div>

                    <!-- MODAL SÓ COM BOTÃO DE CANCELAR -->
                    <div v-else class="btn-container flex column center">
                        <slot name="generic-btn"></slot>

                        <basic-button
                            style="margin-top: 8px"
                            label="Cancelar"
                            v-if="!recurrent"
                            :reverse="true"
                            width="100%"
                            :disabled="disabled"
                            @click.native="closeModal('cancel')"
                        />
                    </div>
                </div>
            </div>

            <div class="overlay" v-show="modal.error">
                <modal-transition :duration="200">
                    <error-modal v-bind="modal" @confirmEvent="closeModal">
                        <basic-button :bgColor="'#ff113a'" label="Fechar" @click.native="closeModal" />
                    </error-modal>
                </modal-transition>
            </div>
        </div>
    </v-slide-y-reverse-transition>
</template>

<script>
import { mapState } from 'vuex'
import _request from '@/common/request'
import BasicButton from '../../buttons/BasicButton.vue'
import ModalTransition from '../../transitions/ModalTransition.vue'
import ErrorModal from './ConfirmationCreateModal.vue'

export default {
    components: { BasicButton, ModalTransition, ErrorModal },

    name: 'ConfirmationModal',

    props: {
        data: {
            type: Object,
            default: () => {},
        },
        text: String,
        strongText: {
            type: String,
            default: '',
        },
        recurrent: {
            type: Boolean,
            default: false,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        isGeneric: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            modal: {
                error: false,
                text: '',
                title: '',
            },

            open: false,
        }
    },

    mounted() {
        this.open = true
    },

    computed: {
        ...mapState(['_date_selected', 'transactions', 'disabled']),

        modalData() {
            const { collection } = this.data
            const start = `Não foi possível ${this.isEdit ? 'editar' : 'excluir'}`
            const title = `${this.isEdit ? 'editar' : 'excluir'}`

            switch (collection) {
                case 'transactions':
                    return { title: `${title} movimentação`, text: `${start} essa(s) movimentação(ões)` }
                case 'objetives':
                    return { title: `${title} objetivo`, text: `${start} esse objetivo` }
                case 'payments':
                    return { title: `${title} pagamento`, text: `${start} esse pagamento` }
                default:
                    return { title: ``, text: `` }
            }
        },
    },

    methods: {
        closeModal(action) {
            this.$emit('closeModalEvent', action)
        },

        _delete(applyForAll) {
            let { _paid, _paymentId, _bankOpPayId, _value, collection, isBankAccess, ...mutationParams } = this.data

            this.$store.commit('DISABLED')

            const loadAllPayments = applyForAll || _bankOpPayId

            _request
                .remove({
                    collection,
                    _id: mutationParams._id,
                    sub_id: mutationParams.sub_id, // Quando é transação de uma fatura
                    data: this.data,
                    queryParams: applyForAll ? '?applyForAll=true' : '', // Quando é uma transação recorrente
                })
                .then(async () => {
                    if (loadAllPayments) await _request.fetch([{ collection: 'payments' }])
                    else if (_paid) await _request.getById({ collection: 'payments', _id: _paymentId })

                    if (mutationParams.sub_id || isBankAccess || collection === 'payments') {
                        await _request.fetch([
                            { collection: 'invoices', queryParams: this._date_selected },
                            { collection: 'transactions', queryParams: this._date_selected },
                        ])
                        if (isBankAccess || collection === 'payments')
                            await _request.fetch([{ collection: 'payments' }])
                    }

                    this.attView()
                })
                .catch((e) => {
                    console.error(e)
                    this.modal = Object.assign(this.modal, this.modalData)
                    this.modal.error = true
                })
                .finally(() => this.$store.commit('DISABLED'))
        },

        editTransaction(applyForAll) {
            let { _paid, _paymentId, _bankOpPayId, _value, collection, ...mutationParams } = this.data

            this.$store.commit('DISABLED')

            _request
                .patch({
                    collection,
                    data: this.data.data,
                    _id: this.data._id,
                    sub_id: this.data.sub_id,
                    queryParams: applyForAll ? '?applyForAll=true' : '',
                })
                .then(async () => {
                    if (applyForAll) await _request.fetch([{ collection: 'payments' }])
                    else if (_paid) await _request.getById({ collection: 'payments', _id: _paymentId })
                    if (mutationParams.sub_id) {
                        await _request.fetch([{ collection: 'invoices', queryParams: this._date_selected }])
                        await _request.fetch([{ collection: 'transactions', queryParams: this._date_selected }])
                    }

                    this.attView()
                })
                .catch((e) => {
                    console.error(e)
                    this.modal = Object.assign(this.modal, this.modalData)
                    this.modal.error = true
                })
                .finally(() => this.$store.commit('DISABLED'))
        },

        attView() {
            this.$store.commit('DISABLED')

            _request
                .fetch([
                    { collection: 'balances', queryParams: this._date_selected },
                    { collection: 'balancesChart', queryParams: this._date_selected },
                ])
                .then(() => this.$root.$emit('attBalancosMensais')) // Evento escutado por Transactions.vue
                .finally(() => {
                    this.$root.$emit('closeTransactionsModal') // Evento escutado por App.vue
                    this.$store.commit('DISABLED')
                    this.closeModal()
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.confirmation-modal {
    .delete-modal {
        width: 400px;
        min-height: 280px;
        padding: 0 24px;
        border-radius: 8px;
        background-color: white;
        overflow: hidden;
        position: relative;

        .text {
            margin-bottom: 32px;
            color: $darker-blue;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
        }

        .close {
            position: absolute;
            padding: unset;
            top: 24px;
            right: 24px;
            font-size: 22px;
            color: #9fa5b1;
        }

        header {
            height: 70px;
        }

        .btn-container {
            margin-bottom: 20px;
            width: 100%;

            .gutter {
                margin-bottom: 8px;
            }

            .excluir {
                background: $brand-blue !important;
                color: white !important;
            }

            .cancelar {
                background-color: #eef1f6 !important;
                color: #9fa5b1 !important;
            }
        }
    }
}

.flex {
    display: flex;
}

.center {
    align-items: center;
}

.space-between {
    justify-content: space-between;
}

.column {
    flex-direction: column;
}

.grow-1 {
    flex-grow: 1;
}
</style>
