<template>
    <div class="invoice-payment">
        <div class="options" :style="{ right: isCalendar ? '8px' : '-2px' }">
            <a
                v-if="!transaction.externalId"
                @click="_openModal"
                class="icon ion-md-checkmark"
                :class="{ paid: transaction.paid }"
            >
                <ion-icon name="checkmark-outline"></ion-icon>
            </a>

            <router-link :to="/movimentacoes/ + creditCardId" :style="{ 'margin-left': isCalendar ? '22px' : '38px' }">
                <show-invoice
                    :class="{
                        extraMargin: transaction.externalId != undefined,
                    }"
                />
            </router-link>

            <pop-up-msg
                v-if="permissioDenied"
                msg="NÃO É POSSÍVEL PAGAR ESTA FATURA!"
                position="52px -70px"
                @onClose="permissioDenied = false"
            />
        </div>

        <div class="overlay" v-if="openModal">
            <modal-transition :show="openModal">
                <div class="modal">
                    <!-- Formulário -->
                    <div v-show="!sucess && !pagamentoStatus" class="header">
                        <h2>PAGAMENTO DE FATURA</h2>

                        <button class="close" type="button" @click.stop="openModal = false">
                            <ion-icon name="close"></ion-icon>
                        </button>
                    </div>

                    <div v-show="!sucess && !pagamentoStatus">
                        <stacked-select
                            label="Pagar com"
                            :model="payments"
                            :isGroupSelect="true"
                            :previousSelected="transaction.payment._id"
                            :notTransaction="true"
                            @change="(value) => (pagamento.payment = value)"
                        />

                        <stacked-input
                            label="Valor do pagamento"
                            :value="pagamento.value"
                            :money="money"
                            textAlign="right"
                            @input="(value) => (pagamento.value = value)"
                        />

                        <div class="btn-group">
                            <basic-button
                                label="Cancelar"
                                :reverse="true"
                                :width="205"
                                @click.native="openModal = false"
                            />

                            <basic-button label="Pagar" :loading="disabled" :width="205" @click.native="validate" />
                        </div>
                    </div>

                    <!-- Resposta da requisição -->
                    <div class="sucess" v-show="sucess && !pagamentoStatus">
                        <i v-if="!hasErr" class="far fa-check-circle"></i>
                        <i v-else class="far fa-times-circle"></i>

                        <p>{{ message }}</p>

                        <div class="btn-group">
                            <basic-button label="Fechar" :width="250" @click.native="openModal = false" />
                        </div>
                    </div>

                    <!-- Alerta de pagamento -->
                    <div class="sucess" v-show="pagamentoStatus && !sucess">
                        <ion-icon name="alert-circle-outline"></ion-icon>

                        <p>{{ message }}</p>

                        <div class="btn-group" style="width: 100%">
                            <basic-button
                                label="Cancelar"
                                :reverse="true"
                                :width="205"
                                @click.native="openModal = false"
                            />

                            <basic-button
                                label="Ok"
                                :loading="disabled"
                                :width="205"
                                @click.native="computeInvoicePayment"
                            />
                        </div>
                    </div>
                </div>
            </modal-transition>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import request from '@/common/request'
import util from '@/common/util'
import StackedInput from '../inputs/StackedInput'
import StackedSelect from '../inputs/StackedSelect'
import ModalTransition from '../transitions/ModalTransition'
import { formatNumber } from '../../common/util'
import BasicButton from '../buttons/BasicButton.vue'
import ShowInvoice from '../icons/ShowInvoice.vue'
import PopUpMsg from '../PopUpMsg.vue'

const initialState = {
    money: {
        decimal: ',',
        thousands: '.',
        prefix: util.returnCurrencyPrefix().prefix + ' ',
        precision: 2,
        masked: false,
    },
    openModal: false,
    sucess: false,
    hasErr: false,
    message: '',
    pagamento: {},
    initialValue: 0,
    pagamentoStatus: '',

    permissioDenied: false,
}

export default {
    name: 'InvoicePayment',

    components: {
        StackedInput,
        StackedSelect,
        ModalTransition,
        BasicButton,
        ShowInvoice,
        PopUpMsg,
    },

    props: {
        transaction: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            ...initialState,
        }
    },

    computed: {
        ...mapState(['_date_selected', 'disabled', 'user']),

        ...mapGetters(['findById', 'groupedPayments', 'getCreditCardInvoiceByID', 'invoicePaidValue']),

        creditCardId() {
            return this.findById('invoices', this.transaction.invoiceReferenceId).creditCardId
        },

        payments() {
            return this.groupedPayments('despesa').slice(0, 2)
        },

        isCalendar() {
            return this.$route.name === 'calendar'
        },
    },

    methods: {
        _openModal() {
            if (this.transaction.user._id === this.user._id) this.openModal = true
            else this.permissioDenied = true
        },

        /** Retorno o status do pagamento da fatura.
         *
         * ``overpaid``: quando o valor é maior que o valor necessário a pagar
         * ``underpaid``: quando o valor é menos que o valor necessário a pagar
         * @return uma string apontando o status do valor a utilizado como pagamento
         */
        valueStatus() {
            const [_value, invoiceValue] = [
                util.sanitizeMoney(this.pagamento.value),
                util.sanitizeMoney(this.initialValue),
            ]
            let status = ''

            if (_value > invoiceValue) status = 'overpaid'
            if (_value < invoiceValue) status = 'underpaid'

            this.pagamentoStatus = status

            return status
        },

        invoiceTransactionsSum(invoiceTransaction, diff) {
            if (diff !== invoiceTransaction.invoiceBalance && diff <= 0.01) return diff
            else if (diff > 0.01) return 0
            return invoiceTransaction.invoiceBalance
        },

        setPaymentObject() {
            const paidValue = this.invoicePaidValue(this.transaction.invoiceReferenceId)
            const diffInvoicePaidValue = paidValue
                ? paidValue + this.transaction.value + this.transaction.invoiceBalance
                : this.transaction.invoiceBalance

            if (this.transaction.paid && diffInvoicePaidValue >= 0) {
                this.message = 'Sua fatura já está paga!'
                this.sucess = true
            }

            this.pagamento.payment = this.transaction.payment._id
            this.pagamento.value = this.initialValue = formatNumber(
                Math.abs(this.invoiceTransactionsSum(this.transaction, diffInvoicePaidValue))
            )
        },

        validate() {
            const status = this.valueStatus()

            if (status) {
                const [firstSubt, secondSubt, thirdSubt] =
                    this.pagamentoStatus === 'overpaid'
                        ? ['maior', 'adicional', 'crédito']
                        : ['menor', 'residual', 'débito']

                this.message = `Você está pagando um valor ${firstSubt} do que o total da fatura. O valor ${secondSubt} será lançado como um ${thirdSubt} na fatura do mês seguinte.`
                return
            }

            this.computeInvoicePayment()
        },

        async computeInvoicePayment() {
            try {
                let value = Number(util.sanitizeMoney(this.pagamento.value))
                const creditCard = this.getCreditCardInvoiceByID(this.transaction.invoiceReferenceId)
                let paymentDate = new Date(this.transaction.date)
                let month = paymentDate.getMonth() - 1
                paymentDate.setMonth(month)

                let _data = {
                    name: 'Pagamento da fatura',
                    paid: true,
                    paymentInvoiceReferenceId: this.pagamento.payment,
                    category: this.transaction.category,
                    date: paymentDate,
                    transactionType: 'revenues',
                    payment: creditCard._id,
                    user: this.transaction.user,
                    value: value,
                }

                this.$store.commit('DISABLED')

                await request.post({ collection: 'invoices', data: _data })
                await request.fetch([{ collection: 'invoices', queryParams: this._date_selected }])
                await request.fetch([{ collection: 'transactions', _id: this.transaction._id, byId: true }])
                this.$emit('attBalancos')
                this.message = 'Fatura paga com sucesso!'
            } catch (e) {
                this.message = 'Ocorreu um erro tente novamente'
                this.hasErr = true
            } finally {
                this.pagamentoStatus = ''
                this.sucess = true
                this.$store.commit('DISABLED')
            }
        },
    },

    watch: {
        openModal: {
            immediate: true,
            handler() {
                if (this.openModal) {
                    this.setPaymentObject()
                    return
                }

                Object.assign(this, initialState)
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.invoice-payment {
    position: absolute;
    height: 100%;
    top: 20px;

    .options {
        display: flex;
        position: relative;

        .icon-placeholder {
            width: 24px;
        }

        .show-invoice {
            margin-left: -7px;

            &.extraMargin {
                margin-left: -18px;
            }
        }

        .fas,
        .icon {
            display: inline-block;
            padding: 6px;
            border-radius: 100%;
            color: $inactive-icon;
            font-size: 18px;
            text-align: center;

            &:hover {
                background-color: $chat-bg;
                color: $active-icon;
            }

            &.paid {
                color: $expense-red;
            }
        }

        .icon {
            height: 24px;
            width: 24px;
            font-size: 22px;
            padding: 0;
        }
    }

    .modal {
        position: fixed;
        padding: 24px;
        padding-top: 18px;
        border-radius: 8px;
        width: 500px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);

        h2 {
            font-weight: 600;
            color: $darker-blue;
            font-size: 18px;
        }

        .close {
            position: absolute;
            top: 0;
            right: 0;
            padding: 14px 16px;

            ion-icon {
                font-size: 22px;
                color: $medium-grey;
            }
        }

        .header {
            text-align: center;
            margin-bottom: 32px;
        }

        .btn-group {
            display: flex;
            margin-top: 30px;
            justify-content: space-between;
        }

        .sucess {
            color: $light-black;
            font-weight: 500;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 18px;
            letter-spacing: 0;
            text-align: center;

            .far,
            ion-icon {
                font-size: 69px;
                margin: 24px;
                color: #2dc785;

                &.fa-times-circle {
                    color: firebrick;
                }
            }

            ion-icon {
                color: firebrick;
            }

            .btn {
                margin: 0 !important;
            }
        }
    }
}
</style>
